import {
  Avatar, Box, Flex, HStack, Heading, Stack, StackProps, Text,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { MdPerson } from "react-icons/md";
import Linkify from "./Linkify";

export type InfoEntry = { label: string; value: string | React.ReactNode; linkTo?: string };

export function InfoField({ label, value, linkTo }: InfoEntry) {
  return (
    <Flex alignItems="flex-start" direction="column">
      <Text fontSize="xs" color="fg.muted">
        {label}
      </Text>
      {typeof value === "string" ? (
        <Linkify linkTo={linkTo}>
          <Text fontSize="sm" fontWeight="medium" color="chakra-body-text">
            {value}
          </Text>
        </Linkify>
      ) : (
        <Linkify linkTo={linkTo}>{value}</Linkify>
      )}
    </Flex>
  );
}

InfoField.defaultProps = {
  linkTo: undefined,
};

export function InfoSpacer() {
  return (
    <Flex direction="column">
      <Box>&nbsp;</Box>
      <Box>&nbsp;</Box>
    </Flex>
  );
}

interface InfoFieldOrSpacerProps {
  fieldInfo: InfoEntry | undefined;
}

export function InfoFieldOrSpacer({ fieldInfo }: InfoFieldOrSpacerProps) {
  return fieldInfo ? <InfoField {...fieldInfo} /> : <InfoSpacer />;
}

export function SectionInfo({ title, children, ...rest }: { title: string } & PropsWithChildren & StackProps) {
  return (
    <Stack {...rest}>
      <Heading color="fg.default" size="xxs">
        {title}
      </Heading>
      {children}
    </Stack>
  );
}

interface AvatarInfo {
  src?: string | undefined;
  name?: string | undefined;
}

interface InfoFieldProfileProps extends InfoFieldOrSpacerProps {
  avatarInfo: AvatarInfo;
  linkTo?: string;
}

export function InfoFieldProfile({
  fieldInfo, avatarInfo, linkTo, ...rest
}: InfoFieldProfileProps) {
  return (
    <HStack spacing="3" {...rest}>
      <Linkify linkTo={linkTo}>
        <Avatar icon={<MdPerson fontSize="1.5rem" />} size="sm" color="brand.700" bg="brand.300" {...avatarInfo} />
      </Linkify>
      <InfoFieldOrSpacer fieldInfo={fieldInfo} />
    </HStack>
  );
}

InfoFieldProfile.defaultProps = {
  linkTo: undefined,
};
