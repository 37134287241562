// eslint-disable-next-line object-curly-newline
import { FaClipboardList } from "react-icons/fa";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  SimpleGrid,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";
import LoadingCard from "components/Card/LoadingCard";
import PathTemplateCard from "components/Card/PathTemplateCard";
import { QUERY_KEYS } from "definitions/constants";
import { useQuery } from "@tanstack/react-query";
import { PEOPLE_TEAM_ROUTES, API_ROUTES } from "definitions/constants/routeConstants";
import { TemplatePathSummary } from "models/automation/templateWorkflow";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

function TemplateWorkflowCards({ data, isLoading }: { data: TemplatePathSummary[] | undefined; isLoading: boolean }) {
  if (isLoading) return <LoadingCard />;

  if (data && data.length > 0) {
    return data.map((template) => (
      <PathTemplateCard
        minH="232px"
        key={template.id}
        id={template.id}
        name={template.name}
        description={template.description || ""}
        emoji={template.emoji || ""}
        destination={generatePath(PEOPLE_TEAM_ROUTES.templatePaths.show, { id: template.id })}
      />
    ));
  }
}

export default function PathTemplatesModalButton() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);

  const { data: templateWorkflows, isLoading: templateWorkflowsLoading } = useQuery<TemplatePathSummary[]>({
    networkMode: "always",
    queryKey: [QUERY_KEYS.templatePaths],
    queryFn: () => apiClient.get(API_ROUTES.templatePaths.base),
  });

  return (
    <>
      <Tooltip label="Templates can be used as a starting point for creating new paths.">
        <Button variant="camino-secondary" onClick={onOpen} rightIcon={<FaClipboardList />}>
          View Templates
        </Button>
      </Tooltip>

      <Modal initialFocusRef={initialRef} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent minWidth="1200px">
          <ModalHeader fontSize={30}>Pre-built Paths</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <SimpleGrid pb={4} overflowY="scroll" minChildWidth="280px" spacing="40px" minWidth="1100px" mt={2}>
              {TemplateWorkflowCards({ data: templateWorkflows, isLoading: templateWorkflowsLoading })}
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
