/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Button, ButtonProps, CreateToastFnReturn, PopoverTrigger, useToast,
} from "@chakra-ui/react";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import LinkButton from "components/Button/LinkButton";
import ModalFormWrapper from "components/ModalForm/ModalFormWrapper";
import {
  API_ROUTES,
  PEOPLE_TEAM_ROUTES,
  SHARED_COMPANY_ROUTES,
  SHARED_ROUTES,
} from "definitions/constants/routeConstants";
import { generatePath } from "react-router-dom";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { titleize } from "helpers/string";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { QUERY_KEYS, TARGET_TYPE } from "definitions/constants";
import { setTimeFromOriginalDateTime } from "helpers/time";
import moment from "moment";
import { useQueryClient } from "@tanstack/react-query";
import DateSelectForm from "components/Forms/DateSelectForm";
import OauthButton from "components/OauthButton";
import SendMessageButton from "components/SendMessageButton";
import useCaminoStore from "hooks/useCaminoStore";
import CompanyUpdateForm from "components/Forms/CompanyUpdateForm";
import IssuesUserForm from "../Forms/IssuesUserForm";

type VariableType =
  | "recipient"
  | "assignee"
  | "new_hire"
  | "manager"
  | "buddy"
  | "people_team_contact"
  | "company"
  | "new_hire_journey";

const CONFIRM_SEND_NOW = {
  header: "CONFIRM sending the action now!",
  body: "This cannot be undone! Be sure you want to send this action now.",
  confirmButtonText: "Confirm Send Now",
};

const CONFIRM_SEND_TOMORROW = {
  header: "CONFIRM you want to send this action TOMORROW",
  body: "This will send this action tomorrow at 8am.",
  confirmButtonText: "Confirm Send Tomorrow",
};

const CONFIRM_SEND_NEXT_MONDAY = {
  header: "CONFIRM you want to send this action Next Monday",
  body: "This will send this action next Monday at 8am.",
  confirmButtonText: "Confirm Send Next Monday",
};

const CONFIRM_SKIP_ACTION = {
  header: "CONFIRM you want to SKIP this action",
  body: "This action will not be sent.",
  confirmButtonText: "Confirm Skip",
};

const CONFIRM_SYNC_USERS = {
  header: "CONFIRM you want to SYNC Slack Users with Camino",
  body: "This will search slack for any users in Camino that do not have a slack ID.",
  confirmButtonText: "Confirm Sync Users",
};

const CONFIRM_SYNC_CHANNELS = {
  header: "CONFIRM you want to SYNC Slack Channels with Camino",
  body: "This will search slack for any channels that have Camino added to them, and the channel information into Camino.",
  confirmButtonText: "Confirm Sync Channels",
};

interface IssueResolutionButtonBaseProps extends ButtonProps {
  action: AssignedAction;
}

interface IssueResolutionOptions {
  variableType: VariableType;
  buttonText: string;
  title: string;
  fields: unknown[];
}

interface IssueResolutionButtonProps extends ActionResolutionButtonProps {
  options?: IssueResolutionOptions;
}

export function SendNowButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const { performRequest } = useDeferredApiCall(
    generatePath(API_ROUTES.assignedActions.forceSend, { id: action?.id || "" }),
    {
      method: "POST",
      onSuccess: (_first, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, action.scheduledWorkflowId] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneyActions, action.onboardingJourney?.id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, "issues"] });
        toast({ title: "Successfully sent", status: "success" });
      },
      onFailure: (_second, _third, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );
  return (
    <ButtonWithConfirm handleConfirm={() => performRequest()} intent="confirmation" {...CONFIRM_SEND_NOW}>
      <Button variant="outline" width="fit-content" {...rest}>
        Send Now
      </Button>
    </ButtonWithConfirm>
  );
}

export function SendTomorrowButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const { performRequest } = useDeferredApiCall(
    generatePath(API_ROUTES.assignedActions.show, { id: action?.id || "" }),
    {
      method: "PUT",
      onSuccess: (_first, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, action.scheduledWorkflowId] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneyActions, action.onboardingJourney?.id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, "issues"] });
        toast({ title: "Successfully scheduled to tomorrow", status: "success" });
      },
      onFailure: (_second, _third, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );
  const scheduledDate = action.trigger.type === "date_time" ? action.trigger.data.datetime : undefined;
  const tomorrow = setTimeFromOriginalDateTime(moment().startOf("day").add(1, "days").toDate(), scheduledDate);
  return (
    <ButtonWithConfirm
      handleConfirm={() => performRequest({ trigger: { data: { datetime: tomorrow } } })}
      intent="confirmation"
      {...CONFIRM_SEND_TOMORROW}
    >
      <Button variant="outline" width="fit-content" {...rest}>
        Send Tomorrow
      </Button>
    </ButtonWithConfirm>
  );
}

export function SendNextMondayButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const { performRequest } = useDeferredApiCall(
    generatePath(API_ROUTES.assignedActions.show, { id: action?.id || "" }),
    {
      method: "PUT",
      onSuccess: (_first, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, action.scheduledWorkflowId] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneyActions, action.onboardingJourney?.id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, "issues"] });
        toast({ title: "Successfully scheduled to tomorrow", status: "success" });
      },
      onFailure: (_second, _third, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );
  const scheduledDate = action.trigger.type === "date_time" ? action.trigger.data.datetime : undefined;
  const nextMonday = setTimeFromOriginalDateTime(moment().startOf("isoWeek").add(1, "week").toDate(), scheduledDate);
  return (
    <ButtonWithConfirm
      handleConfirm={() => performRequest({ trigger: { data: { datetime: nextMonday } } })}
      intent="confirmation"
      {...CONFIRM_SEND_NEXT_MONDAY}
    >
      <Button variant="outline" width="fit-content" {...rest}>
        Send Next Monday
      </Button>
    </ButtonWithConfirm>
  );
}

export function EditSendDateButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
  });
  return (
    <DateSelectForm
      name="trigger.data.datetime"
      label=""
      submitType="PUT"
      isDisabled={action?.status === "processed"}
      initialValue={action?.trigger?.data?.datetime}
      minDate={moment().subtract(1, "days").toDate()}
      preserveTime
      submitUrl={generatePath(API_ROUTES.assignedActions.show, { id: action?.id || "" })}
      width="fit-content"
      onSuccess={() => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, action.scheduledWorkflowId] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneyActions, action.onboardingJourney?.id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, "issues"] });
        toast({ title: "Send date updated", status: "success" });
      }}
    >
      <PopoverTrigger>
        <Button variant="outline" width="fit-content" mt={-1.5} {...rest}>
          Custom Send Date
        </Button>
      </PopoverTrigger>
    </DateSelectForm>
  );
}

export function SkipButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const { performRequest } = useDeferredApiCall(
    generatePath(API_ROUTES.assignedActions.pause, { id: action?.id || "" }),
    {
      method: "POST",
      onSuccess: (_first, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, action.scheduledWorkflowId] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneyActions, action.onboardingJourney?.id] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, "issues"] });
        toast({ title: `Successfully ${action.status === "skipped" ? "Unskipped" : "Skipped"}`, status: "success" });
      },
      onFailure: (_second, _third, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );
  return (
    <ButtonWithConfirm handleConfirm={() => performRequest()} intent="confirmation" {...CONFIRM_SKIP_ACTION}>
      <Button variant="outline" width="fit-content" {...rest}>
        {action.status === "skipped" ? "Unskip" : "Skip"}
      </Button>
    </ButtonWithConfirm>
  );
}

export function ReSyncSlackUsersButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const toast = useToast({
    isClosable: true,
    duration: 9000,
    position: "top",
  });
  const { performRequest } = useDeferredApiCall(generatePath(API_ROUTES.messageServices.users.sync), {
    method: "POST",
    onSuccess: (_first, queryClient) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, action.scheduledWorkflowId] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneyActions, action.onboardingJourney?.id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, "issues"] });
    },
    onFailure: (_second, _third, failureToast: CreateToastFnReturn) => {
      failureToast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return (
    <ButtonWithConfirm
      handleConfirm={() => {
        const request = performRequest();
        toast.promise(request, {
          success: { title: "Users synced!", description: "Users were successfully synced" },
          error: { title: "User sync error", description: "Something went wrong with syncing the users" },
          loading: { title: "Syncing users", description: "Please wait while we sync Camino users with Slack" },
        });
      }}
      intent="confirmation"
      {...CONFIRM_SYNC_USERS}
    >
      <Button variant="outline" width="fit-content" {...rest}>
        Resync Slack Users
      </Button>
    </ButtonWithConfirm>
  );
}

export function ReSyncSlackChannelsButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const { performRequest } = useDeferredApiCall(generatePath(API_ROUTES.messageServices.channels.sync), {
    method: "POST",
    onSuccess: (_first, queryClient, toast) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, action.scheduledWorkflowId] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneyActions, action.onboardingJourney?.id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions, "issues"] });
      toast({ title: "Successfully resynced Slack users with Camino", status: "success" });
    },
    onFailure: (_second, _third, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
  return (
    <ButtonWithConfirm handleConfirm={() => performRequest()} intent="confirmation" {...CONFIRM_SYNC_CHANNELS}>
      <Button variant="outline" width="fit-content" {...rest}>
        Resync Slack Channels
      </Button>
    </ButtonWithConfirm>
  );
}

export function ConnectSlackButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  return (
    <LinkButton {...rest} to={generatePath(PEOPLE_TEAM_ROUTES.company.integrations.index)}>
      Connect Slack
    </LinkButton>
  );
}

interface VariableFormProps {
  action: AssignedAction;
  variableType: VariableType;
}

function getFormByVariableType({ action, variableType }: VariableFormProps) {
  switch (variableType) {
    case "recipient":
      return [
        IssuesUserForm,
        { userId: action?.targetableId },
        {
          title: "Update Recipient Info",
          buttonText: "Add Recipient Info",
          cardLink: generatePath(SHARED_ROUTES.showUser, { id: action?.targetableId || "" }),
        },
      ];
    case "assignee":
      return [
        IssuesUserForm,
        { userId: action?.targetableId },
        {
          title: "Update Assignee Info",
          buttonText: "Add Assignee Info",
          cardLink: generatePath(SHARED_ROUTES.showUser, { id: action?.targetableId || "" }),
        },
      ];
    case "new_hire":
      return [
        IssuesUserForm,
        { userId: action?.onboardingJourney?.userId },
        {
          title: "Update New Hire Info",
          buttonText: "Add New Hire Info",
          cardLink: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, {
            id: action?.onboardingJourney?.id || "",
          }),
        },
      ];
    case "manager":
      return [
        IssuesUserForm,
        { userId: action?.onboardingJourney?.user?.managerId },
        {
          title: "Update Manager Info",
          buttonText: "Add Manager Info",
          cardLink: generatePath(SHARED_ROUTES.showUser, { id: action?.onboardingJourney?.user?.managerId || "" }),
        },
      ];
    case "buddy":
      return [
        IssuesUserForm,
        { userId: action?.onboardingJourney?.buddyId },
        {
          title: "Update Buddy Info",
          buttonText: "Add Buddy Info",
          cardLink: generatePath(SHARED_ROUTES.showUser, { id: action?.onboardingJourney?.buddyId || "" }),
        },
      ];
    case "people_team_contact":
      return [
        IssuesUserForm,
        { userId: action?.onboardingJourney?.peopleTeamContactId },
        {
          title: "Update People Team Contact Info",
          buttonText: "Add People Team Contact Info",
          cardLink: generatePath(SHARED_ROUTES.showUser, {
            id: action?.onboardingJourney?.peopleTeamContactId || "",
          }),
        },
      ];
    case "company":
      return [
        CompanyUpdateForm,
        {},
        {
          title: "Update Company Info",
          buttonText: "Add Company Info",
          cardLink: generatePath(PEOPLE_TEAM_ROUTES.company.info),
        },
      ];
    case "new_hire_journey":
      return [
        IssuesUserForm,
        { userId: action?.onboardingJourney?.userId },
        {
          title: "Update New Hire Info",
          buttonText: "Add New Hire Info",
          cardLink: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, {
            id: action?.onboardingJourney?.id || "",
          }),
        },
      ];
    default:
      throw new Error(`Invalid Variable Type ${variableType}`);
  }
}

export function UpdateMissingInformationButton({ action, options = {}, ...rest }: IssueResolutionButtonProps) {
  const onSuccess = () => {
    console.log("Success");
  };
  const [FormComponent, formOptions, displayOptions] = getFormByVariableType({
    action,
    variableType: options.variableType,
  });
  const buttonText = options.buttonText || displayOptions.buttonText;
  const modalHeader = options.title || displayOptions.title;
  return (
    <ModalFormWrapper
      modalHeader={modalHeader}
      button={(
        <Button variant="outline" width="fit-content" {...rest}>
          {buttonText}
        </Button>
      )}
    >
      <FormComponent
        {...formOptions}
        cardLink={displayOptions.cardLink}
        title={titleize(options.variableType)}
        fields={options.fields}
        onSuccess={onSuccess}
      />
    </ModalFormWrapper>
  );
}

UpdateMissingInformationButton.defaultProps = {
  options: {},
};

export function RemindManagerButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const returnObject = user?.sendMessageAuthorized ? (
    <SendMessageButton
      targetId={action?.onboardingJourney?.user?.managerId}
      targetType={TARGET_TYPE.USER}
      defaultMessage=""
    >
      <Button variant="outline" width="fit-content" {...rest}>
        Remind Manager
      </Button>
    </SendMessageButton>
  ) : (
    <OauthButton>
      <Button variant="outline" width="fit-content" {...rest}>
        Remind Manager
      </Button>
    </OauthButton>
  );
  return returnObject;
}

export function RemindPeopleTeamContactButton({ action, ...rest }: IssueResolutionButtonBaseProps) {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const returnObject = user?.sendMessageAuthorized ? (
    <SendMessageButton
      targetId={action?.onboardingJourney?.manager?.id}
      targetType={TARGET_TYPE.USER}
      defaultMessage=""
    >
      <Button variant="outline" width="fit-content" {...rest}>
        Remind Manager
      </Button>
    </SendMessageButton>
  ) : (
    <OauthButton>
      <Button variant="outline" width="fit-content" {...rest}>
        Remind Manager
      </Button>
    </OauthButton>
  );
  return returnObject;
}

interface MissingNamespaceButtonProps extends ButtonProps {
  action: AssignedAction;
  options?: object;
}

export function AddMissingManagerButton({ action, options = {}, ...rest }: MissingNamespaceButtonProps) {
  return (
    <UpdateMissingInformationButton
      action={action}
      options={{
        variableType: "new_hire_journey",
        fields: ["manager_id"],
        title: "Add Manager",
        buttonText: "Add Manager",
        ...options,
      }}
      {...rest}
    />
  );
}

AddMissingManagerButton.defaultProps = {
  options: {},
};

export function AddMissingBuddyButton({ action, options = {}, ...rest }: MissingNamespaceButtonProps) {
  return (
    <UpdateMissingInformationButton
      action={action}
      options={{
        variableType: "new_hire_journey",
        fields: ["buddy_id"],
        title: "Add Buddy",
        buttonText: "Add Buddy",
      }}
      {...rest}
    />
  );
}

AddMissingBuddyButton.defaultProps = {
  options: {},
};

export function AddMissingPeopleTeamContactButton({ action, options = {}, ...rest }: MissingNamespaceButtonProps) {
  return (
    <UpdateMissingInformationButton
      action={action}
      options={{
        variableType: "new_hire_journey",
        fields: ["people_team_contact_id"],
        title: "Add People Team Contact",
        buttonText: "Add People Team Contact",
        ...options,
      }}
      {...rest}
    />
  );
}

AddMissingPeopleTeamContactButton.defaultProps = {
  options: {},
};
