import { useTheme } from "@chakra-ui/react";
import { GenericAsyncMultiSelect } from "components/Form/Select/GenericAsyncMultiSelect";
import { SelectFieldProps } from "components/Form/typings";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { titleize } from "helpers/string";
import { Path } from "models/automation";
import FormField from "../FormField";

export default function PathsSelectField({
  name,
  label,
  helperText,
  control,
  errors,
  required,
  variant,
  onChangeCallback,
  closeMenuOnSelect = false,
  ...props
}: SelectFieldProps<Path> & { closeMenuOnSelect?: boolean }) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
    >
      <GenericAsyncMultiSelect
        onChangeCallback={onChangeCallback}
        fetchPath={API_ROUTES.paths.base}
        extractValue={(v: Path) => v.id}
        embedOption={(v: Path) => ({
          value: v.id,
          label: `${titleize(v.name)}`,
          rawValue: v,
        })}
        includeDropdownIndicator
        closeMenuOnSelect={closeMenuOnSelect}
        isClearable={false}
        control={control}
        name={name}
        variant={formVariant}
        placeholder={null}
        containerStyles={{ width: "100%" }}
        {...props}
      />
    </FormField>
  );
}

PathsSelectField.defaultProps = {
  closeMenuOnSelect: false,
};
