import { useQuery } from "@tanstack/react-query";
import LoadingCard from "components/Card/LoadingCard";
import ProfileForm from "components/Forms/ProfileForm";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useCaminoStore from "hooks/useCaminoStore";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import { User } from "models/user";
import { useEffect } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export default function ProfilePage() {
  const id = useCaminoStore((state) => state.currentUser?.id);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const { data: user, isLoading } = useQuery<User>({
    queryKey: [QUERY_KEYS.users, id],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.users.show, { id })),
  });

  useEffect(() => {
    if (user && !isLoading) {
      setPageTitle("My Profile");
      setBreadcrumbs([
        {
          name: "My Profile",
          isCurrentPage: true,
        },
      ]);
    }
  }, [user, isLoading, setBreadcrumbs, setPageTitle]);

  return <AppPage>{user ? <ProfileForm user={user} /> : <LoadingCard />}</AppPage>;
}
