/* eslint-disable no-nested-ternary */
import { Button, ButtonProps } from "@chakra-ui/react";
import { IoPlay, IoStop } from "react-icons/io5";
import haveAutomationsStarted from "helpers/newHireJourney";
import { ExtendedNewHireJourney } from "models/joinedModels";

interface StartStopAutomationsButtonProps extends ButtonProps {
  newHireJourney: ExtendedNewHireJourney;
  onUpdate: (arg0: boolean) => void;
}

export default function StartStopAutomationsButton({
  newHireJourney,
  onUpdate,
  ...rest
}: StartStopAutomationsButtonProps) {
  const workflowsEnabled = newHireJourney.workflowsStatus === "enabled";
  return (
    <Button
      disabled={newHireJourney.workflowsStatus === "completed"}
      colorScheme="gray"
      onClick={() => onUpdate(workflowsEnabled)}
      rightIcon={workflowsEnabled ? <IoStop /> : <IoPlay />}
      {...rest}
    >
      {workflowsEnabled
        ? "Pause Automations"
        : haveAutomationsStarted(newHireJourney)
          ? "Resume Automations"
          : "Start Automations"}
    </Button>
  );
}
