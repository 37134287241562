import { Stack, ButtonGroup, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ResponseModel } from "services/ApiClient/responseModel";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { isNetworkingError } from "services/ApiClient/networkingError";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString, array as yupArray } from "yup";
import { Company } from "models/company";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import MultiSelectField from "components/FormElements/Fields/SelectFields/MultiSelectField";
import ProceedCancelButtons from "../Button/ProceedCancelButtons";

const simpleEmployeeForm = yupObject({
  firstName: yupString().label("First Name").required(),
  lastName: yupString().label("Last Name").required(),
  workEmail: yupString().label("Work Email").email().required(),
  roles: yupArray().label("Roles").required(),
  companyId: yupString().label("Company").required(),
});

const FIRST_NAME_FIELD = {
  type: "text",
  label: "First Name",
  name: "firstName",
};

const LAST_NAME_FIELD = {
  type: "text",
  label: "Last Name",
  name: "lastName",
};

const EMAIL_FIELD = {
  type: "email",
  label: "Work Email",
  name: "workEmail",
};

const COMPANY_FIELD = {
  type: "singleSelect",
  label: "Company",
  name: "companyId",
};

const ROLES_FIELD = {
  type: "multiSelect",
  label: "Roles",
  name: "roles",
  options: [
    { value: "onboarder", label: "Onboarder" },
    { value: "manager", label: "Manager" },
    { value: "peopleTeam", label: "People Team" },
    { value: "basic", label: "Basic Employee" },
    { value: "companyAdmin", label: "Company Admin" },
  ],
};

interface SimpleAdminEmployeeFormProps {
  onClose: () => void;
  updateCacheKey?: (cacheKey: string) => void;
  onSuccess?: () => void;
}

export default function SimpleAdminEmployeeForm({ updateCacheKey, onClose, onSuccess }: SimpleAdminEmployeeFormProps) {
  const {
    control,
    setError,
    formState: { errors, isDirty },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(simpleEmployeeForm),
  });
  const toast = useToast({
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const [isLoading, setIsLoading] = useState(false);

  // setTimeout necessary to defer setFocus until microtask queue is empty (event loop weirdness)
  useEffect(() => {
    setTimeout(() => setFocus("firstName"), 0);
  }, [setFocus]);

  /* HandleOnSubmit */
  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      const res = await apiClient.performRequest<ResponseModel<{ id: string }>>(API_ROUTES.admin.users.base, {
        method: "POST",
        body: JSON.stringify({ user: data }),
        headers: { "Content-Type": "application/json" },
      });
      setIsLoading(false);
      const responseData = res.payload.data;
      console.log(res.status);
      console.log(JSON.stringify(res));

      onClose();
      if (updateCacheKey) {
        updateCacheKey(responseData.id);
      }
      if (onSuccess) {
        onSuccess();
      }
      toast({
        title: "New Employee Created.",
        status: "success",
      });
      reset({
        firstName: "",
        lastName: "",
        workEmail: "",
        companyId: "",
        roles: [],
      });
    } catch (error) {
      setIsLoading(false);
      // have to add a user-defined type guard in case its not a NetworkingError
      // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
      if (isNetworkingError(error)) {
        if (error.status === 409) {
          setError("workEmail", {
            type: `${error.status}`,
            message: `A user with the email address ${data.workEmail} already exists.`,
          });
        } else {
          setError("workEmail", {
            type: `${error.status}`,
            message: `An error occurred. Please try again.`,
          });
        }
      }
    }
  };

  return (
    <Stack spacing="4">
      <GenericAsyncSelectField
        fetchPath={API_ROUTES.admin.companies.base}
        extractValue={(c: Company) => c.id}
        embedOption={(c) => ({
          value: c.id,
          label: `${c.name} (${c.domain})`,
          rawValue: c,
        })}
        control={control}
        {...COMPANY_FIELD}
        errors={errors}
      />
      <TextInputField {...FIRST_NAME_FIELD} errors={errors} register={register} />
      <TextInputField {...LAST_NAME_FIELD} errors={errors} register={register} />
      <TextInputField required {...EMAIL_FIELD} errors={errors} register={register} />
      <MultiSelectField required {...ROLES_FIELD} menuPlacement="top" errors={errors} control={control} />
      <ButtonGroup display="flex" justifyContent="flex-end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText="Create Employee"
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </ButtonGroup>
    </Stack>
  );
}

SimpleAdminEmployeeForm.defaultProps = {
  updateCacheKey: undefined,
  onSuccess: null,
};
