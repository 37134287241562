import {
  Card, CardBody, Center, Heading, Image, Stack, Text,
} from "@chakra-ui/react";

interface EmptyProps {
  image: { src: string; alt?: string };
  heading: string;
  body?: string;
}

export default function Empty({ image, heading, body = undefined }: EmptyProps) {
  return (
    <Card>
      <CardBody>
        <Stack p="16">
          <Center>
            <Heading>{heading}</Heading>
          </Center>
          {body && (
            <Center>
              <Text fontSize="lg">{body}</Text>
            </Center>
          )}
          <Center>
            <Image {...image} />
          </Center>
        </Stack>
      </CardBody>
    </Card>
  );
}
