import { generatePath } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { QUERY_KEYS } from "definitions/constants";
import { Box, Flex } from "@chakra-ui/react";
import { createQueryString } from "helpers/queryString";
import { BaseAssignedAction } from "models/automation";
import SearchBar from "components/SearchBar";
import { useMemo, useState } from "react";
import { Filter } from "models/filter";
import { EmptyAutomationStateForNewHireJourney } from "components/EmptyComponents/RandomEmptyState";
import AssignedActionCardTable from "features/AssignedAction/AssignedActionCardTable";
import SelectableSupportTeam from "../../../pages/Shared/NewHires/SelectableSupportTeam";

interface ActionsByListProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function ActionsByList({ newHireJourney }: ActionsByListProps) {
  const [searchText, setSearchText] = useState("");
  const [filterByUserId, setFilterByUserId] = useState<string>();

  const queryString = useMemo(() => {
    const filters: Filter[] = [
      {
        fieldName: "scheduledWorkflowId",
        value: (newHireJourney?.assignedPaths || []).map((scheduledWorkflow) => scheduledWorkflow.id),
        operator: "in",
      },
      {
        fieldName: "action_type",
        value: ["email", "chat"],
        operator: "in",
      },
    ];
    if (filterByUserId) {
      filters.push({
        fieldName: "targetable_id",
        value: filterByUserId,
        operator: "=",
      });
    }
    return createQueryString({
      pagination: { pageIndex: 0, pageSize: 200 },
      filters,
      additionalQueries: {
        q: searchText && searchText.length > 2 ? searchText : undefined,
      },
    });
  }, [filterByUserId, searchText, newHireJourney]);

  const { data: actionsData, isLoading } = useQuery<BaseAssignedAction[]>({
    queryKey: [QUERY_KEYS.newHireJourneyActions, newHireJourney.id, queryString],
    enabled: !!newHireJourney,
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.assignedActions.base)}${queryString.length ? `?${queryString}` : ""}`),
  });

  return (
    <Flex direction="column" alignItems="flex-start">
      <SelectableSupportTeam newHireJourney={newHireJourney} setFilterByUserId={setFilterByUserId} />
      <SearchBar minWidth="500" name="actionsSearch" onInput={setSearchText} my="4" />
      {newHireJourney && (
        <Box mt={4} width="100%">
          <AssignedActionCardTable
            newHireJourney={newHireJourney}
            data={actionsData || []}
            emptyStateComponent={<EmptyAutomationStateForNewHireJourney />}
            isLoading={isLoading}
            sx={{ width: "100%" }}
          />
        </Box>
      )}
    </Flex>
  );
}
