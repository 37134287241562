import { Switch } from "@chakra-ui/react";
import { ControlInputFieldProps } from "components/Form/typings";
import { Controller } from "react-hook-form";
import FormField from "./FormField";

export default function SwitchField({
  name,
  label,
  control,
  helperText,
  errors,
  required,
  sideLayout,
  overrideHelperSpace,
  variant,
}: ControlInputFieldProps) {
  // Floating does not apply to switch, so default it to outline
  const formVariant = "outline";

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      sideLayout={sideLayout}
      overrideHelperSpace={overrideHelperSpace}
      variant={formVariant}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Switch id={name} {...field} colorScheme="brand" isChecked={field.value} variant={variant} />
        )}
      />
    </FormField>
  );
}
