import { OptionBase } from "chakra-react-select";
import ROLE from "models/role";

export interface RoleSelectionOption extends OptionBase {
  label: string;
  value: ROLE;
}

const selectOptions: RoleSelectionOption[] = [
  {
    label: "Basic Employee",
    value: ROLE.BASIC,
  },
  {
    label: "Manager",
    value: ROLE.MANAGER,
  },
  {
    label: "People Team",
    value: ROLE.PEOPLE_TEAM,
  },
  {
    label: "Admin",
    value: ROLE.COMPANY_ADMIN,
  },
  {
    label: "Onboarder",
    value: ROLE.ONBOARDER,
  },
];

export function getAvailableRolesOptions(userRoles: ROLE[]) {
  return selectOptions.filter(({ value }) => (userRoles as string[]).includes(value));
}
