import {
  Box, FlexProps, Flex, Text, HStack,
} from "@chakra-ui/react";
import InputEditButton from "components/Button/InputEditButton";
import ExternalLink from "components/Link";
import { PropsWithChildren } from "react";

interface InfoDisplayValueProps extends FlexProps {
  label: string;
  value?: string;
  toLink?: string;
  emptyDisplay?: string;
  openEdit?: () => void;
}

export function InfoDisplayValue({
  label,
  value,
  toLink,
  emptyDisplay = "None",
  openEdit,
  ...rest
}: InfoDisplayValueProps) {
  return (
    <Flex direction="column" my={2} {...rest}>
      {openEdit ? (
        <HStack>
          <Box color="chakra-subtle-text">{label}</Box>
          <InputEditButton onClick={openEdit} aria-label="Edit new hire onboarding plan" />
        </HStack>
      ) : (
        <Box color="chakra-subtle-text">{label}</Box>
      )}
      {toLink ? (
        <ExternalLink to={toLink} text={value as string} />
      ) : (
        <Text {...(!value ? { as: "i" } : {})} whiteSpace="pre">
          {value || emptyDisplay}
        </Text>
      )}
    </Flex>
  );
}

InfoDisplayValue.defaultProps = {
  value: null,
  toLink: null,
  emptyDisplay: undefined,
  openEdit: null,
};

interface InfoColumnProps extends PropsWithChildren, FlexProps {
  header?: string;
}

export function InfoColumn({ children, header, ...rest }: InfoColumnProps) {
  return (
    <Flex direction="column" w="25%" h="80%" {...rest}>
      {header && (
        <Box borderBottom="2px solid" pb={2}>
          {header}
        </Box>
      )}
      {children}
    </Flex>
  );
}

InfoColumn.defaultProps = {
  header: null,
};
