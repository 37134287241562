import { AbsoluteCenter, Text, Spinner, VStack } from "@chakra-ui/react";
import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import useCaminoStore from "hooks/useCaminoStore";
import { useEffect } from "react";
import { redirect } from "react-router-dom";

export default function AuthenticatePage() {
  const currentUser = useCaminoStore((state) => state.currentUser);

  useEffect(() => {
    if (currentUser) redirect(SHARED_ROUTES.home);
  }, [currentUser]);

  return (
    <AbsoluteCenter>
      <VStack spacing={4}>
        <Text>Authenticating...</Text>
        <Spinner />
      </VStack>
    </AbsoluteCenter>
  );
}
