/* eslint-disable no-nested-ternary */
import { Box } from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { NetworkingError, isNetworkingError } from "services/ApiClient/networkingError";
import { API_ROUTES, PEOPLE_TEAM_ROUTES, SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import { useEffect, useState } from "react";
import UnfoundResource from "components/EmptyComponents/UnfoundResource";
import UnknownError from "components/EmptyComponents/UnknownError";
import { QUERY_KEYS } from "definitions/constants";
import ScheduledActionCard from "components/Path/ActionCard/ScheduledActionCard";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { getPathName } from "helpers/path";
import { useNavbar } from "hooks/useNavbar";
import { actionableTypeConverter } from "helpers/workflowActionableConverters";

export default function AssignedActionPage() {
  const { id } = useParams<{ id: string }>();
  const [isNotFound, setIsNotFound] = useState(false);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const {
    data,
    isLoading,
    error: queryError,
  } = useQuery({
    queryKey: [QUERY_KEYS.assignedActions, id],
    queryFn: async ({ signal }) => apiClient.get<AssignedAction>(generatePath(API_ROUTES.assignedActions.show, { id }), signal),
    retry: (failureCount, error) => {
      if (isNetworkingError(error) && error.status === 404) {
        return failureCount < 1;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: !isNotFound,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.index),
          isCurrentPage: false,
        },
        {
          name: data?.onboardingJourney?.user?.firstName,
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.show, {
            id: data?.onboardingJourney?.id,
          }),
          isCurrentPage: false,
        },
        {
          name: `${data?.onboardingJourney?.user?.firstName}'s ${getPathName(data?.scheduledWorkflow?.workflow)}`,
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.assignedPaths.show, {
            id: data?.scheduledWorkflow?.id,
          }),
          isCurrentPage: false,
        },
        {
          name: `${actionableTypeConverter(data?.workflowAction?.actionable)}: ${data?.name}`,
          isCurrentPage: true,
        },
      ]);
      setPageTitle(`${data?.onboardingJourney?.user?.firstName}'s ${getPathName(data?.scheduledWorkflow?.workflow)}`);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  useEffect(() => {
    // to stop automation refetches
    if ((queryError as NetworkingError)?.status === 404) {
      setIsNotFound(true);
    }
  }, [queryError]);

  return (
    <AppPage>
      {queryError ? (
        (queryError as NetworkingError)?.status === 404 ? (
          <UnfoundResource resourceId={id} resourceName="Assigned Action" />
        ) : (
          <UnknownError resourceId={id} resourceName="Assigned Action" />
        )
      ) : (
        <Box>{data && <ScheduledActionCard isLoading={isLoading} scheduledAction={data} />}</Box>
      )}
    </AppPage>
  );
}
