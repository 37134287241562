import { createIcon } from "@chakra-ui/react";

const CaminoBuddy = createIcon({
  displayName: "CaminoBuddy",
  viewBox: "0 0 17 16",
  path: (
    <>
      <path fill="#C6E2D3" d="M13.576 14c0-2.21-1.846-4-4.122-4s-4.12 1.79-4.12 4h8.242z" />
      <path
        fill="#959393"
        d="M13.4502 10.9358C13.0473 10.5444 12.5765 10.2249 12.0593
           9.99207C12.7886 9.41863 13.2538 8.54363 13.2538 7.56238C13.2538
           5.83113 11.7663 4.41082 9.98262 4.43738C8.22628 4.46394
           6.81122 5.853 6.81122 7.56238C6.81122 8.54363 7.27808
           9.41863 8.00573 9.99207C7.4885 10.2248 7.01764 10.5443
           6.61482 10.9358C5.73585 11.7905 5.23679 12.9186 5.2046
           14.1218C5.20417 14.1384 5.20718 14.155 5.21346 14.1705C5.21974
           14.1861 5.22916 14.2002 5.24116 14.2122C5.25316 14.2241
           5.26751 14.2336 5.28335 14.2401C5.29918 14.2465 5.3162
           14.2499 5.33339 14.2499H6.2349C6.30412 14.2499 6.36208 14.1968
           6.36369 14.1296C6.39427 13.2233 6.77259 12.3749 7.43746
           11.7311C7.77768 11.3992 8.18236 11.136 8.62808 10.9568C9.0738
           10.7777 9.55171 10.6861 10.0341 10.6874C11.0145 10.6874
           11.937 11.0577 12.6308 11.7311C13.2941 12.3749 13.6724
           13.2233 13.7046 14.1296C13.7062 14.1968 13.7642 14.2499
           13.8334 14.2499H14.7349C14.7521 14.2499 14.7691 14.2465 14.7849
           14.2401C14.8008 14.2336 14.8151 14.2241 14.8271 14.2122C14.8391
           14.2002 14.8485 14.1861 14.8548 14.1705C14.8611 14.155
           14.8641 14.1384 14.8637 14.1218C14.8315 12.9186 14.3324
           11.7905 13.4502 10.9358ZM10.0341 9.56238C9.48357 9.56238 8.9652
           9.35457 8.57723 8.97644C8.38252 8.78895 8.22872 8.56531
           8.125 8.31888C8.02128 8.07244 7.96977 7.80825 7.97353
           7.54207C7.97836 7.02957 8.18925 6.53425 8.55791 6.16707C8.94427
           5.78269 9.46103 5.56863 10.0132 5.56238C10.5589 5.55769
           11.0886 5.76394 11.4782 6.13425C11.8774 6.51394 12.0964
           7.02175 12.0964 7.56238C12.0964 8.09675 11.8822 8.59832
           11.4927 8.97644C11.3015 9.16284 11.0742 9.3106 10.8239
           9.41118C10.5735 9.51175 10.3051 9.56314 10.0341 9.56238ZM6.00147
           7.97488C5.98698 7.83894 5.97893 7.70144 5.97893 7.56238C5.97893
           7.31394 6.00308 7.07175 6.04816 6.83582C6.05943 6.77957
           6.02884 6.72175 5.97571 6.69832C5.75677 6.603 5.55554
           6.47175 5.38168 6.30613C5.17681 6.11333 5.0156 5.88119
           4.90833 5.62454C4.80106 5.36789 4.75009 5.09235 4.75867
           4.8155C4.77316 4.31394 4.98083 3.83738 5.34304 3.478C5.74068
           3.08269 6.27515 2.86707 6.84342 2.87332C7.35696 2.878
           7.8528 3.07019 8.22789 3.41082C8.35507 3.52644 8.46454
           3.65457 8.5563 3.79207C8.5885 3.8405 8.65128 3.86082
           8.70601 3.84207C8.98935 3.74675 9.28878 3.67957 9.59626
           3.64832C9.68641 3.63894 9.73793 3.54519 9.69768 3.46707C9.17448
           2.46238 8.10554 1.76863 6.86918 1.74988C5.08386 1.72332
           3.59636 3.14363 3.59636 4.87332C3.59636 5.85457 4.06161
           6.72957 4.79087 7.303C4.27894 7.53269 3.80725 7.84988
           3.39835 8.24675C2.51615 9.10144 2.0171 10.2296 1.9849
           11.4343C1.98447 11.4509 1.98749 11.4675 1.99377 11.483C2.00005
           11.4986 2.00946 11.5127 2.02147 11.5247C2.03347 11.5366
           2.04781 11.5461 2.06365 11.5526C2.07949 11.559 2.0965
           11.5624 2.11369 11.5624H3.01682C3.08604 11.5624 3.14399 11.5093
           3.1456 11.4421C3.17619 10.5358 3.5545 9.68738 4.21937
           9.04363C4.69267 8.58425 5.27221 8.2655 5.90488 8.11082C5.96766
           8.09519 6.00952 8.03738 6.00147 7.97488V7.97488Z"
      />
    </>
  ),
});

export default CaminoBuddy;
