import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter } from "react-router-dom";
import theme from "theme";
import AllRoutes from "routes/AllRoutes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import memoryColorModeManager from "theme/MemoryColorModeManager";
import "assets/application.css";
import { toastOptions } from "definitions/constants/config";

// Create a client
const queryClient = new QueryClient();

export default function App(): JSX.Element {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme} colorModeManager={memoryColorModeManager} toastOptions={toastOptions}>
          <AllRoutes />
        </ChakraProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
}
