import UserUpdateForm, { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import BaseDialogFormHeader from "components/Forms/FormHeader";
import { newHireJourneyPath } from "helpers/url";
import { NewHireJourney } from "models/onboarding";

interface DynamicNewHireJourneyFormProps {
  newHireJourney: NewHireJourney;
  fields: Array<keyof UserUpdateFormValues>;
  onSuccess?: () => void;
  onClose?: () => void;
}

export default function DynamicNewHireJourneyForm({
  newHireJourney,
  fields,
  onSuccess,
  onClose,
}: DynamicNewHireJourneyFormProps) {
  const path = newHireJourneyPath(newHireJourney);
  return (
    <BaseDialogFormHeader
      linkTo={path}
      label="New Hire"
      value={newHireJourney?.user?.fullName as string}
      imageUrl={newHireJourney?.user?.avatarUrl}
      isLoaded={!!newHireJourney}
    >
      <UserUpdateForm userId={newHireJourney?.userId} fields={fields} onSuccess={onSuccess} onClose={onClose} />
    </BaseDialogFormHeader>
  );
}

DynamicNewHireJourneyForm.defaultProps = {
  onSuccess: () => {},
  onClose: () => {},
};
