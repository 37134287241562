/* eslint-disable max-classes-per-file */
import Trix from "trix";

function addUnderlineAttribute() {
  Trix.config.textAttributes.underline = {
    style: { textDecoration: "underline" },
    inheritable: true,
    parser: (element) => element.style.textDecoration === "underline",
  };
}

function addHeadingAttributes() {
  Array.from(["h1", "h2", "h3", "h4", "h5", "h6"]).forEach((tagName, i) => {
    Trix.config.blockAttributes[`heading${i + 1}`] = {
      tagName,
      terminal: true,
      breakOnReturn: true,
      group: false,
    };
  });
}

function addForegroundColorAttributes() {
  Array.from([
    "rgb(136, 118, 38)",
    "rgb(185, 94, 6)",
    "rgb(207, 0, 0)",
    "rgb(216, 28, 170)",
    "rgb(144, 19, 254)",
    "rgb(5, 98, 185)",
    "rgb(17, 138, 15)",
    "rgb(148, 82, 22)",
    "rgb(102, 102, 102)",
  ]).forEach((color, i) => {
    Trix.config.textAttributes[`fgColor${i + 1}`] = {
      style: { color },
      inheritable: true,
      parser: (e) => e.style.color === color,
    };
  });
}

function addBackgroundColorAttributes() {
  Array.from([
    "rgb(250, 247, 133)",
    "rgb(255, 240, 219)",
    "rgb(255, 229, 229)",
    "rgb(255, 228, 247)",
    "rgb(242, 237, 255)",
    "rgb(225, 239, 252)",
    "rgb(228, 248, 226)",
    "rgb(238, 226, 215)",
    "rgb(242, 242, 242)",
  ]).forEach((color, i) => {
    Trix.config.textAttributes[`bgColor${i + 1}`] = {
      style: { backgroundColor: color },
      inheritable: true,
      parser: (e) => e.style.backgroundColor === color,
    };
  });
}

export function addCustomAttributes() {
  addUnderlineAttribute();
  addHeadingAttributes();
  addForegroundColorAttributes();
  addBackgroundColorAttributes();
}

class PrivateRichText {
  constructor(element) {
    this.element = element;
  }

  setup() {
    addCustomAttributes();
    this.insertUnderlineButton();
    this.insertHeadingElements();
    this.insertColorElements();
    this.insertEmojiButton();
    this.adjustHrefInput();
  }

  insertPersonalizationElements() {
    this.insertPersonalizationGroup();
    this.insertPersonalizationTokenButton();
  }

  insertUnderlineButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.constructor.underlineButtonTemplate);
  }

  insertHeadingElements() {
    this.removeOriginalHeadingButton();
    this.insertNewHeadingButton();
    this.insertHeadingDialog();
  }

  removeOriginalHeadingButton() {
    this.buttonGroupBlockTools.removeChild(this.originalHeadingButton);
  }

  insertNewHeadingButton() {
    this.buttonGroupBlockTools.insertAdjacentHTML("afterbegin", this.constructor.headingButtonTemplate);
  }

  insertHeadingDialog() {
    this.dialogsElement.insertAdjacentHTML("beforeend", this.constructor.dialogHeadingTemplate);
  }

  insertColorElements() {
    this.insertColorButton();
    this.insertDialogColor();
  }

  insertColorButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.constructor.colorButtonTemplate);
  }

  insertDialogColor() {
    this.dialogsElement.insertAdjacentHTML("beforeend", this.constructor.dialogColorTemplate);
  }

  insertPersonalizationGroup() {
    this.buttonGroupTextTools.insertAdjacentHTML("afterend", this.constructor.personalizationGroupTemplate);
  }

  insertPersonalizationTokenButton() {
    this.buttonGroupPersonalization.insertAdjacentHTML(
      "beforeend",
      this.constructor.personalizationTokenButtonTemplate,
    );
  }

  insertEmojiButton() {
    this.buttonGroupTextTools.insertAdjacentHTML("beforeend", this.constructor.emojiButtonTemplate);
  }

  adjustHrefInput() {
    const inputElement = this.hrefInput;
    inputElement.type = "text";
    inputElement.pattern = "(?:https?://.+)|(?:\\{\\{.+_url\\}\\})";
  }

  get buttonGroupBlockTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=block-tools]");
  }

  get buttonGroupTextTools() {
    return this.toolbarElement.querySelector("[data-trix-button-group=text-tools]");
  }

  get buttonGroupPersonalization() {
    return this.toolbarElement.querySelector(".trix-button-group--personalization");
  }

  get dialogsElement() {
    return this.toolbarElement.querySelector("[data-trix-dialogs]");
  }

  get originalHeadingButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=heading1]");
  }

  get quoteButton() {
    return this.toolbarElement.querySelector("[data-trix-attribute=quote]");
  }

  get personalizationTokenButton() {
    return this.toolbarElement.querySelector("[data-trix-action=x-personalization-token]");
  }

  get emojiButton() {
    return this.toolbarElement.querySelector("[data-trix-action=x-emoji]");
  }

  get hrefInput() {
    return this.toolbarElement.querySelector("input[name=href]");
  }

  get toolbarElement() {
    return this.element.toolbarElement;
  }

  get editorElement() {
    return this.element;
  }

  static get personalizationGroupTemplate() {
    return '<span class="trix-button-group trix-button-group--personalization"></span>';
  }

  static get underlineButtonTemplate() {
    // eslint-disable-next-line max-len
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-underline" data-trix-attribute="underline" data-trix-key="u" title="Underline" tabindex="-1">Underline</button>';
  }

  static get personalizationTokenButtonTemplate() {
    return '<button type="button" class="trix-button" data-trix-action="x-personalization-token" title="Personalize" tabindex="-1">Personalize</button>';
  }

  static get headingButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-action="x-heading" title="Heading" tabindex="-1">Heading</button>';
  }

  static get colorButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-color" data-trix-action="x-color" title="Color" tabindex="-1">Color</button>';
  }

  static get emojiButtonTemplate() {
    return '<button type="button" class="trix-button trix-button--icon trix-button--icon-emoji" data-trix-action="x-emoji" title="Emoji" tabindex="-1">Emoji</button>';
  }

  static get dialogHeadingTemplate() {
    return `
      <div class="trix-dialog trix-dialog--heading" data-trix-dialog="x-heading" data-trix-dialog-attribute="x-heading">
        <div class="trix-dialog__link-fields">
          <input type="text" name="x-heading" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading1" data-trix-method="hideDialog">H1</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading2" data-trix-method="hideDialog">H2</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading3" data-trix-method="hideDialog">H3</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading4" data-trix-method="hideDialog">H4</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading5" data-trix-method="hideDialog">H5</button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="heading6" data-trix-method="hideDialog">H6</button>
          </div>
        </div>
      </div>
    `;
  }

  static get dialogColorTemplate() {
    return `
      <div class="trix-dialog trix-dialog--color" data-trix-dialog="x-color" data-trix-dialog-attribute="x-color">
        <div class="trix-dialog__link-fields">
          <input type="text" name="x-color" class="trix-dialog-hidden__input" data-trix-input>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor1" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor2" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor3" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor4" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor5" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor6" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor7" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor8" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="fgColor9" data-trix-method="hideDialog"></button>
          </div>
          <div class="trix-button-group">
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor1" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor2" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor3" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor4" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor5" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor6" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor7" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor8" data-trix-method="hideDialog"></button>
            <button type="button" class="trix-button trix-button--dialog" data-trix-attribute="bgColor9" data-trix-method="hideDialog"></button>
          </div>
        </div>
      </div>
    `;
  }
}

export class RichText {
  constructor(element) {
    this.richText = new PrivateRichText(element);
  }

  setup() {
    this.richText.setup();
  }

  insertPersonalizationElements() {
    this.richText.insertPersonalizationElements();
  }

  get personalizationTokenButton() {
    return this.richText.personalizationTokenButton;
  }

  get emojiButton() {
    return this.richText.emojiButton;
  }
}
