import {
  Flex, FlexProps, SystemStyleObject, Tag, TagLabel, TagLeftIcon, TagProps,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { Task } from "models/joinedModels";
import { actionableTypeToIcon, assignedActionStatusDisplay } from "helpers/workflowActionableConverters";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { assignedActionPath } from "helpers/url";
import { isImmediateAssignedAction } from "helpers/assignedAction";
import { ActionableType } from "models/automation";

interface TaskCommunicationProps {
  assignedAction: AssignedAction;
  tagProps?: TagProps;
  overrideImmediate?: boolean;
  linkify?: boolean;
}

function TaskCommunicationTag({
  sx,
  backgroundColor,
  iconSize,
  actionType,
  tagDisplay,
  tagProps,
}: {
  sx: SystemStyleObject;
  backgroundColor: string;
  iconSize: string;
  actionType?: ActionableType;
  tagDisplay: string;
  tagProps?: TagProps;
}) {
  return (
    <Tag sx={sx} variant="subtle" colorScheme={backgroundColor} {...tagProps}>
      {actionType && <TagLeftIcon boxSize={iconSize} as={actionableTypeToIcon({ actionType })} />}
      <TagLabel>{tagDisplay}</TagLabel>
    </Tag>
  )
}

export function TaskCommunication({
  assignedAction,
  overrideImmediate = false,
  linkify = true,
  tagProps = {},
}: TaskCommunicationProps) {
  if (!assignedAction) {
    return null;
  }
  let iconSize = "4";
  let sx = { paddingY: "1", paddingX: "2" };
  if (tagProps?.size) {
    switch (tagProps.size) {
      case "sm":
        iconSize = "2";
        sx = { paddingY: "0.5", paddingX: "2" };
        break;
      case "lg":
        iconSize = "6";
        break;
      default:
        break;
    }
  }
  const { plainActionType, backgroundColor, status, datetime } = assignedActionStatusDisplay(assignedAction);
  let tagDisplay = `${plainActionType} ${status} ${datetime}`;
  if (overrideImmediate && isImmediateAssignedAction(assignedAction) && assignedAction.actionType === "task_notifier") {
    tagDisplay = `Notification sent after kickoff`;
  }
  return (linkify ? (
    <RouterLink to={assignedActionPath(assignedAction)}>
      <TaskCommunicationTag
        sx={sx}
        backgroundColor={backgroundColor as string}
        iconSize={iconSize}
        actionType={assignedAction.actionType}
        tagDisplay={tagDisplay}
        tagProps={tagProps}
      />
    </RouterLink>
  ) : (
    <TaskCommunicationTag
      sx={sx}
      backgroundColor={backgroundColor as string}
      iconSize={iconSize}
      actionType={assignedAction.actionType}
      tagDisplay={tagDisplay}
      tagProps={tagProps}
    />
  ));
}

interface TaskCommunicationsProps extends FlexProps {
  task: Task;
  tagSize?: TagProps["size"];
  overrideImmediate?: boolean;
}

export default function TaskCommunications({ task, tagSize = "md", overrideImmediate = false, ...rest }: TaskCommunicationsProps) {
  return (
    <Flex direction="row" gap="2" align="center" {...rest}>
      {(task?.scheduledWorkflowActions || []).map((assignedAction) => (
        <TaskCommunication
          overrideImmediate={overrideImmediate}
          tagProps={{ size: tagSize }}
          key={assignedAction.id}
          assignedAction={assignedAction}
        />
      ))}
    </Flex>
  );
}

export function TaskTableCommunication({
  assignedAction,
  tagProps = {},
}: TaskCommunicationProps) {
  if (!assignedAction) {
    return null;
  }
  let sx = { paddingY: "0.5", paddingX: "2" };
  const { backgroundColor, status, datetime } = assignedActionStatusDisplay(assignedAction);
  let tagDisplay = `${status === "Sent" ? "Received" : status} ${datetime}`;
  const linkify = status === "Errored"
  return (linkify ? (
    <RouterLink to={assignedActionPath(assignedAction)}>
      <TaskCommunicationTag
        sx={sx}
        backgroundColor={backgroundColor as string}
        tagDisplay={tagDisplay}
        tagProps={tagProps}
      />
    </RouterLink>
  ) : (
    <TaskCommunicationTag
      sx={sx}
      backgroundColor={backgroundColor as string}
      tagDisplay={tagDisplay}
      tagProps={tagProps}
    />
  ));
}