import { create } from "zustand";
import { BreadcrumbInfo, BreadCrumbNotCurrentPage } from "components/Breadcrumbs";
import { useEffect } from "react";

type NavbarStore = {
  breadcrumbs: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>;
  setBreadcrumbs: (breadcrumbs: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>) => void;
  clearBreadcrumbs: () => void;
  pageTitle: string | null;
  setPageTitle: (pageTitle: string) => void;
  clearPageTitle: () => void;
  clearNavbar: () => void;
};

const useNavbarStore = create<NavbarStore>((set) => ({
  breadcrumbs: [],
  setBreadcrumbs: (breadcrumbs: Array<BreadcrumbInfo | BreadCrumbNotCurrentPage>) => set({ breadcrumbs }),
  clearBreadcrumbs: () => set({ breadcrumbs: [] }),
  pageTitle: null,
  setPageTitle: (pageTitle: string) => {
    document.title = pageTitle;
    set({ pageTitle });
  },
  clearPageTitle: () => set({ pageTitle: null }),
  clearNavbar: () => set({ breadcrumbs: [], pageTitle: null }),
}));

export default useNavbarStore;

function useNavbar() {
  const [setBreadcrumbs, setPageTitle, clearNavbar] = useNavbarStore((state) => [
    state.setBreadcrumbs,
    state.setPageTitle,
    state.clearNavbar,
  ]);

  useEffect(
    () => () => {
      clearNavbar();
    },
    [clearNavbar],
  );

  return { setBreadcrumbs, setPageTitle };
}

export { useNavbar };
