export const ACTION_TYPE_FIELD = {
  type: "SELECT",
  label: "Action Type",
  placeholder: "Action Type",
  name: "actionType",
  options: [
    { label: "Email", value: "email" },
    { label: "Chat Message", value: "chat" },
    { label: "Task Notification", value: "task_notifier" },
    { label: "Task Reminder", value: "task_reminder" },
  ],
};

export const NAME_FIELD = {
  type: "text",
  label: "Name",
  placeholder: "Action Name",
  name: "name",
};
