import { Link, SystemStyleObject } from "@chakra-ui/react";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import { FilterMeta, ResponseModel } from "services/ApiClient/responseModel";
import apiClient from "services/ApiClient";
import { timeOnUSDate } from "helpers/time";
import { QUERY_KEYS } from "definitions/constants";
import { simpleColumn } from "./ControlledDataTable";
import GenericTable, { GenericTableProps } from "./GenericTable";

const columnHelper = createColumnHelper<AssignedPath>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "fullName",
    header: "New Hire (click for path details)",
    cell: (row) => (
      <Link
        as={RouterLink}
        to={{
          pathname: generatePath(PEOPLE_TEAM_ROUTES.assignedPaths.show, {
            id: row.getValue().id,
          }),
        }}
      >
        {row.getValue().onboardingJourney.user?.fullName}
      </Link>
    ),
  }),
  columnHelper.accessor((row) => row, {
    id: "actionsCount",
    header: "In-Progress Actions",
    cell: (row) => row.getValue().actions.filter((a) => !["complete", "canceled"].includes(a.status)).length,
  }),
  columnHelper.accessor((row) => row, {
    id: "createdAt",
    header: "Assigned At",
    cell: (row) => timeOnUSDate(row.getValue().createdAt),
  }),
  simpleColumn({ attribute: "creator.fullName", label: "Assigned By" }, columnHelper),
];

interface ScheduledWorkflowsTableProps {
  filters: FilterMeta[];
  sx?: SystemStyleObject;
}

export default function ScheduledWorkflowsTable({ filters, sx }: ScheduledWorkflowsTableProps) {
  const props: GenericTableProps<AssignedPath> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<AssignedPath[]>>(
      `${API_ROUTES.assignedPaths.base}${query}`,
      { method: "GET" },
      signal,
    )
      .then((response) => response.payload),
    queryKey: [QUERY_KEYS.assignedPaths],
    columns,
    meta: { pagination: { perPage: 20 }, filters },
  };

  return <GenericTable sx={sx} {...props} />;
}

ScheduledWorkflowsTable.defaultProps = {
  sx: undefined,
};
