import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import { Navigate } from "react-router-dom";
import useCaminoStore from "hooks/useCaminoStore";
import React from "react";

export default function UnauthenticatedRoute({ children }: { children: React.ReactNode }) {
  const user = useCaminoStore((state) => state.currentUser);

  if (user) {
    console.log("UnauthenticatedRoute: user is already logged in");
    return <Navigate to={SHARED_ROUTES.home} />;
  }

  return children;
}
