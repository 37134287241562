import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { UseModalReturn } from "hooks/useModal";
import { PropsWithChildren } from "react";

export default function Modal({
  modal: { header, ...modalProps },
  children,
}: PropsWithChildren<{ modal: UseModalReturn }>) {
  return (
    <ChakraModal {...modalProps}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={header.fontSize}>{header.title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ChakraModal>
  );
}
