import { Tag } from "@chakra-ui/react";
import moment from "moment";

function getColorScheme(startDate: Date) {
  const today = moment();
  const nextWeek = moment().add(7, "days");
  const target = moment(startDate);

  if (target.isSameOrBefore(today, "day")) {
    return "dateGreen";
  } else if (target.isSameOrBefore(nextWeek, "day")) {
    return "dateYellow";
  } else if (target.isAfter(nextWeek, "day")) {
    return "dateBlue";
  }
  return "gray";
}

function relativeStartDateLabel(startDate: Date) {
  const today = moment();
  const targetDate = moment(startDate);

  const daysDiff = targetDate.diff(today, "days");

  if (daysDiff === 0) {
    return "Today";
  } else if (daysDiff === 1) {
    return "Tomorrow";
  } else if (daysDiff > 1 && daysDiff <= 7) {
    return `In ${daysDiff} days`;
  } else if (daysDiff > 7 && daysDiff <= 14) {
    return "Next week";
  } else if (daysDiff > 14) {
    const weeksDiff = targetDate.diff(today, "weeks");
    return `In ${weeksDiff} week${weeksDiff > 1 ? "s" : ""}`;
  } else if (daysDiff === -1) {
    return "Yesterday";
  } else if (daysDiff < -1 && daysDiff >= -7) {
    return `${Math.abs(daysDiff)} days ago`;
  } else if (daysDiff < -7 && daysDiff >= -14) {
    return "Last week";
  } else if (daysDiff < -14) {
    const weeksDiff = Math.abs(targetDate.diff(today, "weeks"));
    return `${weeksDiff} week${weeksDiff > 1 ? "s" : ""} ago`;
  }
  return "";
}

export default function StartDateTag({ startDate }: { startDate: Date }) {
  const colorScheme = getColorScheme(startDate);
  return (
    <Tag
      variant="outline"
      color={`${colorScheme}.DEFAULT`}
      border={`1px solid var(--chakra-colors-${colorScheme}-DEFAULT)`}
      bg={`${colorScheme}.background`}
      borderRadius="md"
      boxShadow="none"
    >
      {relativeStartDateLabel(startDate)}
    </Tag>
  );
}
