import {
  As, Card, CardBody, CardProps, Icon, LinkBox, LinkOverlay, Text, VStack,
} from "@chakra-ui/react";
import LoadingCard from "./LoadingCard";

interface LinkCardProps extends CardProps {
  target: { url: string; description: string; isExternal?: boolean } | null;
  icon: As;
  label: string;
}

export default function LinkCard({ target, icon, label, ...rest }: LinkCardProps) {
  if ((label || "").length > 22) {
    console.log(`WARNING THE MAX LENGTH FOR A TITLE FOR A RESOURCE CARD IS 22. ${label} is too long`);
  }
  if (!target) {
    return <LoadingCard />;
  }

  return (
    <LinkBox
      as={Card}
      alignSelf="stretch"
      minW="240"
      maxW="240"
      mt="12px"
      _hover={{ bg: "background.tertiary" }}
      {...rest}
    >
      <CardBody>
        <VStack mt="8px" gap="14px" justify="start" alignItems="stretch" height="100%">
          <Text textAlign="center" fontWeight="600" fontSize={16}>
            <LinkOverlay href={target.url} isExternal={target.isExternal ?? true}>
              {label}
            </LinkOverlay>
          </Text>
          <VStack alignItems="center" gap="0">
            <Icon as={icon} boxSize="12" color="fg.subtle" />
          </VStack>
          <VStack alignItems="center" gap="2">
            <Text fontWeight="400" fontSize={14}>
              {target?.description}
            </Text>
          </VStack>
        </VStack>
      </CardBody>
    </LinkBox>
  );
}
