import {
  Box,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import CreateableMultiSelect from "components/Form/Select/CreateableMultiSelect";
import TimezoneSelect from "components/Form/Select/TimezoneSelect";
import { SelectFieldProps, TextInputFieldProps } from "components/Form/typings";

export function SideBySideTextInputField({
  name,
  label,
  helperText,
  register,
  errors,
  disabled,
  required,
}: TextInputFieldProps) {
  const error = errors ? errors[name] : null;
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={required} isDisabled={disabled} variant="outline">
      <Stack direction={{ base: "column", md: "row" }} spacing={{ base: "1.5", md: "8" }} justify="space-between">
        <Box>
          <FormLabel variant="inline">{label}</FormLabel>
          <FormHelperText mt="0" color="gray.400">
            {helperText}
          </FormHelperText>
        </Box>
        <Input disabled={disabled} maxW={{ md: "3xl" }} {...register(name)} />
      </Stack>
      {error && <FormErrorMessage>{(error as any).message}</FormErrorMessage>}
    </FormControl>
  );
}

export function SideBySideTextAreaField({
  name,
  label,
  register,
  helperText,
  errors,
  disabled,
  required,
}: TextInputFieldProps) {
  const error = errors ? errors[name] : null;
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={required} isDisabled={disabled} variant="outline">
      <Stack direction={{ base: "column", md: "row" }} spacing={{ base: "1.5", md: "8" }} justify="space-between">
        <Box>
          <FormLabel variant="inline">{label}</FormLabel>
          <FormHelperText mt="0" color="gray.400">
            {helperText}
          </FormHelperText>
        </Box>
        <Textarea bg="gray.200" color="fg.muted" disabled={disabled} maxW={{ md: "3xl" }} {...register(name)} />
      </Stack>
      {error && <FormErrorMessage>{(error as any).message}</FormErrorMessage>}
    </FormControl>
  );
}

export function SideBySideEmailField({
  name,
  label,
  disabled,
  register,
  helperText,
  errors,
  required,
}: TextInputFieldProps) {
  const error = errors ? errors[name] : null;
  return (
    <FormControl id={name} isInvalid={!!error} isRequired={required} isDisabled={disabled} variant="outline">
      <Stack direction={{ base: "column", md: "row" }} spacing={{ base: "1.5", md: "8" }} justify="space-between">
        <Box>
          <FormLabel variant="inline">{label}</FormLabel>
          <FormHelperText mt="0" color="gray.400">
            {helperText}
          </FormHelperText>
        </Box>
        <Input disabled={disabled} type="email" maxW={{ md: "3xl" }} {...register(name)} />
      </Stack>
      {error && <FormErrorMessage>{(error as any).message}</FormErrorMessage>}
    </FormControl>
  );
}

export function SideBySideCreateableMultiSelect({
  name,
  label,
  placeholder,
  helperText,
  control,
  options,
  errors,
}: SelectFieldProps) {
  const error = errors ? errors[name] : null;
  return (
    <FormControl id={name} isInvalid={!!error} variant="outline">
      <Stack direction={{ base: "column", md: "row" }} spacing={{ base: "1.5", md: "8" }} justify="space-between">
        <FormLabel variant="inline">{label}</FormLabel>
        <Box w={{ md: "3xl" }}>
          <CreateableMultiSelect name={name} control={control} placeholder={placeholder} options={options} />
        </Box>
      </Stack>
      {error && <FormErrorMessage>{(error as any).message}</FormErrorMessage>}
      {helperText && (
        <FormHelperText fontSize="xs" color="gray.400">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

export function SideBySideTimezone({
  name, label, helperText, control, errors,
}: SelectFieldProps) {
  const error = errors ? errors[name] : null;
  return (
    <FormControl id={name} isInvalid={!!error} variant="outline">
      <Stack direction={{ base: "column", md: "row" }} spacing={{ base: "1.5", md: "8" }} justify="space-between">
        <FormLabel variant="inline">{label}</FormLabel>
        <Box w={{ md: "3xl" }}>
          <Box w={{ md: "xs" }}>
            <TimezoneSelect control={control} name={name} />
          </Box>
        </Box>
      </Stack>
      {error && <FormErrorMessage>{(error as any).message}</FormErrorMessage>}
      {helperText && (
        <FormHelperText fontSize="xs" color="gray.400">
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}

SideBySideTextInputField.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  helperText: undefined,
  disabled: false,
};

SideBySideTextAreaField.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  helperText: undefined,
  disabled: false,
};

SideBySideEmailField.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  helperText: undefined,
  disabled: undefined,
};
