const SUBJECT_FIELD = {
  type: "text",
  label: "Subject",
  placeholder: "Subject",
  name: "content.subject",
};

const BODY_FIELD = {
  type: "text",
  label: "Body",
  placeholder: "Body",
  name: "content.body",
};

export { SUBJECT_FIELD, BODY_FIELD };
