/* eslint-disable no-nested-ternary */
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Box,
  HStack,
  LightMode,
  SystemStyleObject,
  Table as ChakraTable,
  TableColumnHeaderProps,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Cell, Header, Table, flexRender } from "@tanstack/react-table";
import SmallArrowPagination from "./SmallArrowPagination";
import { SkeletonRows } from "./SkeletonRow";

interface TableCellProps {
  cell: Cell<any, any>;
  textWrap: boolean;
}

export default function TableCell({ cell, textWrap }: TableCellProps) {
  return (
    <Td
      key={cell.id}
      {...(cell.column.columnDef.meta?.cellProps || {})}
      sx={{
        xOverflow: "scroll",
        position: "relative",
        width: cell.column.getSize(),
        ...(cell.column.columnDef.maxSize ? { maxWidth: cell.column.columnDef.maxSize } : {}),
        ...(cell.column.columnDef.minSize ? { minWidth: cell.column.columnDef.minSize } : {}),
        ...(!!cell.column.columnDef.meta && !!cell.column.columnDef.meta?.sx ? cell.column.columnDef.meta?.sx : {}),
        paddingX: "12px",
      }}
    >
      {typeof cell.getContext() === "string" ? (
        <Text {...(textWrap ? { whiteSpace: "normal" } : {})} color="fg.muted" width="100%">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Text>
      ) : (
        <Box {...(textWrap ? { whiteSpace: "normal" } : {})} color="fg.muted" width="100%">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Box>
      )}
    </Td>
  );
}

export interface TableHeaderProps extends TableColumnHeaderProps {
  header: Header<any, unknown>;
  sx?: SystemStyleObject;
}

export function StyledTh({ header, children, sx = {}, ...rest }: TableHeaderProps) {
  return (
    <Th
      colSpan={header.colSpan}
      {...(header.column.columnDef.meta?.headerProps || {})}
      sx={{
        position: "relative",
        width: header.getSize(),
        ...(header.column.columnDef.maxSize ? { maxWidth: header.column.columnDef.maxSize } : {}),
        ...(header.column.columnDef.minSize ? { minWidth: header.column.columnDef.minSize } : {}),
        ...(!!header.column.columnDef.meta && !!header.column.columnDef.meta?.sx
          ? header.column.columnDef.meta?.sx
          : {}),
        paddingX: "12px",
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Th>
  );
}

export function TableHeader({ header, sx = {}, ...rest }: TableHeaderProps) {
  return header.column.getCanSort() ? (
    <StyledTh cursor="pointer" onClick={() => header.column.toggleSorting()} header={header} sx={sx} {...rest}>
      <HStack {...(header.column.columnDef.meta?.headerHStackProps || {})}>
        <Text color="notBlack.50">
          {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
        </Text>
        {header.column.getIsSorted()
          && (header.column.getIsSorted() === "desc" ? (
            <Box pl="4">
              <TriangleDownIcon color="notBlack.50" aria-label="sorted descending" />
            </Box>
          ) : (
            <Box pl="4">
              <TriangleUpIcon color="notBlack.50" aria-label="sorted ascending" />
            </Box>
          ))}
      </HStack>
    </StyledTh>
  ) : (
    <StyledTh header={header} sx={sx} {...rest}>
      <Text color="notBlack.50">
        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
      </Text>
    </StyledTh>
  );
}

interface BaseTableProps<T> extends TableProps {
  table: Table<T>;
  sx?: SystemStyleObject;
  textWrap?: boolean;
  isLoading?: boolean;
  pageSizeOptions?: number[];
  includeNumberedRow?: boolean;
  emptyStateComponent?: React.ReactNode;
  containerProps?: object;
}

export function BaseTable({
  table,
  sx = {},
  textWrap = false,
  isLoading = false,
  includeNumberedRow = false,
  pageSizeOptions = undefined,
  emptyStateComponent = null,
  containerProps = {},
  ...props
}: BaseTableProps<any>) {
  // eslint-disable-next-line no-underscore-dangle
  const columnNumber = includeNumberedRow ? table._getColumnDefs().length + 1 : table._getColumnDefs().length;

  return (
    <Box sx={sx}>
      <SmallArrowPagination pageSizeOptions={pageSizeOptions} table={table} />
      <Box maxW="100%" overflow="scroll" width="100%" {...containerProps}>
        <ChakraTable overflow="scroll" borderRadius="5px" variant="striped" {...props}>
          <Thead bgColor="fg.accent.subtle">
            <LightMode>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {includeNumberedRow && (
                    <Th key="numberedRow" sx={{ width: "10" }}>
                      <Text color="fg.muted" fontWeight="bold">
                        #
                      </Text>
                    </Th>
                  )}
                  {headerGroup.headers.map((header) => (
                    <TableHeader key={header?.column?.id} header={header} />
                  ))}
                </Tr>
              ))}
            </LightMode>
          </Thead>
          <Tbody {...(isLoading ? { pt: "4" } : {})}>
            {isLoading ? (
              <SkeletonRows columns={columnNumber} rows={10} speed={1.75} />
            ) : !table.getRowModel().rows?.length && emptyStateComponent ? (
              <Tr>
                <Td colSpan={columnNumber} textAlign="center">
                  {emptyStateComponent}
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row, index) => (
                <Tr key={row.id}>
                  {includeNumberedRow && (
                    <Td key="numberedRow">
                      <Text color="fg.muted">{index + 1}</Text>
                    </Td>
                  )}
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell?.id} cell={cell} textWrap={textWrap || false} />
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </ChakraTable>
      </Box>
    </Box>
  );
}
