import {
  Box, Button, ButtonGroup, HStack, Heading,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { useState } from "react";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import { useModal } from "hooks/useModal";
import Modal from "components/Modal";
import PersonalizationTokenMenu from "components/FormElements/Fields/ActionRichTextAreaField/PersonalizationTokenMenu";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import RecipientsContainer from "./RecipientsContainer";
import { BODY_FIELD, SUBJECT_FIELD } from "./fields";
import { Schema } from "./schema";
import { RELATION_TARGET_FIELD, SPECIFIC_USER_FIELD, TARGET_TYPE_FIELD } from "../Shared/fields";

export default function EmailInfo({
  form: {
    register,
    control,
    formState: { errors },
    watch,
    setValue,
  },
}: {
  form: UseFormReturn<Schema>;
}) {
  const [actionType, targetType, additionalRecipients, ccRecipients, bccRecipients, subject] = watch([
    "actionType",
    "targetType",
    "additionalRecipients",
    "ccRecipients",
    "bccRecipients",
    "content.subject",
  ]);
  const targetTitle = "Recipient";
  const targetTypeTitle = `Primary ${targetTitle} Type`;
  const [showAdditionalRecipients, setShowAdditionalRecipients] = useState((additionalRecipients ?? []).length > 0);
  const [showCc, setShowCc] = useState(ccRecipients?.length ?? false);
  const [showBcc, setShowBcc] = useState(bccRecipients?.length ?? false);
  const tokenModal = useModal({
    id: "token",
    header: { title: "Insert Token" },
    size: "lg",
    isCentered: true,
  });

  return (
    <>
      <Heading as="h3" size="xs" mb={4}>
        3. Complete Email Content
      </Heading>
      <HStack width="100%">
        <Box width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...TARGET_TYPE_FIELD}
            options={TARGET_TYPE_FIELD.options.filter((option) => option.value !== "message_service_channel")}
            label={targetTypeTitle}
            placeholder={targetTypeTitle}
            errors={errors}
            control={control}
          />
        </Box>
        <Box width="50%" alignItems="start">
          {targetType === "specific_user" && (
            <UserSelectField control={control} required {...SPECIFIC_USER_FIELD} errors={errors} />
          )}
          {targetType === "relation" && (
            <SingleSelectField
              isClearable={false}
              required
              {...RELATION_TARGET_FIELD}
              label={targetTitle}
              placeholder={targetTitle}
              errors={errors}
              control={control}
            />
          )}
        </Box>
      </HStack>
      {!(showAdditionalRecipients && showCc && showBcc) && (
        <ButtonGroup colorScheme="gray" variant="outline" isAttached mb={4}>
          {!showAdditionalRecipients && (
            <Button onClick={() => setShowAdditionalRecipients((s) => !s)}>+ Recipients</Button>
          )}
          {!showCc && <Button onClick={() => setShowCc((s) => !s)}>Cc</Button>}
          {!showBcc && <Button onClick={() => setShowBcc((s) => !s)}>Bcc</Button>}
        </ButtonGroup>
      )}
      {showAdditionalRecipients && (
        <RecipientsContainer label="Additional Recipients" name="additionalRecipients" control={control} />
      )}
      {showCc && <RecipientsContainer label="Cc" name="ccRecipients" control={control} />}
      {showBcc && <RecipientsContainer label="Bcc" name="bccRecipients" control={control} />}
      <>
        <TextInputField
          {...SUBJECT_FIELD}
          errors={errors}
          register={register}
          rightButton={{ width: "7.5rem", text: "Personalize", onClick: tokenModal.onOpen }}
          formFieldProps={{ mb: 4 }}
        />
        <ActionRichTextAreaField
          actionType={actionType}
          targetType={targetType}
          {...BODY_FIELD}
          errors={errors}
          control={control}
        />
      </>
      <Modal modal={{ ...tokenModal }}>
        <PersonalizationTokenMenu
          actionType="email"
          targetType={targetType}
          onSelect={(token) => {
            const value = `${subject}{{${token.id}}}`;
            setValue(SUBJECT_FIELD.name as keyof Schema, value, { shouldDirty: true });
            tokenModal.onClose();
          }}
        />
      </Modal>
    </>
  );
}
