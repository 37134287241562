import PageTitle from "components/PageTitle";
import PeopleTeamDashboardStats from "components/PeopleTeamDashboard/PeopleTeamDashboardStats";
import AppPage from "layouts/AppPage";

export default function PeopleTeamDashboardPage() {
  return (
    <AppPage>
      <PageTitle text="Onboarding Stats" />
      <PeopleTeamDashboardStats />
    </AppPage>
  );
}
