import { FormLabel, TabPanel } from "@chakra-ui/react";
import ControlledTabs from "components/Form/ControlledTabs";
import { GenericAsyncSelect } from "components/Form/Select/GenericAsyncSelect";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { titleize } from "helpers/string";
import { MessageServiceChannel } from "models/integration/messageService";
import { OnboardingJourneyNetworkRelation } from "models/record_target";
import { Control, FieldErrors, FieldValues } from "react-hook-form";
import GenericAsyncSelectField from "./SelectFields/GenericAsyncSelectField";
import UserSelectField from "./SelectFields/UserSelectField";

export default function TargetableTypeField<Schema extends FieldValues>({
  fieldName,
  label,
  targetTitle,
  control,
  errors,
}: {
  fieldName?: string;
  label?: string;
  targetTitle?: string;
  control: Control<Schema>;
  errors: FieldErrors<Schema>;
}) {
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <ControlledTabs
        control={control}
        name={`${fieldName}.targetType`}
        options={[
          { label: "Network", value: "OnboardingJourneyNetworkRelation" },
          { label: "Specific Individual", value: "User" },
          { label: "Channel", value: "MessageServiceChannel" },
        ]}
      >
        <TabPanel>
          <GenericAsyncSelectField
            name={`${fieldName}.targetId`}
            label="Recipient"
            placeholder={targetTitle}
            fetchPath={API_ROUTES.onboardingJourneyNetworkRelations.base}
            extractValue={(v: OnboardingJourneyNetworkRelation) => v.id}
            embedOption={(v: OnboardingJourneyNetworkRelation) => ({
              value: v.id,
              label: `${titleize(v.name)}`,
              rawValue: v,
            })}
            control={control}
            errors={errors}
          />
        </TabPanel>
        <TabPanel>
          <UserSelectField name={`${fieldName}.targetId`} label="Recipient" control={control} errors={errors} />
        </TabPanel>
        <TabPanel>
          <GenericAsyncSelect
            name={`${fieldName}.targetId`}
            placeholder="Channel"
            fetchPath={API_ROUTES.messageServices.channels.base}
            extractValue={(v: MessageServiceChannel) => v.id}
            embedOption={(v) => ({
              value: v.id,
              label: `#${v.name}`,
              rawValue: v,
            })}
            control={control}
            errors={errors}
          />
        </TabPanel>
      </ControlledTabs>
    </>
  );
}

TargetableTypeField.defaultProps = {
  fieldName: null,
  label: null,
  targetTitle: "Target",
};
