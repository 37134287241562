import {
  Button, Flex, HStack, Icon, Spacer, Text, VStack,
} from "@chakra-ui/react";
import { RadioCard, RadioCardGroup } from "components/FormElements/RadioCardGroup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { OnboardingJourneyNetworkRelation } from "models/record_target";
import { titleize } from "helpers/string";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import { Schema, schema } from "./schema";
import { SelectEmailTargetValue } from "./models";
import {
  CUSTOM_EMAIL_FIELD,
  EMAIL_TYPE_FIELD,
  RELATION_FIELD,
  SELECTABLE_EMAIL_TARGET_TYPE,
  USER_FIELD,
} from "./fields";

function EmailTargetType({ name, icon }: { name: string; icon: any }) {
  return (
    <Flex direction="column" gap="2px" justify="center" alignItems="stretch" height="100%">
      <Text textAlign="center" fontWeight="600" fontSize={14}>
        {name}
      </Text>
      <VStack alignItems="center" gap="0">
        <Icon as={icon} boxSize="6" color="fg.subtle" />
      </VStack>
    </Flex>
  );
}

export default function NewRecipientForm({ onSubmit }: { onSubmit: (data: Schema) => void }) {
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { emailTargetType: "relation", emailType: "work_email" },
  });

  const emailTargetType = watch("emailTargetType");

  useEffect(() => {
    if (emailTargetType === "user") {
      setValue("recipient", { targetType: "User", targetId: "" });
    } else if (emailTargetType === "relation") {
      setValue("recipient", { targetType: "OnboardingJourneyNetworkRelation", targetId: "" });
    }
  }, [emailTargetType, setValue]);

  return (
    <>
      <RadioCardGroup<SelectEmailTargetValue>
        direction="row"
        justify="stretch"
        defaultValue={emailTargetType}
        spacing={3}
        onChange={(value) => setValue("emailTargetType", value)}
        flexWrap="wrap"
        py={4}
      >
        {SELECTABLE_EMAIL_TARGET_TYPE.map((field) => (
          <RadioCard
            key={field.value}
            value={field.value}
            alignSelf="stretch"
            minW="120px"
            maxW="120px"
            minH="100px"
            maxH="100px"
          >
            <EmailTargetType {...field} />
          </RadioCard>
        ))}
      </RadioCardGroup>
      {emailTargetType === "user" && <UserSelectField {...USER_FIELD} control={control} errors={errors} />}
      {emailTargetType === "relation" && (
        <GenericAsyncSelectField
          {...RELATION_FIELD}
          fetchPath={API_ROUTES.onboardingJourneyNetworkRelations.base}
          extractValue={(v: OnboardingJourneyNetworkRelation) => v.id}
          embedOption={(v: OnboardingJourneyNetworkRelation) => ({
            value: v.id,
            label: `${titleize(v.name)}`,
            rawValue: v,
          })}
          control={control}
          errors={errors}
        />
      )}
      {emailTargetType !== "custom" && (
        <SingleSelectField
          {...EMAIL_TYPE_FIELD}
          control={control}
          options={[
            { label: "Work", value: "work_email" },
            { label: "Personal", value: "personal_email" },
          ]}
          errors={errors}
        />
      )}
      {emailTargetType === "custom" && <TextInputField {...CUSTOM_EMAIL_FIELD} register={register} errors={errors} />}
      <HStack>
        <Spacer />
        <Button onClick={handleSubmit(onSubmit)}>Add</Button>
      </HStack>
    </>
  );
}
