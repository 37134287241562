import { PropsWithChildren } from "react";
import { Button } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

interface InnerSidebarNavButtonProps extends PropsWithChildren {
  route: string;
}

export default function InnerSidebarNavButton({ route, children, ...rest }: InnerSidebarNavButtonProps) {
  const { pathname } = useLocation();
  const isActive = pathname === route;

  return (
    <Button
      as={RouterLink}
      to={route}
      variant="ghost"
      fontWeight={isActive ? "bold" : ""}
      color="fg.subtle"
      {...rest}
      width="100%"
    >
      {children}
    </Button>
  );
}
