import { Button, Card, CardBody, CardHeader, Stack, useToast, Heading } from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import { ADMIN_ROUTES, API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { QUERY_KEYS } from "definitions/constants";
import { useEffect } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import AppPage from "layouts/AppPage";
import useNavbar from "hooks/useNavbar";
import { useForm } from "react-hook-form";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import TextAreaField from "components/FormElements/Fields/TextAreaField";

const queryKey = ["admin", QUERY_KEYS.companies, "emailConfiguration"];

type Nullable<T> = { [K in keyof T]: T[K] | null };

type EmailConfiguration = Nullable<{
  id: string;
  companyId: string;
  companyName: string;
  from: string;
  replyTo: string;
  style: string;
  head: string;
  header: string;
  footer: string;
}>;

export default function EmailConfigurationPage() {
  const queryClient = useQueryClient();
  const { setPageTitle, setBreadcrumbs } = useNavbar();
  const toast = useToast({
    position: "top",
    duration: 5000,
    isClosable: true,
    variant: "subtle",
    status: "success",
  });

  useEffect(() => {
    setPageTitle("Email Configuration");
    setBreadcrumbs([
      { name: "Admin", toUrl: ADMIN_ROUTES.hub },
      { name: "Companies", toUrl: ADMIN_ROUTES.companies.index },
      { name: "Email Configuration", isCurrentPage: true },
    ]);
  }, [setBreadcrumbs, setPageTitle]);

  const { companyId } = useParams();

  const { data: emailConfiguration } = useQuery<EmailConfiguration>({
    queryKey,
    queryFn: async () => apiClient.get<EmailConfiguration>(generatePath(API_ROUTES.admin.companies.emailConfiguration.index, { companyId })),
  });

  const { handleSubmit, register } = useForm<EmailConfiguration>({
    mode: "onBlur",
    values: emailConfiguration,
  })

  const { mutate } = useMutation({
    mutationFn: (data: EmailConfiguration) => apiClient.post<{ success: boolean }>(generatePath(API_ROUTES.admin.companies.emailConfiguration.index, { companyId }), data),
    onSuccess: async () => {
      toast({ title: "Email configuration updated successfully" });
      queryClient.invalidateQueries({ queryKey });
    },
    onError: console.error,
  });

  return (
    <AppPage>
      <Card mt="8" p="4">
        <CardHeader>
          <Heading size="sm" textTransform="capitalize">
            {`${emailConfiguration?.companyName || "Company"} Email Configuration`}
          </Heading>
        </CardHeader>
        <CardBody>
          <Stack as="form" onSubmit={handleSubmit((data) => mutate(data), console.error)} spacing="4">
            {[{ name: "from", label: "From", type: "textinput" }, { name: "replyTo", label: "Reply To", type: "textinput" }, { name: "style", label: "Style" }, { name: "head", label: "Head" }, { name: "header", label: "Header" }, { name: "footer", label: "Footer" }].map(({ name, label, type }) => (
              type === "textinput" ? <TextInputField key={name} name={name} label={label} register={register} /> : <TextAreaField key={name} name={name} label={label} register={register} />
            ))}
            <Button type="submit">Save</Button>
          </Stack>
        </CardBody>
      </Card>
    </AppPage>
  );
}
