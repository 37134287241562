/* eslint-disable object-curly-newline */
import { Box, Card, CardBody, Divider, HStack, Heading, Skeleton, Stack, StackProps, Text } from "@chakra-ui/react";
import { Fragment } from "react";

interface StatValue {
  label: string;
  value: string;
}

interface MultiStatValue {
  heading: string;
  stat1: StatValue;
  stat2: StatValue;
  stat3?: StatValue;
}

function StatColumn({ label, value }: StatValue) {
  return (
    <Stack align="center" justify="stretch" height="100%" minWidth="125px">
      <Text textStyle="sm" color="fg.muted">
        {label}
      </Text>
      <Heading color="fg.muted" size="sm">
        {value}
      </Heading>
    </Stack>
  );
}

function ThreeStatsRow({ heading, stat1, stat2, stat3, ...rest }: MultiStatValue & StackProps) {
  return (
    <HStack {...rest} alignItems="flex-end">
      <Heading color="fg.muted" size="xs" mr={6} width="33%">
        {heading}
      </Heading>
      <StatColumn {...stat1} />
      <StatColumn {...stat2} />
      {stat3 && <StatColumn {...stat3} />}
    </HStack>
  );
}

ThreeStatsRow.defaultProps = {
  stat3: undefined,
};

export function MultiStatCard({ stats, width, height }: { stats: MultiStatValue[]; width?: string; height?: string }) {
  return (
    <Card my={4} px={4} width={width} height={height}>
      <Skeleton isLoaded={!!stats.length}>
        <CardBody>
          {stats.map((statValue, index) => (
            <Fragment key={statValue.heading}>
              {index !== 0 ? <Divider /> : null}
              <ThreeStatsRow
                {...statValue}
                {...(index === 0 ? { mt: 0 } : { mt: 3 })}
                {...(index === stats.length - 1 ? { mb: 0 } : { mb: 3 })}
              />
            </Fragment>
          ))}
        </CardBody>
      </Skeleton>
    </Card>
  );
}

MultiStatCard.defaultProps = {
  width: "100%",
  height: "auto",
};

export function Stat(props: StatValue) {
  const { label, value, ...boxProps } = props;
  return (
    <Box px={{ base: "4", md: "6" }} py={{ base: "5", md: "6" }} borderRadius="lg" boxShadow="sm" {...boxProps}>
      <Stack>
        <Text textStyle="sm" color="fg.muted">
          {label}
        </Text>
        <Heading color="fg.muted" size={{ base: "sm", md: "md" }}>
          {value}
        </Heading>
      </Stack>
    </Box>
  );
}
