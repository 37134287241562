import { Box, CardProps, Flex, Text } from "@chakra-ui/react";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import DashboardCard from "./DashboardCard";

interface IssuesCardProps extends CardProps {
  issuesCount: number;
}

export default function IssuesCard({ issuesCount, ...rest }: IssuesCardProps) {
  const automationModifier = issuesCount > 1 ? "s" : "";
  const needsModifier = issuesCount > 1 ? "" : "s";

  return (
    <DashboardCard
      title="Issues"
      emoji="🚨"
      buttonText="Resolve"
      destination={PEOPLE_TEAM_ROUTES.issues.error}
      {...rest}
    >
      <Flex direction="column" justifyContent="center" alignItems="start" h="100%">
        <Box as="p">
          <Text as="span" fontSize="lg" fontWeight="bold">
            {issuesCount}
          </Text>
          <Text as="span" fontSize="lg">
            {` issue${automationModifier} need${needsModifier} attention`}
          </Text>
        </Box>
      </Flex>
    </DashboardCard>
  );
}
