import { CreateToastFnReturn, Button } from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useSyncChannels } from "features/Integrations/hooks";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { MessageServiceChannel } from "models/integration/messageService";

export default function SyncChannelsButton() {
  const {
    performRequest: syncChannels,
    state: { loading: isLoading },
  } = useSyncChannels()

  return (
    <Button onClick={() => syncChannels()} isLoading={isLoading} loadingText="Syncing Channels">
      Sync Channels
    </Button>
  );
}
