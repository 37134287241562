import {
  Button, Center, CenterProps, HStack, Icon, Square, Text, useColorModeValue, VStack,
} from "@chakra-ui/react";
import Dropzone from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";

interface ProfileDropzoneProps extends CenterProps {
  onFileAccept: (acceptedFile: File) => void;
}

export default function ProfileDropzone({ onFileAccept, ...props }: ProfileDropzoneProps) {
  return (
    <Center borderWidth="1px" borderRadius="lg" px="6" py="4" bg={useColorModeValue("white", "gray.800")} {...props}>
      <Dropzone
        accept={{ "image/*": [".png", ".jpg", ".jpeg", ".gif", ".svg", ".heic"] }}
        multiple={false}
        maxSize={2_097_152}
        onDrop={(acceptedFiles) => onFileAccept(acceptedFiles[0])}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input type="file" {...getInputProps()} />
              <VStack spacing="3">
                <Square size="10" bg="bg.subtle" borderRadius="lg">
                  <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
                </Square>
                <VStack spacing="1">
                  <HStack spacing="1" whiteSpace="nowrap">
                    <Button variant="text" colorScheme="brand" size="sm">
                      Click to upload
                    </Button>
                    <Text textStyle="sm" color="fg.muted">
                      or drag and drop
                    </Text>
                  </HStack>
                  <Text textStyle="xs" color="fg.muted">
                    PNG, JPG or GIF up to 2MB
                  </Text>
                </VStack>
              </VStack>
            </div>
          </section>
        )}
      </Dropzone>
    </Center>
  );
}
