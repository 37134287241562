import { createIcon } from "@chakra-ui/react";

const CaminoPeopleTeam = createIcon({
  displayName: "CaminoPeopleTeam",
  viewBox: "1 1 16 14",
  path: (
    <>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.00195 14.625H8.67475C8.67475 12.7265 7.18099 11.1875 5.33835 11.1875C3.49571 11.1875 2.00195 12.7265 2.00195 14.625Z"
        fill="#EABDB8"
      />
      <path
        d="M9.09573 13.2027C8.89309 12.7227 8.59902 12.2868 8.22991 11.919C7.86192 11.5503 7.426 11.2563 6.94622 11.0532C6.94192 11.0511 6.93762 11.05 6.93333 11.0479C7.60257 10.5645 8.03763 9.77705 8.03763 8.88867C8.03763 7.41699 6.84524 6.22461 5.37356 6.22461C3.90188 6.22461 2.7095 7.41699 2.7095 8.88867C2.7095 9.77705 3.14456 10.5645 3.8138 11.0489C3.8095 11.0511 3.8052 11.0521 3.80091 11.0543C3.31966 11.2573 2.88782 11.5484 2.51721 11.9201C2.14845 12.2881 1.85443 12.724 1.65139 13.2038C1.45194 13.6735 1.34436 14.1771 1.3345 14.6873C1.33421 14.6988 1.33622 14.7102 1.34041 14.7209C1.3446 14.7315 1.35089 14.7413 1.3589 14.7495C1.36691 14.7577 1.37648 14.7642 1.38705 14.7687C1.39762 14.7731 1.40897 14.7754 1.42044 14.7754H2.06497C2.11223 14.7754 2.14983 14.7378 2.15091 14.6916C2.17239 13.8623 2.5054 13.0856 3.09407 12.497C3.70315 11.8879 4.51204 11.5527 5.37356 11.5527C6.23509 11.5527 7.04397 11.8879 7.65305 12.497C8.24173 13.0856 8.57473 13.8623 8.59622 14.6916C8.59729 14.7389 8.63489 14.7754 8.68216 14.7754H9.32669C9.33816 14.7754 9.34951 14.7731 9.36008 14.7687C9.37065 14.7642 9.38022 14.7577 9.38823 14.7495C9.39624 14.7413 9.40252 14.7315 9.40671 14.7209C9.4109 14.7102 9.41291 14.6988 9.41263 14.6873C9.40188 14.1738 9.29554 13.6743 9.09573 13.2027ZM5.37356 10.7363C4.8805 10.7363 4.41643 10.544 4.06731 10.1949C3.71819 9.8458 3.52591 9.38174 3.52591 8.88867C3.52591 8.39561 3.71819 7.93154 4.06731 7.58242C4.41643 7.2333 4.8805 7.04102 5.37356 7.04102C5.86663 7.04102 6.33069 7.2333 6.67981 7.58242C7.02893 7.93154 7.22122 8.39561 7.22122 8.88867C7.22122 9.38174 7.02893 9.8458 6.67981 10.1949C6.33069 10.544 5.86663 10.7363 5.37356 10.7363Z"
        fill="#959393"
      />
      <path
        d="M9.0708 7.51438L9.64874 5.99902L10.5862 6.93644L9.0708 7.51438Z"
        fill="#DFE7F2"
      />
      <path
        d="M15.5397 4.26984C15.5397 6.07572 14.0757 7.53967 12.2698 7.53967C10.464 7.53967 9 6.07572 9 4.26984C9 2.46396 10.464 1 12.2698 1C14.0757 1 15.5397 2.46396 15.5397 4.26984Z"
        fill="#DFE7F2"
      />
      <path
        d="M15.8667 4.2426C15.868 4.7461 15.7503 5.2428 15.5233 5.69223C15.2542 6.23078 14.8404 6.68376 14.3283 7.00043C13.8163 7.3171 13.2261 7.48495 12.6241 7.48519C12.1206 7.4865 11.6239 7.36886 11.1744 7.14185L9 7.86667L9.72481 5.69223C9.49781 5.2428 9.38017 4.7461 9.38148 4.2426C9.38171 3.64053 9.54957 3.05041 9.86624 2.53835C10.1829 2.02629 10.6359 1.6125 11.1744 1.34334C11.6239 1.11634 12.1206 0.998698 12.6241 1.00001H12.8148C13.6099 1.04388 14.361 1.37949 14.9241 1.9426C15.4872 2.5057 15.8228 3.25672 15.8667 4.05186V4.2426Z"
        stroke="#959393"
        fill="#DFE7F2"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </>
  ),
});

export default CaminoPeopleTeam;
