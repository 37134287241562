import { relationTargetableField } from "../Shared/fields";

const TITLE_FIELD = {
  type: "text",
  label: "Title",
  placeholder: "Title",
  name: "content.title",
};

const DESCRIPTION_FIELD = {
  type: "text",
  label: "Task Description",
  name: "content.description",
};

const MESSAGE_SUGGESTION_FIELD = {
  type: "text",
  label: "Message Suggestion",
  placeholder: "Message Suggestion",
  name: "content.messageBody",
};

const MESSAGE_TARGET_FIELD = relationTargetableField({
  name: "content.messageTarget",
  placeholder: "Message Target",
  label: "Message Target",
});

export {
  TITLE_FIELD, DESCRIPTION_FIELD, MESSAGE_SUGGESTION_FIELD, MESSAGE_TARGET_FIELD,
};
