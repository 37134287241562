/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Heading, Text, Icon, Card, CardBody, Skeleton, Flex, Tooltip,
} from "@chakra-ui/react";
import { Action } from "models/automation";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { actionableTypeConverter, actionableTypeToIcon } from "helpers/workflowActionableConverters";
import { truncate } from "lodash";
import { TemplateAction } from "models/automation/templateWorkflow";
import { isTemplateAction } from "helpers/action";
import ActionMenuButton from "./ActionMenuButton";
import { ActionTargetText, ActionTriggerText } from "./DisplayInfo";

interface ActionCardProps {
  rowData: Action | TemplateAction;
  getCardProps: () => object;
}

export default function ActionCard({ rowData: action, getCardProps, ...rest }: ActionCardProps) {
  if (!action) {
    return <Skeleton height="100px" />;
  }
  let icon;
  let readableActionType;
  let readableActionName;
  let showPath;
  if (isTemplateAction(action)) {
    icon = actionableTypeToIcon({ actionType: action.actionType, taskType: action.taskType });
    readableActionType = actionableTypeConverter({ actionType: action.actionType, taskType: action.taskType });
    readableActionName = action.name.length >= 40 ? truncate(action.name, { length: 43, omission: "..." }) : action.name;
    showPath = generatePath(PEOPLE_TEAM_ROUTES.templateActions.show, { id: action.id });
  } else {
    icon = actionableTypeToIcon(action.actionable);
    readableActionType = actionableTypeConverter(action.actionable);
    readableActionName = action.actionable.name.length >= 40
      ? truncate(action.actionable.name, { length: 43, omission: "..." })
      : action.actionable.name;
    showPath = generatePath(PEOPLE_TEAM_ROUTES.actions.show, { id: action.id });
  }

  return (
    <Skeleton isLoaded={!!action}>
      <Card p="0" direction="row" boxShadow="md" mb="2" maxW="1000px" overflow="hidden" {...rest}>
        <Tooltip label={readableActionType} aria-label={`Action Type ${readableActionType}`}>
          <Flex
            padding="4"
            objectFit="cover"
            direction="column"
            align="center"
            justify="center"
            bg="brand.400"
            width="100px"
          >
            <Icon as={icon} w={8} h={8} color="white" />
            <Text fontSize="md" fontWeight="medium" textAlign="center" color="white" whiteSpace="normal">
              {readableActionType}
            </Text>
          </Flex>
        </Tooltip>
        <CardBody minW="600px" maxW="900px" display="flex" py="4" pr="4">
          <Flex direction="column" align="flex-start" gap="2" width="100%">
            <Flex justify="space-between" width="100%">
              <RouterLink to={showPath}>
                <Heading size="xs" textAlign="start">
                  {readableActionName}
                </Heading>
              </RouterLink>
              {!isTemplateAction(action) ? <ActionMenuButton action={action} /> : null}
            </Flex>
            <Flex align="center" gap={8}>
              <ActionTargetText includeEmailType action={action} ml="4" />
              <ActionTriggerText action={action} />
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </Skeleton>
  );
}
