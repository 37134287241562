import { useRef } from "react";
import {
  Box,
} from "@chakra-ui/react";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { apiNewHireJourneyPathById } from "helpers/url";
import { useForm } from "react-hook-form";
import AutoForm from "./AutoForm";
import { PENDING_FORM_SECTIONS } from "./pendingNewHireForm";

interface PendingNewHireFormProps {
  newHireJourneyId: string;
  form: ReturnType<typeof useForm>;
  isLoaded: boolean;
}

function PendingNewHireForm({ form, newHireJourneyId, isLoaded }: PendingNewHireFormProps) {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box width="100%" maxWidth="700px">
      <Box ref={containerRef} height="calc(100vh - 96px)" overflowY="auto" position="relative">
        <AutoForm
          form={form}
          sections={PENDING_FORM_SECTIONS}
          isLoaded={isLoaded}
          submitUrl={apiNewHireJourneyPathById(newHireJourneyId || "")}
        />
      </Box>
    </Box>
  );
}

export default PendingNewHireForm;
