/* eslint-disable no-nested-ternary */
import {
  Box,
  LightMode,
  SystemStyleObject,
  Table as ChakraTable,
  TableProps,
  Tbody,
  Td,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import { BlueBorderCardWithHover } from "components/Card/BlueBorderCard";
import SmallArrowPagination from "./SmallArrowPagination";
import { SkeletonRows } from "./SkeletonRow";
import TableCell, { TableHeader } from "./TableComponents";

interface BaseTableProps<T> extends TableProps {
  table: Table<T>;
  sx?: SystemStyleObject;
  textWrap?: boolean;
  isLoading?: boolean;
  pageSizeOptions?: number[];
  includeNumberedRow?: boolean;
  emptyStateComponent?: React.ReactNode;
  containerProps?: object;
  thProps?: object;
  theadProps?: object;
}

export default function BaseRowTable({
  table,
  sx = {},
  textWrap = false,
  isLoading = false,
  includeNumberedRow = false,
  pageSizeOptions = undefined,
  emptyStateComponent = null,
  containerProps = {},
  thProps = {},
  theadProps = {},
  ...props
}: BaseTableProps<any>) {
  // eslint-disable-next-line no-underscore-dangle
  const columnNumber = includeNumberedRow ? table._getColumnDefs().length + 1 : table._getColumnDefs().length;

  return (
    <Box sx={sx} width="100%">
      <SmallArrowPagination pageSizeOptions={pageSizeOptions} table={table} />
      <BlueBorderCardWithHover maxW="100%" overflow="scroll" width="100%" {...containerProps}>
        <ChakraTable overflow="scroll" borderRadius="5px" variant="simple" {...props}>
          <Thead {...theadProps}>
            <LightMode>
              {table.getHeaderGroups().map((headerGroup) => (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHeader key={header?.column?.id} header={header} {...thProps} />
                  ))}
                </Tr>
              ))}
            </LightMode>
          </Thead>
          <Tbody {...(isLoading ? { pt: "4" } : {})}>
            {isLoading ? (
              <SkeletonRows columns={columnNumber} rows={10} speed={1.75} />
            ) : !table.getRowModel().rows?.length && emptyStateComponent ? (
              <Tr>
                <Td colSpan={columnNumber} textAlign="center">
                  {emptyStateComponent}
                </Td>
              </Tr>
            ) : (
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell?.id} cell={cell} textWrap={textWrap || false} />
                  ))}
                </Tr>
              ))
            )}
          </Tbody>
        </ChakraTable>
      </BlueBorderCardWithHover>
    </Box>
  );
}
