import { Routes, Route, Navigate, generatePath } from "react-router-dom";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import allRoutes from "definitions/routes";
import useCaminoStore from "hooks/useCaminoStore";
import { useEffect } from "react";
import apiClient from "services/ApiClient";
import { User } from "models/user";
import RequestInterceptor from "services/ApiClient/requestInterceptor";

export default function AllRoutes() {
  const token = document.querySelector("[name=csrf-token]")?.getAttribute("content");

  apiClient.interceptors = [
    new RequestInterceptor((url, opts) => {
      const headers = opts.headers || {};
      if (token) {
        headers["X-CSRF-Token"] = token;
      }
      return { url, options: { ...opts, headers } };
    }),
  ];

  const setCurrentUser = useCaminoStore((state) => (arg0: User | null) => {
    state.updateCurrentUser(arg0);
    state.setValue({ currentCompany: arg0?.company });
  });

  useEffect(() => {
    const updateCurrentUser = async () => {
      const user = await apiClient.get<User>(generatePath(API_ROUTES.users.show, { id: "current" })).catch(() => null);
      setCurrentUser(user);
    };

    updateCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      {allRoutes.map(({ path, component }) => (
        <Route key={path} path={path} element={component} />
      ))}
      <Route key="undefined-route" path="*" element={<Navigate to={SHARED_ROUTES.home} replace />} />
    </Routes>
  );
}
