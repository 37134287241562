import {
  Card, CardBody, CardHeader, CardProps, Heading, Skeleton,
} from "@chakra-ui/react";
import { generatePath } from "react-router-dom";
import { FaRoute } from "react-icons/fa6";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { LinkTitleCardAttribute } from "components/Card/TitleCard";
import { getPathName } from "helpers/path";

interface AssignedPathInfoCardProps extends CardProps {
  data: AssignedPath;
  isLoading: boolean;
}

export default function AssignedPathInfoCard({ data, isLoading, ...rest }: AssignedPathInfoCardProps) {
  const user = data?.onboardingJourney.user;
  return (
    <Card {...rest}>
      <Skeleton isLoaded={!isLoading}>
        <CardHeader pb="0">
          <Heading size="md">{`${user.firstName}'s ${getPathName(data?.workflow)}`}</Heading>
        </CardHeader>
        <CardBody ml="8">
          <LinkTitleCardAttribute
            icon={FaRoute}
            label="Original Path"
            value={data?.workflow.name}
            to={generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: data?.workflow.id || "" })}
          />
        </CardBody>
      </Skeleton>
    </Card>
  );
}
