import AppPage from "layouts/AppPage";
import { useSearchParams } from "react-router-dom";
import { Center, Flex, Heading } from "@chakra-ui/react";
import SuccessConfetti from "components/SuccessConfetti";
import { titleize } from "helpers/string";

export default function IntegrationSuccessPage() {
  const [searchParams] = useSearchParams();
  const partnerName = searchParams.get("partner");
  const type = searchParams.get("type");
  const integrationText = type === "ind-oauth"
    ? `Successfully authorized Camino to send messages on your behalf via ${titleize(
      partnerName,
    )} - You may close this tab`
    : `Successfully connected to ${titleize(partnerName)} - You may close this tab`;
  return (
    <AppPage>
      <SuccessConfetti />
      <Flex width="100%" height="100%" mt="80px" px="10" align="start">
        <Center>
          <Heading color="fg.muted" size="lg">
            {integrationText}
          </Heading>
        </Center>
      </Flex>
    </AppPage>
  );
}
