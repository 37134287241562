import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { NewHireFormValues } from "features/PendingNewHire/Form/definition";
import { apiNewHireJourneyPathById } from "helpers/url";
import { pick } from "lodash";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { NewHireJourney } from "models/onboarding";
import { User } from "models/user";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

const newHireJourneyKeys = {
  all: ["newHireJourneys"] as const,
  lists: () => [...newHireJourneyKeys.all, "list"] as const,
  list: (filters: string) => [...newHireJourneyKeys.lists(), { filters }] as const,
  details: () => [...newHireJourneyKeys.all, "detail"] as const,
  detail: (id: string) => [...newHireJourneyKeys.details(), id] as const,
};

export const useNewHireJourneyDetailQuery = (id: string) => useQuery<ExtendedNewHireJourney>({
  queryKey: newHireJourneyKeys.detail(id),
  queryFn: async () => apiClient.get(apiNewHireJourneyPathById(id)),
});

export const useNewHiresListQuery = () => useQuery({
  queryKey: newHireJourneyKeys.lists(),
  queryFn: async () => apiClient.get(generatePath(API_ROUTES.newHireJourneys.base)),
});

type UserKeys = keyof User;

export const USER_FIELDS: UserKeys[] = [
  "firstName",
  "lastName",
  "preferredName",
  "title",
  "workEmail",
  "personalEmail",
  "pronouns",
  "timezone",
  "location",
  "rawLocation",
  "googleLocationId",
  "country",
  "department",
  "bio",
  "linkedinUrl",
  "managerId",
  "phoneCountryCode",
  "phoneNumber",
  "employmentType",
];

type NewHireJourneyKeys = keyof ExtendedNewHireJourney | "pending";

export const NEW_HIRE_JOURNEY_FIELDS: NewHireJourneyKeys[] = [
  "buddyId",
  "peopleTeamContactId",
  "startDate",
  "onboardingPlanUrl",
  "teamWikiUrl",
  "teamGoalsUrl",
  "pending",
  "shippingTrackingNumber",
  "shippingTrackingUrl",
  "shippingCarrier",
  "internalOnboardingChecklistUrl",
  "hrisOnboardingUrl",
];

export function useUpdateNewHireJourney(id: string) {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: (data: Partial<NewHireFormValues>) => {
      const user = pick(data, USER_FIELDS);
      const onboardingJourney = pick(data, NEW_HIRE_JOURNEY_FIELDS);
      const workflows = data?.workflowIds ? { workflowIds: data.workflowIds } : {};
      return apiClient.put<NewHireJourney>(generatePath(API_ROUTES.newHireJourneys.show, { id: id || "" }), {
        ...(Object.keys(user).length ? { user } : {}),
        ...(Object.keys(onboardingJourney).length ? { onboardingJourney } : {}),
        ...(Object.keys(workflows).length ? { workflows } : {}),
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneysPending, res.id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneysPendingCount] });
      queryClient.setQueryData(newHireJourneyKeys.detail(res.id), (oldData: NewHireJourney | undefined) => {
        if (!oldData) {
          return res;
        }
        return {
          ...oldData,
          ...res,
        };
      });
    },
    onError: (e) => {
      console.log(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}
