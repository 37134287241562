import { useTheme } from "@chakra-ui/react";
import { GenericAsyncSelect } from "components/Form/Select/GenericAsyncSelect";
import { SelectFieldProps } from "components/Form/typings";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { User } from "models/user";
import FormField from "../FormField";

export default function UserSelectField({
  name,
  label,
  helperText,
  errors,
  required,
  variant,
  placeholder,
  ...props
}: SelectFieldProps<User>) {
  const theme = useTheme();
  const formVariant = variant || theme.components.Form.defaultProps.variant;

  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      variant={formVariant}
    >
      <GenericAsyncSelect
        fetchPath={API_ROUTES.users.search}
        perPagePagination={300}
        embedOption={(user) => ({
          value: user.id,
          label: user.fullName ?? "",
          rawValue: user,
        })}
        extractValue={(user) => user.id}
        name={name}
        variant={formVariant}
        placeholder={formVariant === "floating" ? " " : placeholder}
        containerStyles={{ width: "100%" }}
        {...props}
      />
    </FormField>
  );
}

UserSelectField.defaultProps = {
  placeholder: "User Search (start typing...)",
};
