import { BasicUser } from "models/user";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import {
  HStack, Icon, Text, Link, Tooltip, Box, LinkProps,
} from "@chakra-ui/react";
import { targetText, actionableTypeConverter, actionableTypeToIcon } from "helpers/workflowActionableConverters";
import { MessageServiceChannel } from "models/integration/messageService";
import { Action } from "models/automation";
import { TaskType } from "models/task";

export function DisplayTypeAndTarget({
  actionType,
  taskType = undefined,
  relationTarget,
  specificTargetable,
}: {
  actionType: Action["actionable"]["actionType"] | "task_notifier" | "task_reminder";
  taskType?: TaskType;
  relationTarget: Action["actionable"]["relationTarget"] | undefined;
  specificTargetable: Action["actionable"]["specificTargetable"] | undefined;
}) {
  const target = targetText({ relationTarget, specificTargetable });
  return (
    <HStack spacing="3" width="100%">
      <Tooltip label={actionableTypeConverter({ actionType, taskType })}>
        <Box>
          <Icon boxSize="5" color="fg.muted" as={actionableTypeToIcon({ actionType, taskType })} />
        </Box>
      </Tooltip>
      <Text>{target}</Text>
    </HStack>
  );
}

interface ReceiverLinkOrTBDProps extends LinkProps {
  receiver?: BasicUser | MessageServiceChannel;
}

export default function ReceiverLinkOrTBD({ receiver = undefined, ...rest }: ReceiverLinkOrTBDProps) {
  if (!receiver) return "TBD";

  return "fullName" in receiver ? (
    <Link as={RouterLink} to={{ pathname: generatePath(SHARED_ROUTES.showUser, { id: receiver.id }) }} {...rest}>
      {receiver.fullName}
    </Link>
  ) : (
    `#${receiver.name}`
  );
}
