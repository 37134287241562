/* eslint-disable import/prefer-default-export */
import { CONTENT_VARIABLES } from "definitions/constants/issues";
import { snakeCase } from "lodash";
import { AssignedActionIssue } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import moment from "moment";

type MissingVariable = [target: string, variable: string];

export function findMissingContentVariables(errorMessage: string) {
  const results: MissingVariable[] = [];
  CONTENT_VARIABLES.forEach((variable) => {
    const regex = new RegExp(`([\\w\\s]+?)'s\\s*\\\`${variable}\\\``, "g");
    [...errorMessage.matchAll(regex)].forEach((match) => {
      results.push([match[1].trim(), variable]);
    });
  });
  return results;
}

export function missingContentString(missingVarriable: MissingVariable) {
  return `{{${snakeCase(missingVarriable[0])}.${missingVarriable[1]}}}`;
}

export function contentVariablesByGrouping(missingVariables: MissingVariable[]) {
  const grouped: Record<string, string[]> = {};
  let key = "";
  missingVariables.forEach((missingVariable) => {
    key = snakeCase(missingVariable[0]);
    if (!grouped[key]) {
      grouped[key] = [];
    }
    grouped[key].push(missingVariable[1]);
  });
  return grouped;
}

function removeDuplicateIssues(issues: AssignedActionIssue[]): AssignedActionIssue[] {
  const uniqueErrors = {};
  const severityLevels = {
    error: 2,
    warning: 1,
    info: 0,
  };

  issues.forEach((issue) => {
    const key = `${issue.type}|${issue.message}`;
    if (uniqueErrors[key]) {
      if (severityLevels[issue.severity] > severityLevels[uniqueErrors[key].severity]) {
        uniqueErrors[key] = issue;
      }
    } else {
      uniqueErrors[key] = issue;
    }
  });
  return Object.values(uniqueErrors);
}

export function removeSecondOrderIssues(issues: AssignedActionIssue[], action: AssignedAction) {
  const filteredIssues = removeDuplicateIssues(issues);
  const relationTarget = action?.workflowAction?.actionable?.relationTarget;
  const missingTargetId = filteredIssues.some((issue) => issue.type === "missing_target_id");
  if (!missingTargetId) {
    return filteredIssues;
  }
  const returnIssues = filteredIssues.filter((issue) => issue.type !== "undeliverable");
  const contentIssue = filteredIssues.find((issue) => issue.type === "template_rendering");
  let filterContentOut = false;
  if (contentIssue && missingTargetId) {
    const contentVariables = findMissingContentVariables(contentIssue.message);
    filterContentOut = contentVariables.every((contentVariable) => contentVariable[0] === relationTarget);
  }
  return filterContentOut ? returnIssues.filter((issue) => issue.type !== "template_rendering") : returnIssues;
}

export function findMissingNamespaces(errorMessage: string) {
  const regex = /^Namespaces used in template.+ - (.+)/;
  const match = errorMessage.match(regex);
  if (match) {
    return match[1].split(",").map((value: string) => value.trim());
  }
  return [];
}

export function isWarning(action: AssignedAction) {
  return (
    action?.attemptCount === 0
    && action?.trigger?.type === "date_time"
    && moment(action.trigger.data?.datetime) > moment()
  );
}

export function isError(action: AssignedAction) {
  return (
    action?.attemptCount > 0
    || (action?.trigger?.type === "date_time" && moment(action.trigger.data?.datetime) <= moment())
  );
}
