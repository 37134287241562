import { Box } from "@chakra-ui/react";
import DOMPurify from "dompurify";
import "./styles.css";

export default function EmbeddedHtml({ innerHtml }: { innerHtml: string }) {
  const sanitizedHtml = () => ({
    __html: DOMPurify.sanitize(innerHtml),
  });

  return <Box className="embedded-html" dangerouslySetInnerHTML={sanitizedHtml()} />;
}
