import { createIcon } from "@chakra-ui/react";

const CaminoManager = createIcon({
  displayName: "CaminoManager",
  viewBox: "0 0 18 16",
  path: (
    <>
      <path fill="#F7EFA2" d="M14.03 14c0-2.761-2.306-5-5.151-5-2.845 0-5.152 2.239-5.152 5H14.03z" />
      <path
        fill="#959393"
        d="M14.457 11.931a5.838 5.838 0 00-1.297-1.867 6.06
           6.06 0 00-1.924-1.26l-.02-.007c1.003-.703 1.655-1.849 1.655-3.14 0-2.141-1.787-3.876-3.992-3.876S4.887
           3.516 4.887 5.656c0 1.292.652 2.438 1.655
           3.142-.007.004-.013.005-.02.008a6.008 6.008 0 00-3.221 3.127 5.663
           5.663 0 00-.475 2.158.122.122 0 00.037.09.132.132
           0 00.092.038h.966c.07 0 .127-.055.128-.122a4.593 4.593 0
           011.414-3.192A4.872 4.872 0 018.879 9.53c1.291 0
           2.503.488 3.416 1.374a4.593 4.593 0 011.414
           3.192.126.126 0 00.128.122h.966a.132.132 0 00.092-.038.125.125 0
           00.037-.09 5.676 5.676 0 00-.475-2.16zM8.88 8.344c-.739
           0-1.434-.28-1.958-.788a2.63 2.63 0 010-3.8A2.794 2.794 0
           018.88 2.97c.739 0 1.434.28 1.958.787a2.63 2.63
           0 010 3.8 2.794 2.794 0
           01-1.958.788z"
      />
    </>
  ),
});

export default CaminoManager;
