import {
  LinkBoxProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { QUERY_KEYS } from "definitions/constants";
import React, { useCallback } from "react";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { yupResolver } from "@hookform/resolvers/yup";
import PathsSelectField from "components/FormElements/Fields/SelectFields/PathsSelectField";
import AddPathsButton from "./AddPathsButton";

interface AddNewPathModalProps extends LinkBoxProps {
  currentWorkflowIds: string[];
  newHireJourneyId: string;
}

interface AddPathToNewHireJourney {
  workflowIds: string[];
  newHireJourneyId: string;
}

const WORKFLOW_SELECT_FIELD = {
  type: "text",
  label: "Select Paths",
  name: "workflowIds",
};

const schema = yup.object({
  workflowIds: yup.array().of(yup.string().required()).required(),
});

export default function AddNewPathModal({
  currentWorkflowIds,
  newHireJourneyId,
  children,
  ...rest
}: AddNewPathModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();
  const {
    control,
    formState: { errors, isDirty, isValid },
    watch,
    handleSubmit,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: { workflowIds: currentWorkflowIds },
  });

  const { mutate, isPending: isLoading } = useMutation({
    mutationFn: (data: AddPathToNewHireJourney) => apiClient.post(API_ROUTES.assignedPaths.bulk, { data }),
    onSuccess: async (_data, variables) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneys, newHireJourneyId] });
      toast({
        title: `Path${variables.workflowIds.length > 1 ? "s" : ""} Added.`,
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      onClose();
    },
    onError: () => {
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const onSubmit = useCallback(
    (formData: any) => {
      mutate({ newHireJourneyId, ...formData });
    },
    [mutate, newHireJourneyId],
  );

  const workflowIds = watch("workflowIds");

  return (
    <>
      {children ? (
        React.cloneElement(children as JSX.Element, { onClick: onOpen, ...rest })
      ) : (
        <AddPathsButton onOpen={onOpen} {...rest} />
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Assign Paths to New Hire</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <PathsSelectField control={control} {...WORKFLOW_SELECT_FIELD} errors={errors} />
          </ModalBody>

          <ModalFooter>
            <ProceedCancelButtons
              handleSubmit={handleSubmit(onSubmit)}
              onClose={onClose}
              proceedText="Add Path(s)"
              proceedDisabled={!workflowIds?.length || !isDirty || !isValid}
              isLoading={isLoading}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
