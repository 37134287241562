import { FiMail, FiUser } from "react-icons/fi";
import { FaRegIdBadge } from "react-icons/fa";

const SELECTABLE_EMAIL_TARGET_TYPE = [
  { name: "Role", value: "relation", icon: FaRegIdBadge },
  { name: "Specific User", value: "user", icon: FiUser },
  { name: "Custom Email", value: "custom", icon: FiMail },
];

const RELATION_FIELD = {
  name: "recipient.targetId",
  label: "Select Role",
};

const USER_FIELD = {
  name: "recipient.targetId",
  label: "Select User",
};

const EMAIL_TYPE_FIELD = {
  name: "emailType",
  label: "Email Type",
};

const CUSTOM_EMAIL_FIELD = {
  name: "customEmail",
  label: "Email Address",
};

export {
  SELECTABLE_EMAIL_TARGET_TYPE, RELATION_FIELD, USER_FIELD, EMAIL_TYPE_FIELD, CUSTOM_EMAIL_FIELD,
};
