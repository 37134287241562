import {
  InputGroup, InputLeftElement, Input, InputGroupProps, Icon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";

interface SearchBarProps extends InputGroupProps {
  name: string;
  placeholder?: string;
  onInput: (data: any) => void;
  variant?: string;
  searchIconColor?: string;
}

export default function SearchBar({
  name, placeholder, onInput, variant, searchIconColor, ...rest
}: SearchBarProps) {
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    onInput(searchInput);
  }, [searchInput, onInput]);

  return (
    <InputGroup borderRadius="md" maxW="md" display="inline-flex" {...rest}>
      <InputLeftElement>
        <Icon as={FiSearch} color={searchIconColor} fontSize="lg" />
      </InputLeftElement>
      <Input
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
        placeholder={placeholder}
        variant={variant}
      />
    </InputGroup>
  );
}

SearchBar.defaultProps = {
  placeholder: "Search",
  variant: "Outline.accent",
  searchIconColor: "fg.muted",
};
