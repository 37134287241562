/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  Heading, Text, Icon, Card, CardBody, Skeleton, Flex, useColorModeValue, Box,
} from "@chakra-ui/react";
import { AssignedActionIssue } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { TbAlertTriangleFilled } from "react-icons/tb";
import { LuXOctagon } from "react-icons/lu";
import {
  TitleCardAttributeActionType,
  TitleCardAttributeNewHire,
  TitleCardAttributeRecipient,
  TitleCardAttributeAssignedActionScheduledAt,
  TitleCardAttributeStartDate,
} from "components/Card/TitleCardAttribute";
import { IssueTypeMap } from "features/Issues/definitions";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import {
  SendNowButton, SkipButton, SendTomorrowButton, SendNextMondayButton, EditSendDateButton,
} from "./Button";
import { isError, removeSecondOrderIssues } from "./helpers";

interface IssueCardProps {
  action: AssignedAction;
  issue: AssignedActionIssue;
}

function IssueCard({ action, issue }: IssueCardProps) {
  const bg = useColorModeValue("sortaOrange.200", "gray.500");
  const issueTypeFunction = IssueTypeMap[issue.type] || ((_action, _issue) => null);
  const issueInfo = issueTypeFunction(action, issue);
  if (!issueInfo) {
    console.error("issueInfo", issueInfo);
    return null;
  }
  return (
    <Flex align="flex-start" direction="column" bg={bg} p="4" m="2" borderRadius="5px" maxW="700px" overflow="hidden">
      <Heading whiteSpace="normal" textAlign="start" as="h6" size="xs" color="fg.subtle" mb={2}>
        {issueInfo.humanReadable}
      </Heading>
      <Text whiteSpace="normal" textAlign="start" fontSize="sm" mb={2} color="fg.muted">
        {issueInfo.additionalContext}
      </Text>
      <Text whiteSpace="normal" textAlign="start" fontSize="sm" fontWeight="bold" fontStyle="italic" color="fg.muted">
        {issueInfo.recommendation}
      </Text>
      {issueInfo.resolutionOptions(action)}
    </Flex>
  );
}

interface ActionIssueCardProps {
  rowData: AssignedAction;
  getCardProps: () => object;
}

export default function ActionIssueCard({ rowData: action, getCardProps, ...rest }: ActionIssueCardProps) {
  const isErrorIssue = isError(action);
  const [icon, iconColor, issueText1, issueText2] = isErrorIssue
    ? [LuXOctagon, "rose.500", "Action", "Failed"]
    : [TbAlertTriangleFilled, "sortaOrange.300", "Upcoming", "Failure"];

  return (
    <Card p={4} boxShadow="md" mb="2" maxW="1000px" {...rest}>
      <Skeleton isLoaded={!!action}>
        <CardBody maxW="900px" display="flex">
          <Flex direction="column" align="center" mt={2} mr={12}>
            <Icon as={icon} w={10} h={10} color={iconColor} />
            <Text fontSize="xs" fontWeight="medium" textAlign="center" color="chakra-body-text">
              {issueText1}
            </Text>
            <Text fontSize="xs" fontWeight="medium" textAlign="center" color="chakra-body-text">
              {issueText2}
            </Text>
          </Flex>
          <Flex direction="column">
            <RouterLink to={generatePath(PEOPLE_TEAM_ROUTES.assignedActions.show, { id: action?.id || "" })}>
              <Heading size="xs" mb={2} textAlign="start">
                {action.name}
              </Heading>
            </RouterLink>
            <Flex width="100%" flexWrap="wrap" gap="12">
              <Flex direction="column" gap="4" my="2">
                <TitleCardAttributeActionType action={action} />
                <TitleCardAttributeNewHire newHireJourney={action?.onboardingJourney} />
              </Flex>
              <Flex direction="column" gap="4" my="2">
                <TitleCardAttributeRecipient action={action} />
                <TitleCardAttributeStartDate newHireJourney={action?.onboardingJourney} />
              </Flex>
              <Flex direction="column" gap="4" my="2">
                <TitleCardAttributeAssignedActionScheduledAt action={action} />
              </Flex>
            </Flex>
            <Flex gap={4} my={4} width="100%">
              <Box>
                <SendNowButton action={action} />
              </Box>
              <Box>
                <SendTomorrowButton action={action} />
              </Box>
              <Box>
                <SendNextMondayButton action={action} />
              </Box>
              <EditSendDateButton action={action} />
              <Box>
                <SkipButton colorScheme="red" action={action} />
              </Box>
            </Flex>
            {removeSecondOrderIssues(action?.issues || [], action).map((issue) => (
              <IssueCard action={action} issue={issue} key={JSON.stringify(issue)} />
            ))}
          </Flex>
        </CardBody>
      </Skeleton>
    </Card>
  );
}
