import { AssignedPathContent, PathContent } from "models/automation";
import LoadingCard from "components/Card/LoadingCard";
import {
  useColorModeValue, theme, Card, CardBody, Heading,
} from "@chakra-ui/react";
import EmbeddedHtml from "components/DataDisplay/EmbeddedHtml";
import TaskNotifierExampleImage from "components/ExampleImage/TaskNotifierExampleImage";
import { RichText, TaskType } from "models/task";
import MiniTaskCard from "components/Card/MiniTaskCard";
import { Task } from "models/joinedModels";
import { TASK_TYPES } from "definitions/constants";
import TaskContentSection, { MessageContent } from "components/Task/TaskContent";
import { RecordTarget } from "models/record_target";

interface ActionableContentProps {
  content?: PathContent | AssignedPathContent;
}

function ChatContent({ body }: { body: string }) {
  const cardBg = useColorModeValue(theme.colors.gray[100], theme.colors.gray[600]);
  return (
    <>
      <Heading as="h3" size="xxs" fontWeight="bold" mt="6">
        Message
      </Heading>
      <Card bg={cardBg} w="100%" mt="1">
        <CardBody>
          <EmbeddedHtml innerHtml={body} />
        </CardBody>
      </Card>
    </>
  );
}

function EmailContent({ subject, body }: { subject: string; body: string }) {
  const cardBg = useColorModeValue(theme.colors.gray[100], theme.colors.gray[600]);
  return (
    <>
      <Heading as="h3" size="xxs" fontWeight="bold" mt="6">
        Subject
      </Heading>
      <Card bg={cardBg} w="100%" mt="1">
        <CardBody fontWeight="bold">{subject}</CardBody>
      </Card>
      <Heading as="h3" size="xxs" fontWeight="bold" mt="4">
        Body
      </Heading>
      <Card bg={cardBg} w="100%" mt="1">
        <CardBody>
          <EmbeddedHtml innerHtml={body} />
        </CardBody>
      </Card>
    </>
  );
}

interface TaskContentProps {
  messageBody?: RichText;
  messageTarget?: RecordTarget;
  taskType: TaskType;
  title: string;
  description: string;
}

function TaskContent({
  taskType, title, description, messageBody, messageTarget,
}: TaskContentProps) {
  const cardBg = useColorModeValue(theme.colors.gray[100], theme.colors.gray[600]);
  return (
    <>
      <Heading as="h3" size="xxs" fontWeight="bold" mt="6">
        Title
      </Heading>
      <Card bg={cardBg} w="100%" mt="1">
        <CardBody fontWeight="bold">{title}</CardBody>
      </Card>
      <Heading as="h3" size="xxs" fontWeight="bold" mt="4">
        {taskType && [TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT, TASK_TYPES.SCHEDULE_MEETING].includes(taskType)
          ? "Description"
          : "Example"}
      </Heading>
      <Card bg={cardBg} w="100%" mt="1">
        <CardBody>
          <TaskContentSection taskType={taskType} description={description} includeExtra={false} align="flex-start" />
        </CardBody>
      </Card>
      {taskType === TASK_TYPES.SEND_MESSAGE_PROMPT && (
        <MessageContent messageBody={messageBody} messageTarget={messageTarget} width="100%" alignSelf="center" />
      )}
    </>
  );
}

TaskContent.defaultProps = {
  messageBody: null,
  messageTarget: {},
};

function TaskNotifierContent({ task }: { task: Task }) {
  const cardBg = useColorModeValue(theme.colors.gray[100], theme.colors.gray[600]);
  return (
    <>
      {task?.taskType === TASK_TYPES.CUSTOM && (
        <>
          <Heading as="h3" size="xxs" fontWeight="bold" mt="6">
            Notification Example
          </Heading>
          <Card bg={cardBg} w="100%" mt="1">
            <CardBody width="fit-content">
              <TaskNotifierExampleImage />
            </CardBody>
          </Card>
        </>
      )}
      <Heading as="h3" size="xxs" fontWeight="bold" mt="4">
        Task
      </Heading>
      <Card bg={cardBg} w="100%" mt="1">
        <CardBody display="flex" direction="row" justifyContent="center">
          <MiniTaskCard task={task} />
        </CardBody>
      </Card>
    </>
  );
}

export default function ActionableContent({ content: contentBox }: ActionableContentProps) {
  if (!contentBox) {
    return <LoadingCard />;
  }
  const { actionType, content } = contentBox;

  switch (actionType) {
    case "email":
      return <EmailContent subject={content.subject} body={content.body} />;
    case "chat":
      return <ChatContent body={content.body} />;
    case "task_reminder":
      return <ChatContent body={content.message} />;
    case "task":
      return (
        <TaskContent
          taskType={contentBox.taskType}
          title={content.title}
          description={content.description}
          messageBody={content.messageBody}
          messageTarget={content.messageTarget}
        />
      );
    case "task_notifier":
      return <TaskNotifierContent task={contentBox.task} />;
    default:
      return null;
  }
}

ActionableContent.defaultProps = {
  content: null,
};
