import * as yup from "yup";

export const BUDDY_EXPECTATIONS_FIELD = {
  type: "text",
  label: "Buddy Expectations Url",
  name: "buddyExpectationsUrl",
};

export const BUDDY_SELECTION_FIELD = {
  type: "text",
  label: "Buddy Selection (for Managers) Guide Url",
  name: "buddySelectionUrl",
};

export const ONBOARDING_PLAN_TEMPLATES_FIELD = {
  type: "text",
  label: "Onboarding Plan Templates Url",
  name: "onboardingPlanTemplatesUrl",
};

export const BENEFITS_INFO_FIELD = {
  type: "text",
  label: "Benefits Info Url",
  name: "benefitsInfoUrl",
};

export const HRIS_FIELD = {
  type: "text",
  label: "HRIS Url",
  name: "hrisUrl",
};

export const COMPANY_HANDBOOK_FIELD = {
  type: "text",
  label: "Company Handbook Url",
  name: "companyHandbookUrl",
};

export const COMPANY_WIKI_FIELD = {
  type: "text",
  label: "Company Wiki Url",
  name: "companyWikiUrl",
};

export const COMPANY_GOALS_FIELD = {
  type: "text",
  label: "Company Goals Url",
  name: "companyGoalsUrl",
};

export const schema = yup.object({
  buddyExpectationsUrl: yup.string().notRequired().label(BUDDY_EXPECTATIONS_FIELD.label),
  buddySelectionUrl: yup.string().notRequired().label(BUDDY_SELECTION_FIELD.label),
  onboardingPlanTemplatesUrl: yup.string().notRequired().label(ONBOARDING_PLAN_TEMPLATES_FIELD.label),
  benefitsInfoUrl: yup.string().notRequired().label(BENEFITS_INFO_FIELD.label),
  hrisUrl: yup.string().notRequired().label(HRIS_FIELD.label),
  companyHandbookUrl: yup.string().notRequired().label(COMPANY_HANDBOOK_FIELD.label),
  companyWikiUrl: yup.string().notRequired().label(COMPANY_WIKI_FIELD.label),
  companyGoalsUrl: yup.string().notRequired().label(COMPANY_GOALS_FIELD.label),
});

export type Schema = yup.InferType<typeof schema>;
