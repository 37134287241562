import {
  Box, Card, CardBody, Flex, Text, LinkBox, LinkOverlay, Button, CardProps,
} from "@chakra-ui/react";
import { DashboardHeading } from "components/Heading";
import { Link } from "react-router-dom";

export interface DashboardCardProps extends CardProps {
  destination: string;
  title: string;
  buttonText?: string;
  emoji: string;
}

export default function DashboardCard({
  destination,
  title,
  buttonText = undefined,
  emoji,
  children,
  ...rest
}: DashboardCardProps) {
  return (
    <LinkBox
      as={Card}
      minWidth="250px"
      height={{ base: "158px", xxl: "120px" }}
      _hover={{ bg: "background.tertiary" }}
      {...rest}
    >
      <Box h="100%">
        <CardBody maxH="500px" h="100%" overflow="scroll">
          <LinkOverlay as={Link} to={destination}>
            <Flex direction="column" h="100%">
              <Flex direction="row" align="center" justify="space-between">
                <Flex direction="row" align="center">
                  {emoji && (
                    <Text mr={4} fontSize="xl" height="100%">
                      {emoji}
                    </Text>
                  )}
                  <DashboardHeading>{title}</DashboardHeading>
                </Flex>
                {buttonText && <Button size="xs">{buttonText}</Button>}
              </Flex>
              <Flex mt="4" flexGrow="1" overflow="scroll" align="center">
                {children}
              </Flex>
            </Flex>
          </LinkOverlay>
        </CardBody>
      </Box>
    </LinkBox>
  );
}
