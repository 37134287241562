import { BsCalendarEventFill, BsPersonPlusFill, BsSendCheck } from "react-icons/bs";
import { GoMail, GoChecklist, GoCommentDiscussion, GoQuestion } from "react-icons/go";
import { IoTrailSignOutline } from "react-icons/io5";
import { PiNotePencilDuotone } from "react-icons/pi";
import { RiChatForwardLine } from "react-icons/ri";
import { TbBellCheck } from "react-icons/tb";
import { FaHashtag } from "react-icons/fa6";
import { FiLayers, FiClock, FiZap } from "react-icons/fi";
import { MdOutlineAssignmentTurnedIn, MdOutlinePeopleAlt, MdPerson, MdAssignmentTurnedIn } from "react-icons/md";
import { TASK_TYPES } from ".";

export const TASK_ICONS = {
  [TASK_TYPES.ASSIGN_BUDDY]: BsPersonPlusFill,
  [TASK_TYPES.CREATE_EMAIL_ACCOUNT]: PiNotePencilDuotone,
  [TASK_TYPES.CREATE_ONBOARDING_PLAN]: IoTrailSignOutline,
  [TASK_TYPES.SCHEDULE_MEETING]: GoMail,
  [TASK_TYPES.SEND_MESSAGE_PROMPT]: RiChatForwardLine,
  [TASK_TYPES.CUSTOM]: GoChecklist,
  task_complete: MdAssignmentTurnedIn,
};

export const RESOURCE_ICON = {
  chat: GoCommentDiscussion,
  email: GoMail,
  task_notifer: BsSendCheck,
  task_reminder: TbBellCheck,
  task: GoChecklist,
  all: FiLayers,
  unknown: GoQuestion,
};

export const CHAT_ICONS = {
  NEW_HIRE_NETWORK: MdOutlinePeopleAlt,
  USER: MdPerson,
  CHANNEL: FaHashtag,
};

export const BUSINESS_ICONS = {
  START_DATE: BsCalendarEventFill,
  ASSIGNMENT_TYPE: MdOutlineAssignmentTurnedIn,
  TOTAL_ACTIONS: FiZap,
  TRIGGER_AT: FiClock,
};

export const EMOJI_ICONS = {
  target: "➡️",
  triggerAt: "⚡",
  scheduledAt: "🕒",
  startDate: "📅",
  chat: "💬",
  email: "✉️",
  task: "📝",
  task_notifier: "📣",
  task_reminder: "🔔",
  sentAt: "✔️",
  failed: "❌",
};
