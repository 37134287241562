import { range } from "lodash";

const NAME_FIELD = {
  type: "text",
  label: "Name",
  placeholder: "Action Name",
  name: "name",
};

const REFERENCE_EVENT_FIELD = (name: any) => ({
  type: "SELECT",
  label: "Event",
  placeholder: "Event",
  name: `${name}.data.referenceEvent`,
  options: [
    { label: "Start Date", value: "start_date" },
    { label: "Immediate", value: "immediate" },
  ],
});

const OFFSET_DIRECTION_FIELD = (name: any) => ({
  type: "SELECT",
  label: "Before/After/On",
  placeholder: "Before/After/On",
  name: `${name}.data.offsetDirection`,
  options: [
    { label: "Before", value: "before" },
    { label: "After", value: "after" },
    { label: "On", value: "exact" },
  ],
});

const OFFSET_VALUE_FIELD = (name: any) => ({
  type: "SELECT",
  label: "Timing",
  placeholder: "Number",
  name: `${name}.data.offsetValue`,
  options: range(1, 120).map((value) => ({ label: value.toString(), value })),
});

const OFFSET_UNIT_FIELD = (name: any) => ({
  type: "SELECT",
  label: "Day/Week/Month",
  placeholder: "Day/Week/Month",
  name: `${name}.data.offsetUnit`,
  options: [
    { label: "Day(s)", value: "day" },
    { label: "Week(s)", value: "week" },
    { label: "Month(s)", value: "month" },
    { label: "Specific Day", value: "specific_day" },
  ],
});

const DAY_OF_WEEK_FIELD = (name: any) => ({
  type: "SELECT",
  label: "Day of Week",
  placeholder: "Day of Week",
  name: `${name}.data.dayOfWeek`,
  options: [
    { label: "Monday", value: "monday" },
    { label: "Tuesday", value: "tuesday" },
    { label: "Wednesday", value: "wednesday" },
    { label: "Thursday", value: "thursday" },
    { label: "Friday", value: "friday" },
    { label: "Saturday", value: "saturday" },
    { label: "Sunday", value: "sunday" },
  ],
});

const TIME_OF_DAY_FIELD = (name: any) => ({
  type: "TIME",
  label: "Send At",
  placeholder: "Send At",
  name: `${name}.data.timeOfDay`,
});

const TIMEZONE_TYPE = (name: any) => ({
  type: "SELECT",
  label: "Timezone Source",
  name: `${name}.data.timezoneType`,
  options: [
    { label: "Recipient's Timezone", value: "target_timezone" },
    { label: "Company Default Timezone", value: "company_default_timezone" },
    { label: "Specific Timezone", value: "specific_timezone" },
  ],
});

const SPECIFIC_TIMEZONE = (name: any) => ({
  type: "SELECT",
  label: "Specific Timezone",
  placeholder: "Specific Timezone",
  name: `${name}.data.specificTimezone`,
});

function schedulingFields(name: any) {
  return {
    offsetDirection: OFFSET_DIRECTION_FIELD(name),
    referenceEvent: REFERENCE_EVENT_FIELD(name),
    offsetValue: OFFSET_VALUE_FIELD(name),
    offsetUnit: OFFSET_UNIT_FIELD(name),
    dayOfWeek: DAY_OF_WEEK_FIELD(name),
    timeOfDay: TIME_OF_DAY_FIELD(name),
    timezoneType: TIMEZONE_TYPE(name),
    specificTimezone: SPECIFIC_TIMEZONE(name),
  };
}

interface TargetableTypeFieldArgs {
  targetableTypeName: string;
  targetableTypePlacholder: string;
  targetableTypeLabel: string;
}

export function targetableTypeField({
  targetableTypeName,
  targetableTypePlacholder,
  targetableTypeLabel,
}: TargetableTypeFieldArgs) {
  return {
    type: "SELECT",
    label: targetableTypeLabel,
    placeholder: targetableTypePlacholder,
    name: targetableTypeName,
    options: [
      { label: "Role", value: "relation" },
      { label: "Specific Individual", value: "specific_user" },
      { label: "Channel", value: "message_service_channel" },
    ],
  };
}

interface TargetFieldArgs {
  label: string;
  placeholder: string;
  name: string;
}

export function relationTargetableField({ label, placeholder, name }: TargetFieldArgs) {
  return {
    type: "SELECT",
    label,
    placeholder,
    name,
    options: [
      { label: "New Hire", value: "new_hire" },
      { label: "Manager", value: "manager" },
      { label: "People Team", value: "people_team_contact" },
      { label: "Buddy", value: "buddy" },
    ],
  };
}

const TARGET_TYPE_FIELD = targetableTypeField({
  targetableTypeLabel: "Target Type",
  targetableTypePlacholder: "Target Type",
  targetableTypeName: "targetType",
});

const RELATION_TARGET_FIELD = relationTargetableField({
  name: "relationTarget",
  placeholder: "Target",
  label: "Target",
});

const SPECIFIC_USER_FIELD = {
  type: "SELECT",
  label: "Specific Individual",
  name: "specificTargetableId",
};

const MESSAGE_SERVICE_CHANNEL_FIELD = {
  type: "SELECT",
  label: "Slack/Teams Channel",
  name: "specificTargetableId",
};

const TRIGGER_TYPE_FIELD = {
  type: "SELECT",
  label: "Trigger Type",
  options: [
    { label: "Time Based", value: "timing" },
    { label: "On Task Completion", value: "dependency" },
  ],
};

const DEPENDENCY_ID_FIELD = {
  label: "Task",
  name: "trigger.data.dependencyId",
};

export {
  schedulingFields,
  NAME_FIELD,
  RELATION_TARGET_FIELD,
  TARGET_TYPE_FIELD,
  SPECIFIC_USER_FIELD,
  MESSAGE_SERVICE_CHANNEL_FIELD,
  DEPENDENCY_ID_FIELD,
  TRIGGER_TYPE_FIELD,
};
