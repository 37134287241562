import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PERSONAL_EMAIL_FIELD,
  START_DATE_FIELD,
  TITLE_FIELD,
} from "definitions/constants/fields";
import * as yup from "yup";

export interface NewHireFormValues {
  firstName: string;
  lastName: string;
  title: string;
  personalEmail?: string;
  startDate?: Date;
}

export const newHireSchema = yup.object({
  firstName: yup.string().label(FIRST_NAME_FIELD.label).required(),
  lastName: yup.string().label(LAST_NAME_FIELD.label).required(),
  title: yup.string().label(TITLE_FIELD.label).required(),
  personalEmail: yup.string().label(PERSONAL_EMAIL_FIELD.label).email().lowercase()
    .required(),
  startDate: yup.date().label(START_DATE_FIELD.label),
});
