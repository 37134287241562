import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Link,
  Text,
  Button,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Portal,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link as RouterLink, generatePath, useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { ADMIN_ROUTES, API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { ResponseModel } from "services/ApiClient/responseModel";
import { User } from "models/user";
import { QUERY_KEYS } from "definitions/constants";
import { useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import useCaminoStore from "hooks/useCaminoStore";
import AppPage from "layouts/AppPage";
import NewAdminEmployeeButtonWithModal from "components/ModalForm/NewAdminEmployeeButtonWithModal";
import GenericTable, { GenericTableProps, simpleColumn } from "components/Table/GenericTable";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import useNavbar from "hooks/useNavbar";
import CompanySelect from "./CompanySelect";
import { getAvailableRolesOptions } from "features/Role/helpers";

const CONFIRM_DELETE_USER = (name: string) => ({
  header: `Delete ${name}?`,
  body: `Are you sure you'd like to delete ${name}?`,
  confirmButtonText: "Yes, delete",
});

const CONFIRM_DELETE_AUTOMATIONS = (name: string) => ({
  header: `Remove Automations for ${name}?`,
  body: `Are you sure you'd like to delete all automations for ${name}?`,
  confirmButtonText: "Yes, delete",
});

const columnHelper = createColumnHelper<User>();

const makeColumns = (
  handleImpersonate: (id: string) => void,
  handleDeleteUser: (id: string) => void,
  handleDeleteAutomations: (id: string) => void,
  handleValidateAutomations: (id: string) => void,
) => [
    columnHelper.accessor((row) => row, {
      id: "name",
      header: "Name",
      cell: (row) => (
        <Link
          as={RouterLink}
          to={{
            pathname: generatePath(SHARED_ROUTES.showUser, { id: row.getValue().id }),
          }}
        >
          {row.getValue().fullName}
        </Link>
      ),
      enableMultiSort: true,
    }),
    simpleColumn({ attribute: "workEmail", label: "Work Email" }, columnHelper, true),
    columnHelper.accessor((row) => row, {
      id: "company",
      header: "Company",
      cell: (row) => row.getValue().company.name,
      enableMultiSort: true,
    }),
    columnHelper.accessor((row) => row, {
      id: "permission",
      header: "Roles",
      enableSorting: false,
      cell: (row) => (
        <Text fontSize="xs">
          {getAvailableRolesOptions(row.getValue().permission)
            .map((value) => value.label)
            .join(", ")}
        </Text>
      ),
    }),
    columnHelper.accessor((row) => row, {
      id: "menu",
      header: "",
      enableSorting: false,
      cell: (row) => {
        const user = row.getValue();
        const userId = user.id;
        const { fullName } = user;
        return (
          <Menu>
            <MenuButton as={Button} size="xs" colorScheme="gray" rightIcon={<ChevronDownIcon />}>
              Actions
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem onClick={() => handleImpersonate(userId)}>Impersonate</MenuItem>
                <MenuItem onClick={() => handleValidateAutomations(userId)}>Validate Automations</MenuItem>
                <ButtonWithConfirm
                  {...CONFIRM_DELETE_USER(fullName)}
                  intent="warning"
                  handleConfirm={() => handleDeleteUser(userId)}
                >
                  <MenuItem>Delete User</MenuItem>
                </ButtonWithConfirm>
                <ButtonWithConfirm
                  {...CONFIRM_DELETE_AUTOMATIONS(fullName)}
                  intent="warning"
                  handleConfirm={() => handleDeleteAutomations(userId)}
                >
                  <MenuItem>Delete Automations</MenuItem>
                </ButtonWithConfirm>
              </MenuList>
            </Portal>
          </Menu>
        );
      },
    }),
  ];

const queryKey = [QUERY_KEYS.employees, "admin"];

export default function UsersPage() {
  const [setValue, isImpersonating] = useCaminoStore((state) => [state.setValue, state.isImpersonating]);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setPageTitle, setBreadcrumbs } = useNavbar();
  useEffect(() => {
    setPageTitle("Users");
    setBreadcrumbs([
      { name: "Admin", toUrl: ADMIN_ROUTES.hub },
      { name: "Users", isCurrentPage: true },
    ]);
  }, [setBreadcrumbs, setPageTitle]);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey });
  }, [queryClient, isImpersonating]);

  const handleImpersonate = async (id: string) => {
    const { company, ...user } = await apiClient.post<User>(
      generatePath(API_ROUTES.admin.users.impersonations.show, { userId: id }),
      {},
    );
    queryClient.clear();
    setValue({
      currentUser: user,
      currentCompany: company,
      currentRole: user.permission[0],
      isImpersonating: true,
    });
    navigate(SHARED_ROUTES.home);
  };

  const handleDeleteUser = async (id: string) => {
    await apiClient.delete(generatePath(API_ROUTES.admin.users.show, { id }));
    queryClient.invalidateQueries({ queryKey });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.users] });
  };

  const handleDeleteAutomations = async (userId: string) => {
    await apiClient.delete(generatePath(API_ROUTES.admin.users.automations.base, { userId }));
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths] });
  };

  const handleValidateAutomations = async (userId: string) => {
    await apiClient.post(generatePath(API_ROUTES.admin.users.automations.validate, { userId }), {});
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedActions] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths] });
  };

  const [companyFilter, setCompanyFilter] = useState(undefined as { id: string; name: string } | undefined);
  if (companyFilter) queryKey.push(companyFilter.id);

  const tableProps: GenericTableProps<User> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<User[]>>(`${API_ROUTES.admin.users.base}${query}`, undefined, signal)
      .then((response) => response.payload),
    queryKey,
    columns: makeColumns(handleImpersonate, handleDeleteUser, handleDeleteAutomations, handleValidateAutomations),
    meta: {
      pagination: { perPage: 100 },
      filters: companyFilter ? [{ fieldName: "companyId", value: companyFilter.id, operator: "=" }] : undefined,
    },
  };

  return (
    <AppPage>
      <Card mt="8" p="4">
        <CardHeader>
          <HStack justify="space-between">
            <CompanySelect value={companyFilter} onChange={(value) => setCompanyFilter(value)} />
            <NewAdminEmployeeButtonWithModal />
          </HStack>
        </CardHeader>
        <CardBody>{companyFilter && <GenericTable {...tableProps} />}</CardBody>
      </Card>
    </AppPage>
  );
}
