import { OnboardingJourneyNetworkRelation, Target, TargetType } from "models/record_target";
import { MdPerson, MdOutlinePeopleAlt } from "react-icons/md";
import { FaHashtag } from "react-icons/fa6";
import { MessageServiceChannel } from "models/integration/messageService";
import { User } from "models/user";
import { ActionAttributeCard } from "components/Path/ActionCard";
import { titleize } from "helpers/string";
import { CardProps } from "@chakra-ui/react";
import { TARGET_TYPE } from "definitions/constants";

export const TASK_TYPES = {
  CUSTOM: "custom",
  ASSIGN_BUDDY: "assign_buddy",
  CREATE_ONBOARDING_PLAN: "create_onboarding_plan",
  CREATE_EMAIL_ACCOUNT: "create_email_account",
  SEND_MESSAGE: "send_message",
  SCHEDULE_MEETING: "schedule_meeting",
};

export function TargetTypeDisplay({
  targetType,
  record,
  ...rest
}: { targetType: TargetType; record: Target } & CardProps) {
  switch (targetType) {
    case TARGET_TYPE.ONBOARDING_JOURNEY_NETWORK_RELATION: {
      const secondaryText = record
        ? titleize((record as OnboardingJourneyNetworkRelation).name)
        : `User ${(record as User).fullName} (${titleize((record as OnboardingJourneyNetworkRelation).name)})`;
      return <ActionAttributeCard icon={MdOutlinePeopleAlt} text={`${secondaryText}`} {...rest} />;
    }
    case TARGET_TYPE.USER:
      return <ActionAttributeCard icon={MdPerson} text={(record as User).fullName} {...rest} />;
    case TARGET_TYPE.MESSAGE_SERVICE_CHANNEL:
      return <ActionAttributeCard icon={FaHashtag} text={(record as MessageServiceChannel).name} {...rest} />;
    default:
      return null;
  }
}
