import { As } from "@chakra-ui/react";
import {
  PEOPLE_TEAM_ROUTES,
  SHARED_ROUTES,
  SHARED_COMPANY_ROUTES,
} from "definitions/constants/routeConstants";
import ROLE from "models/role";
import { AiOutlineIssuesClose, AiOutlineAppstoreAdd } from "react-icons/ai";
import {
  FiHome,
  FiList, FiUsers,
} from "react-icons/fi";
import { GiTrail } from "react-icons/gi";
import { GoChecklist } from "react-icons/go";
import { IoFileTrayFullSharp } from "react-icons/io5";
import { TbMap2 } from "react-icons/tb";
import { MdSync } from "react-icons/md";

interface SidebarSection {
  id: string;
  header?: string;
  items: SideBarItem[];
}

interface SubItem {
  name: string;
  route: string;
}

interface SideBarItem {
  role: string;
  name: string;
  route: string;
  icon: As;
  subItems?: SubItem[];
}

const baseTasks = {
  name: "My Tasks",
  route: SHARED_ROUTES.tasks.index,
  icon: GoChecklist,
};

const peopleTeamDashboard = {
  role: "PEOPLE_TEAM",
  name: "Home",
  route: PEOPLE_TEAM_ROUTES.dashboard,
  icon: FiHome,
};

const peopleTeamIssues = {
  role: "PEOPLE_TEAM",
  name: "Issues",
  route: PEOPLE_TEAM_ROUTES.issues.error,
  icon: AiOutlineIssuesClose,
  subItems: [
    {
      name: "Failed",
      route: PEOPLE_TEAM_ROUTES.issues.error,
    },
    {
      name: "Upcoming Failures",
      route: PEOPLE_TEAM_ROUTES.issues.warning,
    },
  ],
};

const peopleTeamPendingNewHires = {
  role: "PEOPLE_TEAM",
  name: "Pending New Hires",
  route: SHARED_COMPANY_ROUTES.newHireJourneys.pending.index,
  icon: MdSync,
};

const peopleTeamNewHires = {
  role: "PEOPLE_TEAM",
  name: "New Hires",
  route: SHARED_COMPANY_ROUTES.newHireJourneys.index,
  icon: TbMap2,
};

const peopleTeamWorkforce = {
  role: "PEOPLE_TEAM",
  name: "Workforce",
  route: PEOPLE_TEAM_ROUTES.workforce,
  icon: FiUsers,
};

const peopleTeamPaths = {
  role: "PEOPLE_TEAM",
  name: "Paths",
  route: PEOPLE_TEAM_ROUTES.paths.index,
  icon: GiTrail,
};

const peopleTeamActivityLog = {
  role: "PEOPLE_TEAM",
  name: "Activity Feed",
  route: PEOPLE_TEAM_ROUTES.activityFeed.index,
  icon: FiList,
};

const companyInfo = {
  role: "PEOPLE_TEAM",
  name: "Settings",
  route: PEOPLE_TEAM_ROUTES.company.settings,
  icon: IoFileTrayFullSharp,
};

const companyIntegrations = {
  role: "PEOPLE_TEAM",
  name: "Integrations",
  route: PEOPLE_TEAM_ROUTES.company.integrations.index,
  icon: AiOutlineAppstoreAdd,
};

export const teammateSidebarItems: SidebarSection[] = [{ id: "teammate", items: [{ ...baseTasks, role: "BASIC" }] }];

export const peopleTeamSidebarItems: SidebarSection[] = [
  {
    id: "peopleTeam1",
    items: [peopleTeamDashboard, { ...baseTasks, role: "PEOPLE_TEAM" }, peopleTeamIssues],
  },
  {
    id: "peopleTeam2",
    header: "Employees",
    items: [peopleTeamPendingNewHires, peopleTeamNewHires, peopleTeamWorkforce],
  },
  {
    id: "peopleTeam3",
    header: "Onboarding",
    items: [peopleTeamPaths, peopleTeamActivityLog],
  },
  {
    id: "peopleTeam4",
    header: "Company",
    items: [companyInfo, companyIntegrations],
  },
];

export default function sideBarItemsByRole(role: ROLE): SidebarSection[] {
  switch (role) {
    case ROLE.COMPANY_ADMIN:
    case ROLE.PEOPLE_TEAM:
      return peopleTeamSidebarItems;
    case ROLE.BASIC:
      return teammateSidebarItems;
    case ROLE.MANAGER:
    case ROLE.ONBOARDER:
    default:
      return [];
  }
}
