import { Button, Flex, Heading } from "@chakra-ui/react";
import TaskReminderInput from "components/ModalForm/PathActionForm/TaskReminderInput";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { Schema } from "./schema";
import Scheduling from "../Shared/Trigger/Scheduling";

const reminderText = [
  "🔔 Hi there! A gentle reminder to complete this outstanding task ASAP",
  "👋 Us again! Please complete this outstanding task ASAP! Other automated messages may depend on it",
  "🧐 Is there anyone out there? Please complete this onboarding task!",
  "🙏 Please complete this task, if there are 3 reminders already it must be important!",
  "🚶 Prolly don't want to hear from us again, but can you complete this task?",
];

export default function Reminders({ form }: { form: UseFormReturn<Schema> }) {
  const { control, watch } = form;
  const [referenceEvent] = watch(["trigger.data.referenceEvent"]);

  const { fields, append, remove } = useFieldArray({ control, name: "reminders" });

  const addReminder = () => append({
    message: reminderText[fields.length % 5],
    trigger: { type: "timing", data: { referenceEvent, offsetDirection: "exact" } },
  });

  return (
    <>
      <Flex mb={4} alignItems="start" justifyContent="space-between">
        <Heading as="h3" size="xs">
          4. Add Due Date & Reminders
        </Heading>
        <Button onClick={addReminder}>Add Reminder</Button>
      </Flex>
      <>
        <Heading as="h4" size="xs" mb={2}>
          Due Date
        </Heading>
        <Scheduling<Schema> form={form} fieldName="dueDateTrigger" hideTimeOfDay />
        <Heading as="h4" size="xs" mb={2}>
          Reminders
        </Heading>
        {fields.length === 0 && "No reminders added yet"}
        {fields.map((field, index) => (
          <TaskReminderInput
            form={form}
            index={index}
            remove={remove}
            // eslint-disable-next-line react/no-array-index-key
            key={`reminders.${index}.${JSON.stringify(field.trigger)}`}
          />
        ))}
      </>
    </>
  );
}
