import * as yup from "yup";
import { ActionableType, PathRelationTarget } from "models/automation";
import { LegacyTargetType } from "models/record_target";
import { sharedSchema } from "../Shared/schema";

const content = yup.object({
  body: yup.string().required().label("Body"),
});

export const schema = sharedSchema.concat(
  yup.object({
    actionType: yup.mixed<Extract<ActionableType, "chat">>().required().label("Action Type"),
    targetType: yup.mixed<LegacyTargetType>().required().label("Recipient"),
    relationTarget: yup
      .mixed<PathRelationTarget>()
      .label("Recipient")
      .when("targetType", {
        is: "relation",
        then: (s) => s.required(),
        otherwise: (s) => s.notRequired(),
      }),
    specificTargetableId: yup.string().when("targetType", {
      is: "specific_user",
      then: (s) => s.required().label("User"),
      otherwise: (s) => s.when("targetType", {
        is: "message_service_channel",
        then: (s2) => s2.required().label("Channel"),
        otherwise: (s2) => s2.notRequired(),
      }),
    }),
    specificTargetableType: yup.string().when("targetType", {
      is: "specific_user",
      then: (s) => s.required(),
      otherwise: (s) => s.when("targetType", {
        is: "message_service_channel",
        then: (s2) => s2.required(),
        otherwise: (s2) => s2.notRequired(),
      }),
    }),
    content,
  }),
);

export type Schema = yup.InferType<typeof schema>;
