import React, { useState, useEffect } from "react";
import { CreatableSelect } from "chakra-react-select";
import { useController } from "react-hook-form";
import { startCase } from "lodash";
import clsx from "clsx";
import apiClient from "services/ApiClient";
import { ControlledSelectProps, OptionType } from ".";

interface CreateableSingleSelectProps extends ControlledSelectProps {
  resourceUrl: string;
}

function CreateableSingleSelect({
  control,
  name,
  id,
  placeholder,
  rules,
  className,
  resourceUrl,
  options: initialOptions,
  ...props
}: CreateableSingleSelectProps) {
  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules,
  });

  const [options, setOptions] = useState<OptionType[]>(initialOptions || []);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadOptions = async () => {
      setIsLoading(true);
      try {
        const response = await apiClient.get(resourceUrl);
        const responseData = Array.isArray(response) ? response : response?.data || [];

        const loadedOptions = responseData.map((item: string) => ({
          label: startCase(item),
          value: item,
        }));

        setOptions(loadedOptions);
      } catch (error) {
        console.error("Failed to load departments:", error);
      } finally {
        setIsLoading(false);
      }
    };

    loadOptions();
  }, [resourceUrl]);

  const handleChange = (newValue: OptionType | null) => {
    if (newValue) {
      onChange(newValue.value);
      if (newValue.__isNew__) {
        const optionExists = options.some((option) => option.value === newValue.value);
        if (!optionExists) {
          // Add the new option to the list
          setOptions((prevOptions) => [...prevOptions, newValue]);
        }
      }
    } else {
      onChange(null);
    }
  };

  const currentValue = value
    ? options.find((option) => option.value === value) || { label: startCase(value), value }
    : null;

  return (
    <CreatableSelect
      name={name}
      menuPortalTarget={document.body}
      placeholder={placeholder}
      ref={ref}
      onChange={handleChange}
      options={options}
      value={currentValue}
      isLoading={isLoading}
      components={{ DropdownIndicator: null }}
      classNamePrefix="chakra-react-select"
      className={clsx("chakra-react-select-container-single-select", className)}
      chakraStyles={{
        option: (provided) => ({
          ...provided,
          color: "fg.muted",
        }),
        control: (provided) => ({
          ...provided,
          cursor: "pointer",
        }),
      }}
      styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 1400 }) }}
      {...props}
    />
  );
}

export default CreateableSingleSelect;
