import { Step, Steps } from "components/Steps";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { TRIGGER_DEFAULT_VALUES } from "definitions/constants/trigger";
import { useCallback } from "react";
import { toPathTrigger } from "helpers/workflowActionableConverters";
import ActionInfo from "../Shared/ActionInfo";
import TaskInfo from "./TaskInfo";
import { schema, Schema } from "./schema";
import useSubmitForm from "../Shared/useSubmitForm";
import Reminders from "./Reminders";

export default function Form({
  workflowId,
  onSuccess,
  defaultValues,
}: {
  workflowId: string;
  onSuccess: () => void;
  defaultValues: Partial<Schema>;
}) {
  const form = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
    defaultValues: { ...TRIGGER_DEFAULT_VALUES, ...{ dueDateTrigger: { type: "timing" } }, ...defaultValues },
  });

  const transformer = useCallback(
    (id: string, data: Schema) => ({
      workflowAction: {
        workflowId: id,
        actionable: {
          ...data,
          trigger: toPathTrigger(data.trigger),
          dueDateTrigger: data.dueDateTrigger && toPathTrigger(data.dueDateTrigger),
          reminders: data.reminders?.map((reminder) => ({
            ...reminder,
            trigger: toPathTrigger(reminder.trigger),
          })),
        },
      },
    }),
    [],
  );

  const { onSubmit, isLoading } = useSubmitForm<Schema>({ workflowId, onSuccess, transformer });

  return (
    <Steps
      handleComplete={form.handleSubmit(onSubmit, (e) => console.error(e))}
      submitButtonTitle="Submit"
      isLoading={isLoading}
    >
      <Step id="action-info">
        <ActionInfo form={form} actionTypeName="Task" workflowId={workflowId} />
      </Step>
      <Step id="task-info">
        <TaskInfo form={form} />
      </Step>
      <Step id="task-reminders">
        <Reminders form={form} />
      </Step>
    </Steps>
  );
}
