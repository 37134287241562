import { Button, SkeletonText, StackProps, VStack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { NewHireJourney } from "models/onboarding";
import { useEffect, useMemo } from "react";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";

const ONBOARDING_PLAN_URL_FIELD = {
  label: "Onboarding Plan Url",
  name: "onboardingPlanUrl",
  helperText: "Url should include the prefix (http:// or https://)",
  type: "url",
  defaultValue: "https://",
};

const resourceSchema = yup.object({
  onboardingPlanUrl: yup.string().url().nullable().default(null)
    .label(ONBOARDING_PLAN_URL_FIELD.label),
});

const values = (data: NewHireJourney | undefined) => ({
  onboardingPlanUrl: data?.onboardingPlanUrl || null,
});

interface NewHireJourneyDocsFormValues {
  onboardingPlanUrl: string | null;
}

interface NewHireJourneyDocsFormProps extends StackProps {
  data: NewHireJourney | undefined;
  isLoading: boolean;
  onFormSubmit: (data: NewHireJourneyDocsFormValues) => void;
}

export default function NewHireJourneyDocsForm({
  data,
  isLoading,
  onFormSubmit,
  ...rest
}: NewHireJourneyDocsFormProps) {
  const defaultValues = useMemo(() => values(data), [data]);

  const {
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(resourceSchema),
    defaultValues,
  });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  if (!data) return <SkeletonText mt="4" noOfLines={12} spacing="4" skeletonHeight="4" speed={1.75} />;

  return (
    <VStack spacing="3" alignItems="start" width="100%" {...rest}>
      <VStack spacing="3" width="100%">
        <TextInputField {...ONBOARDING_PLAN_URL_FIELD} errors={errors} register={register} />
      </VStack>
      <Button
        isLoading={isLoading}
        isDisabled={!(isDirty && isValid)}
        alignSelf="end"
        width="30%"
        minW={40}
        onClick={handleSubmit(onFormSubmit)}
      >
        Update
      </Button>
    </VStack>
  );
}
