import {
  Button, Text, Center, HStack, Icon, Square, VStack,
} from "@chakra-ui/react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useCallback } from "react";
import Dropzone from "react-dropzone";
import { FiUploadCloud } from "react-icons/fi";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export default function ImportUsersForm({
  company,
  onSuccess,
}: {
  company: { id: string; name: string };
  onSuccess: () => void;
}) {
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const reader = new FileReader();
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        reader.onload = async () => {
          const data = reader.result as string;
          await apiClient.post(generatePath(API_ROUTES.admin.companies.importUsers, { id: company.id }), {
            file: { fileName: file.name, data },
          });
          onSuccess();
        };
        reader.readAsDataURL(file);
      }
    },
    [company.id, onSuccess],
  );

  return (
    <Center>
      <Dropzone accept={{ "text/csv": [".csv"] }} multiple={false} maxSize={10_485_760} onDrop={onDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input type="file" {...getInputProps()} />
              <VStack spacing="3">
                <Square size="10" bg="bg.subtle" borderRadius="lg">
                  <Icon as={FiUploadCloud} boxSize="5" color="fg.muted" />
                </Square>
                <VStack spacing="1">
                  <HStack spacing="1" whiteSpace="nowrap">
                    <Button variant="text" colorScheme="brand" size="sm">
                      Click to upload
                    </Button>
                    <Text textStyle="sm" color="fg.muted">
                      or drag and drop
                    </Text>
                  </HStack>
                  <Text textStyle="xs" color="fg.muted">
                    CSV up to 10MB
                  </Text>
                </VStack>
              </VStack>
            </div>
          </section>
        )}
      </Dropzone>
    </Center>
  );
}
