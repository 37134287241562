import { theme as proTheme } from "@chakra-ui/pro-theme";
import { extendTheme, theme as baseTheme } from "@chakra-ui/react";
import { floatingNoValueBorder, formInputWithValueBorder, selectedInputBorder } from "definitions/constants/styling";
import colors from "./colors";
import { fonts, textStyles } from "./fonts";
import "@fontsource/lato/100.css";
import "@fontsource/lato/300.css";
import "@fontsource/lato/400.css";
import "@fontsource/lato/700.css";
import "@fontsource/lato/900.css";
import { floatingButton, floatingForm } from "./floatingLabels";

const theme = extendTheme(proTheme, {
  breakpoints: {
    ...baseTheme.breakpoints,
    xxl: "94em",
  },
  colors: { ...baseTheme.colors, ...colors },
  config: {
    initialColorMode: "system",
    useSystemColorMode: true,
  },
  fonts: { ...baseTheme.fonts, ...fonts },
  textStyles: { ...textStyles },
  semanticTokens: {
    colors: {
      error: {
        default: "error.default",
        _dark: "error.default",
      },
      warning: {
        default: "orange.500",
        _dark: "orange.500",
      },
      background: {
        primary: {
          default: "white",
          _dark: "gray.700",
        },
        secondary: {
          default: "gray.50",
          _dark: "gray.800",
        },
        tertiary: {
          default: "gray.100",
          _dark: "gray.700",
        },
      },
      border: {
        default: "gray.600",
        _dark: "gray.600",
      },
    },
  },
  styles: {
    global: {
      ":root": {
        "--form-error-color": "var(--chakra-colors-error-default)",
      },
    },
  },
  components: {
    Text: {
      baseStyle: {
        // color: "fg.subtle"
      },
    },
    Button: {
      variants: {
        "camino-secondary": {
          backgroundColor: "coral.950",
          color: "white",
          border: "none",
          _hover: {
            bg: "coral.1050",
          },
        },
        alternative: {
          backgroundColor: "rose.600",
          color: "white",
          _hover: {
            bg: "rose.700",
          },
        },
        floating: (props: any) => {
          const outlineVariant = baseTheme.components.Button?.variants?.outline(props);
          return {
            ...outlineVariant,
            ...floatingButton,
          };
        },
      },
    },
    Checkbox: {
      baseStyle: {
        // using px bc the select component has a 1px border, making the checkbox 1px smaller
        // on top and bottom. Since we cannot override the select, and they sit next to each
        // other often, just adjusting this so they are the same height
        p: "7px",
        px: 4,
        control: {
          borderColor: "border.default",
          _hover: {
            borderColor: "gray.400",
          },
        },
      },
    },
    Form: {
      variants: {
        floating: floatingForm,
      },
      defaultProps: {
        variant: "floating",
      },
    },
    InputGroup: {
      baseStyle: {
        field: {
          borderSize: "2px",
          borderColor: "transparent",
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          color: "fg.muted",
          _dark: {
            bg: "transparent",
          },
          _focusVisible: {
            boxShadow: "none",
          },
          _active: {
            ...selectedInputBorder,
            "&:invalid": {
              boxShadow: "none",
            },
          },
          _focusWithin: {
            // this goes after the has value as we do not want it to have box shadow while in focus
            "&:not([aria-invalid='true'])": {
              ...selectedInputBorder,
            },
          },
          _hover: {
            // this goes after the has value as we do not want it to have box shadow while in focus
            "&:not(:focus)": {
              ...formInputWithValueBorder,
            },
            "&:invalid, &:invalid:focus": {
              boxShadow: "none",
            },
          },
          ...floatingNoValueBorder,
          borderWidth: "2px",
          _invalid: {
            border: "2px solid var(--chakra-colors-error-DEFAULT)",
          },
        },
      },
      defaultProps: {
        variant: "outline",
      },
      variants: {
        floating: {
          field: {
            "&:not(:placeholder-shown)": {
              // This styles the input when it has a value (no placeholder is shown)
              _invalid: {
                border: "2px solid var(--chakra-colors-error-DEFAULT) !important",
              },
            },
            _placeholder: {
              color: "gray.400",
            },
          },
        },
      },
    },
    Popover: {
      variants: {
        aboveSticky: {
          popper: {
            zIndex: "var(--chakra-zIndices-popover)",
          },
        },
      },
    },
    RadioCard: {
      baseStyle: {
        // using px bc the select component has a 1px border, making the checkbox 1px smaller
        // on top and bottom. Since we cannot override the select, and they sit next to each
        // other often, just adjusting this so they are the same height
        p: "7px",
        px: 4,
        control: {
          borderColor: "border.default",
          _hover: {
            borderColor: "gray.400",
          },
        },
      },
    },
    Tag: {
      variants: {
        path: {
          container: {
            // this is brand.500 with an opacity of 30%
            bg: "brand.200",
            _hover: {
              bg: "brand.300",
            },
          },
        },
      },
    },
    Textarea: {
      baseStyle: {
        color: "fg.subtle",
        borderColor: "border.default",
        _placeholder: {
          color: "fg.subtle",
        },
      },
      variants: {
        outline: {
          borderColor: "gray.600",
          _hover: {
            borderColor: "gray.400",
          },
          _dark: {
            background: "gray.700",
          },
        },
      },
    },
  },
});

export default theme;
