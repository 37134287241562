import {
  CreateToastFnReturn,
  useToast,
  HStack,
  Stack,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import { QUERY_KEYS } from "definitions/constants";
import { Company } from "models/company";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import {
  NAME_FIELD,
  SLUG_FIELD,
  DOMAIN_FIELD,
  WEBSITE_URL_FIELD,
  LOGO_URL_FIELD,
  NEW_HIRE_INFO_PACKET_URL_FIELD,
  BENEFITS_INFO_URL_FIELD,
  HRIS_URL_FIELD,
  COMPANY_HANDBOOK_URL_FIELD,
  COMPANY_WIKI_URL_FIELD,
  COMPANY_GOALS_URL_FIELD,
  ACRONYM_REPOSITORY_URL_FIELD,
  BUDDY_EXPECTATIONS_URL_FIELD,
  BUDDY_SELECTION_URL_FIELD,
  ONBOARDING_PLAN_TEMPLATES_URL_FIELD,
  CUSTOM_EMAIL_DEFAULT_FROM_FIELD,
  CUSTOM_EMAIL_DEFAULT_REPLY_TO_FIELD,
  Schema,
  schema,
} from "./definitions";

function slugify(text: string): string {
  return text
    .toLowerCase()
    .replace(/ /g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, ""); // Remove all non-word chars
}

type FormData = Schema;

function CompanyForm({
  performRequest,
  onSuccess,
  onClose,
  defaultValues,
  proceedText,
}: {
  performRequest: (data: FormData) => Promise<Company>;
  onSuccess: (res: Company, queryClient: QueryClient, toast: CreateToastFnReturn) => void;
  onClose: () => void;
  proceedText: string;
  defaultValues: Partial<Schema>;
}) {
  const toast = useToast({ duration: 9000, isClosable: true, position: "top" });
  const queryClient = useQueryClient();

  const onSubmit = useCallback(
    async (data: Schema) => {
      try {
        const res = await performRequest(data);
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.companies, res.id] });
        onSuccess(res, queryClient, toast);
        onClose();
      } catch (error) {
        console.error(error);
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
        });
      }
    },
    [onClose, onSuccess, performRequest, queryClient, toast],
  );

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    register,
    reset,
    setFocus,
    watch,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver<Schema>(schema),
    defaultValues,
  });

  const name = watch("name");

  useEffect(() => {
    setFocus("name");
    reset();
  }, [reset, setFocus]);

  useEffect(() => {
    if (name && !dirtyFields.slug) {
      const slug = slugify(name);
      reset({ slug });
    }
  }, [name, reset, dirtyFields]);

  const requiredFields = [NAME_FIELD, SLUG_FIELD, DOMAIN_FIELD, WEBSITE_URL_FIELD];
  const optionalFields = [CUSTOM_EMAIL_DEFAULT_FROM_FIELD, CUSTOM_EMAIL_DEFAULT_REPLY_TO_FIELD];

  const additionalFields = [
    LOGO_URL_FIELD,
    NEW_HIRE_INFO_PACKET_URL_FIELD,
    BENEFITS_INFO_URL_FIELD,
    HRIS_URL_FIELD,
    COMPANY_HANDBOOK_URL_FIELD,
    COMPANY_WIKI_URL_FIELD,
    COMPANY_GOALS_URL_FIELD,
    ACRONYM_REPOSITORY_URL_FIELD,
    BUDDY_EXPECTATIONS_URL_FIELD,
    BUDDY_SELECTION_URL_FIELD,
    ONBOARDING_PLAN_TEMPLATES_URL_FIELD,
  ];

  return (
    <Stack width="100%" alignItems="start">
      {requiredFields.map((field) => (
        <TextInputField required {...field} errors={errors} register={register} />
      ))}
      {optionalFields.map((field) => (
        <TextInputField {...field} errors={errors} register={register} />
      ))}
      <Accordion allowToggle w="100%">
        <AccordionItem>
          <AccordionButton>
            Additional Fields
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            {additionalFields.map((field) => (
              <TextInputField required {...field} errors={errors} register={register} />
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <HStack my={8} alignSelf="end" justify="end">
        <Button onClick={handleSubmit(onSubmit, console.error)}>{proceedText}</Button>
      </HStack>
    </Stack>
  );
}

export function CompanyCreateForm({
  onClose,
  onSuccess,
}: {
  onClose: () => void;
  onSuccess: (company: Company) => void;
}) {
  return (
    <CompanyForm
      performRequest={(data) => apiClient.post<Company>(generatePath(API_ROUTES.admin.companies.base), data)}
      onSuccess={(res, _1, toast) => {
        toast({ title: "New company created.", status: "success" });
        onSuccess(res);
      }}
      onClose={onClose}
      proceedText="Create Company"
      defaultValues={{}}
    />
  );
}

export function CompanyUpdateForm({ company, onSuccess }: { company: Company; onSuccess: (company: Company) => void }) {
  return (
    <CompanyForm
      performRequest={(data) => apiClient.put<Company>(generatePath(API_ROUTES.admin.companies.show, { id: company.id }), data)}
      onSuccess={(res, _1, toast) => {
        toast({ title: "Company updated.", status: "success" });
        onSuccess(res);
      }}
      onClose={() => { }}
      proceedText="Update Company"
      defaultValues={company}
    />
  );
}
