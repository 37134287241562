/* eslint-disable import/prefer-default-export */

export const DEFAULT_TIME_OF_DAY = "09:00:00";

export const TRIGGER_DEFAULT_VALUES: { trigger: { type: "timing"; data: { referenceEvent: "start_date"; offsetDirection: "exact"; timeOfDay: string; timezoneType: "target_timezone"; } } } = {
  trigger: {
    type: "timing",
    data: {
      referenceEvent: "start_date",
      offsetDirection: "exact",
      timeOfDay: DEFAULT_TIME_OF_DAY,
      timezoneType: "target_timezone",
    },
  },
};
