import { HStack, Stack } from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import {
  FIRST_NAME_FIELD,
  LAST_NAME_FIELD,
  PERSONAL_EMAIL_FIELD,
  START_DATE_FIELD,
  TITLE_FIELD,
} from "definitions/constants/fields";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import DatePickerField from "components/FormElements/Fields/DatePickerField";
import { newHireSchema, NewHireFormValues } from "./definitions";

interface NewHireFormProps {
  onClose: () => void;
  onSubmit: (data: NewHireFormValues) => void;
  isLoading: boolean;
  proceedText: string;
  defaultValues: Partial<NewHireFormValues>;
  excludedUserIds?: string[];
}

export default function NewHireFormFields({
  onClose,
  defaultValues,
  onSubmit,
  isLoading,
  proceedText,
}: NewHireFormProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setFocus,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(newHireSchema),
    defaultValues,
  });

  useEffect(() => {
    setFocus("firstName");
  }, [setFocus]);

  useEffect(() => {
    reset({ ...defaultValues });
  }, [defaultValues, reset]);

  return (
    <>
      <Stack gap={2.5}>
        <TextInputField required {...FIRST_NAME_FIELD} register={register} errors={errors} />
        <TextInputField required {...LAST_NAME_FIELD} register={register} errors={errors} />
        <DatePickerField required control={control} {...START_DATE_FIELD} errors={errors} />
        <TextInputField required {...TITLE_FIELD} errors={errors} register={register} />
        <TextInputField required {...PERSONAL_EMAIL_FIELD} errors={errors} register={register} />
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit(onSubmit)}
          onClose={onClose}
          proceedText={proceedText}
          isLoading={isLoading}
        />
      </HStack>
    </>
  );
}

NewHireFormFields.defaultProps = {
  excludedUserIds: [],
};
