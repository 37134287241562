import { StorageManager } from "@chakra-ui/color-mode/dist/storage-manager";
import { ColorMode } from "@chakra-ui/color-mode";

class MemoryColorModeManager implements StorageManager {
  colorMode: ColorMode | undefined;

  storageType: "localStorage" | "cookie" = "localStorage";

  get type() {
    return this.storageType;
  }

  ssr = false;

  get(): ColorMode | undefined {
    return this.colorMode;
  }

  set(value: ColorMode | "system") {
    if (value === "system") {
      this.colorMode = MemoryColorModeManager.currentColorMode();
    } else {
      this.colorMode = value;
    }
  }

  static currentColorMode(): ColorMode {
    return window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
}

const memoryColorModeManager = new MemoryColorModeManager();

export default memoryColorModeManager;
