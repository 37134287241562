import {
  Box, BoxProps, Button, ButtonGroup, Center, Flex, HStack, StackProps,
} from "@chakra-ui/react";

interface StepperButtonProps {
  title: string;
  disabled: boolean;
  handleClick: () => void;
}

interface StepperControlProps extends StackProps {
  isLoading: boolean;
  nextButton: StepperButtonProps;
  prevButton: StepperButtonProps;
  currentStep: number;
  numberOfSteps: number;
  setStep: (step: number) => void;
}

interface Props extends BoxProps {
  isActive: boolean;
}

function StepDot(props: Props) {
  const { isActive, ...boxProps } = props;
  return (
    <Box
      boxSize="2.5"
      bg={isActive ? "accent" : "border.default"}
      borderRadius="full"
      transition="background 0.2s"
      {...boxProps}
    />
  );
}

function StepIndicator({
  currentStep,
  numberOfSteps,
  setStep,
}: {
  currentStep: number;
  numberOfSteps: number;
  setStep: (step: number) => void;
}) {
  return (
    <HStack spacing="4">
      {[...Array(numberOfSteps)].map((_, id) => (
        <StepDot
          // eslint-disable-next-line react/no-array-index-key
          key={id}
          cursor="pointer"
          onClick={() => setStep(id)}
          isActive={currentStep === id}
        />
      ))}
    </HStack>
  );
}

function StepperControl({
  nextButton,
  prevButton,
  currentStep,
  numberOfSteps,
  setStep,
  isLoading,
  ...rest
}: StepperControlProps) {
  return (
    <HStack
      justify="space-between"
      spacing="4"
      p="2"
      py="4"
      bg="background.primary"
      position="sticky"
      bottom="calc(0% - 8px)"
      mx="4"
      {...rest}
    >
      <Flex />
      <Center>
        <StepIndicator currentStep={currentStep} numberOfSteps={numberOfSteps} setStep={setStep} />
      </Center>
      <HStack w="fit-content" spacing="4" justify="end">
        <ButtonGroup>
          <Button variant="outline" isDisabled={prevButton.disabled || isLoading} onClick={prevButton.handleClick}>
            {prevButton.title}
          </Button>
          <Button isDisabled={nextButton.disabled} isLoading={isLoading} onClick={nextButton.handleClick}>
            {nextButton.title}
          </Button>
        </ButtonGroup>
      </HStack>
    </HStack>
  );
}

export default StepperControl;
