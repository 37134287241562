import { HStack, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { Company } from "models/company";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";
import HourSelectField from "components/FormElements/Fields/SelectFields/HoursSelectField";
import { DEFAULT_FIELD_GAP } from "definitions/constants/styling";
import { useCurrentCompanyQuery, useUpdateCompany } from "features/Company/hooks";
import {
  DEFAULT_TIMEZONE_FIELD,
  DEFAULT_TRIGGER_TIME_OF_DAY_FIELD,
  Schema,
  schema,
  SUGGESTED_EMAIL_FORMAT_FIELD,
} from "./definitions";

const values = (company: Company | null | undefined) => {
  if (!company) return {};
  const { defaultTimezone, defaultTriggerTimeOfDay, suggestedEmailFormat } = company;
  return { defaultTimezone, defaultTriggerTimeOfDay, suggestedEmailFormat };
};

export default function CompanyDefaultsForm({
  onClose,
}: {
  onClose: () => void;
}) {
  const { data: company } = useCurrentCompanyQuery();
  const defaultValues: Partial<Schema> = values(company);
  const { mutate, isPending } = useUpdateCompany();
  const {
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    control,
    register,
    reset,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    values: defaultValues,
  });

  return (
    <Stack gap={DEFAULT_FIELD_GAP} alignItems="start">
      <TextInputField register={register} {...SUGGESTED_EMAIL_FORMAT_FIELD} errors={errors} />
      <TimezoneSelectField control={control} {...DEFAULT_TIMEZONE_FIELD} errors={errors} />
      <HourSelectField control={control} isClearable={false} {...DEFAULT_TRIGGER_TIME_OF_DAY_FIELD} errors={errors} />

      <HStack my={8} alignSelf="end" justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit((data) => mutate({ data, dirtyFields }, { onSuccess: () => { reset(); onClose(); } }))}
          onClose={onClose}
          proceedText="Update Company Info"
          proceedDisabled={!isDirty}
          isLoading={isPending}
        />
      </HStack>
    </Stack>
  );
}

