import * as yup from "yup";
import { ACTION_TYPE_FIELD, NAME_FIELD } from "./fields";

const dateTime = yup.object({
  datetime: yup.date().required(),
});

type DateTimeSchema = yup.InferType<typeof dateTime>;

const dependency = yup.object({
  dependencyType: yup.mixed<"Task">().required(),
  dependencyId: yup.string().required(),
});

type DependencySchema = yup.InferType<typeof dependency>;

export const sharedSchema = yup.object({
  actionType: yup
    .mixed<"email" | "chat" | "task_notifier" | "task_reminder">()
    .label(ACTION_TYPE_FIELD.label)
    .required(),
  name: yup.string().label(NAME_FIELD.label).notRequired(),
  trigger: yup.object({
    type: yup.mixed<"date_time" | "dependency">().notRequired(),
    data: yup.mixed<DateTimeSchema | DependencySchema>().when("type", {
      is: "date_time",
      then: () => dateTime.notRequired(),
      otherwise: () => dependency.notRequired(),
    }),
  }),
});

export type SharedSchema = yup.InferType<typeof sharedSchema>;
