import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Steps, Step } from "components/Steps";
import { TRIGGER_DEFAULT_VALUES } from "definitions/constants/trigger";
import { useCallback } from "react";
import { toPathTrigger } from "helpers/workflowActionableConverters";
import { schema, Schema } from "./schema";
import ChatInfo from "./ChatInfo";
import ActionInfo from "../Shared/ActionInfo";
import useSubmitForm from "../Shared/useSubmitForm";

export default function Form({
  workflowId,
  onSuccess,
  defaultValues,
}: {
  workflowId: string;
  onSuccess: () => void;
  defaultValues: Partial<Schema>;
}) {
  const form = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver<Schema>(schema, { stripUnknown: true }),
    defaultValues: { ...TRIGGER_DEFAULT_VALUES, ...defaultValues },
  });
  const transformer = useCallback(
    (id: string, data: Schema) => ({
      workflowAction: { workflowId: id, actionable: { ...data, trigger: toPathTrigger(data.trigger) } },
    }),
    [],
  );

  const { onSubmit, isLoading } = useSubmitForm({ workflowId, onSuccess, transformer });

  return (
    <Steps
      handleComplete={form.handleSubmit(onSubmit, (e) => console.error(e))}
      submitButtonTitle="Submit"
      isLoading={isLoading}
    >
      <Step id="action-info">
        <ActionInfo form={form} workflowId={workflowId} actionTypeName="Chat Message" />
      </Step>
      <Step id="chat-info">
        <ChatInfo form={form} />
      </Step>
    </Steps>
  );
}
