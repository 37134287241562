import { Box, HStack, Text } from "@chakra-ui/react";
import { useWatch, UseFormReturn, FieldPathByValue } from "react-hook-form";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import TimeOfDay from "components/Forms/PathActions/Shared/Trigger/TimeOfDay";
import generateSchedulingSummary from "components/Forms/PathActions/Shared/helpers";
import { Maybe } from "yup";
import { schedulingFields } from "../definitions";
import { Schema, TriggerSchema } from "../schema";
import { useEffect } from "react";

export default function Scheduling({
  fieldName,
  form,
  hideTimeOfDay = false,
}: {
  fieldName: FieldPathByValue<Schema, Maybe<TriggerSchema>>;
  form: UseFormReturn<Schema>;
  hideTimeOfDay?: boolean;
}) {
  const {
    control,
    formState: { errors },
  } = form;
  // using "useWatch" - "watch" was not triggering the useEffect to run when referenceEvent or offsetDirection changed
  const fields = schedulingFields(fieldName);
  const [relationTarget, actionType, offsetDirection] = useWatch({
    control,
    name: ["relationTarget", "actionType", fields.offsetDirection.name as FieldPathByValue<Schema, string>],
  });
  const trigger = useWatch({ control, name: fieldName });
  useEffect(() => {
    if (offsetDirection) form.setValue(`${fieldName}.type`, "timing");
  }, [form, offsetDirection]);

  let schedulingSummary = "";
  if (trigger?.type === "timing" && trigger.data) schedulingSummary = generateSchedulingSummary({ field: "trigger", trigger });
  const offsetDirectionOptions = fields.offsetDirection.options.filter((option) => {
    if (relationTarget === "new_hire" && trigger?.data?.referenceEvent === "start_date" && actionType !== "email") {
      return option.value !== "before";
    }

    return option.value !== "before" || trigger?.data?.referenceEvent !== "immediate";
  });
  const referenceEventOptions = fields.referenceEvent.options.filter(
    (option) => !["task", "chat"].includes(actionType) || relationTarget !== "new_hire" || option.value !== "immediate",
  );

  return (
    <>
      {schedulingSummary.length > 0 && <Text fontSize="s" fontStyle="italic">{`Summary: ${schedulingSummary}`}</Text>}
      <HStack width="100%">
        <Box display="inline-block" width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...fields.offsetDirection}
            options={offsetDirectionOptions}
            errors={errors}
            control={control}
          />
        </Box>
        <Box width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...fields.referenceEvent}
            options={referenceEventOptions}
            errors={errors}
            control={control}
          />
        </Box>
      </HStack>

      {trigger?.data?.offsetDirection && trigger.data.offsetDirection !== "exact" && (
        <HStack width="100%">
          <Box display="inline-block" width="15%">
            <SingleSelectField isClearable={false} required {...fields.offsetValue} errors={errors} control={control} />
          </Box>
          <Box display="inline-block" width="20%">
            <SingleSelectField isClearable={false} required {...fields.offsetUnit} errors={errors} control={control} />
          </Box>
          {trigger.data.offsetUnit === "specific_day" && (
            <Box width="25%">
              <SingleSelectField isClearable={false} required {...fields.dayOfWeek} errors={errors} control={control} />
            </Box>
          )}
        </HStack>
      )}
      {!hideTimeOfDay && <TimeOfDay<Schema> fieldName={fieldName} form={form} />}
    </>
  );
}
