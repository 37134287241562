import {
  Box, Skeleton, Heading, BoxProps,
} from "@chakra-ui/react";

interface PageTitleProps extends BoxProps {
  isLoaded?: boolean;
  text: string;
}

export default function PageTitle({ isLoaded, text, ...rest }: PageTitleProps) {
  return (
    <Box pl={8} {...rest}>
      <Skeleton isLoaded={isLoaded}>
        <Heading color="fg.muted" size="md">
          {text}
        </Heading>
      </Skeleton>
    </Box>
  );
}

PageTitle.defaultProps = {
  isLoaded: true,
};
