import { Button, ButtonProps } from "@chakra-ui/react";
import { UseModalReturn } from "hooks/useModal";
import { PropsWithChildren } from "react";
import Modal from "../Modal";

interface ModalButtonProps extends ButtonProps {
  title: string;
  modal: UseModalReturn;
}

export default function ModalButton({
  title, modal, children, ...rest
}: PropsWithChildren<ModalButtonProps>) {
  return (
    <>
      <Button onClick={modal.onOpen} {...rest}>
        {title}
      </Button>

      <Modal {...{ modal }}>{children}</Modal>
    </>
  );
}
