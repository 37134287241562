import { Box, ButtonGroup, HStack, Button } from "@chakra-ui/react";
import {
  Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch,
} from "react-hook-form";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { MessageServiceChannel } from "models/integration/messageService";
import SelectableActionType from "components/Form/SelectableActionType";
import { actionableTypeToIcon, actionableTypeConverter } from "helpers/workflowActionableConverters";
import { titleize } from "helpers/string";
import { ActionAttributeCard } from "components/Path/ActionCard";
import RecipientsContainer from "components/Forms/PathActions/Email/RecipientsContainer";
import { useState } from "react";
import Modal from "components/Modal";
import PersonalizationTokenMenu from "components/FormElements/Fields/ActionRichTextAreaField/PersonalizationTokenMenu";
import { useModal } from "hooks/useModal";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { Schema } from "./schema";
import {
  TARGET_TYPE_FIELD,
  MESSAGE_SERVICE_CHANNEL_FIELD,
  NAME_FIELD,
  SPECIFIC_USER_FIELD,
  RELATION_TARGET_FIELD,
} from "./definitions";

export default function Meta({
  register,
  control,
  errors,
  watch,
  setValue,
  isUpdate,
}: {
  register: UseFormRegister<Schema>;
  control: Control<Schema>;
  errors: FieldErrors<Schema>;
  watch: UseFormWatch<Schema>;
  setValue: UseFormSetValue<Schema>;
  isUpdate: boolean;
}) {
  const [actionType, targetType, taskType, emailType, additionalRecipients, ccRecipients, bccRecipients, actionName] = watch([
    "actionType",
    "targetType",
    "taskType",
    "emailType",
    "additionalRecipients",
    "ccRecipients",
    "bccRecipients",
    "name",
  ]);
  const tokenModal = useModal({
    id: "name-token",
    header: { title: "Insert Token" },
    size: "lg",
    isCentered: true,
  });
  const targetTitle = ["email", "chat"].includes(actionType) ? "Recipient" : "Assignee";
  const targetTypeTitle = `${targetTitle} Type`;
  const targetTypeOptions = TARGET_TYPE_FIELD.options.filter((option) => (actionType === "chat" ? true : option.value !== "message_service_channel"));
  const [showAdditionalRecipients, setShowAdditionalRecipients] = useState((additionalRecipients?.length ?? 0) > 0);
  const [showCc, setShowCc] = useState((ccRecipients?.length ?? 0) > 0);
  const [showBcc, setShowBcc] = useState((bccRecipients?.length ?? 0) > 0);

  return (
    <>
      <HStack width="100%">
        {isUpdate ? (
          <ActionAttributeCard
            icon={actionableTypeToIcon({ actionType, taskType })}
            text={emailType ? titleize(emailType) : actionableTypeConverter({ actionType, taskType })}
          />
        ) : (
          <SelectableActionType setValue={setValue} />
        )}
      </HStack>

      <HStack width="100%">
        <Box width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...TARGET_TYPE_FIELD}
            options={targetTypeOptions}
            label={targetTypeTitle}
            placeholder={targetTypeTitle}
            errors={errors}
            control={control}
          />
        </Box>
        <Box width="50%" alignItems="start">
          {targetType === "specific_user" && (
            <UserSelectField control={control} required {...SPECIFIC_USER_FIELD} errors={errors} />
          )}
          {targetType === "message_service_channel" && (
            <GenericAsyncSelectField
              fetchPath={API_ROUTES.messageServices.channels.base}
              extractValue={(v: MessageServiceChannel) => v.id}
              embedOption={(v) => ({
                value: v.id,
                label: `#${v.name}`,
                rawValue: v,
              })}
              control={control}
              {...MESSAGE_SERVICE_CHANNEL_FIELD}
              errors={errors}
            />
          )}
          {targetType === "relation" && (
            <SingleSelectField
              isClearable={false}
              required
              {...RELATION_TARGET_FIELD}
              label={targetTitle}
              placeholder={targetTitle}
              errors={errors}
              control={control}
            />
          )}
        </Box>
      </HStack>
      {actionType === "email" && !(showAdditionalRecipients && showCc && showBcc) && (
        <ButtonGroup colorScheme="gray" variant="outline" isAttached mb={4}>
          {!showAdditionalRecipients && (
            <Button onClick={() => setShowAdditionalRecipients((s) => !s)}>+ Recipients</Button>
          )}
          {!showCc && <Button onClick={() => setShowCc((s) => !s)}>Cc</Button>}
          {!showBcc && <Button onClick={() => setShowBcc((s) => !s)}>Bcc</Button>}
        </ButtonGroup>
      )}
      {actionType === "email" && showAdditionalRecipients && (
        <RecipientsContainer label="Additional Recipients" name="additionalRecipients" control={control} />
      )}
      {actionType === "email" && showCc && <RecipientsContainer label="Cc" name="ccRecipients" control={control} />}
      {actionType === "email" && showBcc && <RecipientsContainer label="Bcc" name="bccRecipients" control={control} />}
      <HStack width="100%">
        <TextInputField
          required
          {...NAME_FIELD}
          errors={errors}
          register={register}
          rightButton={
            actionType === "task" ? { width: "7.5rem", text: "Personalize", onClick: tokenModal.onOpen } : undefined
          }
        />
      </HStack>
      <Modal modal={tokenModal}>
        <PersonalizationTokenMenu
          actionType={actionType}
          targetType={targetType}
          onSelect={(token) => {
            setValue("name", `${actionName}{{${token.id}}}`, { shouldDirty: true });
            tokenModal.onClose();
          }}
        />
      </Modal>
    </>
  );
}
