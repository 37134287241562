import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import moment from "moment";
import { IconType } from "react-icons";
import { MdFlag } from "react-icons/md";
import { TbAlertCircleFilled } from "react-icons/tb";

const READABLE_SCHEDULED_ACTION_STATUS_CONVERTER = {
  awaiting_info: "Awaiting Info",
  needs_attention: "Needs Attention",
  error: "Error",
  ready: "Ready",
  processed: "Sent",
  skipped: "Skipped",
  removed: "Removed",
};

export function scheduledActionStatusConverter(status: keyof typeof READABLE_SCHEDULED_ACTION_STATUS_CONVERTER) {
  return READABLE_SCHEDULED_ACTION_STATUS_CONVERTER[status];
}

export const READABLE_SCHEDULED_ACTION_STATUS_TO_ICON_CONVERTER: Record<string, IconType> = {
  needs_attention: MdFlag,
  error: TbAlertCircleFilled,
};

export function scheduledActionStatusToIcon(status: keyof typeof READABLE_SCHEDULED_ACTION_STATUS_CONVERTER) {
  return READABLE_SCHEDULED_ACTION_STATUS_TO_ICON_CONVERTER[status] || "";
}

const READABLE_SCHEDULED_ACTION_STATUS_TO_COLOR_CONVERTER = {
  awaiting_info: "brand",
  needs_attention: "orange",
  error: "red",
  ready: "brand",
  processed: "green",
  skipped: "gray",
  removed: "gray",
};

function scheduledActionStatusToColor(status: keyof typeof READABLE_SCHEDULED_ACTION_STATUS_CONVERTER) {
  return READABLE_SCHEDULED_ACTION_STATUS_TO_COLOR_CONVERTER[status];
}

export function isElevated(assignedAction: AssignedAction) {
  return (
    assignedAction.status === "awaiting_info"
    && assignedAction.trigger.type === "date_time"
    && new Date(assignedAction.trigger.data.datetime) <= moment().add(1, "week").toDate()
  );
}

export function scheduledActionToStatusColor(assignedAction: AssignedAction) {
  if (isElevated(assignedAction)) {
    return scheduledActionStatusToColor("needs_attention");
  }
  return scheduledActionStatusToColor(assignedAction.status);
}

export function countCompletedScheduledActions(scheduledWorkflowActions: BaseAssignedAction[]) {
  return scheduledWorkflowActions.filter((action) => action.status === "processed").length;
}

export function countValidScheduledActions(scheduledWorkflowActions: BaseAssignedAction[]) {
  return scheduledWorkflowActions.filter((action) => !["skipped", "removed"].includes(action.status)).length;
}
