import {
  Card, CardBody, CardHeader, Center, HStack, Heading, Stack,
} from "@chakra-ui/react";
import NewEmployeeButtonWithModal from "components/ModalForm/NewEmployeeButtonWithModal";
import SearchBar from "components/SearchBar";
import WorkforceTable from "components/Table/WorkforceTable";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import { useEffect, useState } from "react";

export default function WorkforcePage() {
  const [searchText, setSearchText] = useState("");
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Workforce",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Workforce");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Center>
        <Stack direction="column" w="100%" alignItems="flex-start">
          <SearchBar minWidth="500" name="tasksSearch" onInput={setSearchText} my="4" />
        </Stack>
      </Center>
      <Card mt="8" p="4">
        <CardHeader>
          <HStack justify="space-between">
            <Heading size="xs" textTransform="capitalize">
              Workforce
            </Heading>
            <NewEmployeeButtonWithModal />
          </HStack>
        </CardHeader>
        <CardBody>
          <WorkforceTable searchText={searchText} />
        </CardBody>
      </Card>
    </AppPage>
  );
}
