import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import EmployeeCard from "components/Card/EmployeeCard";
import { User } from "models/user";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { QUERY_KEYS } from "definitions/constants";
import { useQuery } from "@tanstack/react-query";
import {
  Modal, ModalBody, ModalContent, useDisclosure,
} from "@chakra-ui/react";
import ProfileForm from "components/Forms/ProfileForm";
import { useNavbar } from "hooks/useNavbar";
import { useEffect } from "react";

export default function UserPage() {
  const { id } = useParams<{ id: string }>();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const { data: employee, isLoading } = useQuery<User>({
    queryKey: [QUERY_KEYS.users, id],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.users.show, { id })),
  });

  useEffect(() => {
    if (employee && !isLoading) {
      setPageTitle(`${employee?.firstName}'s Profile`);
      setBreadcrumbs([
        {
          name: "Workforce",
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.workforce),
          isCurrentPage: false,
        },
        {
          name: `${employee?.firstName}'s Profile`,
          isCurrentPage: true,
        },
      ]);
    }
  }, [employee, isLoading, setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <EmployeeCard employee={employee} editClickedHandler={onOpen} />
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalContent>
          <ModalBody>
            <ProfileForm user={employee as unknown as User} onSave={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </AppPage>
  );
}
