import { useSearchParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import {
  Card, CardBody, Tab, TabIndicator, TabList, TabPanel, TabPanels, Tabs,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import { TIME_RANGE } from "definitions/constants";
import ActivityList from "./ActivityList";

const TAB_QUERY_PARAM = "t";

export default function ActivityFeedPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = Number(searchParams.get(TAB_QUERY_PARAM)) ?? 0;
  const setTabIndex = (index: number) => setSearchParams(
    (params) => {
      params.set(TAB_QUERY_PARAM, String(index));
      return params;
    },
    { replace: true },
  );

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Activity Feed",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Activity Feed");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Card>
        <CardBody>
          <Tabs defaultIndex={tabIndex} onChange={setTabIndex} size="lg" variant="indicator" isLazy>
            <TabList width="fit-content">
              <Tab color="fg.muted">Recent</Tab>
              <Tab color="fg.muted">Upcoming</Tab>
            </TabList>
            <TabIndicator />
            <TabPanels>
              <TabPanel>
                <ActivityList
                  defaultTimePeriod={TIME_RANGE.TODAY_UP_TO_NOW.value}
                  timePeriodOptions={[
                    TIME_RANGE.TODAY_UP_TO_NOW,
                    TIME_RANGE.YESTERDAY,
                    TIME_RANGE.THIS_WEEK_START_TO_NOW,
                    TIME_RANGE.LAST_MONTH_TO_NOW,
                    TIME_RANGE.INFINITE_TO_NOW,
                  ]}
                />
              </TabPanel>
              <TabPanel>
                <ActivityList
                  defaultTimePeriod={TIME_RANGE.NOW_TO_END_OF_TODAY.value}
                  timePeriodOptions={[
                    TIME_RANGE.NOW_TO_END_OF_TODAY,
                    TIME_RANGE.TOMORROW,
                    TIME_RANGE.NOW_TO_THIS_WEEK_END,
                    TIME_RANGE.NEXT_WEEK,
                    TIME_RANGE.NOW_PLUS_ONE_MONTH,
                    TIME_RANGE.NOW_TO_INFINITE,
                  ]}
                  sortDesc={false}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </AppPage>
  );
}
