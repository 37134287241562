import { Text, TextProps } from "@chakra-ui/react";
import { toSendAtText, toTriggerText } from "components/Trigger";
import { EMOJI_ICONS } from "definitions/constants/icons";
import { isDependencyTrigger, isTemplateAction, trigger } from "helpers/action";
import {
  actionTargetText,
  actionableTypeConverter,
  emailTypeConverter,
  targetText,
} from "helpers/workflowActionableConverters";
import { Action, PathTrigger } from "models/automation";
import { TemplateAction } from "models/automation/templateWorkflow";

interface ActionDisplayProps extends TextProps {
  action: Action | TemplateAction;
}

export function ActionTypeText({ action, ...rest }: ActionDisplayProps) {
  const emailType = action?.actionType === "email" ? ` (${emailTypeConverter(action?.emailType)})` : "";
  const readableActionType = actionableTypeConverter(action);
  const emoji = EMOJI_ICONS[action?.actionType];
  return (
    <Text fontSize="md" fontWeight="medium" textAlign="center" color="chakra-body-text" {...rest}>
      {`${emoji} ${readableActionType}${emailType}`}
    </Text>
  );
}

export function ActionTargetText({
  action,
  includeEmailType,
  ...rest
}: ActionDisplayProps & { includeEmailType?: boolean }) {
  const readableTarget = isTemplateAction(action)
    ? targetText({ relationTarget: action.relationTarget })
    : actionTargetText(action);
  let additionalInfo = "";
  if (includeEmailType && action.actionType === "email") {
    additionalInfo = action.emailType === "personal_email" ? " (Personal)" : " (Work)";
  }
  return (
    <Text fontSize="sm" fontWeight="medium" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.target} ${readableTarget}${additionalInfo}`}
    </Text>
  );
}

ActionTargetText.defaultProps = {
  includeEmailType: false,
};

export function ActionTriggerText({ action, ...rest }: ActionDisplayProps) {
  const triggerText = isTemplateAction(action)
    ? toTriggerText({ actionable: { trigger: action.trigger } } as Action)
    : toTriggerText(action);
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.triggerAt} ${triggerText}`}
    </Text>
  );
}

export function ActionTriggerAndTimingText({ action, ...rest }: ActionDisplayProps) {
  const resolvedAction = isTemplateAction(action)
    ? ({ actionable: { trigger: action.trigger as PathTrigger } } as Action)
    : action;
  const preText = isDependencyTrigger(trigger(action)) ? "After " : "";
  const sendAtText = isDependencyTrigger(trigger(action)) ? "" : toSendAtText(resolvedAction);
  const triggerText = toTriggerText(resolvedAction);
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.triggerAt} ${preText}${triggerText}${sendAtText}`}
    </Text>
  );
}

export function ActionSendAtText({ action, ...rest }: ActionDisplayProps) {
  const sendAtText = isTemplateAction(action)
    ? toSendAtText({ actionable: { trigger: action.trigger as PathTrigger } } as Action)
    : toSendAtText(action);
  return (
    <Text fontSize="md" textAlign="center" color="chakra-body-text" {...rest}>
      {`${EMOJI_ICONS.triggerAt} ${sendAtText}`}
    </Text>
  );
}
