import { Text } from "@chakra-ui/react";
import { DEFAULT_TIME_OF_DAY } from "definitions/constants/trigger";
import { display12hrTimeOfDay } from "helpers/time";
import { automationTriggerText } from "helpers/workflowActionableConverters";
import { Action, PathTimingTriggerData, PathTrigger } from "models/automation";

export function TriggerComponent({ action }: { action: Action }) {
  const { trigger } = action.actionable;
  switch (trigger.type) {
    case "dependency":
      // TODO: add dependency/task completion trigger here, reference/link the dependency
      return <Text>Task Completion</Text>;
    case "timing":
      return automationTriggerText(trigger);
    default:
      return null;
  }
}

export function toTriggerText(action: Action) {
  const { trigger } = action.actionable;
  switch (trigger.type) {
    case "dependency":
      // TODO: add dependency/task completion trigger here, reference/link the dependency
      return "Task Completion";
    case "timing":
      return automationTriggerText(trigger);
    default:
      return null;
  }
}

function timingSendAtText(trigger: PathTrigger) {
  const {
    referenceEvent, offset, timeOfDay, timezoneType, specificTimezone,
  } = (trigger?.data
    || {}) as PathTimingTriggerData;
  if (referenceEvent === "immediate" && offset === 0) {
    return " (1 minute after onboarding kicks off)";
  }
  const formattedTimeOfDay = display12hrTimeOfDay(timeOfDay || DEFAULT_TIME_OF_DAY);
  let addedText = " (Recipient's Timezone)";
  switch (timezoneType) {
    case "specific_timezone":
      addedText = ` (${specificTimezone})`;
      break;
    case "company_default_timezone":
      addedText = " (Company Default Timezone)";
      break;
    default:
      break;
  }
  return ` at ${formattedTimeOfDay}${addedText}`;
}

export function toSendAtText(action: Action) {
  const { trigger } = action.actionable;
  switch (trigger.type) {
    case "dependency":
      // TODO: add dependency/task completion trigger here, reference/link the dependency
      return "TBD";
    case "timing":
      return timingSendAtText(trigger);
    default:
      return null;
  }
}
