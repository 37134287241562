import { useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import ClickableSuccessCheck from "components/SuccessCheck/ClickableSuccessCheck";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { BaseTask } from "models/task";
import { useState } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

interface ClickableTaskStatusProps {
  task: BaseTask | undefined;
  onSuccess?: (response: any) => void;
}

export default function ClickableTaskStatus({ task, onSuccess = undefined }: ClickableTaskStatusProps) {
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const [complete, setComplete] = useState<boolean>(!!task?.completedAt);

  const { mutate } = useMutation({
    mutationFn: async () => {
      const taskIncompleteStatus = task?.notifiedAt ? "notified" : "assigned";
      const res = await apiClient.put<BaseTask>(generatePath(API_ROUTES.tasks.show, { id: task?.id }), {
        task: { status: task?.status === "completed" ? taskIncompleteStatus : "completed" },
      });
      return res;
    },
    onSuccess: async (res) => {
      onSuccess?.(res);
      toast({ title: res?.completedAt ? "Task Completed 🎉" : "Task Marked Incomplete" });
      setComplete(!!res?.completedAt);
    },
    onError: (e) => {
      console.log(e);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
  return <ClickableSuccessCheck skipped={task?.status === "skipped"} complete={complete} onClick={() => mutate()} />;
}
