import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { WORKFLOW_SELECT_FIELD } from "definitions/constants/fields";
import { VStack } from "@chakra-ui/react";
import PathsSelectFieldWithConfirm from "components/FormElements/Fields/SelectFields/PathsSelectFieldWithConfirm";
import { useUpdateNewHireJourney } from "features/NewHireJourney/hooks";
import { dirtyValues } from "helpers/form";
import { newHireSchema } from "./definition";

interface AddPathsFormData {
  workflowIds: string[];
}

interface AddPathsFormProps {
  newHireJourney: ExtendedNewHireJourney;
}

function AddPathsForm({ newHireJourney }: AddPathsFormProps) {
  const { mutate, isPending } = useUpdateNewHireJourney(newHireJourney?.id || "");

  const {
    control,
    formState: { errors, dirtyFields },
    handleSubmit,
  } = useForm<any>({
    mode: "onBlur",
    resolver: yupResolver(newHireSchema.pick(["workflowIds"])),
    values: {
      workflowIds: newHireJourney.assignedPaths.map((assignedPath) => assignedPath.workflow.id),
    },
  });

  const onSubmit: SubmitHandler<AddPathsFormData> = (data) => {
    const filteredData = dirtyValues(data, dirtyFields);
    if (Object.keys(filteredData).length > 0) mutate(filteredData);
  };

  return (
    <VStack spacing={4} minWidth="500px">
      <PathsSelectFieldWithConfirm
        onSubmit={handleSubmit(onSubmit)}
        isLoading={isPending}
        {...WORKFLOW_SELECT_FIELD}
        control={control}
        errors={errors}
        required
      />
    </VStack>
  );
}

export default AddPathsForm;
