import { useEffect, useRef } from "react";

const useOnUpdate = (callback: () => void, deps: any[]) => {
  const isFirst = useRef(true);
  useEffect(() => {
    if (!isFirst.current) {
      callback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    isFirst.current = false;
  }, []);
};

export default useOnUpdate;
