import { Heading } from "@chakra-ui/react";
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormResetField,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import TaskExampleImage from "components/ExampleImage/TaskExampleImage";
import { TASK_TYPES } from "definitions/constants";
import { useEffect } from "react";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import { useModal } from "hooks/useModal";
import PersonalizationTokenMenu from "components/FormElements/Fields/ActionRichTextAreaField/PersonalizationTokenMenu";
import Modal from "components/Modal";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import TargetableTypeField from "components/FormElements/Fields/TargetableTypeField";
import {
  BODY_FIELD, DESCRIPTION_FIELD, MESSAGE_FIELD, SUBJECT_FIELD, MESSAGE_SUGGESTION_FIELD,
} from "./definitions";
import { Schema } from "./schema";

export default function Content({
  register,
  control,
  errors,
  watch,
  resetField,
  setValue,
}: {
  register: UseFormRegister<Schema>;
  control: Control<Schema>;
  errors: FieldErrors<Schema>;
  watch: UseFormWatch<Schema>;
  resetField: UseFormResetField<Schema>;
  setValue: UseFormSetValue<Schema>;
}) {
  const [actionType, targetType, taskType, subject] = watch([
    "actionType",
    "targetType",
    "taskType",
    "content.subject",
  ]);
  const messageTargetType = watch("content.messageTarget.targetType");
  const tokenModal = useModal({
    id: "subject-token",
    header: { title: "Insert Token" },
    size: "lg",
    isCentered: true,
  });

  useEffect(() => {
    if (taskType === TASK_TYPES.SEND_MESSAGE_PROMPT && !messageTargetType) {
      resetField("content.messageTarget.targetType", { defaultValue: "OnboardingJourneyNetworkRelation" });
    }
  }, [resetField, taskType, messageTargetType]);

  return (
    <>
      <Heading size="xs" mt="6">
        Content
      </Heading>

      <>
        {actionType === "email" && (
          <TextInputField
            {...SUBJECT_FIELD}
            errors={errors}
            register={register}
            rightButton={{ width: "7.5rem", text: "Personalize", onClick: tokenModal.onOpen }}
          />
        )}
        {actionType === "email" && (
          <ActionRichTextAreaField
            actionType={actionType}
            targetType={targetType}
            {...BODY_FIELD}
            errors={errors}
            control={control}
          />
        )}
        {actionType === "chat" && (
          <ActionRichTextAreaField
            actionType={actionType}
            targetType={targetType}
            {...MESSAGE_FIELD}
            errors={errors}
            control={control}
          />
        )}
        {actionType === "task" && [TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT].includes(taskType as string) && (
          <ActionRichTextAreaField
            actionType={actionType}
            targetType={targetType}
            {...DESCRIPTION_FIELD}
            errors={errors}
            control={control}
          />
        )}
        {actionType === "task" && TASK_TYPES.SEND_MESSAGE_PROMPT === taskType && (
          <>
            <TargetableTypeField<Schema>
              errors={errors}
              control={control}
              label="Message Recipient"
              fieldName="content.messageTarget"
              targetTitle="Message Target"
            />
            <ActionRichTextAreaField
              actionType={actionType}
              targetType={targetType}
              {...MESSAGE_SUGGESTION_FIELD}
              errors={errors}
              control={control}
            />
          </>
        )}
        {actionType === "task"
          && taskType
          && ![TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT, TASK_TYPES.SCHEDULE_MEETING].includes(taskType) && (
            <TaskExampleImage taskType={taskType} />
        )}
      </>
      <Modal modal={tokenModal}>
        <PersonalizationTokenMenu
          actionType={actionType}
          targetType={targetType}
          onSelect={(token) => {
            setValue("content.subject", `${subject}{{${token.id}}}`, { shouldDirty: true });
            tokenModal.onClose();
          }}
        />
      </Modal>
    </>
  );
}
