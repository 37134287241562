/* eslint-disable import/prefer-default-export */
import { Text } from "@chakra-ui/react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { hasCustomizedDateTimeTrigger, hasImmediateTrigger, hasDependencyTrigger } from "./helpers";

export function TriggerExplanation({ assignedAction }: { assignedAction: AssignedAction }) {
  let triggerExplanation;
  if (hasCustomizedDateTimeTrigger(assignedAction)) {
    triggerExplanation = "This action's send date and time has been customized by a user.";
  } else if (hasImmediateTrigger(assignedAction)) {
    triggerExplanation = "This action will be scheduled to be sent 1 minute after the user starts their onboarding in Camino.";
  } else if (hasDependencyTrigger(assignedAction)) {
    triggerExplanation = "This action will be scheduled to be sent 1 minute the task it depends on is marked completed.";
  } else {
    return null;
  }
  return <Text fontSize="md">{triggerExplanation}</Text>;
}
