import "./styles.css";
import { ControlInputFieldProps } from "components/Form/typings";
// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import Trix from "trix";
import { useRef } from "react";
import Modal from "components/Modal";
import { useModal } from "hooks/useModal";
import { ActionableType } from "models/automation";
import { LegacyTargetType } from "models/record_target";
import PersonalizationTokenMenu from "./PersonalizationTokenMenu";
import RichTextAreaField from "../RichTextAreaField";
import { RichText } from "../RichTextAreaField/richtext";

type Props = ControlInputFieldProps & {
  actionType: ActionableType;
  targetType?: LegacyTargetType;
};

export default function ActionRichTextAreaField(props: Props) {
  const modal = useModal({
    id: "personalization-token",
    size: "lg",
    header: { title: "Insert Token", fontSize: "lg" },
    isCentered: true,
  });
  const { actionType, targetType, ...rest } = props;
  const trixRef = useRef<Trix>(null);

  return (
    <>
      <RichTextAreaField
        {...rest}
        ref={trixRef}
        onInitialize={(_: any, richText: RichText) => {
          richText.insertPersonalizationElements();
          richText.personalizationTokenButton.addEventListener("click", () => {
            modal.onOpen();
          });
        }}
      />
      <Modal modal={modal}>
        <PersonalizationTokenMenu
          actionType={actionType}
          targetType={targetType}
          onSelect={(token) => {
            const { editor } = trixRef.current;
            const attachment = new Trix.Attachment({ content: token.content, sgid: token.sgid });
            editor.insertAttachment(attachment);
            modal.onClose();
          }}
        />
      </Modal>
    </>
  );
}

ActionRichTextAreaField.defaultProps = {
  targetType: undefined,
};
