import AppPage from "layouts/AppPage";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import NewHireJourneyCardTable from "features/NewHireJourney/NewHireJourneyCardTable";

export default function NewHireJourneysPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([{ name: "New Hires", isCurrentPage: true }]);
    setPageTitle("New Hires");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <NewHireJourneyCardTable />
    </AppPage>
  );
}
