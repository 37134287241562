import { Button } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import { BaseAssignedAction } from "models/automation";
import ModalFormWrapper from "../ModalFormWrapper";
import ScheduledActionForm from ".";

interface ScheduledWorkflowActionButtonWithModalProps extends PropsWithChildren {
  scheduledAction: BaseAssignedAction | null;
}

export default function ScheduledActionButtonWithModal({
  scheduledAction,
  children,
}: ScheduledWorkflowActionButtonWithModalProps) {
  const [buttonLabel, modalHeader] = scheduledAction
    ? ["Edit Assigned Action", "Edit Assigned Action"]
    : ["Create New Assigned Action", "Create New Assigned Action"];

  return (
    <ModalFormWrapper
      eid={scheduledAction?.id}
      size="5xl"
      modalHeader={modalHeader}
      button={(children as JSX.Element) || <Button>{buttonLabel}</Button>}
    >
      <ScheduledActionForm scheduledAction={scheduledAction} />
    </ModalFormWrapper>
  );
}
