import * as yup from "yup";
import {
  ActionableType, EmailType, EmailRecipient, PathRelationTarget,
} from "models/automation";
import { LegacyTargetType } from "models/record_target";
import { sharedSchema } from "../Shared/schema";

const content = yup.object({
  subject: yup.string().required().label("Subject"),
  body: yup.string().required().label("Body"),
});

export const schema = sharedSchema.concat(
  yup.object({
    actionType: yup.mixed<Extract<ActionableType, "email">>().required().label("Action Type"),
    targetType: yup.mixed<LegacyTargetType>().required().label("Recipient"),
    emailType: yup.mixed<EmailType>().required(),
    relationTarget: yup
      .mixed<PathRelationTarget>()
      .label("Recipient")
      .when("targetType", {
        is: "relation",
        then: (s) => s.required(),
        otherwise: (s) => s.notRequired(),
      }),
    specificTargetableId: yup.string().when("targetType", {
      is: "specific_user",
      then: (s) => s.required().label("User"),
      otherwise: (s) => s.notRequired(),
    }),
    specificTargetableType: yup.string().when("targetType", {
      is: "specific_user",
      then: (s) => s.required(),
      otherwise: (s) => s.notRequired(),
    }),
    additionalRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Additional Recipients"),
    ccRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Cc Recipients"),
    bccRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Bcc Recipients"),
    content,
  }),
);

export type Schema = yup.InferType<typeof schema>;
