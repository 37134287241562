import { useQuery } from "@tanstack/react-query";
import AppPage from "layouts/AppPage";
import { generatePath } from "react-router-dom";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useEffect, useState } from "react";
import { NewIncomingWebhook } from "models/integration/incomingWebhook";
import { Integration } from "models/integration";
import { useNavbar } from "hooks/useNavbar";
import NewCredentialsCard from "./NewCredentialsCard";
import ConnectedIntegrations from "./ConnectedIntegrations";
import AdditionalIntegrations from "./AddtionalIntegrations";

export default function CompanyIntegrationsPage() {
  const [newWebhookCredentials, setNewWebhookCredentials] = useState<NewIncomingWebhook | null>(null);
  const { setPageTitle, setBreadcrumbs } = useNavbar();

  useEffect(() => {
    setPageTitle("Integrations");
    setBreadcrumbs([{ name: "Integrations", isCurrentPage: true }]);
  }, [setPageTitle, setBreadcrumbs]);

  const { data: integrations } = useQuery({
    queryKey: [QUERY_KEYS.integrations],
    queryFn: async ({ signal }) => apiClient.get<Integration[]>(generatePath(API_ROUTES.integrations.index), signal),
  });

  return (
    <AppPage>
      {newWebhookCredentials && <NewCredentialsCard webhookCredentials={newWebhookCredentials} />}
      <ConnectedIntegrations integrations={integrations || []} mb="4" />
      <AdditionalIntegrations setNewWebhookCredentials={setNewWebhookCredentials} />
    </AppPage>
  );
}
