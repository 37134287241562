/* eslint-disable react/require-default-props, react/default-props-match-prop-types, react/no-unused-prop-types */
import { Box, BoxProps, Heading } from "@chakra-ui/react";
import { useMemo } from "react";

export enum StepState {
  initial,
  loading,
  complete,
}

interface StepProps extends BoxProps {
  id: string;
  title?: string;
  isCurrentStep?: boolean;
  onClickNext?: (callback: (state: StepState) => void) => void;
  __TYPE: "Step";
}

function Step({
  id, title, children, isCurrentStep, __TYPE, onClickNext, ...rest
}: StepProps) {
  const key = useMemo(() => id || crypto.randomUUID(), [id]);
  return (
    <Box key={key} as="section" display={isCurrentStep ? "block" : "none"} {...rest}>
      {title && (
        <Heading as="h2" fontSize="lg" mb="4">
          {title}
        </Heading>
      )}
      <Box overflow="visible">{children}</Box>
    </Box>
  );
}

Step.defaultProps = {
  title: undefined,
  isCurrentStep: false,
  __TYPE: "Step",
  onClickNext: undefined,
};

export default Step;

/* eslint-enable react/require-default-props, react/default-props-match-prop-types, react/no-unused-prop-types */
