import { Box, CardProps, Flex, Text } from "@chakra-ui/react";
import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import DashboardCard from "./DashboardCard";

interface TasksCardProps extends CardProps {
  tasksCount: number;
}

export default function TasksCard({ tasksCount, ...rest }: TasksCardProps) {
  const taskModifier = tasksCount > 1 ? "s" : "";

  return (
    <DashboardCard title="Tasks" emoji="✅" buttonText="Review" destination={SHARED_ROUTES.tasks.index} {...rest}>
      <Flex direction="column" justifyContent="center" alignItems="start" h="100%">
        <Box as="p">
          <Text as="span" mr="1" fontSize="lg" fontWeight="bold">
            {tasksCount}
          </Text>
          <Text as="span" fontSize="lg">
            {` task${taskModifier} pending`}
          </Text>
        </Box>
      </Flex>
    </DashboardCard>
  );
}
