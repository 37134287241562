import { Box, HStack, Heading } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { MessageServiceChannel } from "models/integration/messageService";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import { RELATION_TARGET_FIELD, SPECIFIC_USER_FIELD, TARGET_TYPE_FIELD } from "../Shared/fields";
import { Schema } from "./schema";
import { BODY_FIELD, MESSAGE_SERVICE_CHANNEL_FIELD } from "./fields";

export default function ChatInfo({
  form: {
    control,
    watch,
    formState: { errors },
  },
}: {
  form: UseFormReturn<Schema>;
}) {
  const [actionType, targetType] = watch(["actionType", "targetType"]);
  const targetTitle = "Recipient";
  const targetTypeTitle = `${targetTitle} Type`;

  return (
    <>
      <Heading as="h3" size="xs" mb={4}>
        3. Complete Chat Content
      </Heading>
      <HStack width="100%" mb={2}>
        <Box width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...TARGET_TYPE_FIELD}
            label={targetTypeTitle}
            placeholder={targetTypeTitle}
            errors={errors}
            control={control}
          />
        </Box>
        <Box width="50%" alignItems="start">
          {targetType === "specific_user" && (
            <UserSelectField control={control} required {...SPECIFIC_USER_FIELD} errors={errors} />
          )}
          {targetType === "relation" && (
            <SingleSelectField
              isClearable={false}
              required
              {...RELATION_TARGET_FIELD}
              label={targetTitle}
              placeholder={targetTitle}
              errors={errors}
              control={control}
            />
          )}
          {targetType === "message_service_channel" && (
            <GenericAsyncSelectField
              fetchPath={API_ROUTES.messageServices.channels.base}
              extractValue={(v: MessageServiceChannel) => v.id}
              embedOption={(v) => ({
                value: v.id,
                label: `#${v.name}`,
                rawValue: v,
              })}
              control={control}
              {...MESSAGE_SERVICE_CHANNEL_FIELD}
              errors={errors}
            />
          )}
        </Box>
      </HStack>
      <ActionRichTextAreaField
        actionType={actionType}
        targetType={targetType}
        {...BODY_FIELD}
        errors={errors}
        control={control}
      />
    </>
  );
}
