import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import SendMessageForm from "components/ModalForm/SendMessageForm";
import React, { PropsWithChildren } from "react";

interface SendMessageButtonProps extends PropsWithChildren {
  targetId: string;
  targetType: string;
  defaultMessage: string;
  onSuccess?: () => void;
}

export default function SendMessageButton({
  targetId,
  defaultMessage,
  targetType,
  onSuccess,
  children,
  ...rest
}: SendMessageButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {React.cloneElement(children as JSX.Element, { onClick: onOpen })}

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="2xl" {...rest}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Send Slack Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SendMessageForm
              onClose={onClose}
              targetId={targetId}
              targetType={targetType}
              draftMessage={defaultMessage}
              onSuccess={onSuccess}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

SendMessageButton.defaultProps = {
  onSuccess: () => null,
};
