// The goal of this component is to have an editable, sortable, filterable, and pagenatable table
// but one that has all the items from the start (does not make calls to the backend for more)

import { SystemStyleObject, TableProps } from "@chakra-ui/react";

import {
  ColumnDef,
  ColumnHelper,
  DeepKeys,
  SortingFn,
  SortingState,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
  Table,
} from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import React, { useMemo } from "react";
import { PAGE_INDEX_QUERY_PARAM, PAGE_SIZE_QUERY_PARAM } from "definitions/constants";
import BaseCardTable from "./BaseCardTable";

export interface IColumnDefinition<T> {
  attribute: DeepKeys<T>;
  label: string;
  disableSorting?: true;
  size?: number;
  maxSize?: number;
  minSize?: number;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const simpleColumn = <T,>(columnDefinition: IColumnDefinition<T>, colHelper: ColumnHelper<T>) => colHelper.accessor(columnDefinition.attribute, {
  header: columnDefinition.label,
  enableSorting: !columnDefinition.disableSorting,
  ...(columnDefinition.size ? { size: columnDefinition.size } : {}),
  ...(columnDefinition.maxSize ? { maxSize: columnDefinition.maxSize } : {}),
  ...(columnDefinition.minSize ? { minSize: columnDefinition.minSize } : {}),
});

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const totalPages = <T,>(data: T[], pagesize: number) => Math.ceil(data.length / pagesize);

export interface ControlledCardTableProps<T> extends TableProps {
  cardComponent: React.ComponentType<any>;
  isLoading?: boolean;
  data: T[];
  columns: ColumnDef<T, any>[];
  sx?: SystemStyleObject;
  initialPagination?: { pageIndex: number; pageSize: number };
  customSortingObject?: {
    [key: string]: SortingFn<unknown>;
  };
  defaultSort?: SortingState;
  emptyStateComponent?: React.ReactNode;
  getCardProps?: () => object;
  getTrProps?: () => object;
  getTdProps?: () => object;
  getContainerProps?: () => object;
  setTable?: (table: Table<T>) => void;
  includePagination?: boolean;
}

export default function ControlledCardTable<T>({
  cardComponent,
  customSortingObject = {},
  data,
  columns,
  sx = undefined,
  isLoading = false,
  defaultSort = undefined,
  initialPagination = undefined,
  emptyStateComponent = undefined,
  includePagination = true,
  setTable = () => ({}),
  getCardProps = () => ({}),
  getTrProps = () => ({}),
  getTdProps = () => ({}),
  getContainerProps = () => ({}),
  ...props
}: ControlledCardTableProps<T>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const defaultPagination = useMemo(() => {
    let pagination = { pageSize: 20, pageIndex: 0 };
    if (initialPagination) pagination = initialPagination;
    if (searchParams.get(PAGE_SIZE_QUERY_PARAM)) pagination.pageSize = Number(searchParams.get(PAGE_SIZE_QUERY_PARAM));
    if (searchParams.get(PAGE_INDEX_QUERY_PARAM)) pagination.pageIndex = Number(searchParams.get(PAGE_INDEX_QUERY_PARAM));
    return pagination;
  }, [initialPagination, searchParams]);

  const [pagination, setPagination] = React.useState(defaultPagination);
  const [sorting, setSorting] = React.useState<SortingState>(defaultSort ?? []);

  React.useEffect(() => {
    setSearchParams(
      (params) => {
        params.set(PAGE_SIZE_QUERY_PARAM, String(pagination.pageSize));
        params.set(PAGE_INDEX_QUERY_PARAM, String(pagination.pageIndex));
        return params;
      },
      { replace: true },
    );
  }, [pagination, setSearchParams]);

  const table = useReactTable({
    data: data || [],
    columns,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    sortingFns: { ...customSortingObject },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onPaginationChange: setPagination,
    getPaginationRowModel: getPaginationRowModel(),
    enableSortingRemoval: true,
  });

  React.useEffect(() => {
    if (setTable) {
      setTable(table);
    }
  }, [setTable, table]);

  return (
    <BaseCardTable
      includePagination={includePagination}
      cardComponent={cardComponent}
      table={table}
      sx={sx}
      isLoading={isLoading}
      emptyStateComponent={emptyStateComponent}
      getCardProps={getCardProps}
      getTrProps={getTrProps}
      getTdProps={getTdProps}
      getContainerProps={getContainerProps}
      {...props}
    />
  );
}
