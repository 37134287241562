/* eslint-disable max-len */
import { Icon, IconProps } from "@chakra-ui/react";

function CaminoAlertIcon(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_960_12588)">
        <path
          d="M8.00016 5.33398V8.00065M8.00016 10.6673H8.00683M14.6668 8.00065C14.6668 11.6826 11.6821 14.6673 8.00016 14.6673C4.31826 14.6673 1.3335 11.6826 1.3335 8.00065C1.3335 4.31875 4.31826 1.33398 8.00016 1.33398C11.6821 1.33398 14.6668 4.31875 14.6668 8.00065Z"
          stroke="#2A95FF"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_960_12588">
          <rect width="16px" height="16px" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
export default CaminoAlertIcon;
