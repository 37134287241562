import {
  Box,
  Skeleton,
  Card,
  Flex,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  Button,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { truncateUrl } from "helpers/url";
import { Company } from "models/company";
import CompanyResourcesForm from "components/ModalForm/CompanyResourcesForm";
import { InfoDisplayValue, InfoColumn } from "components/DataDisplay/InfoDisplayValue";

interface CompanyResourcesCardProps {
  company?: Company;
  editClickedHandler?: () => void;
}

function CompanyResourcesCard({ company = undefined, editClickedHandler = () => null }: CompanyResourcesCardProps) {
  return (
    <Skeleton isLoaded={!!company}>
      <Card mt={5} p="25px 35px" maxW={1300}>
        <Flex direction="column" width="100%">
          <Flex justifyContent="space-between" w="100%" alignItems="center" p={5}>
            <Box opacity="70%" fontSize="2xl">
              Company Resources
            </Box>
            <Button onClick={editClickedHandler} px="20px">
              Edit
            </Button>
          </Flex>
          <Flex justifyContent="space-between">
            <InfoColumn pt={2}>
              <InfoDisplayValue
                value={company?.buddyExpectationsUrl && truncateUrl(company?.buddyExpectationsUrl.slice(8))}
                label="Buddy Expectations"
                toLink={company?.buddyExpectationsUrl}
              />
              <InfoDisplayValue
                value={company?.buddySelectionUrl && truncateUrl(company?.buddySelectionUrl.slice(8))}
                label="Buddy Selection Guidelines"
                toLink={company?.buddySelectionUrl}
              />
              <InfoDisplayValue
                value={company?.onboardingPlanTemplatesUrl && truncateUrl(company?.onboardingPlanTemplatesUrl.slice(8))}
                label="Onboarding Plan Templates"
                toLink={company?.onboardingPlanTemplatesUrl}
              />
            </InfoColumn>
            <InfoColumn pt={2}>
              <InfoDisplayValue
                value={company?.companyWikiUrl && truncateUrl(company?.companyWikiUrl.slice(8))}
                label="Wiki"
                toLink={company?.companyWikiUrl}
              />
              <InfoDisplayValue
                value={company?.companyHandbookUrl && truncateUrl(company?.companyHandbookUrl.slice(8))}
                label="Handbook"
                toLink={company?.companyHandbookUrl}
              />
              <InfoDisplayValue
                value={company?.companyGoalsUrl && truncateUrl(company?.companyGoalsUrl.slice(8))}
                label="Company Goals"
                toLink={company?.companyGoalsUrl}
              />
            </InfoColumn>
            <InfoColumn pt={2}>
              <InfoDisplayValue
                value={company?.hrisUrl && truncateUrl(company?.hrisUrl)}
                label="HrisUrl"
                toLink={company?.hrisUrl}
              />
              <InfoDisplayValue
                value={company?.benefitsInfoUrl && company?.benefitsInfoUrl}
                label="Benefits"
                toLink={company?.benefitsInfoUrl}
              />
            </InfoColumn>
          </Flex>
        </Flex>
      </Card>
    </Skeleton>
  );
}

interface CompanyResourcesSectionProps {
  company?: Company;
}

export default function CompanyResourcesSection({ company = undefined }: CompanyResourcesSectionProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Box>
      <CompanyResourcesCard company={company} editClickedHandler={onOpen} />
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Company Resources</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CompanyResourcesForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
