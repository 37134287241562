/* eslint-disable import/prefer-default-export */
import {
  PEOPLE_TEAM_ROUTES,
  SHARED_COMPANY_ROUTES,
} from "definitions/constants/routeConstants";

export function isActiveSidebar(pathname: string, route: string) {
  if (pathname.includes(SHARED_COMPANY_ROUTES.newHireJourneys.index)) {
    return pathname === route;
  }
  return (
    pathname.includes(route)
    && ![
      PEOPLE_TEAM_ROUTES.issues.error,
      PEOPLE_TEAM_ROUTES.dashboard,
    ].includes(route)
  );
}
export function removeLastPath(pathname: string) {
  return pathname.split("/").slice(0, -1).join("/");
}
