import { ButtonGroup, Text, Flex } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import UpdateTaskForm from "components/Forms/AssignedActions/Task/UpdateTaskForm";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useSendNowAssignedAction from "features/AssignedAction/hooks/useSendNowAssignedAction";
import { findTaskNotifier } from "features/Task/helpers";
import { Task } from "models/joinedModels";
import { useMemo, useRef, useState } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

const CONFIRM_SEND_NOW = {
  header: "Confirm you want to send this task now!",
  body: "This cannot be undone! Be sure you want to send this task now.",
  confirmButtonText: "Send Now",
  buttonText: "Send Now",
};

interface TaskDrawerFormProps {
  onClose: () => void;
  resourceId: string;
}

export default function TaskDrawerForm({ onClose, resourceId }: TaskDrawerFormProps) {
  const formRef = useRef<any>(null); // Create a ref to access form methods
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const { data: task, isLoading: taskIsLoading } = useQuery({
    queryKey: [QUERY_KEYS.tasks, resourceId],
    queryFn: async ({ signal }) => apiClient.get<Task>(generatePath(API_ROUTES.tasks.show, { id: resourceId || "" }), signal),
    enabled: !!resourceId && resourceId.length > 0,
  });

  const initialNotifier = useMemo(() => findTaskNotifier(task as Task), [task]);

  const handleSubmit = (onSuccessCallback: () => void) => {
    formRef.current?.handleSubmit(onSuccessCallback); // Call form submit from the ref
  };

  const { sendNow, isReady } = useSendNowAssignedAction({
    assignedAction: initialNotifier,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.tasks, task?.id] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths, initialNotifier?.scheduledWorkflowId] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.newHireJourneys, task?.newHireJourneyId] });
    },
  });

  return (
    <Flex direction="column">
      {taskIsLoading && <Text>Loading...</Text>}
      {!taskIsLoading && task && (
        <UpdateTaskForm
          ref={formRef}
          task={task}
          onSuccess={onClose}
          setIsSubmitLoading={setIsSubmitLoading}
          setIsFormDirty={setIsFormDirty}
        />
      )}
      <ButtonGroup display="flex" justifyContent="flex-end">
        <ButtonWithConfirm
          intent="warning"
          isDisabled={!isReady}
          {...CONFIRM_SEND_NOW}
          handleConfirm={() => handleSubmit(() => sendNow())}
        />
        <ProceedCancelButtons
          handleSubmit={() => handleSubmit(() => {})}
          onClose={onClose}
          proceedText="Update Task"
          proceedDisabled={!isFormDirty}
          isLoading={isSubmitLoading}
        />
      </ButtonGroup>
    </Flex>
  );
}
