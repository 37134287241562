import { PathBase } from "models/automation";
import { TemplatePathSummary } from "models/automation/templatePath";

export function getPathName(path: PathBase | undefined) {
  if (!path) {
    return "";
  }

  return `${path.name}${path.name.endsWith("Path") ? "" : " Path"}`;
}

export function getTemplateName(templatePath: TemplatePathSummary) {
  if (!templatePath) {
    return "";
  }

  return `${templatePath.name}${templatePath.name.endsWith("Template") ? "" : " Template"}`;
}
