/* eslint-disable import/prefer-default-export */
import { useToast } from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyUpdateFormValues } from "components/Forms/CompanyUpdateForm";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { Company } from "models/company";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

const companyKeys = {
  all: ["companies"] as const,
  current: () => [...companyKeys.all, "current"] as const,
};

export const useCurrentCompanyQuery = () => useQuery<Company>({
  queryKey: companyKeys.current(),
  queryFn: async () => apiClient.get<Company>(generatePath(API_ROUTES.companies.show, { id: "current" })),
  refetchOnWindowFocus: false,
});

export function useUpdateCompany() {
  const queryClient = useQueryClient();
  const toast = useToast();
  return useMutation({
    mutationFn: (params: { data: CompanyUpdateFormValues; dirtyFields: Record<string, boolean> }) => {
      const { data, dirtyFields } = params;
      const updateData = Object.fromEntries(
        Object.entries(data).filter(([key]) => dirtyFields[key as keyof CompanyUpdateFormValues] === true),
      );
      return apiClient.put<Company>(generatePath(API_ROUTES.companies.show, { id: "current" }), {
        company: updateData,
      });
    },
    onSuccess: () => {
      toast({
        title: "Success!",
        status: "success",
      });
      return queryClient.invalidateQueries({ queryKey: companyKeys.current() });
    },
    onError: (mutateError) => {
      console.log("userCreateError", mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });
}
