import React, { createContext, useContext, useState, PropsWithChildren, useMemo } from 'react';

interface FormStatusContextProps {
  isFormValid: boolean;
  setIsFormValid: (isValid: boolean) => void;
}

const FormContext = createContext<FormStatusContextProps | undefined>(undefined);

export function FormStatusProvider({ children }: PropsWithChildren) {
  const [isFormValid, setIsFormValid] = useState(false);

  const contextValue = useMemo(() => ({ isFormValid, setIsFormValid }), [isFormValid]);

  return (
    <FormContext.Provider value={contextValue}>
      {children}
    </FormContext.Provider>
  );
}

export const useFormStatusContext = () => {
  const context = useContext(FormContext);
  if (context === undefined) {
    throw new Error("useFormStatusContext must be used within a FormStatusProvider");
  }
  return context;
};
