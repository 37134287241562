/* eslint-disable object-curly-newline */
import { RadioCardGroup, RadioCard } from "components/FormElements/RadioCardGroup";
import {
  As,
  CardProps,
  Icon,
  VStack,
  Text,
  Flex,
} from "@chakra-ui/react";
import { useCallback, useEffect } from "react";
import { UseFormSetValue } from "react-hook-form";
import { Schema } from "components/ModalForm/PathActionForm/schema";
import { IconType } from "react-icons";
import { FiHash, FiMail, FiMessageSquare, FiUser } from "react-icons/fi";

export type SelectNotifierTargetValue = "message_user" | "message_channel" | "email_custom" | "email_user";

interface SelectableNotifierTargetType {
  name: string;
  value: SelectNotifierTargetValue;
  icon: IconType;
}

const SELECTABLE_NOTIFIER_TARGET_TYPE: SelectableNotifierTargetType[] = [
  { name: "Message to Channel", value: "message_channel", icon: FiHash },
  { name: "Message to User", value: "message_user", icon: FiMessageSquare },
  { name: "Email to Camino User", value: "email_user", icon: FiUser },
  { name: "Email to Custom Email", value: "email_custom", icon: FiMail },
];

type SelectToFormValuesMapType = {
  [key in SelectNotifierTargetValue]: Pick<Schema, "actionType" | "emailType" | "taskType">;
};

const SELECT_TO_FORM_VALUES_MAP: SelectToFormValuesMapType = {
  message_user: {
    deliveryMethod: "chat",
    recipientType: "User",
  },
  message_channel: {
    deliveryMethod: "chat",
    recipientType: "MessageServiceChannel",
  },
  email_custom: {
    deliveryMethod: "email",
  },
  email_user: {
    deliveryMethod: "email",
    emailType: "work",
    recipientType: "User",
  },
};

function actionTypeMap(notifierTargetType: SelectNotifierTargetValue) {
  return SELECT_TO_FORM_VALUES_MAP[notifierTargetType];
}

interface NotifierTargetTypeProps extends CardProps {
  name: string;
  icon: As;
}

export function NotifierTargetType({ name, icon }: NotifierTargetTypeProps) {
  return (
    <Flex direction="column" gap="2px" justify="center" alignItems="stretch" height="100%">
      <Text textAlign="center" fontWeight="600" fontSize={14}>
        {name}
      </Text>
      <VStack alignItems="center" gap="0">
        <Icon as={icon} boxSize="6" color="fg.subtle" />
      </VStack>
    </Flex>
  );
}

interface NotifierTargetTypeSelectorProps {
  defaultNotifierTargetType: string;
  setValue: UseFormSetValue<Schema>;
  setNotifierTargetType: (value: SelectNotifierTargetValue) => void;
  updateCacheKey: (key: string) => void;
  resetField: (field: string) => void;
}

export default function NotifierTargetTypeSelector({ defaultNotifierTargetType, setNotifierTargetType, setValue, updateCacheKey, resetField }: NotifierTargetTypeSelectorProps) {
  const updateValues = useCallback(
    (values: SelectToFormValuesMapType[keyof SelectToFormValuesMapType]) => {
      Object.entries(values).forEach(([field, value]) => {
        setValue(field as keyof SelectToFormValuesMapType[keyof SelectToFormValuesMapType], value);
      });
    },
    [setValue],
  );
  const processUpdate = useCallback(
    (notifierTargetType: SelectNotifierTargetValue) => {
      setNotifierTargetType(notifierTargetType);
      updateValues(actionTypeMap(notifierTargetType));
      updateCacheKey(notifierTargetType);
      resetField("recipientId");
    },
    [resetField, setNotifierTargetType, updateCacheKey, updateValues],
  );

  useEffect(() => {
    // need to set the intial value
    processUpdate(defaultNotifierTargetType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RadioCardGroup
      direction="row"
      justify="stretch"
      defaultValue={defaultNotifierTargetType}
      spacing="3"
      onChange={(value: keyof SelectToFormValuesMapType) => processUpdate(value)}
      flexWrap="wrap"
    >
      {SELECTABLE_NOTIFIER_TARGET_TYPE.map((notifierTargetTypeField) => (
        <RadioCard
          key={notifierTargetTypeField.value}
          alignSelf="stretch"
          value={notifierTargetTypeField.value}
          minW="120px"
          maxW="120px"
          minH="100px"
          maxH="100px"
        >
          <NotifierTargetType {...notifierTargetTypeField} />
        </RadioCard>
      ))}
    </RadioCardGroup>
  );
}
