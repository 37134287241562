import { Icon, IconProps } from "@chakra-ui/react";

export default function CaminoEmail(props: IconProps) {
  return (
    <Icon
      width="36px"
      height="36px"
      viewBox="0 0 36 36"
      fill="none"
      transform="translateY(.5px)"
      {...props}
    >
      <rect x="3.75" y="6.75" width="28.5" height="22.5" fill="#DFE7F2"/>
      <path d="M33 9C33 7.35 31.65 6 30 6H6C4.35 6 3 7.35 3 9M33 9V27C33 28.65 31.65 30 30 30H6C4.35 30 3 28.65 3 27V9M33 9L18 19.5L3 9" stroke="#959393" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
  );
}
