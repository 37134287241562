import { EmailIcon } from "@chakra-ui/icons";
import { Tag, TagCloseButton, TagLabel, TagLeftIcon } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { titleize } from "helpers/string";
import { EmailRecipient } from "models/automation";
import { OnboardingJourneyNetworkRelation } from "models/record_target";
import { User } from "models/user";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";

export default function EmailTag({ recipient, remove }: { recipient: EmailRecipient; remove: () => void }) {
  const appendEmailType = (value: string, emailType: string) => {
    const abbreviation = emailType?.slice(0, 1);
    if (!abbreviation) return value;
    return `${value} (${abbreviation})`;
  };
  let { id, targetType } = { id: "", targetType: "" };
  if (recipient.customEmail) {
    // No-op
  } else {
    id = recipient.recipient.targetId;
    targetType = recipient.recipient.targetType;
  }
  const { data: userData } = useQuery<User>({
    queryKey: [QUERY_KEYS.users, id],
    queryFn: () => apiClient.get<User>(generatePath(API_ROUTES.users.show, { id })),
    enabled: targetType === "User",
  });
  const { data: relationData } = useQuery<OnboardingJourneyNetworkRelation>({
    queryKey: ["OnboardingJourneyNetworkRelation", id],
    queryFn: () => apiClient.get<OnboardingJourneyNetworkRelation>(
      generatePath(API_ROUTES.onboardingJourneyNetworkRelations.show, { id }),
    ),
    enabled: targetType === "OnboardingJourneyNetworkRelation",
  });
  let values = { value: "Loading", icon: EmailIcon };
  if (recipient.customEmail) {
    values = { value: recipient.customEmail, icon: EmailIcon };
  } else if (targetType === "User") {
    if (userData) values = { value: appendEmailType(userData.fullName, recipient.emailType), icon: EmailIcon };
  } else if (targetType === "OnboardingJourneyNetworkRelation") {
    if (relationData) values = { value: appendEmailType(titleize(relationData.name), recipient.emailType), icon: EmailIcon };
  }

  return (
    <Tag size="lg" variant="solid" colorScheme="brand">
      <TagLeftIcon boxSize="12px" as={values.icon} />
      <TagLabel>{values.value}</TagLabel>
      <TagCloseButton onClick={remove} />
    </Tag>
  );
}
