enum ROLE {
  BASIC = "basic",
  ONBOARDER = "onboarder",
  MANAGER = "manager",
  PEOPLE_TEAM = "people_team",
  COMPANY_ADMIN = "company_admin",
  CAMINO_TEAM = "camino_team",
}

export default ROLE;
