export const SUBJECT_FIELD = {
  label: "Subject",
  placeholder: "Subject",
  name: "content.subject",
};

export const BODY_FIELD = {
  label: "Body",
  placeholder: "Body",
  name: "content.body",
};
export const EMAIL_TYPE_FIELD = {
  label: "Email Type",
  placeholder: "Email Type",
  name: "emailType",
  options: [
    { label: "Work", value: "work_email" },
    { label: "Personal", value: "personal_email" },
  ],
};
