import {
  Card, Stack, HStack, Text, IconButton, LinkBox, LinkOverlay,
} from "@chakra-ui/react";
import RemovePathConfirmation from "components/Alert/RemovePathConfirmation";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { countCompletedScheduledActions, countValidScheduledActions } from "helpers/scheduledWorkflowConverters";
import { BaseAssignedPath } from "models/automation";
import { FiEdit2 } from "react-icons/fi";
import { generatePath, useNavigate, Link as ReactRouterLink } from "react-router-dom";

interface AssignedPathCardProps {
  assignedPath: BaseAssignedPath;
}

export default function AssignedPathCard({ assignedPath, ...rest }: AssignedPathCardProps) {
  const navigate = useNavigate();
  return (
    <LinkBox as={Card} key={assignedPath.id} p="4" maxW="400px" _hover={{ bg: "background.tertiary" }} {...rest}>
      <Stack shouldWrapChildren spacing="4">
        <HStack justify="space-between">
          <Text textStyle="sm" fontWeight="medium" color="fg.emphasized">
            <LinkOverlay
              as={ReactRouterLink}
              to={generatePath(PEOPLE_TEAM_ROUTES.assignedPaths.show, { id: assignedPath.id })}
            >
              {assignedPath?.workflow?.name}
            </LinkOverlay>
          </Text>
          <HStack spacing={{ base: "0", sm: "1" }}>
            <IconButton
              icon={<FiEdit2 />}
              size="2xs"
              onClick={() => navigate(generatePath(PEOPLE_TEAM_ROUTES.assignedPaths.show, { id: assignedPath.id }))}
              variant="tertiary"
              aria-label="Edit experience"
            />
            <RemovePathConfirmation
              assignedPathId={assignedPath?.id}
              newHireJourneyId={assignedPath?.onboardingJourneyId}
              iconSize="2xs"
            />
          </HStack>
        </HStack>
        <HStack spacing="3">
          <Text textStyle="xs" color="fg.subtle" fontWeight="medium">
            {`${countCompletedScheduledActions(assignedPath?.actions || [])} of ${countValidScheduledActions(
              assignedPath?.actions || [],
            )} Completed`}
          </Text>
        </HStack>
      </Stack>
    </LinkBox>
  );
}
