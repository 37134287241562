import apiClient from "services/ApiClient";
import { NetworkingError } from "services/ApiClient/networkingError";
import { useCallback, useState } from "react";
import { CreateToastFnReturn, useToast } from "@chakra-ui/react";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";

type NetworkState<T> =
  | { data: T; loading: false; error?: undefined }
  | { data?: undefined; loading: true; error?: undefined }
  | { data?: undefined; loading: false; error: NetworkingError }
  | { data?: undefined; loading: false; error?: undefined };

type APIClientOptions<T> = {
  method: "GET" | "DELETE" | "POST" | "PUT";
  onSuccess?: (data: T, queryClient: QueryClient, toast: CreateToastFnReturn, navigate: NavigateFunction) => void;
  onFailure?: (
    error: NetworkingError,
    queryClient: QueryClient,
    toast: CreateToastFnReturn,
    navigate: NavigateFunction,
  ) => void;
};

export default function useDeferredApiCall<T>(path: string, options: APIClientOptions<T>) {
  const [state, setState] = useState<NetworkState<T>>({ loading: false });
  const toast = useToast({
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const performRequest = useCallback(
    async (body?: object) => {
      const controller = new AbortController();
      setState({ loading: true });
      try {
        let data: T;
        switch (options.method) {
          case "GET":
            data = await apiClient.get<T>(path, controller.signal);
            break;
          case "POST":
            data = await apiClient.post<T>(path, body as object, controller.signal);
            break;
          case "PUT":
            data = await apiClient.put<T>(path, body as object, controller.signal);
            break;
          case "DELETE":
            data = await apiClient.delete<T>(path, controller.signal);
            break;
          default:
            throw new Error("Invalid method");
        }
        setState({ data, loading: false });
        if (options.onSuccess) {
          options.onSuccess(data, queryClient, toast, navigate);
        }
      } catch (error) {
        setState({ loading: false, error: error as NetworkingError });
        console.error(error);
        if (options.onFailure) {
          options.onFailure(error as NetworkingError, queryClient, toast, navigate);
        }
      }
      return () => {
        controller.abort(); // Cancel the request when the component unmounts or dependencies change
      };
    },
    [toast, queryClient, path, options, navigate],
  ); // Add dependencies here if there are any

  return { state, performRequest };
}
