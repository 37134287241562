import { ColumnSort, PaginationState, SortingState } from "@tanstack/react-table";
import { createQueryString } from "helpers/queryString";
import React from "react";
import { MetaQuery } from "services/ApiClient/responseModel";

export default function useQueryString(
  meta?: MetaQuery,
  additionalQueries?: Record<string, string | undefined | string[]>,
) {
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: (meta?.pagination?.initialPage ?? 1) - 1,
    pageSize: meta?.pagination?.perPage ?? 50,
  });

  const pagination = { pageIndex, pageSize };

  const initalSort = (meta?.sort || []).map(
    (initialSort) => ({
      id: initialSort.sortBy,
      desc: initialSort.direction === "desc",
    }) as ColumnSort,
  );

  const [sortBy, setSortBy] = React.useState<SortingState>(initalSort);

  const queryString = createQueryString({
    pagination: meta?.pagination && { pageIndex, pageSize },
    sortBy,
    filters: meta?.filters,
    additionalQueries,
  });

  return {
    queryString,
    pagination,
    setPagination,
    sortBy,
    setSortBy,
  };
}
