import { schedulingFields } from "./fields";
import { TimingDataSchema, TriggerSchema } from "./schema";

type FieldOptions = { label: string; value: string }[];

function getFieldLabel(field: any, value: string): string {
  return (field.options as FieldOptions).find((option) => option.value === value)?.label || value;
}

export default function generateSchedulingSummary({
  field,
  trigger,
}: {
  field: string;
  trigger: TriggerSchema;
}): string {
  if (trigger.type === "dependency") return "";
  const {
    referenceEvent, offsetDirection, offsetUnit, offsetValue, dayOfWeek,
  } = trigger.data;
  const fields = schedulingFields(field);
  if (!offsetDirection) {
    return "";
  } else if (!referenceEvent) {
    return "Select an event";
  } else if (offsetDirection === "exact") {
    return `${referenceEvent === "immediate" ? "" : "On "}${getFieldLabel(fields.referenceEvent, referenceEvent)}`;
  } else if (!offsetValue) {
    return "Select a timing value";
  } else if (!offsetUnit) {
    return "Select a timing unit";
  } else if (offsetUnit === "specific_day") {
    if (!dayOfWeek) return "Select a day of the week";
    return `${offsetValue} ${getFieldLabel(fields.dayOfWeek, dayOfWeek)}(s) ${getFieldLabel(
      fields.offsetDirection,
      offsetDirection,
    )} ${getFieldLabel(fields.referenceEvent, referenceEvent)}`;
  }
  return `${offsetValue} ${getFieldLabel(fields.offsetUnit, offsetUnit)} ${getFieldLabel(
    fields.offsetDirection,
    offsetDirection,
  )} ${getFieldLabel(fields.referenceEvent, referenceEvent)}`;
}

export function isImmediateNoOffsetTrigger({
  referenceEvent,
  offsetDirection,
}: Pick<TimingDataSchema, "referenceEvent" | "offsetDirection">): boolean {
  return referenceEvent === "immediate" && offsetDirection === "exact";
}
