import { BaseAssignedPath, BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { NewHireJourney } from "models/onboarding";
import { ExtendedNewHireJourney, Task } from "models/joinedModels";
import { CombinedAction, CombinedActionId, TaskId } from "models/automation/combinedAction";
import { isTask } from "../features/Task/helpers";
import { BaseTask } from "models/task";
import { snakeCase } from "lodash";

function scheduledActionHasStarted(action: AssignedAction | BaseAssignedAction) {
  return !!action.firstAttemptedAt;
}

export function pullScheduledActions(scheduledWorkflows: BaseAssignedPath[]) {
  return scheduledWorkflows.map((scheduledWorkflow) => scheduledWorkflow.actions).flat();
}

export default function haveAutomationsStarted(newHireJourney: ExtendedNewHireJourney) {
  const actions = pullScheduledActions(newHireJourney?.assignedPaths || []);
  return actions ? actions.some((action) => scheduledActionHasStarted(action)) : false;
}

export function pullUniqueNewHireJourneys(actions: AssignedAction[]) {
  const uniqueNewHireJourneys: { [key: string]: NewHireJourney } = {};
  actions.forEach((action) => {
    if (action?.onboardingJourney?.id) {
      uniqueNewHireJourneys[action.onboardingJourney.id] = action.onboardingJourney;
    }
  });
  return Object.values(uniqueNewHireJourneys);
}

export function pullUniqueNewHireJourneysFromTasks(tasks: Task[]) {
  const uniqueNewHireJourneys: { [key: string]: NewHireJourney } = {};
  tasks.forEach((task) => {
    if (task?.newHireJourneyId) {
      uniqueNewHireJourneys[task.newHireJourneyId] = task.newHireJourney;
    }
  });
  return Object.values(uniqueNewHireJourneys);
}

function filterActionsByType(actions: Array<CombinedAction>, actionType: string) {
  if (!actionType) {
    return actions;
  }
  return actions.filter((action) => (actionType === "task" ? isTask(action) : (action as BaseAssignedAction).actionType === actionType));
}

function isTaskRelationType(task: BaseTask, relationType: string) {
  if (!relationType) {
    return false;
  }
  const action = task?.taskNotifier || task?.scheduledWorkflowActions?.[0];
  return action?.workflowAction?.actionable?.targetType === "relation" && action?.workflowAction?.actionable?.relationTarget === snakeCase(relationType);
}

function isActionRelationType(action: BaseAssignedAction, relationType: string) {
  if (!relationType) {
    return false;
  }
  return action?.workflowAction?.actionable?.targetType === "relation" && action?.workflowAction?.actionable?.relationTarget === snakeCase(relationType);
}

function filterActionsByRelationType(actions: Array<CombinedAction>, relationType: string) {
  if (!relationType) {
    return actions;
  }
  return actions.filter((action) => (isTask(action) ? isTaskRelationType(action, relationType) : isActionRelationType(action, relationType)));
}

function filterActionsBySearchString(actions: Array<CombinedAction>, searchString: string) {
  if (!searchString) {
    return actions;
  }
  const lowerSearchString = searchString.toLowerCase();
  return (
    actions.filter((action: CombinedAction) => {
      if (isTask(action)) {
        return (
          action?.title?.toLowerCase()?.includes(lowerSearchString)
          || action?.description?.toLowerCase()?.includes(lowerSearchString)
          || action?.assignee?.fullName?.toLowerCase()?.includes(lowerSearchString)
        );
      }
      return (
        action?.name?.toLowerCase().includes(lowerSearchString)
        || (action as AssignedAction)?.content?.subject?.toLowerCase()?.includes(searchString.toLowerCase())
        || (action as AssignedAction)?.content?.body?.toLowerCase()?.includes(searchString.toLowerCase())
        || (action as AssignedAction)?.targetable?.fullName?.toLowerCase()?.includes(searchString.toLowerCase())
      );
    }) || []
  );
}

export function mapNotifiersToTasks(newHireJourney: ExtendedNewHireJourney) {
  const taskMap = new Map(newHireJourney.tasks.map((task) => [task.id, task]));

  newHireJourney.assignedPaths.forEach((path) => path.actions.forEach((action) => {
    if (action.actionType === "task_notifier" && action?.taskId) {
      const matchingTask = taskMap.get(action?.taskId);
      if (matchingTask) {
        matchingTask.taskNotifier = action;
      }
    }
  }));
}

export function filterActions(
  actions: Array<CombinedAction>,
  searchString: string,
  actionType: string,
  relationType: string,
): Array<CombinedAction> {
  let filteredActions = filterActionsBySearchString(actions, searchString);
  filteredActions = filterActionsByType(filteredActions, actionType);
  return filterActionsByRelationType(filteredActions, relationType);
}

export function toActionId(action: CombinedAction): CombinedActionId {
  if (isTask(action)) {
    return action.id as TaskId;
  } else if (action.actionType === "chat") {
    return `chat_${action.id}`;
  } else if (action.actionType === "email") {
    return `email_${action.id}`;
  }
  return `action_${action.id}` as CombinedActionId;
}

export function fromActionId(actionId: string) {
  const [actionType, id] = actionId.split("_");
  if (actionType === "task") {
    return { actionType, id: actionId };
  }
  return { actionType, id };
}
