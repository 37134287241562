import { Button, useColorModeValue } from "@chakra-ui/react";

interface ProceedCancelButtonsProps {
  handleSubmit: () => void;
  onClose: () => void;
  proceedText: string;
  proceedDisabled?: boolean;
  isLoading?: boolean;
  loadingText?: string;
}

export default function ProceedCancelButtons({
  handleSubmit,
  onClose,
  proceedText,
  proceedDisabled,
  isLoading,
  loadingText,
}: ProceedCancelButtonsProps) {
  return (
    <>
      <Button
        onClick={onClose}
        variant="outline"
        color={useColorModeValue("inherit", "gray.50")}
        colorScheme="gray"
        mr={1}
      >
        Cancel
      </Button>
      <Button onClick={handleSubmit} isLoading={isLoading} loadingText={loadingText} isDisabled={proceedDisabled}>
        {proceedText}
      </Button>
    </>
  );
}

ProceedCancelButtons.defaultProps = {
  isLoading: false,
  loadingText: "Submitting",
  proceedDisabled: undefined,
};
