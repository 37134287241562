import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  Button,
  useDisclosure,
  Portal,
  PopoverProps,
  PopoverFooterProps,
  PopoverBodyProps,
} from "@chakra-ui/react";
import React from "react";

interface PopoverFormWrapperProps extends PopoverProps {
  header?: string;
  buttonText: string;
  onSubmit?: () => void;
  onClose?: () => void;
  triggerButton: React.ReactNode;
  bodyProps?: PopoverBodyProps;
  footerProps?: PopoverFooterProps;
}

export default function PopoverFormWrapper({
  header,
  buttonText,
  triggerButton,
  children,
  onSubmit = () => {},
  onClose: _onClose = () => {},
  bodyProps = {},
  footerProps = {},
  ...rest
}: PopoverFormWrapperProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const handleClick = () => {
    onSubmit?.();
    onClose();
  };

  const handleClose = () => {
    _onClose?.();
    onClose();
  };

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}
      variant="aboveSticky"
      {...rest}
    >
      <PopoverTrigger>
        {triggerButton && React.isValidElement(triggerButton)
          ? React.cloneElement(triggerButton, { onClick: onOpen })
          : null}
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          {header && (
            <PopoverHeader fontSize="lg" fontWeight="bold" color="fg.subtle">
              {header}
            </PopoverHeader>
          )}
          <PopoverBody>{children}</PopoverBody>
          <PopoverFooter display="flex" justifyContent="flex-end" {...footerProps}>
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
            <Button ml={2} colorScheme="brand" onClick={handleClick}>
              {buttonText}
            </Button>
          </PopoverFooter>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
