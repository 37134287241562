import { CreatableSelect } from "chakra-react-select";
import { useState } from "react";
import { useController } from "react-hook-form";
import { startCase } from "lodash";
import clsx from "clsx";
import { ControlledSelectProps, OptionType } from ".";

function ControlledSelect({
  control, name, id, placeholder, rules, className, ...props
}: ControlledSelectProps) {
  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules,
  });
  const createAllOptions = (values?: string[]) => (values || []).map((val) => ({ label: startCase(val), val }));
  // we need to have the local value be an array of option types ({name: string, label: string})
  // so react-select's internals can show it and manage the value
  // we also need the value that is passed to the form to be an array of strings, so we have
  // two values
  const [localOptionValue, setLocalOptionValue] = useState(createAllOptions(value));

  // Options are formatted automatically upon input to be an object with
  // 3 keys - label, value and __isnew__ => This checks if the value is
  // a string and if not, pulls just the option value so that we are storing
  // only strings and not the option objects. This is a hacky workaround.
  const convertOptionToString = (inputValue: string | OptionType) => (typeof inputValue === "string" ? inputValue : inputValue.value);

  const convertOptionToValue = (label: OptionType[]) => {
    setLocalOptionValue(label);
    onChange(label.map((x) => convertOptionToString(x)));
  };

  return (
    <CreatableSelect
      isMulti
      useBasicStyles
      name={name}
      menuPortalTarget={document.body}
      placeholder={placeholder}
      ref={ref}
      onChange={convertOptionToValue}
      options={createAllOptions(value)}
      value={localOptionValue}
      components={{ DropdownIndicator: null }}
      classNamePrefix="chakra-react-select"
      className={clsx("chakra-react-select-container-multi-select", className)}
      chakraStyles={{
        option: (provided) => ({
          ...provided,
          color: "fg.muted",
        }),
        control: (provided) => ({
          ...provided,
          cursor: "pointer",
        }),
        valueContainer: (provided) => ({
          ...provided,
          className: "multi-select",
        }),
      }}
      styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 1400 }) }}
      {...props}
    />
  );
}

export default ControlledSelect;
