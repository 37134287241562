import { Filter } from "models/filter";
import { compact } from "lodash";

export function createPaginationQueryString(pageIndex: number, pageSize: number) {
  return `${new URLSearchParams(`pagination[page]=${pageIndex}&pagination[per_page]=${pageSize}`)}`;
}

function createSortingQueryString(index: number, sort: { fieldName: string; isDescending: boolean }) {
  return `${new URLSearchParams(
    `sort[${index}][sort_by]=${sort.fieldName}&sort[${index}][direction]=${sort.isDescending ? "desc" : "asc"}`,
  )}`;
}

export function createSortingsQueryString(sort: { fieldName: string; isDescending: boolean }[]) {
  return sort.reduce(
    (accumulator, currentValue, currentIndex) => accumulator + (currentIndex > 0 ? "&" : "") + createSortingQueryString(currentIndex, currentValue),
    "",
  );
}

function createFilterQueryString(index: number, filter: Filter) {
  if (filter.operator === "in" || filter.operator === "not_in") {
    return `${new URLSearchParams(
      `filter[${index}][field]=${filter.fieldName}&filter[${index}][operator]=${filter.operator}${filter.value
        .map((value) => `&filter[${index}][value][]=${value}`)
        .join("")}`,
    )}`;
  }
  return `${new URLSearchParams(
    `filter[${index}][field]=${filter.fieldName}&filter[${index}][value]=${filter.value}&filter[${index}][operator]=${filter.operator}`,
  )}`;
}

export function createFiltersQueryString(filters: Filter[]) {
  return filters.reduce(
    (accumulator, currentValue, currentIndex) => accumulator + (currentIndex > 0 ? "&" : "") + createFilterQueryString(currentIndex, currentValue),
    "",
  );
}

function createKeyValueString(key: string, value: string | string[] | undefined) {
  if (value === undefined) {
    return undefined;
  } else if (Array.isArray(value)) {
    return value.map((v) => `${key}[]=${v}`).join("&");
  }
  return `${key}=${value}`;
}

export function createQueryString({
  pagination,
  sortBy,
  filters,
  additionalQueries,
}: {
  pagination?: { pageIndex: number; pageSize: number };
  sortBy?: { id: string; desc: boolean }[];
  filters?: Filter[];
  additionalQueries?: Record<string, string | undefined | string[]>;
}) {
  let queryString = pagination ? createPaginationQueryString(pagination.pageIndex + 1, pagination.pageSize) : "";
  if (sortBy?.length) {
    queryString += queryString.length ? "&" : "";
    queryString += createSortingsQueryString(sortBy.map((sort) => ({ fieldName: sort.id, isDescending: sort.desc })));
  }
  if (filters?.length) {
    queryString += queryString.length ? "&" : "";
    queryString += createFiltersQueryString(filters);
  }
  if (additionalQueries) {
    queryString += queryString.length ? "&" : "";
    queryString += compact(
      Object.entries(additionalQueries).map(([key, value]) => createKeyValueString(key, value)),
    ).join("&");
  }
  return queryString;
}
