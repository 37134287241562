/* eslint-disable object-curly-newline */
import { RadioCardGroup, RadioCard } from "components/FormElements/RadioCardGroup";
import { TbMailPin, TbMailStar } from "react-icons/tb";
import { PiChatDuotone } from "react-icons/pi";
import { TASK_TYPES } from "definitions/constants";
import { taskTypeToIcon } from "helpers/workflowActionableConverters";
import { ActionableType, EmailType } from "models/automation";
import {
  As,
  CardProps,
  Icon,
  VStack,
  Text,
  Flex,
  TabPanel,
  Tab,
  TabIndicator,
  TabList,
  TabPanels,
  Tabs,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { UseFormSetValue } from "react-hook-form";
import { Schema } from "components/ModalForm/PathActionForm/schema";
import { IconType } from "react-icons";

export interface SelectableActionTypeContent {
  name: string;
  value: string;
  icon: IconType;
  helperText?: string;
}

export const COMMUNICATION_ACTION_TYPE_VALUES: SelectableActionTypeContent[] = [
  {
    name: "Email to Personal",
    value: "email_personal",
    icon: TbMailPin,
    helperText: "Email the recipient's personal email",
  },
  { name: "Email to Work", value: "email_work", icon: TbMailStar, helperText: "Email the recipient's work email" },
  { name: "Chat Message", value: "chat", icon: PiChatDuotone, helperText: "Send a slack message from the Camino App" },
];

export const TASK_ACTION_TYPE_VALUES: SelectableActionTypeContent[] = [
  {
    name: "Basic Task",
    value: "task_custom",
    icon: taskTypeToIcon(TASK_TYPES.CUSTOM),
    helperText: "A basic task, which can be marked as 'completed' or 'skipped' by recipients.",
  },
  {
    name: "Assign Buddy",
    value: "task_assign_buddy",
    icon: taskTypeToIcon(TASK_TYPES.ASSIGN_BUDDY),
    helperText: "A task prompting the recipient to assign a buddy via slack.",
  },
  {
    name: "Create Onboarding Plan",
    value: "task_create_onboarding",
    icon: taskTypeToIcon(TASK_TYPES.CREATE_ONBOARDING_PLAN),
    helperText: "A task prompting the recipient to input a link to a personalized onboarding plan.",
  },
  {
    name: "Create Email Account",
    value: "task_create_email",
    icon: taskTypeToIcon(TASK_TYPES.CREATE_EMAIL_ACCOUNT),
    helperText: "A task prompting the recipient to create an email account.",
  },
  {
    name: "Send Message Prompt",
    value: "task_message_prompt",
    icon: taskTypeToIcon(TASK_TYPES.SEND_MESSAGE_PROMPT),
    helperText: "A task prompting the recipient to send a editable, pre-filled message to a person or channel.",
  },
];

type ActionTypeMapType = {
  [key: string]: Pick<Schema, "actionType" | "emailType" | "taskType">;
};

const LONG_ACTION_TYPE_TO_VALUES_MAP: ActionTypeMapType = {
  email_personal: {
    actionType: "email" as ActionableType,
    emailType: "personal_email" as EmailType,
    taskType: undefined,
  },
  email_work: { actionType: "email" as ActionableType, emailType: "work_email" as EmailType, taskType: undefined },
  chat: { actionType: "chat" as ActionableType, taskType: undefined, emailType: undefined },
  task_custom: { actionType: "task" as ActionableType, taskType: TASK_TYPES.CUSTOM, emailType: undefined },
  task_assign_buddy: { actionType: "task" as ActionableType, taskType: TASK_TYPES.ASSIGN_BUDDY, emailType: undefined },
  task_create_onboarding: {
    actionType: "task",
    taskType: TASK_TYPES.CREATE_ONBOARDING_PLAN,
    emailType: undefined,
  },
  task_create_email: {
    actionType: "task",
    taskType: TASK_TYPES.CREATE_EMAIL_ACCOUNT,
    emailType: undefined,
  },
  task_message_prompt: {
    actionType: "task",
    taskType: TASK_TYPES.SEND_MESSAGE_PROMPT,
    emailType: undefined,
  },
};

function actionTypeMap(longActionType: keyof ActionTypeMapType): ActionTypeMapType[any] {
  return LONG_ACTION_TYPE_TO_VALUES_MAP[longActionType] || LONG_ACTION_TYPE_TO_VALUES_MAP.email_personal;
}

interface ActionTypeContentProps extends CardProps {
  name: string;
  icon: As;
  helperText?: string;
}

export function ActionTypeContent({ name, icon, helperText }: ActionTypeContentProps) {
  return (
    <Tooltip label={helperText} isDisabled={!helperText}>
      <Flex direction="column" gap="2px" justify="center" alignItems="stretch" height="100%">
        <Flex width="100%" align="center" justify="center">
          <Text textAlign="center" fontWeight="600" fontSize={14}>
            {name}
          </Text>
        </Flex>
        <VStack alignItems="center" gap="0">
          <Icon as={icon} boxSize="12" color="fg.subtle" />
        </VStack>
      </Flex>
    </Tooltip>
  );
}

ActionTypeContent.defaultProps = {
  helperText: "",
};

interface SelectableSupportTeamProps {
  setValue: UseFormSetValue<Schema>;
}

export default function Content({ setValue }: SelectableSupportTeamProps) {
  const updateValues = useCallback(
    (values: ActionTypeMapType[keyof ActionTypeMapType]) => {
      Object.entries(values).forEach(([field, value]) => {
        setValue(field as keyof ActionTypeMapType[keyof ActionTypeMapType], value);
      });
    },
    [setValue],
  );

  return (
    <Tabs isFitted variant="indicator" width="100%">
      <TabList width="50%">
        <Tab>Communication</Tab>
        <Tab>Task</Tab>
      </TabList>
      <TabIndicator />
      <TabPanels>
        <TabPanel paddingX={0}>
          <RadioCardGroup
            direction="row"
            justify="stretch"
            defaultValue=""
            spacing="3"
            onChange={(value: keyof ActionTypeMapType) => updateValues(actionTypeMap(value))}
            flexWrap="wrap"
          >
            {COMMUNICATION_ACTION_TYPE_VALUES.map((actionTypeField) => (
              <RadioCard
                alignSelf="stretch"
                value={actionTypeField.value}
                minW="160px"
                maxW="160px"
                minH="160px"
                maxH="160px"
              >
                <ActionTypeContent {...actionTypeField} />
              </RadioCard>
            ))}
          </RadioCardGroup>
        </TabPanel>
        <TabPanel paddingX={0}>
          <RadioCardGroup
            direction="row"
            justify="stretch"
            defaultValue=""
            spacing="3"
            onChange={(value: keyof ActionTypeMapType) => updateValues(actionTypeMap(value))}
            flexWrap="wrap"
          >
            {TASK_ACTION_TYPE_VALUES.map((actionTypeField) => (
              <RadioCard
                alignSelf="stretch"
                value={actionTypeField.value}
                minW="160px"
                maxW="160px"
                minH="160px"
                maxH="160px"
              >
                <ActionTypeContent {...actionTypeField} />
              </RadioCard>
            ))}
          </RadioCardGroup>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}
