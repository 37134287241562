import {
  Box, Card, CardBody, Heading, useToast,
} from "@chakra-ui/react";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { TemplatePath } from "models/automation/templateWorkflow";
import { QUERY_KEYS } from "definitions/constants";
import { useEffect } from "react";
import { getTemplateName } from "helpers/path";
import { useNavbar } from "hooks/useNavbar";
import ActionCardTable from "features/Action/ActionCardTable";
import TemplateWorkflowInfoCard from "./TemplateWorkflowInfoCard";

export default function TemplatePathPage() {
  const { id } = useParams<{ id: string }>();
  const toast = useToast({
    status: "success",
    duration: 9000,
    isClosable: true,
    position: "top",
  });
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.templatePath, id],
    queryFn: async ({ signal }) => apiClient.get<TemplatePath>(generatePath(API_ROUTES.templatePaths.show, { id }), signal),
  });

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "Template Paths",
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.paths.index),
          isCurrentPage: false,
        },
        {
          name: getTemplateName(data),
          isCurrentPage: true,
        },
      ]);
      setPageTitle(getTemplateName(data));
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Box>
        {data && (
          <TemplateWorkflowInfoCard
            isLoading={isLoading}
            creatorName={data.creator?.fullName ?? "Camino"}
            {...data}
            handleUseTemplate={async (name: string, autoAdd: boolean) => {
              const resp = await apiClient.post<{ id: string }>(API_ROUTES.paths.template, {
                templateWorkflowId: data.id,
                name,
                autoAdd,
              });
              queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.paths] });
              queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.defaultPaths] });
              toast({
                title: "Template copied successfully!",
              });
              navigate(generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: resp.id }));
            }}
          />
        )}
        <Card mt="8">
          <CardBody>
            <Heading size="xs">Included Actions</Heading>
            {data && <ActionCardTable sx={{ pb: "4", mt: "4" }} data={data.actions} />}
          </CardBody>
        </Card>
      </Box>
    </AppPage>
  );
}
