import {
  Text, Box, HStack, Heading, Center,
} from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { TASK_TYPES } from "definitions/constants";
import TaskExampleImage from "components/ExampleImage/TaskExampleImage";
import ActionRichTextAreaField from "components/FormElements/Fields/ActionRichTextAreaField";
import { toLegacyTargetType } from "models/record_target";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import TargetableTypeField from "components/FormElements/Fields/TargetableTypeField";
import { DESCRIPTION_FIELD, MESSAGE_SUGGESTION_FIELD } from "./fields";
import { Schema } from "./schema";
import { RELATION_TARGET_FIELD, SPECIFIC_USER_FIELD, TARGET_TYPE_FIELD } from "../Shared/fields";

export default function TaskInfo({
  form: {
    formState: { errors },
    control,
    watch,
  },
}: {
  form: UseFormReturn<Schema>;
}) {
  const [actionType, targetType, taskType, messageTarget] = watch([
    "actionType",
    "targetType",
    "taskType",
    "content.messageTarget",
  ]);
  const targetTitle = "Assignee";
  const targetTypeTitle = `${targetTitle} Type`;

  return (
    <>
      <Heading as="h3" size="xs" mb={4}>
        3. Complete Task Content
      </Heading>
      <HStack width="100%">
        <Box width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...TARGET_TYPE_FIELD}
            options={TARGET_TYPE_FIELD.options.filter((option) => option.value !== "message_service_channel")}
            label={targetTypeTitle}
            placeholder={targetTypeTitle}
            errors={errors}
            control={control}
          />
        </Box>
        <Box width="50%" alignItems="start">
          {targetType === "specific_user" && (
            <UserSelectField control={control} required {...SPECIFIC_USER_FIELD} errors={errors} />
          )}
          {targetType === "relation" && (
            <SingleSelectField
              isClearable={false}
              required
              {...RELATION_TARGET_FIELD}
              label={targetTitle}
              placeholder={targetTitle}
              errors={errors}
              control={control}
            />
          )}
        </Box>
      </HStack>
      {[TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT].includes(taskType) && (
        <ActionRichTextAreaField
          actionType={actionType}
          targetType={targetType}
          {...DESCRIPTION_FIELD}
          errors={errors}
          control={control}
        />
      )}
      {TASK_TYPES.SEND_MESSAGE_PROMPT === taskType && (
        <>
          <TargetableTypeField<Schema>
            errors={errors}
            control={control}
            label="Message Recipient"
            fieldName="content.messageTarget"
            targetTitle="Message Target"
          />
          <ActionRichTextAreaField
            actionType="chat"
            targetType={(messageTarget && toLegacyTargetType(messageTarget.targetType)) || undefined}
            {...MESSAGE_SUGGESTION_FIELD}
            errors={errors}
            control={control}
          />
        </>
      )}
      {![TASK_TYPES.CUSTOM, TASK_TYPES.SEND_MESSAGE_PROMPT, TASK_TYPES.SCHEDULE_MEETING].includes(taskType) && (
        <Box my={4}>
          <TaskExampleImage taskType={taskType} />
          <Center>
            <Text fontStyle="italic">Example of the task...</Text>
          </Center>
        </Box>
      )}
    </>
  );
}
