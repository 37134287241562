import { create } from "zustand";

interface FeatureFlagStore {
  onboardingPlans: boolean;
  settings: boolean;
}

const useFeatureFlagStore = create<FeatureFlagStore>(() => ({
  onboardingPlans: true,
  settings: false,
}));

export default useFeatureFlagStore;
