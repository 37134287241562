import { Tag } from "@chakra-ui/react";
import { combinedActionDateTime, isImmediateCombinedAction } from "helpers/assignedAction";
import { relativeDate } from "helpers/time";
import { CombinedAction } from "models/automation/combinedAction";
import moment from "moment";

function getColorScheme(datetime: Date) {
  if (!datetime) {
    return "dateYellow";
  }
  const today = moment();
  const targetDate = moment(datetime);

  if (targetDate.isBefore(today, "day")) {
    return "error";
  } else if (targetDate.isSame(today, "day")) {
    return "dateGreen";
  } else if (targetDate.isAfter(today, "day")) {
    return "dateBlue";
  }
  return "gray";
}

function actionSendDate(datetime: Date) {
  return datetime ? relativeDate(datetime) : "TBD";
}

interface SendDateWithImmediateTagProps {
  action: CombinedAction;
  showImmediateDate?: boolean;
}

export default function SendDateWithImmediateTag({ action, showImmediateDate = false }: SendDateWithImmediateTagProps) {
  const isImmediate = isImmediateCombinedAction(action);
  const sendDate = combinedActionDateTime(action);
  const colorScheme = isImmediate ? "dateBlue" : getColorScheme(sendDate);
  return (
    <Tag
      variant="outline"
      color={`${colorScheme}.DEFAULT`}
      border={`1px solid var(--chakra-colors-${colorScheme}-DEFAULT)`}
      bg={`${colorScheme}.background`}
      borderRadius="md"
      boxShadow="none"
    >
      {isImmediate && !showImmediateDate ? "1 Minute After Kickoff" : actionSendDate(sendDate)}
    </Tag>
  );
}
