import {
  Card,
  CardBody,
  CardHeader,
  HStack,
  Button,
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  Portal,
  Spacer,
  useToast,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { generatePath, useNavigate } from "react-router-dom";
import { createColumnHelper } from "@tanstack/react-table";
import { ADMIN_ROUTES, API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { ResponseModel } from "services/ApiClient/responseModel";
import { QUERY_KEYS } from "definitions/constants";
import { useCallback, useEffect, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import AppPage from "layouts/AppPage";
import GenericTable, { GenericTableProps, simpleColumn } from "components/Table/GenericTable";
import ButtonWithConfirm from "components/Button/ButtonWithConfirm";
import { CompanyCreateForm } from "components/Forms/Admin/Company";
import { Company } from "models/company";
import Modal from "components/Modal";
import { useModal } from "hooks/useModal";
import useNavbar from "hooks/useNavbar";
import ImportUsersForm from "./ImportUsersForm";

const CONFIRM_DELETE_COMPANY = (name: string) => ({
  header: `Delete ${name}?`,
  body: `Are you sure you'd like to delete ${name}?`,
  confirmButtonText: "Yes, delete",
});

const columnHelper = createColumnHelper<Company>();

const makeColumns = (
  handleEdit: (id: string) => void,
  handleDelete: (id: string) => void,
  handleImport: (id: string, name: string) => void,
  handleEmailConfiguration: (id: string) => void,
) => [
    simpleColumn({ attribute: "name", label: "Name" }, columnHelper, true),
    simpleColumn({ attribute: "slug", label: "Slug" }, columnHelper, true),
    simpleColumn({ attribute: "domain", label: "Domain" }, columnHelper, true),
    simpleColumn({ attribute: "websiteUrl", label: "Website" }, columnHelper, true),
    columnHelper.accessor((row) => row, {
      id: "menu",
      header: "",
      enableSorting: false,
      cell: (row) => {
        const company = row.getValue();
        const companyId = company.id;
        const { name } = company;
        return (
          <Menu>
            <MenuButton as={Button} size="xs" colorScheme="gray" rightIcon={<ChevronDownIcon />}>
              Actions
            </MenuButton>
            <Portal>
              <MenuList>
                <MenuItem onClick={() => handleEdit(companyId)}>Edit</MenuItem>
                <MenuItem onClick={() => handleImport(companyId, name)}>Import Users</MenuItem>
                <MenuItem onClick={() => handleEmailConfiguration(companyId)}>Email Configuration</MenuItem>
                <ButtonWithConfirm
                  {...CONFIRM_DELETE_COMPANY(name)}
                  intent="warning"
                  handleConfirm={() => handleDelete(companyId)}
                >
                  <MenuItem>Delete</MenuItem>
                </ButtonWithConfirm>
              </MenuList>
            </Portal>
          </Menu>
        );
      },
    }),
  ];

const queryKey = [QUERY_KEYS.companies, "admin"];

export default function CompaniesPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [importCompany, setImportCompany] = useState<{ id: string; name: string }>();
  const modal = useModal({ id: "create", header: { title: "Create Company" }, size: "2xl" });
  const importModal = useModal({
    id: "import",
    header: { title: `Import Users for ${importCompany?.name}` },
    size: "2xl",
  });
  const { setPageTitle, setBreadcrumbs } = useNavbar();
  const toast = useToast({
    position: "top",
    duration: 5000,
    isClosable: true,
    variant: "subtle",
    status: "success",
  });

  const handleDelete = useCallback(
    async (id: string) => {
      await apiClient.delete(generatePath(API_ROUTES.admin.companies.show, { id }));
      queryClient.invalidateQueries({ queryKey });
    },
    [queryClient],
  );

  const handleEdit = useCallback(
    (id: string) => navigate(generatePath(ADMIN_ROUTES.companies.show, { id })),
    [navigate],
  );

  const handleImport = useCallback(
    (id: string, name: string) => {
      setImportCompany({ id, name });
      importModal.onOpen();
    },
    [importModal],
  );

  const handleEmailConfiguration = useCallback(
    (id: string) => navigate(generatePath(ADMIN_ROUTES.companies.emailConfiguration.index, { companyId: id })),
    [navigate],
  );

  const onImportSuccess = useCallback(() => {
    toast({ title: "Users imported successfully" });
    setImportCompany(undefined);
    importModal.onClose();
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.users] });
  }, [toast, setImportCompany, importModal, queryClient]);

  const tableProps: GenericTableProps<Company> = {
    fetchData: (query, signal) => apiClient
      .performRequest<ResponseModel<Company[]>>(`${API_ROUTES.admin.companies.base}${query}`, undefined, signal)
      .then((response) => response.payload),
    queryKey,
    columns: makeColumns(handleEdit, handleDelete, handleImport, handleEmailConfiguration),
    meta: {
      pagination: { perPage: 100 },
      filters: undefined,
    },
  };

  useEffect(() => {
    setPageTitle("Companies");
    setBreadcrumbs([
      { name: "Admin", toUrl: ADMIN_ROUTES.hub },
      { name: "Companies", isCurrentPage: true },
    ]);
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Card mt="8" p="4">
        <CardHeader>
          <HStack justify="space-between">
            <Spacer />
            <Button onClick={modal.onOpen} colorScheme="brand">
              Create
            </Button>
          </HStack>
        </CardHeader>
        <CardBody>
          <GenericTable {...tableProps} />
        </CardBody>
      </Card>
      <Modal modal={modal}>
        <CompanyCreateForm onClose={modal.onClose} onSuccess={() => queryClient.invalidateQueries({ queryKey })} />
      </Modal>
      <Modal modal={importModal}>
        {importCompany && <ImportUsersForm company={importCompany} onSuccess={onImportSuccess} />}
      </Modal>
    </AppPage>
  );
}
