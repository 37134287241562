import { capitalize, ceil, floor } from "lodash";

export function truncateMiddle(value: string | undefined, maxLength: number = 23, separator = "...") {
  if (!value) return value;
  if (value.length <= maxLength) return value;
  const segment1 = value.slice(0, ceil(maxLength / 2 - ceil(separator.length / 2)));
  const segment2 = value.slice(value.length - floor(maxLength / 2 - floor(separator.length / 2)));
  return `${segment1}${separator}${segment2}`;
}

export function truncateEnd(value: string | undefined, maxLength: number = 32, ending = "...") {
  if (!value) return value;
  if (value.length <= maxLength) return value;
  return `${value.slice(0, maxLength - 1)}${ending}`;
}

export function titleize(str: string) {
  return str
    ?.split("_")
    .map((c) => capitalize(c))
    .join(" ");
}
