import { Company } from "models/company";
import { Box } from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";

export default function CompanySelect({
  value,
  onChange,
}: {
  value: Company | null;
  onChange: (value: Company | null) => void;
}) {
  return (
    <Box width="260px">
      <AsyncSelect
        useBasicStyles
        onChange={onChange}
        placeholder="Choose Company"
        defaultOptions
        loadOptions={(inputValue) => apiClient.get<Company[]>(`${API_ROUTES.admin.companies.base}?q=${inputValue}`)}
        getOptionLabel={(option) => option.name}
        getOptionValue={(option) => option.id}
        value={value}
      />
    </Box>
  );
}
