import { useToast } from "@chakra-ui/react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { AssignedAction } from "models/automation/scheduledWorkflow";

interface UseSubmitAssignedActionUpdate {
  assignedActionId: string;
  onSubmitSuccess?: () => void;
}

export default function useSubmitUpdateForm({ assignedActionId, onSubmitSuccess }: UseSubmitAssignedActionUpdate) {
  const queryClient = useQueryClient();
  const toast = useToast({
    status: "success",
    duration: 2000,
    isClosable: true,
    position: "top",
  });

  /* HandleOnSubmit */
  const { mutate: onSubmit, isPending: isLoading } = useMutation({
    mutationFn: (data: any) => apiClient.put<AssignedAction>(
      generatePath(API_ROUTES.assignedActions.show, { id: assignedActionId || "" }),
      data,
    ),
    onSuccess: (res: AssignedAction) => {
      queryClient.setQueryData(
        [QUERY_KEYS.assignedActions, assignedActionId],
        (oldData: any) => {
          if (oldData) {
            return {
              ...oldData,
              ...res,
            } as AssignedAction;
          }
          return res;
        }
      );
      toast({
        title: "Updated!",
      });
      onSubmitSuccess?.();
      return res;
    },
    onError: (mutateError) => {
      console.log("Action Update Error", mutateError);
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });

  return { onSubmit, isLoading };
}
