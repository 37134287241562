import { HStack, Stack, useToast } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { object as yupObject, string as yupString } from "yup";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { AssignedPath } from "models/automation/scheduledWorkflow";
import { isNetworkingError } from "services/ApiClient/networkingError";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { compact } from "lodash";
import { NewHireJourney } from "models/onboarding";
import { QUERY_KEYS } from "definitions/constants";
import GenericAsyncSelectField from "components/FormElements/Fields/SelectFields/GenericAsyncSelectField";
import UserSelectField from "components/FormElements/Fields/SelectFields/UserSelectField";
import DatePickerField from "components/FormElements/Fields/DatePickerField";

const ASSIGNED_NEW_HIRE_FIELD = {
  type: "SELECT",
  required: true,
  label: "New Hire to Assign Path",
  name: "userId",
  helperText: "User to assign the path to.",
};

const MANAGER_FIELD = {
  type: "SELECT",
  label: "New Hire Manager",
  name: "managerId",
  helperText:
    "This user doesn't have a manager associated with them. Add their manager manually here, because the user's manager will receive pre-onboarding tasks, reminders, and notifications.",
};

const PEOPLE_TEAM_FIELD = {
  type: "SELECT",
  label: "People Team Contact",
  name: "peopleTeamContactId",
  helperText:
    "The people team member to get all reminders, automated messages, ensure the onboarding plan has been set up, etc.",
  required: true,
};

const START_DATE_FIELD = {
  type: "date",
  label: "Start Date",
  name: "startDate",
  required: true,
  helperText: "Automated messages will be generated based on this date, so it is important to verify it.",
};

const OFFER_DATE_FIELD = {
  type: "date",
  label: "Offer Sign Date",
  name: "offerSignDate",
  helperText: "Automated messages may be generated based on this date.",
};

const createScheduledWorkflowFormSchema = yupObject({
  [ASSIGNED_NEW_HIRE_FIELD.name]: yupString().label(ASSIGNED_NEW_HIRE_FIELD.label).required(),
  [MANAGER_FIELD.name]: yupString().label(MANAGER_FIELD.label),
  [PEOPLE_TEAM_FIELD.name]: yupString().label(PEOPLE_TEAM_FIELD.label).required(),
});

interface AssignPathFormProps {
  workflowId: string;
  peopleTeamContactId?: string;
  onClose: () => void;
  updatePeopleTeamContactId: (value: string) => void;
}

export default function AssignPathForm({ workflowId, onClose, peopleTeamContactId = undefined }: AssignPathFormProps) {
  const {
    control,
    formState: { errors, isDirty },
    handleSubmit: handleOnNewHireSubmit,
    watch,
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(createScheduledWorkflowFormSchema),
    defaultValues: {
      peopleTeamContactId,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const queryClient = useQueryClient();
  const watchNewHireField = watch(ASSIGNED_NEW_HIRE_FIELD.name);
  const [newHireCacheKey] = useState("");
  const [otherCacheKey, updateOtherCacheKey] = useState("");
  const [showField, setShowField] = useState({
    manager: false,
    startDate: false,
    offerSignDate: false,
    peopleTeam: false,
  });
  const excludedUserIds = compact([watchNewHireField]);

  const onChangeNewHireCallback = (journey: NewHireJourney | undefined) => {
    if (otherCacheKey !== journey?.user?.id) updateOtherCacheKey(journey?.user?.id || "");
    setValue(MANAGER_FIELD.name, journey?.manager?.id);
    setValue(START_DATE_FIELD.name, journey?.startDate?.toString());
    setValue(OFFER_DATE_FIELD.name, journey?.offerSignDate?.toString());
    setValue(PEOPLE_TEAM_FIELD.name, journey?.peopleTeamContact?.id);
    setShowField({
      manager: !journey?.manager?.id,
      startDate: !journey?.startDate,
      offerSignDate: !journey?.offerSignDate,
      peopleTeam: !journey?.peopleTeamContact,
    });
  };

  /* HandleOnSubmit */
  const onAddNewHireSubmit = async (data: any) => {
    try {
      const {
        userId,
        peopleTeamContactId: peopleTeamId,
        managerId,
        buddyId,
        offerSignDate,
        startDate,
        offerExtensionDate,
      } = data;
      const postData = {
        scheduledWorkflow: { workflowId },
        onboarding_journey: {
          userId,
          peopleTeamContactId: peopleTeamId,
          managerId,
          buddyId,
          offerExtensionDate,
          offerSignDate,
          startDate,
        },
      };
      setIsLoading(true);
      const res = await apiClient.post<AssignedPath>(API_ROUTES.assignedPaths.base, postData);
      setIsLoading(false);

      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.assignedPaths] });
      toast({
        title: `${res.onboardingJourney.user.fullName} Successfuly Assigned Workflow`,
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "top",
      });
      onClose();
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      if (
        isNetworkingError(error)
        && (error.errors || []).length
        && error.errors[0] === "User already assigned this automation plan"
      ) {
        toast({
          title: "Error",
          description: "User already assigned this automation plan",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: "Error",
          description: "There was an error, please try again.",
          status: "error",
          duration: 9000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  return (
    <>
      <Stack alignItems="start">
        <Stack width="60%" alignItems="start">
          <GenericAsyncSelectField
            fetchPath={API_ROUTES.newHireJourneys.base}
            extractValue={(v: NewHireJourney) => v.user.id}
            embedOption={(v) => ({
              value: v.user.id,
              label: `${v.user.fullName} (${v.user.workEmail || "No Email"})`,
              rawValue: v,
            })}
            onChangeCallback={onChangeNewHireCallback}
            cacheKey={newHireCacheKey}
            control={control}
            {...ASSIGNED_NEW_HIRE_FIELD}
            errors={errors}
          />
          {watchNewHireField && showField.manager && (
            <UserSelectField
              includeCreateNewUser
              cacheKey={otherCacheKey}
              updateCacheKey={updateOtherCacheKey}
              control={control}
              {...MANAGER_FIELD}
              excludedIds={excludedUserIds}
              errors={errors}
              disabled={!watchNewHireField} // Not truly needed since this won't show currently, but just in case that changes
            />
          )}
          {watchNewHireField && showField.peopleTeam && (
            <UserSelectField
              includeCreateNewUser
              excludedIds={excludedUserIds}
              cacheKey={otherCacheKey}
              updateCacheKey={updateOtherCacheKey}
              control={control}
              {...PEOPLE_TEAM_FIELD}
              errors={errors}
              disabled={!watchNewHireField}
            />
          )}
        </Stack>
        <Stack>
          {watchNewHireField && showField.startDate && (
            <DatePickerField control={control} {...START_DATE_FIELD} errors={errors} />
          )}
          {watchNewHireField && showField.offerSignDate && (
            <DatePickerField control={control} {...OFFER_DATE_FIELD} errors={errors} />
          )}
        </Stack>
      </Stack>
      <HStack mt={12} justify="end">
        <ProceedCancelButtons
          handleSubmit={handleOnNewHireSubmit(onAddNewHireSubmit)}
          onClose={onClose}
          proceedText="Assign"
          proceedDisabled={!isDirty}
          isLoading={isLoading}
        />
      </HStack>
    </>
  );
}
