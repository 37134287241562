import { floatingNoValueBorder, formInputWithValueBorder, selectedInputBorder } from "definitions/constants/styling";

const FLOATING_LABEL_BASE_TOP = 7;
const SELECT_VALUE_PADDING_TOP = 18;
const TEXT_CURSOR_MARGIN_TOP = SELECT_VALUE_PADDING_TOP;
const INPUT_HEIGHT = "3.2rem";
const ICON_PADDING_LEFT = 20;
const ICON_WIDTH = 20;
const INPUT_VALUE_PADDING_LEFT_WITH_ICON = 60;
const LABEL_PADDING_LEFT_WITH_ICON = 64;
// Need to calculate this as the DateTimePicker button does not allow for us to say padding left
// is 60px (unlike the input)
const CALCULATED_PADDING_LEFT = INPUT_VALUE_PADDING_LEFT_WITH_ICON - ICON_PADDING_LEFT - ICON_WIDTH;

const TRANSFORM_VALUE = "scale(0.75) translateY(-13px)";

export const floatingLabelTransformImportant = {
  transform: `${TRANSFORM_VALUE} !important`,
  color: "fg.emphasized",
};

export const floatingLabelTransform = {
  transform: TRANSFORM_VALUE,
  color: "fg.emphasized",
};

export const inputValueWithIconPaddingLeft = {
  paddingLeft: `${INPUT_VALUE_PADDING_LEFT_WITH_ICON}px`,
};

export const labelWithIconPaddingLeft = {
  paddingLeft: `${LABEL_PADDING_LEFT_WITH_ICON}px`,
};

export const floatingLabelBase = {
  top: `${FLOATING_LABEL_BASE_TOP}px`,
  color: "gray.400",
  fontSize: "md",
  left: 0,
  zIndex: 2,
  position: "absolute",
  pointerEvents: "none",
  mx: 3,
  px: 2,
  my: 2,
  transformOrigin: "left top",
};

export const floatingLabelWithIcon = {
  ...floatingLabelBase,
  ...labelWithIconPaddingLeft,
  top: "5px",
};

// Input Styles
export const floatingInputBase = {
  height: INPUT_HEIGHT,
  paddingTop: "14px",
  paddingLeft: "17px",
  "&:not(:placeholder-shown):not(:hover):not(:focus):not(:invalid)": {
    ...formInputWithValueBorder,
  },
};

export const inputWithIcon = {
  ...floatingInputBase,
  ...inputValueWithIconPaddingLeft,
};

// REACT SELECT

const sharedReactSelectWithValueSelector = ".chakra-react-select--has-value "
  + ".chakra-react-select__control:not(.chakra-react-select__control--is-focused):not("
  + ".chakra-react-select__control--menu-is-open):not(:hover)";

const reactSelectSharedStyles = {
  ".chakra-react-select--has-value ~ .floating-label": floatingLabelTransform,

  ".chakra-react-select__value-container": {
    marginLeft: "3px",
    height: "100%",
  },

  // Borders
  ".chakra-react-select__control": {
    ...floatingNoValueBorder,
  },
  ".chakra-react-select__control:hover:not(.chakra-react-select__control--is-focused)": {
    ...formInputWithValueBorder,
  },
  ".chakra-react-select__control--is-focused, .chakra-react-select__control.chakra-react-select__control--is-focused:hover":
    {
      ...selectedInputBorder,
    },
  [sharedReactSelectWithValueSelector]: {
    ...formInputWithValueBorder,
  },
};

export const reactSelectSingleStyles = {
  ".chakra-react-select__single-value": {
    height: "100%",
    paddingTop: `${SELECT_VALUE_PADDING_TOP}px`,
  },
  ".chakra-input-container:has(.chakra-react-select-container-single-select) + .chakra-form__label.floating-label": {
    top: `${FLOATING_LABEL_BASE_TOP}px`,
  },
  ".chakra-react-select-container-single-select + .chakra-form__label.floating-label": {
    top: `${FLOATING_LABEL_BASE_TOP}px`,
  },
  ".timezone .chakra-react-select__single-value": {
    paddingLeft: "3px",
    paddingTop: `${SELECT_VALUE_PADDING_TOP - 2}px`,
  },
  ".chakra-react-select-container-single-select, .chakra-react-select__single-value": {
    maxHeight: `${INPUT_HEIGHT}`,
  },
  ".chakra-react-select-container-single-select .chakra-react-select__input-container": {
    minHeight: `${INPUT_HEIGHT}`,
  },
  ".chakra-react-select-container-single-select:has(.chakra-react-select__contol--is-focused) ~ label": {
    transform: "none",
  },

  // hidden input
  ".chakra-react-select-container-single-select input.chakra-react-select__input": {
    height: "20px",
    marginTop: `${TEXT_CURSOR_MARGIN_TOP}px !important`,
  },

  // BORDER STYLES
  ".chakra-react-select-container-single-select .chakra-react-select__control": {
    height: INPUT_HEIGHT,
  },
};

export const reactSelectMultiStyles = {
  ".chakra-react-select__value-container.chakra-react-select__value-container--is-multi": {
    paddingInlineStart: 2,
    minHeight: `${INPUT_HEIGHT}`,
  },
  [".chakra-react-select__value-container.chakra-react-select__value-container--is-multi.chakra-react-select__value-container--has-value, "
  + ".chakra-react-select__control--is-focused .chakra-react-select__value-container.chakra-react-select__value-container--is-multi"]:
    {
      paddingTop: "22px",
    },
};

// INPUT

export const chakraInputIconStyles = {
  ".chakra-input__left-element, .chakra-input__right-element": {
    paddingLeft: `${ICON_PADDING_LEFT}px`,
    alignSelf: "center",
    height: "100%",
    ".chakra-icon": {
      transform: "scale(1.5)",
    },
    ".left-box-icon": {
      transform: "scale(1.5)",
    },
  },
  ".chakra-input__left-element + .chakra-input": inputWithIcon,
};

// BUTTON

const formButtonStyles = {
  ".chakra-button.has-value ~ .chakra-form__label": floatingLabelTransform,

  // BORDERS
  ".chakra-button.has-value": {
    ...formInputWithValueBorder,
  },
  ".chakra-button[aria-expanded='true']": {
    ...selectedInputBorder,
  },
};

const trixStyles = {
  ".trix-editor": {
    paddingTop: "20px",
    paddingLeft: "15px",
    border: "2px solid var(--chakra-colors-border-default)",
    borderRadius: "md",
  },
  ".trix-editor:not(:focus):hover": {
    ...formInputWithValueBorder,
  },
  ".trix-editor:focus": {
    ...selectedInputBorder,
  },
  ".trix-editor.has-value:not(:focus)": {
    ...formInputWithValueBorder,
  },
  ".trix-container ~ label.has-value": {
    transform: "scale(0.75) translateY(-12px) !important",
    color: "fg.emphasized",
  },
};

export const floatingForm = (props: any) => ({
  container: {
    // Floating label styles
    _focusWithin: {
      ".floating-label": floatingLabelTransform,
      ".chakra-input__group:has(.chakra-input__left-element) ~ .floating-label": floatingLabelTransformImportant,
    },

    ".floating-label": floatingLabelBase,

    "input:not(:placeholder-shown) + .floating-label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
      floatingLabelTransform,
    ".chakra-input__group > input:not(:placeholder-shown) ~ label": floatingLabelTransform,
    ".chakra-input__group:has(input:not(:placeholder-shown)) ~ label": floatingLabelTransform,
    ".chakra-input__group:has(.chakra-input__left-element):has(input:not(:placeholder-shown)) ~ .floating-label":
      floatingLabelTransformImportant,
    ".chakra-input__group:has(.chakra-input__left-element) > input:not(:placeholder-shown) ~ .floating-label": {
      transform: "scale(0.75) translateY(-12px) !important",
    },
    "input[type='time']:not([value='']) ~ label": floatingLabelTransform,

    // Button Styles
    ...formButtonStyles,

    // Input styles
    ...chakraInputIconStyles,
    "input:not(.chakra-react-select__input)": floatingInputBase,
    ".timezone.chakra-react-select-container-single-select input.chakra-react-select__input": {
      marginTop: `${TEXT_CURSOR_MARGIN_TOP - 2}px !important`,
    },

    // React Select styles
    ...reactSelectSharedStyles,
    ...reactSelectSingleStyles,
    ...reactSelectMultiStyles,
    ".chakra-react-select__indicator": {
      transform: "translateY(1px)",
    },

    ".chakra-input__group:has(.chakra-input__left-element) ~ .floating-label": floatingLabelWithIcon,

    ...trixStyles,
  },
});

export const floatingButton = {
  ...floatingNoValueBorder,
  height: `${INPUT_HEIGHT} !important`,
  paddingTop: "18px !important",
  paddingLeft: `${ICON_PADDING_LEFT}px !important`,
  width: "100%",
  _hover: {
    ...formInputWithValueBorder,
  },
  _active: {
    ...selectedInputBorder,
  },

  // Icon styles
  ".chakra-button__icon": {
    transform: "translateY(-50%) !important",
    marginRight: `${CALCULATED_PADDING_LEFT}px`,
  },

  // Label styles with icons
  "&:has(.chakra-button__left-icon) ~ label": labelWithIconPaddingLeft,
  ".chakra-input__group:has(.chakra-input__left-element) ~ label": labelWithIconPaddingLeft,

  // Input styles with icons
  ".chakra-input__left-element + .chakra-input": inputValueWithIconPaddingLeft,
};
