import { AssignedAction } from "models/automation/scheduledWorkflow";
import { AssignedActionDateTimeTrigger, ScheduledPathTrigger } from "../models/automation";

// eslint-disable-next-line import/prefer-default-export
export function scheduledTriggerSort(a: ScheduledPathTrigger, b: ScheduledPathTrigger) {
  if (a.type === "date_time" && b.type === "date_time") {
    return a.data.datetime >= b.data.datetime ? 1 : -1;
  } else if (a.type !== "date_time" && b.type === "date_time") {
    return 1;
  } else if (a.type === "date_time" && b.type !== "date_time") {
    return -1;
  }
  return 0;
}

export function hasCustomizedTrigger(assignedAction: AssignedAction): boolean {
  return !!(assignedAction?.trigger as AssignedActionDateTimeTrigger)?.data?.customized;
}

export function timeIncludesSeconds(time: string): boolean {
  const partsLength = time.split(":")?.length;
  return partsLength === 3;
}

export function toTimeWithSeconds(time: string): string {
  if (!time) return time;
  return timeIncludesSeconds(time) ? time : `${time}:00`;
}

export function toTimeWithoutSeconds(time: string): string {
  if (!time) return time;
  return timeIncludesSeconds(time) ? time.slice(0, -3) : time;
}
