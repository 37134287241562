import {
  Flex, Card, Skeleton, CardBody, Divider,
} from "@chakra-ui/react";
import { InfoFieldProfile } from "components/DataDisplay/InfoField";
import { PropsWithChildren } from "react";

interface BaseDialogFormHeaderProps extends PropsWithChildren {
  linkTo: string;
  label: any;
  value: string;
  imageUrl?: string;
  isLoaded?: boolean;
}

export default function BaseDialogFormHeader({
  linkTo,
  label,
  value,
  imageUrl,
  isLoaded,
  children,
}: BaseDialogFormHeaderProps) {
  return (
    <Flex direction="column" width="100%">
      <Flex mb="2" width="100%" align="center" justify="center">
        <Card borderColor="brand.500" borderWidth="1px">
          <Skeleton isLoaded={!!isLoaded}>
            <CardBody>
              <InfoFieldProfile
                linkTo={linkTo}
                avatarInfo={imageUrl ? { src: imageUrl } : {}}
                fieldInfo={{
                  label,
                  value,
                }}
              />
            </CardBody>
          </Skeleton>
        </Card>
      </Flex>
      <Divider my="4" />
      {children}
    </Flex>
  );
}

BaseDialogFormHeader.defaultProps = {
  imageUrl: undefined,
  isLoaded: true,
};
