import { useCallback, useRef } from "react";
import { debounce } from "lodash";
import { UseFormReturn, useFormState, useWatch } from "react-hook-form";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useToast } from "@chakra-ui/react";
import apiClient from "services/ApiClient";
import { useMutation } from "@tanstack/react-query";

type UseAutoSaveProps = {
  form: UseFormReturn<any>;
  submitUrl: string;
  onSuccess?: (response: any, payload: any) => void;
  onError?: (error: any) => void;
  transformData?: (data: any) => any;
};

const useAutoSave = ({
  form, submitUrl, transformData = (data) => data, onSuccess, onError,
}: UseAutoSaveProps) => {
  const toast = useToast();
  const { trigger, getValues, control, reset } = form;

  const { isDirty, dirtyFields, errors } = useFormState({ control });

  const watchedData = useWatch({
    control,
  });

  const memoizedTransformData = useCallback((data: any) => transformData(data), [transformData]);

  const {
    mutate: onSubmit,
    isPending: isSubmitting,
    isSuccess,
    isError,
  } = useMutation({
    mutationFn: (data: object) => apiClient.put(submitUrl, data),
    onSuccess: async (resp, payload) => {
      onSuccess?.(resp, payload);
      reset({}, { keepValues: true });
    },
    onError: (mutateError) => {
      console.log("userCreateError", mutateError);
      onError?.(mutateError);
      toast({
        title: "Error",
        description: "There was an error, please try again.",
        status: "error",
      });
    },
  });

  const debouncedSave = useRef(
    debounce((innerDirtyFields, innerErrors, getFormValues) => {
      const dirtyFieldNames = Object.keys(innerDirtyFields);

      if (dirtyFieldNames.length > 0) {
        // Validate all dirty fields at once
        trigger(dirtyFieldNames).then((isValid) => {
          const validFields: Record<string, any> = {};
          // Iterate through dirty fields and collect only those without errors
          dirtyFieldNames.forEach((fieldName) => {
            if (!innerErrors[fieldName]) {
              validFields[fieldName] = getFormValues(fieldName);
            }
          });

          // If there are valid fields, submit them to the backend
          if (Object.keys(validFields).length > 0) {
            try {
              onSubmit(memoizedTransformData(validFields));
            } catch (error) {
              console.error("Error while autosaving:", error);
            }
          }
        });
      }
    }, 3000),
  ).current;

  useDeepCompareEffect(() => {
    if (isDirty) {
      debouncedSave(dirtyFields, errors, getValues);
    }
  }, [watchedData]);

  return { isSubmitting, isSuccess, isError };
};

export default useAutoSave;
