import { MessageServiceChannel } from "./integration/messageService";
import { User } from "./user";

export type LegacyTargetType = "relation" | "specific_user" | "message_service_channel";

export type TargetType = "OnboardingJourneyNetworkRelation" | "User" | "MessageServiceChannel";

export function toLegacyTargetType(targetType: TargetType): LegacyTargetType {
  switch (targetType) {
    case "OnboardingJourneyNetworkRelation":
      return "relation";
    case "User":
      return "specific_user";
    case "MessageServiceChannel":
      return "message_service_channel";
    default:
      throw new Error(`Unknown target type: ${targetType}`);
  }
}

export function toTargetType(legacyTargetType: LegacyTargetType): TargetType {
  switch (legacyTargetType) {
    case "relation":
      return "OnboardingJourneyNetworkRelation";
    case "specific_user":
      return "User";
    case "message_service_channel":
      return "MessageServiceChannel";
    default:
      throw new Error(`Unknown target type: ${legacyTargetType}`);
  }
}

export type RelationTarget = "buddy" | "new_hire" | "manager" | "people_team_contact";

export type OnboardingJourneyNetworkRelation = {
  id: string;
  name: RelationTarget;
};

export type Target = User | MessageServiceChannel | OnboardingJourneyNetworkRelation;

export type RecordTarget = {
  name: string;
  type: "record_target";
  targetType: TargetType;
  target: Target;
};
