import { PathTimingTriggerData, AssignedActionDateTimeTrigger } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";

export function hasImmediateTrigger(assignedAction: AssignedAction): boolean {
  return (
    (assignedAction.workflowAction?.actionable?.trigger?.data as PathTimingTriggerData)?.referenceEvent === "immediate"
  );
}

export function hasDependencyTrigger(assignedAction: AssignedAction): boolean {
  return assignedAction?.trigger?.type === "dependency";
}

export function hasCustomizedDateTimeTrigger(assignedAction: AssignedAction): boolean {
  return !!(assignedAction?.trigger as AssignedActionDateTimeTrigger)?.data?.customized;
}

export function showDatepicker(assignedAction: AssignedAction): boolean {
  // if it has a custom datetime trigger, show the datepicker
  if (hasCustomizedDateTimeTrigger(assignedAction)) {
    return true;
  }
  // otherwise only show it if the trigger is a date_time trigger
  return !hasImmediateTrigger(assignedAction) && !hasDependencyTrigger(assignedAction);
}
