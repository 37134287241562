import { Box, CardBody, Flex, Heading, Spinner } from "@chakra-ui/react";
import { BlueBorderCard } from "components/Card/BlueBorderCard";
import { camelCase } from "lodash";
import { AutoFormSection } from "models/autoForm";
import { UseFormReturn } from "react-hook-form";
import useAutoSave from "components/FormElements/useAutoSave";
import { useMemo, useRef } from "react";
import { buildDataTransformer } from "./buildForm";
import FormSection from "./FormSection";

interface AutoFormProps {
  form: UseFormReturn<any>;
  sections: AutoFormSection[];
  submitUrl: string;
  isLoaded?: boolean;
}

export default function AutoForm({
  form,
  sections,
  submitUrl,
  isLoaded = true,
}: AutoFormProps) {
  const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const transformData = useMemo(() => buildDataTransformer(sections), [sections]);

  useAutoSave({ form, transformData, submitUrl });

  if (!isLoaded) {
    return <Spinner />;
  }

  return (
    <Flex direction="column" mt="4">
      {sections.map((section) => {
        const sectionId = `section-${camelCase(section.title)}`;
        return (
          <Box
            key={section.title}
            id={sectionId}
            ref={(el) => {
              sectionRefs.current[sectionId] = el;
              return el;
            }}
          >
            <BlueBorderCard>
              <CardBody p="8" px="12">
                <Heading mb="4" size="xs">
                  {section.title}
                </Heading>
                <FormSection section={section} form={form} />
              </CardBody>
            </BlueBorderCard>
          </Box>
        );
      })}
    </Flex>
  );
}
