import { createIcon } from "@chakra-ui/react";

const CaminoCalendar = createIcon({
  displayName: "CaminoCalendar",
  viewBox: "0 0 17 16",
  path: (
    <>
      <path fill="#EABDB8" d="M1.121 3h13.394v2H1.121V3z" />
      <path fill="white" d="M1.121 5h13.394v9H1.121V5z" />
      <path
        fill="#959393"
        d="M3.182 3c-.57 0-1.03.448-1.03 1v9c0 .552.46 1
           1.03 1h10.303c.569 0 1.03-.448 1.03-1V4c0-.552-.461-1-1.03-1H3.182zm-2.06 1c0-1.105.922-2
           2.06-2h10.303c1.138 0 2.06.895 2.06 2v9c0 1.105-.922
           2-2.06 2H3.182c-1.138 0-2.06-.895-2.06-2V4z"
      />
      <path
        fill="#959393"
        d="M9.621 8a.762.762 0 00.773-.75.762.762 0 00-.773-.75.762.762
           0 00-.773.75c0 .414.346.75.773.75zM12.197 8a.762.762 0 00.773-.75.762.762
           0 00-.773-.75.762.762 0 00-.773.75c0 .414.346.75.773.75zM9.621 10.5a.762.762
           0 00.773-.75A.762.762 0 009.62 9a.762.762 0
           00-.773.75c0 .414.346.75.773.75zM12.197 10.5a.762.762 0 00.773-.75.762.762 0
           00-.773-.75.762.762 0 00-.773.75c0 .414.346.75.773.75zM4.47 10.5a.762.762 0
           00.772-.75A.762.762 0 004.47 9a.762.762 0 00-.773.75c0
           .414.346.75.772.75zM7.045 10.5a.762.762 0 00.773-.75.762.762 0 00-.773-.75.762.762
           0 00-.772.75c0 .414.346.75.772.75zM4.47 13a.762.762 0 00.772-.75.762.762
           0 00-.772-.75.762.762 0 00-.773.75c0 .414.346.75.772.75zM7.045 13a.762.762
           0 00.773-.75.762.762 0 00-.773-.75.762.762 0 00-.772.75c0
           .414.346.75.772.75zM9.621 13a.762.762 0 00.773-.75.762.762 0 00-.773-.75.762.762
           0 00-.773.75c0 .414.346.75.773.75z"
      />
      <path
        fill="#959393"
        d="M9.621 8a.762.762 0 00.773-.75.762.762 0 00-.773-.75.762.762
           0 00-.773.75c0 .414.346.75.773.75zM12.197 8a.762.762 0 00.773-.75.762.762
           0 00-.773-.75.762.762 0 00-.773.75c0 .414.346.75.773.75zM9.621 10.5a.762.762
           0 00.773-.75A.762.762 0 009.62 9a.762.762 0
           00-.773.75c0 .414.346.75.773.75zM12.197 10.5a.762.762 0 00.773-.75.762.762 0
           00-.773-.75.762.762 0 00-.773.75c0 .414.346.75.773.75zM4.47 10.5a.762.762 0
           00.772-.75A.762.762 0 004.47 9a.762.762 0 00-.773.75c0
           .414.346.75.772.75zM7.045 10.5a.762.762 0 00.773-.75.762.762 0 00-.773-.75.762.762
           0 00-.772.75c0 .414.346.75.772.75zM4.47 13a.762.762 0 00.772-.75.762.762
           0 00-.772-.75.762.762 0 00-.773.75c0 .414.346.75.772.75zM7.045 13a.762.762
           0 00.773-.75.762.762 0 00-.773-.75.762.762 0 00-.772.75c0
           .414.346.75.772.75zM9.621 13a.762.762 0 00.773-.75.762.762 0 00-.773-.75.762.762
           0 00-.773.75c0 .414.346.75.773.75z"
      />
    </>
  ),
});

export default CaminoCalendar;
