import moment from "moment";

export function validDate(date: Date | undefined): Date | undefined {
  if (date instanceof Date && !Number.isNaN(date)) return date;
  if (typeof date === "string" && Date.parse(date)) return moment(date).toDate();
  return undefined;
}

export function formatDate(date: Date, format: string = "dddd, MMMM DD"): string {
  return moment(date).format(format);
}

export function parseAndFormatDate(value: string, format: string = "dddd, MMMM DD"): string | null {
  const date = new Date(value);
  return Number.isNaN(date.getMilliseconds()) ? null : formatDate(date, format);
}

export function dateStrToLocalDate(dateStr: Date | string | undefined): Date | undefined {
  if (!dateStr) return undefined;
  if (dateStr instanceof Date) return dateStr;
  const [year, month, day] = dateStr.split("-").map(Number);
  return new Date(year, month - 1, day);
}
