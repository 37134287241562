import * as yup from "yup";
import { EmailType } from "models/automation";
import { SelectEmailTargetValue } from "./models";

export const schema = yup.object().shape({
  emailTargetType: yup.mixed<SelectEmailTargetValue>().required(),
  recipient: yup.object().when("emailTargetType", {
    is: (val: SelectEmailTargetValue) => val === "user" || val === "relation",
    then: (s) => s.shape({
      targetType: yup.string().oneOf(["User", "OnboardingJourneyNetworkRelation"]),
      targetId: yup.string().required().label("Recipient"),
    }),
    otherwise: (s) => s.notRequired(),
  }),
  customEmail: yup
    .string()
    .email()
    .when("emailTargetType", {
      is: "custom",
      then: (s) => s.required().label("Email"),
    }),
  emailType: yup.mixed<EmailType>().when("emailTargetType", {
    is: (s: SelectEmailTargetValue) => s !== "custom",
    then: (s) => s.required().label("Email Type"),
    otherwise: (s) => s.notRequired(),
  }),
});

export type Schema = yup.InferType<typeof schema>;
