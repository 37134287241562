import { Text } from "@chakra-ui/react";
import { SegmentedControlButton, SegmentedControlGroup } from "components/FormElements/UnderlineSegmentedControlGroup";
import { TimeRangeValue } from "definitions/constants";
import { Dispatch, SetStateAction } from "react";

interface SelectableTimePeriodProps {
  options: Array<TimeRangeValue>;
  setTimePeriodValue: Dispatch<SetStateAction<string>>;
  timePeriodValue: string;
}

export default function SelectableTimePeriod({
  options,
  timePeriodValue,
  setTimePeriodValue,
}: SelectableTimePeriodProps) {
  return (
    <SegmentedControlGroup
      defaultValue={timePeriodValue}
      onChange={(value: any) => setTimePeriodValue(value)}
    >
      {options.map((option) => (
        <SegmentedControlButton key={option.value} value={option.value}>
          <Text size="sm">{option.label}</Text>
        </SegmentedControlButton>
      ))}
    </SegmentedControlGroup>
  );
}
