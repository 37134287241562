import { Alert, Box, CloseButton, Text, useColorMode, useColorModeValue } from "@chakra-ui/react";
import CaminoAlertIcon from "components/Icon/CaminoAlertIcon";
import { useState } from "react";

function InfoAlert() {
  const [isVisible, setIsVisible] = useState(true);
  const bg = useColorModeValue("boldBlue.200", "gray.700");

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <Alert
        status="info"
        borderRadius="md"
        borderWidth="1px"
        borderColor="boldBlue.700"
        alignItems="center"
        bg={bg}
        width="fit-content"
        pr="10"
        py="2"
      >
        <Box as={CaminoAlertIcon} boxSize="20px" mr={2} />
        <Box flex="1" color="fg.emphasized" fontSize="sm">
          <Text>
            All automations are
            {" "}
            <Text fontWeight="bold" as="span" color="fg.emphasized">
              currently pending
            </Text>
            . Once you
            {" "}
            <Text fontWeight="bold" as="span" color="fg.emphasized">
              &ldquo;Kick Off Onboarding&ldquo;
            </Text>
            , they become
            {" "}
            <Text fontWeight="bold" as="span" color="fg.emphasized">
              active
            </Text>
            {" "}
            .
          </Text>
        </Box>
        <CloseButton
          size="xs"
          position="absolute"
          right="2.5"
          sx={{ _hover: { bg: "transparent", color: "fg.emphasized" } }}
          onClick={handleClose}
        />
      </Alert>
    )
  );
}

export default InfoAlert;
