import {
  Box, HStack, Heading, IconButton, Spacer, Stack, Text,
} from "@chakra-ui/react";
import { UseFieldArrayRemove, UseFormReturn } from "react-hook-form";
import { MdClose } from "react-icons/md";
import generateSchedulingSummary from "components/Forms/PathActions/Shared/helpers";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import { Schema } from "./schema";
import { MESSAGE_FIELD, schedulingFields } from "./definitions";

export default function TaskReminderInput({
  index,
  remove,
  form: {
    control,
    register,
    watch,
    formState: { errors },
  },
}: {
  form: UseFormReturn<Schema>;
  index: number;
  remove: UseFieldArrayRemove;
}) {
  const trigger = watch(`reminders.${index}.trigger`);

  const triggerFields = schedulingFields("trigger");

  if (trigger === undefined || trigger?.type === "dependency") return null;

  let schedulingSummary = "";
  if (trigger.type === "timing") schedulingSummary = generateSchedulingSummary({ field: "trigger", trigger });

  return (
    <Stack mt={6} border="1px solid" borderColor="gray.600" borderRadius="lg" p={5} pos="relative">
      <HStack>
        <Heading size="xxs" mb={2}>
          {`Reminder ${index + 1}`}
        </Heading>
        <Spacer />
        <IconButton aria-label="remove" variant="tertiary" icon={<MdClose />} onClick={() => remove(index)} />
      </HStack>
      <Stack>
        <TextInputField {...MESSAGE_FIELD} name={`reminders.${index}.message`} errors={errors} register={register} />
      </Stack>
      <Text fontSize="xs" fontStyle="italic">
        {schedulingSummary.length > 0 && `Summary: ${schedulingSummary}`}
      </Text>
      <HStack width="100%">
        <Box display="inline-block" width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...triggerFields.offsetDirection}
            name={`reminders.${index}.trigger.data.offsetDirection`}
            errors={errors}
            control={control}
          />
        </Box>
        <Box width="50%">
          <SingleSelectField
            isClearable={false}
            required
            {...triggerFields.referenceEvent}
            name={`reminders.${index}.trigger.data.referenceEvent`}
            errors={errors}
            control={control}
          />
        </Box>
      </HStack>
      {!!trigger.data?.offsetDirection && trigger.data.offsetDirection !== "exact" && (
        <HStack width="100%">
          <Box display="inline-block" width="15%">
            <SingleSelectField
              isClearable={false}
              required
              {...triggerFields.offsetValue}
              name={`reminders.${index}.trigger.data.offsetValue`}
              errors={errors}
              control={control}
            />
          </Box>
          <Box display="inline-block" width="20%">
            <SingleSelectField
              isClearable={false}
              required
              {...triggerFields.offsetUnit}
              name={`reminders.${index}.trigger.data.offsetUnit`}
              errors={errors}
              control={control}
            />
          </Box>
          {trigger.data.offsetUnit === "specific_day" && (
            <Box width="25%">
              <SingleSelectField
                isClearable={false}
                required
                {...triggerFields.dayOfWeek}
                name={`reminders.${index}.trigger.data.dayOfWeek`}
                errors={errors}
                control={control}
              />
            </Box>
          )}
        </HStack>
      )}
    </Stack>
  );
}
