import { Box, CardProps, Flex, Text } from "@chakra-ui/react";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import DashboardCard from "./DashboardCard";

export default function CreatePathCard({ ...rest }: CardProps) {
  return (
    <DashboardCard
      title="Create Path"
      emoji="🚀"
      buttonText="Get Started"
      destination={PEOPLE_TEAM_ROUTES.paths.index}
      {...rest}
    >
      <Flex direction="column" justifyContent="center" alignItems="start" h="100%">
        <Box as="p">
          <Text as="span" fontSize="lg">
            Create your first path — try using one of our templates!
          </Text>
        </Box>
      </Flex>
    </DashboardCard>
  );
}
