import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, VStack } from "@chakra-ui/react";
import SecureInputField from "components/FormElements/Fields/InputFields/SecureInputField";

const schema = yup.object({
  apiKey: yup.string().trim().label("API Key").required(),
});

export default function APIKeyCredentialsForm({ onSubmit }: { onSubmit: (data: Record<string, string>) => void }) {
  const { handleSubmit, register } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
  });

  return (
    <VStack spacing={4}>
      <SecureInputField register={register} name="apiKey" label="API Key" placeholder="Enter key" />
      <Button onClick={handleSubmit(onSubmit)}>Submit</Button>
    </VStack>
  );
}
