import {
  LinkBox, LinkOverlay, Text, Card, CardBody, Heading, Flex, CardProps,
} from "@chakra-ui/react";
import { Link as ReactRouterLink, generatePath } from "react-router-dom";
import { Path } from "models/automation";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import PathMenuButton from "./PathMenuButton";

interface PathMiniCardProps extends CardProps {
  workflow: Path;
}

export default function PathMiniCard({ workflow, ...rest }: PathMiniCardProps) {
  return (
    <LinkBox _hover={{ bg: "background.tertiary" }} as={Card} {...rest}>
      <CardBody>
        <Flex direction="column" height="100%">
          <Flex direction="column" justify="space-between" mt="2" height="100%">
            <Flex justifyContent="space-between" alignItems="top">
              <Heading size="xxs">
                <LinkOverlay as={ReactRouterLink} to={generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: workflow.id })}>
                  {workflow.name}
                </LinkOverlay>
              </Heading>
              <PathMenuButton path={workflow} />
            </Flex>
            <Flex direction="row" justify="space-between" align="end">
              <Text pt="2" fontSize="sm">
                {`${(workflow.actions || []).length} Actions`}
              </Text>
              <Text fontSize="sm">{workflow?.autoAdd ? "Auto-Assigned to New Hires" : "Manually Assigned"}</Text>
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </LinkBox>
  );
}
