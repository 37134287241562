import { Text } from "@chakra-ui/react";
import { SegmentedControlButton, SegmentedControlGroup } from "components/FormElements/UnderlineSegmentedControlGroup";
import CaminoEmail from "components/Icon/CaminoEmail";
import CaminoMessage from "components/Icon/CaminoMessage";
import CaminoTask from "components/Icon/CaminoTask";
import { RESOURCE_ICON } from "definitions/constants/icons";
import { ActionableType } from "models/automation";
import { Dispatch, SetStateAction } from "react";

const SelectableActionTypeOptions = [
  { label: "All", value: "", icon: RESOURCE_ICON.all },
  { label: "Email", value: "email", icon: CaminoEmail },
  { label: "Chat Message", value: "chat", icon: CaminoMessage },
  { label: "Task", value: "task", icon: CaminoTask },
];

interface SelectableActionTypeProps {
  setActionType: Dispatch<SetStateAction<ActionableType | "">>;
  actionType: string;
}

export default function SelectableActionType({ actionType, setActionType }: SelectableActionTypeProps) {
  return (
    <SegmentedControlGroup
      name="actionType"
      value={actionType}
      onChange={(value: any) => setActionType(value)}
    >
      {SelectableActionTypeOptions.map(({ value, icon, label }) => (
        <SegmentedControlButton key={value} value={value} icon={icon}>
          <Text size="sm">{label}</Text>
        </SegmentedControlButton>
      ))}
    </SegmentedControlGroup>
  );
}
