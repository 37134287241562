import { range } from "lodash";
import * as yup from "yup";
import {
  ActionableType,
  DayOfWeek,
  OffsetUnit,
  PathReferenceEvent,
  PathRelationTarget,
  PathTriggerType,
  SpecificTimezone,
  TimeOfDay,
  TimezoneType,
} from "models/automation";
import { LegacyTargetType } from "models/record_target";

const timingData = yup.object({
  referenceEvent: yup.mixed<PathReferenceEvent>().required().label("Event"),
  offsetDirection: yup.mixed<"exact" | "before" | "after">().required().label("Before/After/On"),
  offsetValue: yup.number().when("offsetDirection", {
    is: (value: string) => value && value !== "exact",
    then: (schema) => schema.required().oneOf(range(1, 120)).label("Timing"),
    otherwise: (schema) => schema.notRequired(),
  }),
  offsetUnit: yup
    .mixed<OffsetUnit>()
    .label("Day/Week/Month")
    .when("offsetDirection", {
      is: (value: string) => value && value !== "exact",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  dayOfWeek: yup
    .mixed<DayOfWeek>()
    .label("Day Of Week")
    .when("offsetUnit", {
      is: (value: string) => value === "specific_day",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  timeOfDay: yup.mixed<TimeOfDay>().label("Send At").notRequired(),
  timezoneType: yup.mixed<TimezoneType>().label("Timezone Source").notRequired(),
  specificTimezone: yup
    .mixed<SpecificTimezone>()
    .label("Which Timezone?")
    .when("targetTimezone", {
      is: (value: string) => value === "specificTimezone",
      then: (schema) => schema.required().default("America/Los_Angeles"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export type TimingDataSchema = yup.InferType<typeof timingData>;

export const timing = yup.object({
  type: yup.mixed<Extract<PathTriggerType, "timing">>().required().nonNullable(),
  data: yup.mixed<TimingDataSchema>().required().nonNullable(),
});

export type TimingSchema = yup.InferType<typeof timing>;

const dependencyData = yup.object({
  event: yup.mixed<"task.completed">().required(),
  dependencyType: yup.mixed<"WorkflowAction">().required(),
  dependencyId: yup.string().label("Task").required(),
});

type DependencyDataSchema = yup.InferType<typeof dependencyData>;

const dependency = yup.object({
  type: yup.mixed<Extract<PathTriggerType, "dependency">>().required().nonNullable(),
  data: yup.mixed<DependencyDataSchema>().required().nonNullable(),
});

type DependencySchema = yup.InferType<typeof dependency>;

export const trigger = yup.mixed<TimingSchema | DependencySchema>().defined().nonNullable();

export type TriggerSchema = yup.InferType<typeof trigger>;

export const sharedSchema = yup.object({
  actionType: yup.mixed<ActionableType>().required().nonNullable().label("Action Type"),
  targetType: yup.mixed<LegacyTargetType>().required().nonNullable().label("Recipient/Assignee"),
  name: yup.string().required().nonNullable().label("Name"),
  trigger: trigger.required().nonNullable(),
  relationTarget: yup.mixed<PathRelationTarget>().notRequired().nonNullable(),
  specificTargetableId: yup.string().notRequired().nonNullable(),
  specificTargetableType: yup.string().notRequired().nonNullable(),
});

export type SharedSchema = yup.InferType<typeof sharedSchema>;
