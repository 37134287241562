import {
  Box,
  BoxProps,
  Circle,
  createIcon,
  Icon,
  Stack,
  StackProps,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  useStyleConfig,
} from "@chakra-ui/react";
import {
  Children, cloneElement, isValidElement, ReactElement, useMemo,
} from "react";

interface RadioCardGroupProps<T> extends Omit<StackProps, "onChange"> {
  name?: string;
  value?: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
}

export function RadioCardGroup<T extends string>(props: RadioCardGroupProps<T>) {
  const {
    children, name, defaultValue, value, onChange, ...rest
  } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const cards = useMemo(
    () => Children.toArray(children)
      .filter<ReactElement<RadioCardProps>>(isValidElement)
      .map((card) => cloneElement(card, {
        radioProps: getRadioProps({
          value: card.props.value,
        }),
      })),
    [children, getRadioProps],
  );

  return <Stack {...getRootProps(rest)}>{cards}</Stack>;
}

RadioCardGroup.defaultProps = {
  name: undefined,
  value: undefined,
  defaultValue: undefined,
  onChange: () => null,
};

export const CheckIcon = createIcon({
  displayName: "CheckIcon",
  viewBox: "0 0 12 10",
  path: (
    <polyline fill="none" strokeWidth="2px" stroke="currentColor" strokeDasharray="16px" points="1.5 6 4.5 9 10.5 1" />
  ),
});

interface RadioCardProps extends BoxProps {
  value: string;
  radioProps?: UseRadioProps;
  labelProps?: object;
  includeCheckbox?: boolean;
}

export function RadioCard(props: RadioCardProps) {
  const { radioProps, children, labelProps, includeCheckbox = false, ...rest } = props;
  const { getInputProps, getRadioProps, getLabelProps, state } = useRadio(radioProps);
  const id = useId(undefined, "radio-button");

  const styles = useStyleConfig("RadioCard", props);

  return (
    <Box
      as="label"
      cursor="pointer"
      {...getLabelProps(labelProps)}
      display="flex"
      sx={{
        ".focus-visible + [data-focus]": {
          boxShadow: "outline",
          zIndex: 1,
        },
      }}
    >
      <input {...getInputProps()} aria-labelledby={id} />
      <Box sx={styles} {...getRadioProps()} {...rest}>
        <Stack direction="row" height="100%" align="center">
          <Box flex="1">{children}</Box>
          {includeCheckbox && (
            state.isChecked ? (
            <Circle bg="accent" size="4">
              <Icon as={CheckIcon} boxSize="2.5" color="fg.inverted" />
            </Circle>
          ) : (
            <Circle borderWidth="2px" size="4" />
          ))}
        </Stack>
      </Box>
    </Box>
  );
}

RadioCard.defaultProps = {
  radioProps: {},
  labelProps: {},
};
