import {
  Tr, Td, Skeleton, BoxProps,
} from "@chakra-ui/react";

export function SkeletonRow({ columns, ...rest }: { columns: number }) {
  return (
    <Tr>
      <Td colSpan={columns} px="0" py="2">
        <Skeleton height="40px" width="100%" {...rest} />
      </Td>
    </Tr>
  );
}

interface SkeletonRowsProps {
  columns: number;
  rows: number;
  boxProps?: BoxProps;
  speed: number;
}

export function SkeletonRows({
  columns, rows, boxProps, ...rest
}: SkeletonRowsProps) {
  return (
    <>
      {Array.from({ length: rows }).map(() => (
        <SkeletonRow key={crypto.randomUUID()} columns={columns} {...boxProps} {...rest} />
      ))}
    </>
  );
}

SkeletonRows.defaultProps = {
  boxProps: {},
};
