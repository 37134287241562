import { createIcon } from "@chakra-ui/react";

const CaminoLink = createIcon({
  displayName: "CaminoLink",
  viewBox: "0 0 18 17",
  path: (
    <>
      <path
        fill="#4097EB"
        d="M13.242 4.937a.504.504 0 01.728 0l2.06 2.125a.543.543
           0 010 .751l-2.06 2.125a.504.504 0 01-.728
           0 .543.543 0 010-.751l1.696-1.75-1.696-1.749a.543.543 0 010-.751z"
      />
      <path
        fill="#4097EB"
        d="M1.758 11.023c0-2.228 1.738-4.117 3.928-4.117h9.466c.284 0 .515.238.515.532
           0 .293-.231.53-.515.53H5.686c-1.595 0-2.898 1.388-2.898 3.055v.665c0 .293-.23.53-.515.53a.523.523
           0 01-.515-.53v-.665z"
      />
    </>
  ),
});

export default CaminoLink;
