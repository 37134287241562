/* eslint-disable object-curly-newline */
import {
  Tag,
  TagCloseButton,
  TagLabel,
  TagLeftIcon,
  TagProps,
  Skeleton,
  Tooltip,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  CreateToastFnReturn,
  useDisclosure,
} from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import { COMMUNICATION_TYPE, QUERY_KEYS } from "definitions/constants";
import { RESOURCE_ICON, CHAT_ICONS } from "definitions/constants/icons";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { MessageServiceChannel } from "models/integration/messageService";
import { NotifierTarget } from "models/notifiers";
import { User } from "models/user";
import React from "react";
import { IconType } from "react-icons";
import { generatePath } from "react-router-dom";

interface NotifierBaseTagProps extends TagProps {
  content: string;
  icon: IconType;
  isLoading: boolean;
  hoverText?: string;
}

function NotifierBaseTag({ id, content, icon, isLoading, hoverText, ...rest }: NotifierBaseTagProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  const { performRequest: onDelete } = useDeferredApiCall<{ success: boolean }>(
    generatePath(API_ROUTES.notifierTargets.show, { id: id || "" }),
    {
      method: "DELETE",
      onSuccess: (_, queryClient, toast) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.notifiers] });
        toast({ title: "Successfully removed" });
      },
      onFailure: (_, queryClient: QueryClient, toast: CreateToastFnReturn) => {
        toast({ title: "There was an error, please try again", status: "error" });
      },
    },
  );

  const handleDelete = () => {
    onDelete(); // Trigger the delete function passed as prop
    onClose(); // Close the modal
  };
  return (
    <Skeleton isLoaded={!isLoading}>
      <Tooltip label={hoverText} isDisabled={!hoverText}>
        <Tag size="lg" variant="subtle" {...rest}>
          <TagLeftIcon boxSize="12px" as={icon} />
          <TagLabel>{content}</TagLabel>
          <TagCloseButton onClick={onOpen} />
        </Tag>
      </Tooltip>

      {/* Confirmation Modal */}
      <AlertDialog isOpen={isOpen} onClose={onClose} leastDestructiveRef={cancelRef} isCentered>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Remove Notifier
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Remove
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Skeleton>
  );
}

NotifierBaseTag.defaultProps = {
  hoverText: null,
};

function getHoverText(notifierTarget: NotifierTarget) {
  let recipientTypeText = "";
  if (notifierTarget.customEmail) {
    recipientTypeText = `the email address ${notifierTarget.customEmail}`;
  } else if (notifierTarget.recipientType === "MessageServiceChannel") {
    recipientTypeText = `the channel #${notifierTarget?.recipient?.name}`;
  } else if (notifierTarget.recipientType === "User") {
    recipientTypeText = notifierTarget?.recipient?.fullName;
  }
  return `${notifierTarget.deliveryMethod === "email" ? "An email" : "A message"} will be sent to ${recipientTypeText}`;
}

function NotifierChatTag({ notifierTarget }: { notifierTarget: NotifierTarget }) {
  return (
    <NotifierBaseTag
      id={notifierTarget?.id}
      isLoading={!notifierTarget}
      content={
        notifierTarget.recipientType === "User"
          ? (notifierTarget?.recipient as User)?.fullName
          : (notifierTarget?.recipient as MessageServiceChannel)?.name
      }
      icon={notifierTarget.recipientType === "User" ? RESOURCE_ICON.chat : CHAT_ICONS.CHANNEL}
      hoverText={getHoverText(notifierTarget)}
    />
  );
}

function getEmailContent(notifierTarget: NotifierTarget) {
  if (notifierTarget.customEmail) {
    return notifierTarget.customEmail;
  }
  return (notifierTarget.recipient as User)?.workEmail;
}

function NotifierEmailTag({ notifierTarget }: { notifierTarget: NotifierTarget }) {
  return (
    <NotifierBaseTag
      id={notifierTarget?.id}
      isLoading={!notifierTarget}
      content={getEmailContent(notifierTarget)}
      icon={RESOURCE_ICON[COMMUNICATION_TYPE.EMAIL]}
      hoverText={getHoverText(notifierTarget)}
    />
  );
}

export default function NotifierTargetTag({ notifierTarget }: { notifierTarget: NotifierTarget }) {
  if (notifierTarget.deliveryMethod === "chat") {
    return <NotifierChatTag notifierTarget={notifierTarget} />;
  }
  return <NotifierEmailTag notifierTarget={notifierTarget} />;
}
