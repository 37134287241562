import { CreatePathForm } from "components/Forms/PathForm";
import { AddIcon } from "@chakra-ui/icons";
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import React from "react";

export default function CreatePathButtonWithModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);

  return (
    <>
      <Tooltip label="These paths are unique to your company and can be modified as desired and later assigned to new hires.">
        <Button onClick={onOpen} rightIcon={<AddIcon />}>
          Create Path
        </Button>
      </Tooltip>

      <Modal initialFocusRef={initialRef} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader fontSize={30}>Create Path</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <CreatePathForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
