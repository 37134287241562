import { FilterMeta } from "@tanstack/react-table";
import { TIME_RANGE, TimeRangeValue } from "definitions/constants";
import { ActionableType } from "models/automation";
import moment from "moment";

export function actionTypeFilter(actionType: ActionableType | "", fieldName: string) {
  if (!actionType) return [];
  const pageFilters: FilterMeta[] = [];
  switch (actionType) {
    case "chat":
      pageFilters.push({
        fieldName,
        value: "chat",
        operator: "=",
      });
      break;
    case "task":
      pageFilters.push({
        fieldName,
        value: ["task", "task_notifier", "task_reminder"],
        operator: "in",
      });
      break;
    case "email":
      pageFilters.push({
        fieldName,
        value: "email",
        operator: "=",
      });
      break;
    default:
      break;
  }
  return pageFilters;
}

export default function timePeriodFilter(timeValue: TimeRangeValue["value"] | undefined, fieldName: string) {
  if (!timeValue) return [];
  const pageFilters: FilterMeta[] = [];
  switch (timeValue) {
    case TIME_RANGE.INFINITE_TO_NOW.value:
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.LAST_MONTH_TO_NOW.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").subtract(1, "months").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.LAST_TWO_WEEKS_TO_NOW.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").subtract(2, "weeks").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.LAST_WEEK.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("isoWeek").subtract(1, "weeks").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().startOf("isoWeek").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.THIS_WEEK_START_TO_NOW.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("isoWeek").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.LAST_WEEK_THROUGH_NOW.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("isoWeek").subtract(1, "weeks").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.YESTERDAY.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").subtract(1, "days").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.TODAY_UP_TO_NOW.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.TODAY.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").add(1, "days").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.NOW_TO_END_OF_TODAY.value:
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").add(1, "days").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.TOMORROW.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").add(1, "days").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").add(2, "days").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.NOW_TO_THIS_WEEK_END.value:
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().endOf("isoWeek").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.THIS_WEEK.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("isoWeek").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().endOf("isoWeek").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.NEXT_WEEK.value:
      pageFilters.push({
        fieldName,
        value: moment().startOf("isoWeek").add(1, "weeks").toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().endOf("isoWeek").add(1, "weeks").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.NOW_PLUS_ONE_WEEK.value:
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").add(1, "weeks").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.NOW_PLUS_ONE_MONTH.value:
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: ">=",
      });
      pageFilters.push({
        fieldName,
        value: moment().startOf("day").add(1, "months").toISOString(),
        operator: "<=",
      });
      break;
    case TIME_RANGE.NOW_TO_INFINITE.value:
      pageFilters.push({
        fieldName,
        value: moment().toISOString(),
        operator: ">=",
      });
      break;
    default:
      break;
  }
  return pageFilters;
}
