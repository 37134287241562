import { Flex } from "@chakra-ui/react";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { Fragment } from "react";
import {
  ReSyncSlackUsersButton,
  ConnectSlackButton,
  ReSyncSlackChannelsButton,
  UpdateMissingInformationButton,
  RemindManagerButton,
  AddMissingManagerButton,
  AddMissingBuddyButton,
  AddMissingPeopleTeamContactButton,
} from "./Button";

export function missingMessageServiceUserResolutionOptions(action: AssignedAction) {
  return (
    <Flex gap={4} mt={2}>
      <ReSyncSlackUsersButton colorScheme="rose" action={action} />
      <RemindManagerButton colorScheme="rose" action={action} />
    </Flex>
  );
}

export function missingMessageServiceResolutionOptions(action: AssignedAction) {
  return (
    <Flex gap={4} mt={2}>
      <ConnectSlackButton colorScheme="rose" action={action} />
    </Flex>
  );
}

export function defaultResolutionOptions() {
  return <Flex gap={4} mt={2} />;
}

export function channelNotFoundResolutionOptions(action: AssignedAction) {
  return (
    <Flex gap={4} mt={2}>
      <ReSyncSlackChannelsButton colorScheme="rose" action={action} />
    </Flex>
  );
}

export function missingEmailAddressResolutionOptions(action: AssignedAction, options = {}) {
  const field = action?.workflowAction?.actionable?.emailType;
  const displayEmail = field === "work_email" ? "Work Email" : "Personal Email";
  return (
    <Flex gap={4} mt={2}>
      {/* ADD_EMAIL_ADDRESS */}
      {/* ADD SPECIFIC EMAIL ADDRESS */}
      <UpdateMissingInformationButton
        colorScheme="rose"
        action={action}
        options={{
          variableType: "recipient",
          fields: [field],
          title: `Add ${displayEmail}`,
          buttonText: `Add ${displayEmail}`,
          ...options,
        }}
      />
    </Flex>
  );
}

export function missingBuddyResolutionOptions(action: AssignedAction, _options = {}) {
  return (
    <Flex gap={4} mt={2}>
      {/* ADD_EMAIL_ADDRESS */}
      {/* ADD SPECIFIC EMAIL ADDRESS */}
      <RemindManagerButton colorScheme="rose" action={action} />
      <AddMissingBuddyButton colorScheme="rose" action={action} />
    </Flex>
  );
}

export function missingManagerResolutionOptions(action: AssignedAction, _options = {}) {
  return (
    <Flex gap={4} mt={2}>
      <AddMissingManagerButton colorScheme="rose" action={action} />
    </Flex>
  );
}

export function missingPeopleTeamContactResolutionOptions(action: AssignedAction, _options = {}) {
  return (
    <Flex gap={4} mt={2}>
      <AddMissingPeopleTeamContactButton colorScheme="rose" action={action} />
    </Flex>
  );
}

type VariableGroups =
  | "recipient"
  | "assignee"
  | "new_hire"
  | "manager"
  | "buddy"
  | "people_team_contact"
  | "company"
  | "new_hire_journey";

interface MissingVariablesByGroup {
  [key: VariableGroups]: string[];
}

export function buildMissingContentVariableResolutionOptions(missingVariableGroups: MissingVariablesByGroup) {
  function missingContentVariableResolutionOptions(action: AssignedAction, options = {}) {
    return (
      <Flex gap={4} mt={2}>
        {Object.keys(missingVariableGroups).map((group) => {
          const variables = missingVariableGroups[group];
          const containsSlackMention = variables.includes("slack_mention");
          return (
            <Fragment key={group}>
              {containsSlackMention && <ReSyncSlackUsersButton colorScheme="rose" action={action} />}
              <UpdateMissingInformationButton
                colorScheme="rose"
                action={action}
                options={{ variableType: group, fields: missingVariableGroups[group], ...options }}
              />
            </Fragment>
          );
        })}
      </Flex>
    );
  }
  return missingContentVariableResolutionOptions;
}

export function buildMissingNamespacesResolutionOptions(missingNamespaces: VariableGroups[]) {
  if (missingNamespaces.some((value) => !["manager", "buddy", "people_team_contact"].includes(value))) {
    console.log(
      `Missing namespaces ${missingNamespaces} includes values outside of 'manager', 'buddy', or 'people_team_contact'`,
    );
  }
  function missingNamespacesResolutionOptions(action: AssignedAction, _options = {}) {
    return (
      <Flex gap={4} mt={2}>
        {missingNamespaces.includes("buddy") ? <RemindManagerButton colorScheme="rose" action={action} /> : null}
        {missingNamespaces.includes("buddy") ? <AddMissingBuddyButton colorScheme="rose" action={action} /> : null}
        {missingNamespaces.includes("manager") ? <AddMissingManagerButton colorScheme="rose" action={action} /> : null}
        {missingNamespaces.includes("people_team_contact") ? (
          <AddMissingPeopleTeamContactButton colorScheme="rose" action={action} />
        ) : null}
      </Flex>
    );
  }
  return missingNamespacesResolutionOptions;
}
