import { Flex, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface GreyBlackFieldProps extends PropsWithChildren {
  label: string;
  labelProps?: object;
  valueProps?: object;
}

export default function GreyBlackField({ label, children, labelProps = {}, valueProps = {} }: GreyBlackFieldProps) {
  return (
    <Flex align="flex-start" direction="column" {...labelProps}>
      <Text fontSize="2xs" color="notBlack.50">
        {label}
      </Text>
      {typeof children === "string" ? (
        <Text fontSize="xs" fontWeight="medium" lineHeight="1" {...valueProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Flex>
  );
}

GreyBlackField.defaultProps = {
  labelProps: {},
  valueProps: {},
};

export function HorizontalGreyBlackField({ label, children, labelProps = {}, valueProps = {} }: GreyBlackFieldProps) {
  return (
    <Flex align="center" gap="2" {...labelProps}>
      <Text fontSize="2xs" color="notBlack.50">
        {label}
      </Text>
      {typeof children === "string" ? (
        <Text fontSize="xs" fontWeight="medium" lineHeight="1" {...valueProps}>
          {children}
        </Text>
      ) : (
        children
      )}
    </Flex>
  );
}

HorizontalGreyBlackField.defaultProps = {
  labelProps: {},
  valueProps: {},
};
