import { Center, CenterProps, Image } from "@chakra-ui/react";

interface ExampleImageProps extends CenterProps {
  imageSource: string;
}

export default function ExampleImage({ imageSource, ...rest }: ExampleImageProps) {
  return (
    <Center width="100%" px="2" {...rest}>
      <Image
        maxW="500px"
        w="100%"
        border="1px solid"
        borderRadius="lg"
        borderColor="border"
        src={imageSource}
        alt="Task example image"
      />
    </Center>
  );
}
