import { Flex, calc } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import PageTitle from "components/PageTitle";
import useCaminoStore from "hooks/useCaminoStore";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import { useEffect } from "react";
import apiClient from "services/ApiClient";
import PeopleTeamUserDashboardTable from "components/Table/PeopleTeamUserDashboardTable";
import { DashboardStats, DashboardNewHire } from "./models";
import CreatePathCard from "./CreatePathCard";
import CreateNewHireCard from "./CreateNewHireCard";
import IssuesCard from "./IssuesCard";
import TasksCard from "./TasksCard";
import SetUpIntegrationCard from "./SetUpIntegrationCard";
import PendingNewHiresCard from "./PendingNewHiresCard";
import OnboardingOverviewCard from "./OnboardingOverviewCard";

function NewUserContent({
  workflowsCount,
  onboardingJourneysCount,
  connectedIntegrations,
}: {
  workflowsCount: number;
  onboardingJourneysCount: number;
  connectedIntegrations: DashboardStats["connectedIntegrations"];
}) {
  const cardWidth = calc("50%").subtract("6px").toString();
  const showCreatePathCard = workflowsCount === 0;
  const showAddIntegrationCard = !connectedIntegrations.messageService;
  const showCreateNewHireCard = !(showCreatePathCard && showAddIntegrationCard) && onboardingJourneysCount === 0;
  return (
    <>
      {showCreatePathCard && <CreatePathCard width={cardWidth} />}
      {showAddIntegrationCard && (
        <SetUpIntegrationCard connectedIntegrations={connectedIntegrations} width={cardWidth} />
      )}
      {showCreateNewHireCard && <CreateNewHireCard width={cardWidth} />}
    </>
  );
}

function ExistingUserContent({ data }: { data: DashboardStats }) {
  const cardWidth = calc("25%").subtract("6px").toString();
  const maxWidth = calc("33%").subtract("6px").toString();
  return (
    <>
      <OnboardingOverviewCard minWidth="250px" width={cardWidth} maxWidth={maxWidth} />
      {data.issuesCount > 0 && (
        <IssuesCard minWidth="250px" width={cardWidth} maxWidth={maxWidth} issuesCount={data.issuesCount} />
      )}
      {data.tasksCount > 0 && (
        <TasksCard minWidth="250px" width={cardWidth} maxWidth={maxWidth} tasksCount={data.tasksCount} />
      )}
      {data.pendingNewHiresCount > 0 && (
        <PendingNewHiresCard
          minWidth="250px"
          width={cardWidth}
          maxWidth={maxWidth}
          pendingNewHiresCount={data.pendingNewHiresCount}
        />
      )}
    </>
  );
}

export default function PeopleTeamDashboardPage() {
  const [user] = useCaminoStore((state) => [state.currentUser]);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setPageTitle("Home");
    setBreadcrumbs([]);
  }, [setBreadcrumbs, setPageTitle]);

  const { data: statsData } = useQuery<DashboardStats>({
    queryKey: ["dashboardPeopleTeamStats"],
    queryFn: () => apiClient.get("/dashboard/people-team-stats"),
  });

  const { data: newHires, isLoading: newHiresIsLoading } = useQuery<DashboardNewHire[]>({
    queryKey: ["dashboardPeopleTeamNewHires"],
    queryFn: () => apiClient.get("/dashboard/people-team-new-hires"),
  });

  return (
    <AppPage>
      <PageTitle text={`Welcome,${user?.firstName ? ` ${user.firstName}!` : "!"}`} isLoaded={!!user?.firstName} />
      {statsData && (
        <>
          <Flex width="100%" direction="row" align="center" mt="4" gap="2" flexWrap="wrap">
            <NewUserContent
              workflowsCount={statsData.workflowsCount}
              onboardingJourneysCount={statsData.onboardingJourneysCount}
              connectedIntegrations={statsData.connectedIntegrations}
            />
            <ExistingUserContent data={statsData} />
          </Flex>
          {newHires && <PeopleTeamUserDashboardTable data={newHires} isLoading={newHiresIsLoading} />}
        </>
      )}
    </AppPage>
  );
}
