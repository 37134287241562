import { ResponseModel } from "services/ApiClient/responseModel";
import RequestInterceptor from "./requestInterceptor";
import { RequestOptions } from "./requestOptions";
import { NetworkingError } from "./networkingError";

export function isJson(inputString: string) {
  try {
    JSON.parse(inputString);
    return true;
  } catch (e) {
    return false;
  }
}

class NetworkingClient {
  baseUrl: URL;

  interceptors: RequestInterceptor[] = [];

  constructor(baseUrl: URL) {
    this.baseUrl = baseUrl;
  }

  async get<T = any>(path: string, signal?: AbortSignal): Promise<T> {
    const requestOptions: RequestOptions = {
      method: "GET",
    };
    return (await this.performRequest<ResponseModel<T>>(path, requestOptions, signal)).payload.data;
  }

  async post<T = any>(path: string, body: object, signal?: AbortSignal): Promise<T> {
    const requestOptions: RequestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    return (await this.performRequest<ResponseModel<T>>(path, requestOptions, signal)).payload.data;
  }

  async put<T = any>(path: string, body: object, signal?: AbortSignal): Promise<T> {
    const requestOptions: RequestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };
    return (await this.performRequest<ResponseModel<T>>(path, requestOptions, signal)).payload.data;
  }

  async delete<T = any>(path: string, signal?: AbortSignal): Promise<T> {
    const requestOptions: RequestOptions = {
      method: "DELETE",
    };
    return (await this.performRequest<ResponseModel<T>>(path, requestOptions, signal)).payload.data;
  }

  /// If you need access to the response status code, or your API returns something other than the
  /// ResponseModel interface, you can use this method instead of the convenience methods above.
  async performRequest<T = any>(
    path: string,
    requestOptions: RequestOptions = { method: "GET" },
    signal?: AbortSignal,
  ): Promise<{ payload: T; status: number }> {
    const headers = requestOptions.headers || {};

    const { url, options } = this.interceptors.reduce<{ url: URL; options: RequestOptions }>(
      (partial, interceptor) => interceptor.request(partial.url, partial.options),
      { url: this.url(path), options: { ...requestOptions, headers } },
    );

    return fetch(url, { ...options, signal }).then(async (response: Response) => {
      const text = await response.text();
      // checks for if it is JSON because rails will send back HTML responses at times
      const data = text && isJson(text) && JSON.parse(text);
      if (!response.ok) {
        const errors = data?.errors || [response.statusText];
        const error: NetworkingError = { errors, status: response.status };
        if (response.status === 401) {
          localStorage.removeItem("user-storage");
        }
        return Promise.reject(error);
      }
      return { payload: data, status: response.status };
    });
  }

  private url(...pathComponents: string[]): URL {
    const path = pathComponents.reduce(
      (partial, next) => `${partial}/${next.replace(/^\//, "")}`,
      this.baseUrl.pathname,
    );

    return new URL(path, this.baseUrl);
  }
}

const apiClient = new NetworkingClient(new URL("api/v1", process.env.BASE_URL));

export default apiClient;
