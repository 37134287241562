import * as yup from "yup";
import { TargetType } from "models/record_target";

const messageTarget = yup.object({
  targetType: yup.mixed<TargetType>().required(),
  targetId: yup.string().required().label("Message Recipient"),
});

export const schema = yup.object({
  taskType: yup.string().label("Task Type").notRequired(),
  description: yup.string().label("Description").notRequired(),
  title: yup.string().label("Title").notRequired(),
  messageTarget: messageTarget.notRequired().label("Message Recipient"),
  messageBody: yup.string().notRequired().label("Message Suggestion"),
});

export type Schema = yup.InferType<typeof schema>;
