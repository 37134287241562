import { CardProps, Flex, Text } from "@chakra-ui/react";
import { SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import DashboardCard from "./DashboardCard";

export default function OnboardingOverviewCard({ ...rest }: CardProps) {
  return (
    <DashboardCard
      title="Overview"
      emoji="🦉"
      buttonText="View"
      destination={SHARED_COMPANY_ROUTES.newHireJourneys.overview}
      {...rest}
    >
      <Flex direction="column" justifyContent="center" alignItems="start" h="100%">
        <Text as="span" fontSize="lg">
          See the full onboarding overview
        </Text>
      </Flex>
    </DashboardCard>
  );
}
