const BODY_FIELD = {
  type: "text",
  label: "Message",
  placeholder: "Message",
  name: "content.body",
};

const MESSAGE_SERVICE_CHANNEL_FIELD = {
  type: "SELECT",
  label: "Channel",
  name: "specificTargetableId",
};

export { BODY_FIELD, MESSAGE_SERVICE_CHANNEL_FIELD };
