import { TabPanel } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import ControlledTabs from "components/Form/ControlledTabs";
import Scheduling from "./Scheduling";
import { SharedSchema } from "../schema";
import TaskCompletion from "./TaskCompletion";
import { TRIGGER_TYPE_FIELD } from "../fields";

export default function Trigger({
  workflowId,
  workflowActionId,
  form,
}: {
  workflowId: string;
  workflowActionId?: string;
  form: UseFormReturn<SharedSchema>;
}) {
  const { control } = form;
  return (
    <ControlledTabs control={control} {...TRIGGER_TYPE_FIELD} name="trigger.type">
      <TabPanel paddingX={0}>
        <Scheduling<SharedSchema> form={form} fieldName="trigger" />
      </TabPanel>
      <TabPanel paddingX={0}>
        <TaskCompletion form={form} workflowId={workflowId} workflowActionId={workflowActionId} />
      </TabPanel>
    </ControlledTabs>
  );
}

Trigger.defaultProps = {
  workflowActionId: undefined,
};
