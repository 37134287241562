export const ACTION_TYPE_FIELD = {
  type: "SELECT",
  label: "Action Type",
  placeholder: "Action Type",
  name: "actionType",
  options: [
    { label: "Email", value: "email" },
    { label: "Chat Message", value: "chat" },
    { label: "Task Notification", value: "task_notifier" },
    { label: "Task Reminder", value: "task_reminder" },
  ],
};

export const NAME_FIELD = {
  type: "text",
  label: "Name",
  placeholder: "Action Name",
  name: "name",
};

export const EMAIL_TYPE_FIELD = {
  type: "SELECT",
  label: "Email Type",
  placeholder: "Email Type",
  name: "emailType",
  options: [
    { label: "Work", value: "work_email" },
    { label: "Personal", value: "personal_email" },
  ],
};

export const SUBJECT_FIELD = {
  type: "text",
  label: "Subject",
  placeholder: "Subject",
  name: "subject",
};

export const BODY_FIELD = {
  type: "text",
  label: "Body",
  placeholder: "Body",
  name: "body",
};

export const MESSAGE_FIELD = {
  type: "text",
  label: "Message",
  placeholder: "Message",
  name: "message",
};

export const TRIGGER_DATE_TIME_FIELD = {
  type: "date",
  label: "Date",
  name: "trigger.data.datetime",
};
