import AssignedActionsTable from "components/Table/AssignedActionsTable";
import { Box, Center, Flex, Stack } from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { TimeRangeValue, QUERY_KEYS, TimeRangeKeys } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { createQueryString } from "helpers/queryString";
import { ActionableType, BaseAssignedAction } from "models/automation";
import { useState, useMemo } from "react";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import timePeriodFilter, { actionTypeFilter } from "helpers/filters";
import { RandomEmptyActivityFeedState } from "components/EmptyComponents/RandomEmptyState";
import { Filter } from "models/filter";
import SelectableTimePeriod from "../../../components/Selectable/SelectableTimePeriod";
import SelectableActionType from "../../../components/Selectable/SelectableActionType";

interface ActivityListProps {
  defaultTimePeriod: TimeRangeValue["value"];
  timePeriodOptions?: Array<TimeRangeValue>;
  sortDesc?: boolean;
  defaultFilters?: Filter[];
}

export default function ActivityList({
  timePeriodOptions = undefined,
  defaultTimePeriod,
  sortDesc = true,
  defaultFilters = [],
}: ActivityListProps) {
  const [searchText, setSearchText] = useState("");
  const [timePeriodValue, setTimePeriodValue] = useState<string>(defaultTimePeriod);
  const [actionType, setActionType] = useState<ActionableType | "">("");

  const filters = useMemo(
    () => [
      ...defaultFilters,
      ...timePeriodFilter(timePeriodValue as TimeRangeKeys, "trigger.datetime") as Filter[],
      ...actionTypeFilter(actionType, "action_type") as Filter[],
    ],
    [timePeriodValue, actionType],
  );

  const queryString = useMemo(
    () => createQueryString({
      pagination: { pageIndex: 0, pageSize: 100 },
      filters,
      additionalQueries: {
        view: "extended",
        q: searchText && searchText.length > 2 ? searchText : undefined,
      },
    }),
    [searchText, filters],
  );

  const { data: actionsData, isLoading } = useQuery<BaseAssignedAction[]>({
    queryKey: [QUERY_KEYS.assignedActions, queryString],
    queryFn: async () => apiClient.get(`${generatePath(API_ROUTES.assignedActions.base)}${queryString.length ? `?${queryString}` : ""}`),
  });

  return (
    <>
      <Stack direction="column" w="100%" gap="4" mt="4" alignItems="flex-start">
        <Flex
          justify="space-between"
          gap="4"
          direction={{ base: "column", lg: "row" }}
          w="100%"
          align={{ base: "flex-start", lg: "center" }}
        >
          <SearchBar
            border="1px solid var(--stroke-vibes, #F1F1F1)"
            minWidth="250px"
            maxWidth="350px"
            name="actionsSearch"
            onInput={setSearchText}
            flexShrink="1"
          />
        </Flex>
        <Flex
          gap="4"
          justify="space-between"
          direction={{ base: "column", xl: "row" }}
          w="100%"
          align={{ base: "flex-start", lg: "center" }}
        >
          {timePeriodOptions && (
            <SelectableTimePeriod
              options={timePeriodOptions}
              setTimePeriodValue={setTimePeriodValue}
              timePeriodValue={timePeriodValue}
            />
          )}
          <Box mr={{ base: "0", lg: "8" }} flexShrink="0">
            <SelectableActionType
              actionType={actionType}
              setActionType={setActionType}
            />
          </Box>
        </Flex>
      </Stack>
      <Box mt={4}>
        <AssignedActionsTable
          tableDefaultSort={{ id: "trigger", desc: sortDesc as boolean }}
          data={actionsData || []}
          emptyStateComponent={<RandomEmptyActivityFeedState />}
          isLoaded={!isLoading}
        />
      </Box>
    </>
  );
}
