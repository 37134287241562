import { Card, CardProps } from "@chakra-ui/react";

const BlueBorderCardStyles = {
  p: "0",
  mb: "2",
  borderColor: "var(--chakra-colors-boldBlue-DEFAULT)",
  borderWidth: "1px",
  width: "100%",
  height: "fit-content",
  maxWidth: "100%",
  borderRadius: "lg",
  boxShadow: "5px 5px 10px 0px rgba(0, 0, 0, 0.08)",
  overflow: "hidden",
};

export function BlueBorderCardWithHover({ children, ...rest }: CardProps) {
  return (
    <Card
      {...BlueBorderCardStyles}
      direction="row"
      _hover={{
        borderColor: "var(--chakra-colors-brand-DEFAULT)",
        borderWidth: "2px",
      }}
      {...rest}
    >
      {children}
    </Card>
  );
}

export function BlueBorderCard({ children, sx, ...rest }: CardProps) {
  return (
    <Card sx={sx} {...BlueBorderCardStyles} {...rest}>
      {children}
    </Card>
  );
}
