import { GroupBase, OptionsOrGroups, Select } from "chakra-react-select";
import { useController } from "react-hook-form";
import apiClient from "services/ApiClient";
import { DebouncedFunc, debounce } from "lodash";
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from "react";
import { DEFAULT_INPUT_VARIANT } from "definitions/constants/styling";
import clsx from "clsx";
import { SystemStyleObject } from "@chakra-ui/react";
import { ControlledSelectProps, OptionType } from ".";

export interface GenericAsyncMultiSelectProps<T> extends ControlledSelectProps {
  embedOption: (arg0: T) => Option<T>;
  extractValue: (arg0: T) => string;
  fetchPath: string;
  excludedIds?: string[];
  onChangeCallback?: (arg0: T | undefined) => void;
  cacheKey?: string;
  readOnly?: boolean;
  includeDropdownIndicator?: boolean;
  containerStyles?: SystemStyleObject;
  onConfirm?: () => void;
  components?: {
    [key: string]: React.ComponentType<any>;
  };
  onMenuClose?: () => void;
}

interface Option<T> {
  value: string;
  label: string;
  rawValue: T;
}

async function fetchOptions<T>(
  path: string,
  extractValue: (arg0: T) => string,
  embedOption: (arg0: T) => Option<T>,
  inputValue: string,
  excludedIds: string[],
): Promise<OptionsOrGroups<Option<T>, GroupBase<Option<T>>>> {
  return apiClient
    .get<T[]>(`${path}?q=${encodeURIComponent(inputValue)}`)
    .then((value) => value.filter((v) => !(excludedIds || []).includes(extractValue(v))).map((v) => embedOption(v)))
    .catch(() => []);
}

export function GenericAsyncMultiSelect<T>({
  control,
  name,
  id,
  placeholder,
  embedOption,
  extractValue,
  rules,
  fetchPath,
  excludedIds,
  onChangeCallback,
  cacheKey,
  readOnly,
  variant,
  includeDropdownIndicator,
  className,
  containerStyles = {},
  components,
  onConfirm,
  onMenuClose,
  ...props
}: GenericAsyncMultiSelectProps<T>) {
  const {
    field: { onChange, value: controlValue },
  } = useController({
    name,
    control,
    rules,
  });
  type OptionsGroups = OptionsOrGroups<Option<T>, GroupBase<Option<T>>>;

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OptionsGroups>([]);
  const [optionsCache, setOptionsCache] = useState<Record<string, OptionsGroups>>({});
  const deb = useRef<DebouncedFunc<() => Promise<void>> | undefined>();

  const loadOptions = useCallback(
    (inputValue: string, callback: (options: OptionsGroups) => void) => {
      deb.current?.cancel();
      deb.current = debounce(async () => {
        setIsLoading(true);
        callback(await fetchOptions(fetchPath, extractValue, embedOption, inputValue, excludedIds || []));
        setIsLoading(false);
      }, 300);
      deb.current();
    },
    [fetchPath, extractValue, embedOption, excludedIds],
  );

  useEffect(() => {
    if (!optionsCache[""]) {
      loadOptions("", (opts) => {
        setOptions(opts);
        setOptionsCache(opts ? { ...optionsCache, "": opts } : optionsCache);
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onInputChange = useCallback(
    (newValue: string) => {
      if (!newValue) {
        setOptions(optionsCache[""] || []);
        return;
      }
      if (optionsCache[newValue]) {
        setOptions(optionsCache[newValue]);
      } else {
        loadOptions(newValue, (opts) => {
          setOptions(opts || []);
          setOptionsCache(opts ? { ...optionsCache, [newValue]: opts } : optionsCache);
        });
      }
    },
    [loadOptions, optionsCache],
  );

  const handleOnChange = useCallback(
    (val: OptionType<T>[] | undefined) => {
      onChange(val?.map((option) => option.value) || []);
      if (onChangeCallback) onChangeCallback(val?.map((option) => option.rawValue) || []);
    },
    [onChange, onChangeCallback],
  );

  const value = useMemo(
    () => options.filter(
      (option) => (controlValue || []).includes((option as Option<T>).value) as OptionType<T> | undefined,
    ),
    [options, controlValue],
  );
  const formVariant = variant || DEFAULT_INPUT_VARIANT;

  return (
    <Select
      key={cacheKey}
      name={name}
      isLoading={isLoading}
      menuPortalTarget={document.body}
      useBasicStyles
      isMulti
      isClearable={!readOnly}
      placeholder={formVariant === "floating" ? " " : placeholder}
      onChange={handleOnChange}
      options={options}
      value={value}
      onInputChange={onInputChange}
      components={components || (includeDropdownIndicator ? {} : { DropdownIndicator: null })}
      classNamePrefix="chakra-react-select"
      className={clsx("chakra-react-select-container-multi-select", className)}
      variant={formVariant}
      onMenuClose={onMenuClose}
      {...(onConfirm ? { onConfirm } : {})}
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          cursor: "pointer",
          ...containerStyles,
        }),
        option: (provided) => ({
          ...provided,
          color: "fg.muted",
        }),
        valueContainer: (provided) => ({
          ...provided,
          className: "multi-select",
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: "fg.default",
        }),
        multiValue: (provided) => ({
          ...provided,
          background: "multiSelectBlue.DEFAULT",
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          color: "fg.default",
          fontSize: "sm",
          fontWeidth: "bold",
          opacity: "0.7",
          "&hover": {
            opacity: "1",
          },
        }),
      }}
      styles={{ menuPortal: (provided) => ({ ...provided, zIndex: 1400 }) }}
      {...props}
    />
  );
}

GenericAsyncMultiSelect.defaultProps = {
  excludedIds: [],
  onChangeCallback: undefined,
  cacheKey: undefined,
  readOnly: false,
  includeDropdownIndicator: false,
  containerStyles: {},
  onConfirm: null,
  components: {},
  onMenuClose: undefined, // Default to no-op if not provided
};
