import * as yup from "yup";
import { sharedSchema } from "../Shared/schema";
import { BODY_FIELD } from "./fields";

const content = yup.object({
  body: yup.string().required().label(BODY_FIELD.label),
});

export const schema = sharedSchema.concat(
  yup.object({
    content,
  }),
);

export type Schema = yup.InferType<typeof schema>;
