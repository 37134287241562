import {
  Card, CardBody, CardHeader, Heading, Skeleton,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { CompanyUpdateForm } from "components/Forms/Admin/Company";
import { QUERY_KEYS } from "definitions/constants";
import { ADMIN_ROUTES, API_ROUTES } from "definitions/constants/routeConstants";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import { Company } from "models/company";
import { useCallback, useEffect } from "react";
import { generatePath, useParams } from "react-router-dom";
import apiClient from "services/ApiClient";

export default function CompanyPage() {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { setPageTitle, setBreadcrumbs } = useNavbar();
  const { data: company, isLoading } = useQuery<Company>({
    queryKey: [QUERY_KEYS.companies, "admin", id],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.admin.companies.show, { id })),
  });
  const onSuccess = useCallback(
    (res: Company) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.companies, "admin"] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.companies, "admin", res.id] });
    },
    [queryClient],
  );

  useEffect(() => {
    setPageTitle("Company Details");
    setBreadcrumbs([
      { name: "Admin", toUrl: ADMIN_ROUTES.hub },
      { name: "Companies", toUrl: ADMIN_ROUTES.companies.index },
      { name: "Company Details", isCurrentPage: true },
    ]);
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Card>
        <CardHeader>
          <Heading size="xs" textTransform="capitalize">
            Company Details
          </Heading>
        </CardHeader>
        <CardBody>
          <Skeleton isLoaded={!isLoading} height="100%">
            {company && <CompanyUpdateForm company={company} onSuccess={onSuccess} />}
          </Skeleton>
        </CardBody>
      </Card>
    </AppPage>
  );
}
