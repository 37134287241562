import { theme as baseTheme } from "@chakra-ui/react";

const fonts = {
  heading: `Lato, ${baseTheme.fonts?.heading}`,
  body: `Lato, ${baseTheme.fonts?.body}`,
} as const;

const textStyles = {
  heading: {
    fontFamily: `Lato, ${baseTheme.fonts?.body}`,
    fontWeight: "normal",
  },
  body: {
    fontFamily: `Lato, ${baseTheme.fonts?.body}`,
    fontWeight: "normal",
  },
  tag: {
    fontFamily: `Lato, ${baseTheme.fonts?.body}`,
    fontWeight: "normal",
  },
} as const;

export type Fonts = typeof fonts;
export type TextStyels = typeof textStyles;

export { fonts, textStyles };
