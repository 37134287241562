import { Box, CardProps, Flex, Text } from "@chakra-ui/react";
import { SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import DashboardCard from "./DashboardCard";

export default function CreateNewHireCard({ ...rest }: CardProps) {
  return (
    <DashboardCard
      title="Create New Hire"
      emoji="👩‍💻"
      buttonText="Add New Hire"
      destination={SHARED_COMPANY_ROUTES.newHireJourneys.index}
      {...rest}
    >
      <Flex direction="column" justifyContent="center" alignItems="start" h="100%">
        <Box as="p">
          <Text as="span" fontSize="lg">
            Add your first new hire!
          </Text>
        </Box>
      </Flex>
    </DashboardCard>
  );
}
