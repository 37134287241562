import {
  Tabs, TabList, Tab, TabPanels, TabIndicator,
} from "@chakra-ui/react";
import {
  Control, FieldValues, Path, useController,
} from "react-hook-form";

type TabOption = {
  label: string;
  value: string;
};

type ControlledTabsProps<T extends FieldValues> = {
  children: React.ReactNode;
  options: TabOption[];
  control: Control<T>;
  name: Path<T>;
};

export default function ControlledTabs<T extends FieldValues>({
  options,
  control,
  name,
  children,
}: ControlledTabsProps<T>) {
  const {
    field: { onChange, value },
  } = useController({ name, control });
  return (
    <Tabs
      isFitted
      variant="indicator"
      width="100%"
      index={options.findIndex((o) => o.value === value)}
      onChange={(index) => {
        onChange({ target: { name, value: options[index].value } } as unknown as React.ChangeEvent<HTMLInputElement>);
      }}
    >
      <TabList width="50%">
        {options.map((option) => (
          <Tab key={option.value}>{option.label}</Tab>
        ))}
      </TabList>
      <TabIndicator />
      <TabPanels>{children}</TabPanels>
    </Tabs>
  );
}
