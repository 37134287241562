import {
  Card, CardBody, CardHeader, Heading, Skeleton, SkeletonText,
} from "@chakra-ui/react";
import { ANIMATION_SPEED } from "definitions/constants";

export default function LoadingCard() {
  return (
    <Card w={{ md: "2xl" }}>
      <CardHeader px={10} pt={10} pb={0}>
        <Skeleton speed={ANIMATION_SPEED}>
          <Heading color="fg.muted" size="md">
            Lorem Ipsum
          </Heading>
        </Skeleton>
      </CardHeader>

      <CardBody>
        <SkeletonText mt="4" noOfLines={12} spacing="4" skeletonHeight="4" speed={ANIMATION_SPEED} />
      </CardBody>
    </Card>
  );
}
