import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { QUERY_KEYS } from "definitions/constants";
import {
  Box, Card, CardBody, Flex, Heading,
} from "@chakra-ui/react";
import SearchBar from "components/SearchBar";
import { useEffect, useMemo, useState } from "react";
import NewHireJourneySelect from "components/Form/Select/NewHireJourneySingleSelect";
import { pullUniqueNewHireJourneys } from "helpers/newHireJourney";
import { NewHireJourney } from "models/onboarding";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { useNavbar } from "hooks/useNavbar";
import { FilterMeta } from "services/ApiClient/responseModel";
import { createQueryString } from "helpers/queryString";
import IssuesCardTable from "features/Issues/IssueCardTable";
import { useLocation, useSearchParams } from "react-router-dom";
import SmallArrowPagination from "components/Table/SmallArrowPagination";
import { range } from "lodash";
import { isError, isWarning } from "features/Issues/helpers";

export default function IssuesPage() {
  const { pathname } = useLocation();
  const lastPath = pathname.split("/").pop();
  const [issueTable, setIssueTable] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [title, setTitle] = useState("");
  const [initialJourneys, setInitialJourneys] = useState<NewHireJourney[]>([]);
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const [searchParams, setSearchParams] = useSearchParams();
  const newHireId = searchParams.get("newHireId");

  const updateNewHireId = (id: string) => {
    if (id) {
      searchParams.set("newHireId", id);
    } else {
      searchParams.delete("newHireId");
    }
    setSearchParams(searchParams);
  };

  const controller = new AbortController();

  const filters: FilterMeta[] = [];
  if (newHireId) {
    filters.push({
      fieldName: "scheduled_workflows.onboarding_journey_id",
      value: newHireId,
      operator: "=",
    });
  }

  const queryString = createQueryString({
    pagination: { pageIndex: 0, pageSize: 200 },
    filters,
    additionalQueries: searchText && searchText.length > 2 ? { q: searchText } : {},
  });

  const apiRoute = useMemo(
    () => (lastPath === "error" ? API_ROUTES.assignedActions.issues.past : API_ROUTES.assignedActions.issues.future),
    [lastPath],
  );

  const { data: actionsData, isLoading } = useQuery<AssignedAction[]>({
    queryKey: [QUERY_KEYS.assignedActions, "issues", lastPath, queryString],
    queryFn: async () => apiClient.get(`${apiRoute}?${queryString}`, controller.signal),
  });

  const filteredData = useMemo(() => {
    switch (lastPath) {
      case "error":
        setTitle("Failed Actions");
        return (actionsData || []).filter((item) => isError(item));
      case "warning":
        setTitle("Upcoming Failures");
        return (actionsData || []).filter((item) => isWarning(item));
      default:
        setTitle("Failed & Upcoming Failures");
        return actionsData || [];
    }
  }, [lastPath, actionsData, setTitle]);

  useEffect(() => {
    // only want this to happen on the intial page load
    if (initialJourneys.length === 0 && actionsData && actionsData.length !== 0) {
      setInitialJourneys(pullUniqueNewHireJourneys(actionsData));
    }
  }, [initialJourneys, setInitialJourneys, actionsData]);

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Issues",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Issues");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Box h="100%">
        <Flex w="100%" direction="column" alignItems="flex-start">
          <Card w="100%" mb="4">
            <CardBody>
              <Heading size="sm">{title}</Heading>
              <SearchBar
                borderColor="fg.muted"
                borderRadius="5px"
                border="1px"
                minWidth="500"
                name="actionsSearch"
                onInput={setSearchText}
                my="4"
              />
              <Flex justify="space-between">
                <Box width="400px">
                  <NewHireJourneySelect
                    newHireJourneys={initialJourneys}
                    selectedNewHireJourneyId={newHireId}
                    onChange={(e) => updateNewHireId(e as string)}
                    placeholder="Select by New Hire"
                    isClearable
                    useBasicStyles
                  />
                </Box>
                {issueTable && <SmallArrowPagination pb="0" pageSizeOptions={range(20, 100, 20)} table={issueTable} />}
              </Flex>
            </CardBody>
          </Card>
        </Flex>
        <IssuesCardTable setTable={setIssueTable} data={filteredData || []} isLoading={isLoading} />
      </Box>
    </AppPage>
  );
}
