import ROLE from "models/role";
import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import { Navigate } from "react-router-dom";
import useCaminoStore from "hooks/useCaminoStore";
import React from "react";
import { intersection } from "lodash";

export default function AuthenticatedRoute({
  permission,
  children,
}: {
  permission: ROLE[];
  children: React.ReactNode;
}) {
  const user = useCaminoStore((state) => state.currentUser);

  if (!user) {
    return <Navigate to={SHARED_ROUTES.login} />;
  }

  const isAuthorized = user.companyId === process.env.CAMINO_PREFIX_ID || intersection(permission, user.permission).length > 0;

  if (!isAuthorized) {
    console.log("AuthenticatedRoute: user does not have permission to view this page", permission, user.permission);
    return <Navigate to={SHARED_ROUTES.home} />;
  }

  return children;
}
