import { Button } from "@chakra-ui/react";
import ActionUpdateForm from "components/ModalForm/PathActionForm/ActionUpdateForm";
import { Action } from "models/automation";
import { PropsWithChildren } from "react";
import ModalFormWrapper from "../ModalFormWrapper";

interface UpdateActionButtonWithModalProps extends PropsWithChildren {
  action: Action | null;
}

export default function UpdateActionButtonWithModal({ action, children }: UpdateActionButtonWithModalProps) {
  return (
    <ModalFormWrapper
      eid={action?.id}
      modalHeader="Edit Action"
      button={(children as JSX.Element) || <Button>Edit Action</Button>}
      size="5xl"
    >
      <ActionUpdateForm action={action} />
    </ModalFormWrapper>
  );
}
