import { Box, Flex, Text } from "@chakra-ui/react";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import DashboardCard from "./DashboardCard";
import { DashboardStats } from "./models";

export default function SetUpIntegrationCard({
  connectedIntegrations,
  width,
}: {
  connectedIntegrations: DashboardStats["connectedIntegrations"];
  width: string;
}) {
  const hasSetUpATS = connectedIntegrations.applicantTrackingSystem;
  const hasSetUpMessageService = connectedIntegrations.messageService;
  let title = "Connect your Slack and Applicant Tracking Systems";
  if (hasSetUpATS && !hasSetUpMessageService) {
    title = "Connect Camino with your Slack";
  } else if (!hasSetUpATS && hasSetUpMessageService) {
    title = "Connect your Applicant Tracking System to Camino";
  }
  return (
    <DashboardCard
      title="Set Up Integrations"
      emoji="⚙️"
      buttonText="Get Started"
      destination={PEOPLE_TEAM_ROUTES.company.integrations.index}
      width={width}
    >
      <Flex direction="column" justifyContent="center" alignItems="start" h="100%">
        <Box as="p">
          <Text as="span" fontSize="lg">
            {title}
          </Text>
        </Box>
      </Flex>
    </DashboardCard>
  );
}
