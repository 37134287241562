import { ColumnSort, createColumnHelper } from "@tanstack/react-table";
import { scheduledTriggerSort } from "helpers/trigger";
import { BaseAssignedAction } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import CardTable, { ControlledCardTableProps } from "components/Table/CardTable/ControlledCardTable";
import { RandomEmptyIssueState } from "components/EmptyComponents/RandomEmptyState";
import ActionIssueCard from "features/Issues/ActionIssueCard";

const columnHelper = createColumnHelper<BaseAssignedAction | AssignedAction>();

const columnDefs = [
  columnHelper.accessor((row) => row, {
    id: "actionType",
    header: "Type",
  }),
  columnHelper.accessor((row) => row, {
    id: "target",
    header: "Recipient/Assignee",
  }),
  columnHelper.accessor((row) => row, {
    id: "taskName",
    header: "Name",
  }),
  columnHelper.accessor((row) => row, {
    id: "referenceTrigger",
    header: "Reference Trigger",
  }),
  columnHelper.accessor((row) => row, {
    id: "trigger",
    header: "Trigger",
    sortingFn: (a, b) => scheduledTriggerSort(a.original.trigger, b.original.trigger),
  }),
  columnHelper.accessor((row) => row, {
    id: "status",
    header: "Status",
  }),
];

interface IssuesCardTableProps {
  data: BaseAssignedAction[];
  tableDefaultSort?: ColumnSort;
  initialPagination?: { pageIndex: number; pageSize: number };
  isLoading?: boolean;
  getCardProps?: () => object;
  setTable?: () => object;
}

export default function IssuesCardTable({
  data,
  isLoading,
  tableDefaultSort,
  setTable,
  getCardProps,
  ...rest
}: IssuesCardTableProps) {
  const tableProps: Pick<
  ControlledCardTableProps<BaseAssignedAction | AssignedAction>,
  "columns" | "initialPagination" | "defaultSort" | "emptyStateComponent" | "data"
  > = {
    columns: columnDefs,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: tableDefaultSort ? [tableDefaultSort] : undefined,
    emptyStateComponent: <RandomEmptyIssueState />,
    data,
  };

  return (
    <CardTable
      {...tableProps}
      isLoading={isLoading}
      cardComponent={ActionIssueCard}
      getCardProps={getCardProps}
      setTable={setTable}
      includePagination={false}
      {...rest}
    />
  );
}

IssuesCardTable.defaultProps = {
  tableDefaultSort: { id: "trigger", desc: false },
  isLoading: false,
  initialPagination: undefined,
  getCardProps: () => ({}),
  setTable: () => ({}),
};
