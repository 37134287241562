/* eslint-disable react-hooks/exhaustive-deps */
import useStep from "hooks/useStep";
import { Box, Flex, Spacer } from "@chakra-ui/react";
import React, {
  PropsWithChildren, cloneElement, useCallback, useEffect, useMemo, useState,
} from "react";
import StepControl from "./StepControl";
import Step, { StepState } from "./Step";

/* eslint-disable no-underscore-dangle */
function typeOfComponent(component: React.ReactNode) {
  return (
    component?.props?.__TYPE
    || component?.type?.toString().replace("Symbol(react.fragment)", "react.fragment")
    || undefined
  );
}
/* eslint-enable no-underscore-dangle */

interface StepsProps extends PropsWithChildren {
  isLoading?: boolean;
  submitButtonTitle?: string;
  handleComplete: () => void;
  alignControl?: "top" | "bottom";
  controlProps?: Record<string, unknown>;
  defaultIndex?: number;
  onChange?: (stepIndex: number) => void;
}

const isStep = (child: React.ReactNode) => typeOfComponent(child) === "Step" || (React.isValidElement(child) && child.type === Step);

function Steps({
  children,
  submitButtonTitle,
  handleComplete,
  isLoading,
  alignControl,
  controlProps,
  defaultIndex,
  onChange,
}: StepsProps) {
  const steps = React.Children.toArray(children).filter(isStep);
  const numberOfSteps = steps.length;
  const [currentStepIndex, {
    setStep, canGoToPrevStep, canGoToNextStep, goToPrevStep, goToNextStep,
  }] = useStep({
    maxStep: steps.length - 1,
    initialStep: defaultIndex || 0,
  });
  const clonedSteps = steps.map((child, index) => cloneElement(child as JSX.Element, { isCurrentStep: index === currentStepIndex }));
  const [isNextStepLoading, setIsNextStepLoading] = useState(false);

  useEffect(() => {
    if (onChange) {
      onChange(currentStepIndex);
    }
  }, [currentStepIndex]);

  const advance = useCallback(
    (hasNextStep: boolean) => {
      if (hasNextStep) {
        goToNextStep();
      } else {
        handleComplete();
      }
    },
    [handleComplete, goToNextStep],
  );

  const handleNextClick = useCallback(
    (hasNextStep: boolean) => {
      const currentStep = clonedSteps[currentStepIndex] as React.ReactElement<any>;
      const { onClickNext } = currentStep.props;

      if (onClickNext) {
        onClickNext((state: StepState) => {
          switch (state) {
            case StepState.initial:
              setIsNextStepLoading(false);
              break;
            case StepState.loading:
              setIsNextStepLoading(true);
              break;
            case StepState.complete:
              setIsNextStepLoading(false);
              advance(hasNextStep);
              break;
            default:
              break;
          }
        });
      } else {
        advance(hasNextStep);
      }
    },
    [clonedSteps, currentStepIndex, setIsNextStepLoading, advance],
  );

  const prevButton = useMemo(
    () => ({
      title: "Previous",
      disabled: !canGoToPrevStep,
      handleClick: goToPrevStep,
    }),
    [canGoToPrevStep, goToPrevStep],
  );

  const nextButton = useMemo(
    () => ({
      title: submitButtonTitle && !canGoToNextStep ? submitButtonTitle : "Next",
      disabled: false,
      handleClick: () => handleNextClick(canGoToNextStep),
    }),
    [canGoToNextStep, handleNextClick, submitButtonTitle],
  );

  return alignControl === "top" ? (
    <Flex flexDir="column" w="100%" minH="600px" pt="2">
      <Box overflowY="visible">{clonedSteps}</Box>
      <Spacer />
      <StepControl
        isLoading={isLoading || false}
        prevButton={prevButton}
        nextButton={nextButton}
        currentStep={currentStepIndex}
        setStep={setStep}
        numberOfSteps={numberOfSteps}
        {...controlProps}
      />
      <Spacer />
      <Box overflowY="scroll">{clonedSteps}</Box>
    </Flex>
  ) : (
    <Flex flexDir="column" w="100%" minH="600px" pt="2">
      <Box overflowY="scroll">{clonedSteps}</Box>
      <Spacer />
      <StepControl
        isLoading={isLoading || isNextStepLoading}
        prevButton={prevButton}
        nextButton={nextButton}
        currentStep={currentStepIndex}
        setStep={setStep}
        numberOfSteps={numberOfSteps}
        {...controlProps}
      />
    </Flex>
  );
}

Steps.defaultProps = {
  isLoading: false,
  submitButtonTitle: undefined,
  controlProps: {},
  alignControl: "bottom",
  defaultIndex: null,
  onChange: (stepIndex: number) => {},
};

export { Steps, Step, StepState };
