import { useEffect, useRef, useState } from "react";
import Confetti from "react-confetti";

const LENGTH_OF_CONFETTI_SHOWING = 3000;

export default function SuccessConfetti() {
  const [showConfetti, setShowConfetti] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, LENGTH_OF_CONFETTI_SHOWING);
    return () => clearTimeout(timer);
  }, [setShowConfetti]);

  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  return (
    <Confetti width={windowSize.current[0]} height={windowSize.current[1]} numberOfPieces={showConfetti ? 200 : 0} />
  );
}
