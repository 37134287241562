/* eslint-disable import/prefer-default-export, @typescript-eslint/naming-convention, react/destructuring-assignment */
import { ISSUE_TYPE_OPTIONS } from "definitions/constants/issues";
import { AssignedActionIssue } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { titleize } from "helpers/string";
import {
  contentVariablesByGrouping,
  findMissingContentVariables,
  findMissingNamespaces,
  missingContentString,
} from "./helpers";
import {
  buildMissingContentVariableResolutionOptions,
  buildMissingNamespacesResolutionOptions,
  channelNotFoundResolutionOptions,
  defaultResolutionOptions,
  missingBuddyResolutionOptions,
  missingEmailAddressResolutionOptions,
  missingManagerResolutionOptions,
  missingMessageServiceResolutionOptions,
  missingMessageServiceUserResolutionOptions,
  missingPeopleTeamContactResolutionOptions,
} from "./ResolutionOptions";

const MISSING_MESSAGE_SERVICE_USER = (_action: AssignedAction, _issue: AssignedActionIssue) => ({
  issueType: ISSUE_TYPE_OPTIONS.TARGET_SLACK_USER_DOES_NOT_EXIST,
  humanReadable: "Slack user does not exist.",
  additionalContext:
    "The Slack user that Camino is trying to message does not exist. Either this person has not been added to Slack, or the work email address in Camino does not match the email address in Slack.",
  recommendation:
    "Ensure the user exists in Slack and that the work email address in Camino matches the email address in Slack.",
  resolutionOptions: missingMessageServiceUserResolutionOptions,
});

const MISSING_MESSAGE_SERVICE = (_action: AssignedAction, _issue: AssignedActionIssue) => ({
  issueType: ISSUE_TYPE_OPTIONS.SLACK_NOT_CONNECTED,
  humanReadable: "Slack is not connected to Camino.",
  additionalContext:
    "Camino has not been added to your Slack workspace. This means that Camino cannot send messages to Slack users.",
  recommendation: "Connect Camino to your Slack workspace.",
  resolutionOptions: missingMessageServiceResolutionOptions,
});

const CONTENT_ISSUE = (_action: AssignedAction, issue: AssignedActionIssue) => {
  const variables = findMissingContentVariables(issue.message);
  const missingVariables = contentVariablesByGrouping(variables);
  const multipleVariables = variables.length > 1;
  const variableOrVariables = multipleVariables ? "variables" : "variable";
  const isOrAre = multipleVariables ? "are" : "is";
  const missingVariablesText = variables.map((variable) => missingContentString(variable)).join(", ");
  const containsSlackMention = variables.some((value) => value[1] === "slack_mention");
  let recommendation = `Add the ${missingVariablesText}`;
  if (containsSlackMention) {
    recommendation += ". Ensure user is added in Slack and sync Slack Users with Camino.";
  }

  return {
    issueType: ISSUE_TYPE_OPTIONS.CONTENT_ISSUE,
    humanReadable: `Missing values for ${variableOrVariables} ${missingVariablesText}.`,
    additionalContext: `The ${missingVariablesText} ${variableOrVariables}, which ${isOrAre} in the content, ${isOrAre} missing. Camino cannot generate the content and process the action.`,
    recommendation,
    options: {
      missingVariables,
    },
    resolutionOptions: buildMissingContentVariableResolutionOptions(missingVariables),
  };
};

const MISSING_NAMESPACES = (_action: AssignedAction, issue: AssignedActionIssue) => {
  const namespaces = findMissingNamespaces(issue.message);
  const missingNamespacesText = namespaces.map((variable: string) => titleize(variable)).join(", ");

  return {
    issueType: ISSUE_TYPE_OPTIONS.UNDEFINED_TEMPLATE_NAMESPACE,
    humanReadable: `Missing values for ${missingNamespacesText}.`,
    additionalContext: `The content references missing values: ${namespaces.join(
      ", ",
    )}. Camino cannot generate the content and process the action.`,
    recommendation: `Add the ${missingNamespacesText}`,
    options: {
      namespaces,
    },
    resolutionOptions: buildMissingNamespacesResolutionOptions(namespaces),
  };
};

const CHANNEL_NOT_FOUND = (action: AssignedAction, _issue: AssignedActionIssue) => {
  const channelName = action?.workflowAction?.actionable?.targetable?.name;
  return {
    issueType: ISSUE_TYPE_OPTIONS.CHANNEL_NOT_FOUND,
    humanReadable: `Slack channel #${channelName} cannot be found.`,
    additionalContext:
      "Camino has not been added to the Slack channel it is trying to message, or there was an issue syncing Camino with this channel.",
    recommendation:
      "Add Camino to the Slack channel via Slack, and then 'sync channels' through the Camino integration page.",
    resolutionOptions: channelNotFoundResolutionOptions,
  };
};

const MISSING_EMAIL_ADDRESS = (action: AssignedAction, _issue: AssignedActionIssue) => {
  const emailType = action.emailType === "work_email" ? "work" : "personal";
  return {
    issueType: ISSUE_TYPE_OPTIONS.EMAIL_ADDRESS_MISSING,
    humanReadable: `The ${emailType} email address is missing for ${action?.targetable?.fullName}.`,
    additionalContext: `The ${emailType} email address is missing, so Camino cannot send an email.`,
    recommendation: `Add the ${emailType} email address to recipient's profile.`,
    resolutionOptions: missingEmailAddressResolutionOptions,
  };
};

const UNDELIVERABLE = (action: AssignedAction, issue: AssignedActionIssue) => {
  const { actionType } = action;
  if (actionType === "email") {
    return MISSING_EMAIL_ADDRESS(action, issue);
  } else if (action?.workflowAction?.actionable?.targetType === "message_service_channel") {
    return CHANNEL_NOT_FOUND(action, issue);
  }
  return MISSING_MESSAGE_SERVICE_USER(action, issue);
};

const ACTION_IS_SCHEDULED_IN_THE_PAST = (_action: AssignedAction, _issue: AssignedActionIssue) => ({
  issueType: ISSUE_TYPE_OPTIONS.ACTION_IS_SCHEDULED_IN_THE_PAST,
  humanReadable: "This action is scheduled in the past.",
  additionalContext: "The action will not be processed as the send day/time is in the past.",
  recommendation: "Reschedule the action or skip it if it's not important.",
  resolutionOptions: defaultResolutionOptions,
});

const REFERENCE_EVENT_OVERLAP = (_action: AssignedAction, _issue: AssignedActionIssue) => ({
  issueType: ISSUE_TYPE_OPTIONS.REFERENCE_EVENT_OVERLAP,
  humanReadable: "This immediate action is scheduled to occur after the start date.",
  additionalContext: "Sending an immediate action after the start date may not be desired.",
  recommendation: "Confirm you want to send this action, and send it now or schedule it, or skip it if not important.",
  resolutionOptions: defaultResolutionOptions,
});

const UNPAUSED_AFTER_TRIGGER_DATE = (_action: AssignedAction, _issue: AssignedActionIssue) => ({
  issueType: ISSUE_TYPE_OPTIONS.UNPAUSED_AFTER_TRIGGER_DATE,
  humanReadable: "This action was unpaused, but the day/time that it would be processed is already in the past.",
  additionalContext:
    "This happens if an action or path was paused, and then unpaused after the date the action was to be processed.",
  recommendation: "Sent this action now, reschedule it, or skip it",
  resolutionOptions: defaultResolutionOptions,
});

const BUDDY_HAS_NOT_BEEN_SELECTED = {
  issueType: ISSUE_TYPE_OPTIONS.MISSING_TARGET_ID,
  humanReadable: "The buddy has not been selected.",
  additionalContext:
    "The new hire does not have a buddy assigned, so any message or task for the buddy cannot be processed.",
  recommendation: "Remind the manager to assign a buddy, or assign a buddy yourself.",
  resolutionOptions: missingBuddyResolutionOptions,
};

const MANAGER_DOES_NOT_EXIST = {
  issueType: ISSUE_TYPE_OPTIONS.MISSING_TARGET_ID,
  humanReadable: "The new hire does not have a manager assigned.",
  additionalContext:
    "The new hire does not have a manager assigned, so any message or task for the manager cannot be processed.",
  recommendation: "Add the manager to the new hire's profile.",
  resolutionOptions: missingManagerResolutionOptions,
};

const PEOPLE_TEAM_CONTACT_DOES_NOT_EXIST = {
  issueType: ISSUE_TYPE_OPTIONS.MISSING_TARGET_ID,
  humanReadable: "The new hire does not have a people team contact assigned.",
  additionalContext:
    "The new hire does not have a people team contact assigned, so any message or task for the people team contact cannot be processed.",
  recommendation: "Add the people team contact to the new hire's profile.",
  resolutionOptions: missingPeopleTeamContactResolutionOptions,
};

function MISSING_TARGET_ID(action: AssignedAction, _issue: AssignedActionIssue) {
  switch (action.workflowAction.actionable.relationTarget) {
    case "buddy":
      return BUDDY_HAS_NOT_BEEN_SELECTED;
    case "manager":
      return MANAGER_DOES_NOT_EXIST;
    case "people_team_contact":
      return PEOPLE_TEAM_CONTACT_DOES_NOT_EXIST;
    default:
      break;
  }
  return null;
}

export const IssueTypeMap = {
  undefined_template_namespace: MISSING_NAMESPACES,
  trigger_at_in_past: ACTION_IS_SCHEDULED_IN_THE_PAST,
  reference_event_overlap: REFERENCE_EVENT_OVERLAP,
  unpaused_after_trigger_date: UNPAUSED_AFTER_TRIGGER_DATE,
  missing_target_id: MISSING_TARGET_ID,
  missing_message_service_user: MISSING_MESSAGE_SERVICE_USER,
  missing_message_service: MISSING_MESSAGE_SERVICE,
  missing_email_address: MISSING_EMAIL_ADDRESS,
  template_rendering: CONTENT_ISSUE,
  channel_not_found: CHANNEL_NOT_FOUND,
  undeliverable: UNDELIVERABLE,
};
