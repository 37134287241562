import { Button } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import ModalFormWrapper from "components/ModalForm/ModalFormWrapper";
import NotifierTargetForm from ".";

interface NotifierTargetFormButtonWithModalProps extends PropsWithChildren {
  notifierId: string;
}

export default function NotifierTargetFormButtonWithModal({ notifierId, children }: NotifierTargetFormButtonWithModalProps) {
  return (
    <ModalFormWrapper
      modalHeader="Add Notifier Recipient"
      button={(children as JSX.Element) || <Button>Add Recipient</Button>}
      size="xl"
    >
      <NotifierTargetForm notifierId={notifierId} />
    </ModalFormWrapper>
  );
}
