import AppPage from "layouts/AppPage";
import { useNavbar } from "hooks/useNavbar";
import { useEffect } from "react";
import InnerSidebar from "layouts/InnerSidebar";
import { useCurrentCompanyQuery } from "features/Company/hooks";
import CompanyInfoSection from "./CompanyInfoSection";
import CompanyResourcesSection from "./CompanyResourcesSection";

export default function PeopleTeamCompanyPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const { data: company } = useCurrentCompanyQuery();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Company Info",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Company Info");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <InnerSidebar>
        <CompanyInfoSection company={company} />
        <CompanyResourcesSection company={company} />
      </InnerSidebar>
    </AppPage>
  );
}
