import { Heading } from "@chakra-ui/react";
import NotificationsForm from "features/Notifiers/NotifierForm";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import InnerSidebar from "layouts/InnerSidebar";
import { useEffect } from "react";

export default function NotifiersPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Notifier Settings",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Notifier Settings");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <InnerSidebar>
        <Heading mb="8" size="sm" color="fg.subtle">
          Notifier Settings
        </Heading>
        <NotificationsForm />
      </InnerSidebar>
    </AppPage>
  );
}
