import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Menu, MenuButton, MenuList, MenuItem, Button, Portal,
} from "@chakra-ui/react";
import { BaseAssignedAction } from "models/automation";
import ScheduledActionButtonWithModal from "./ModalForm/ScheduledActionForm/ScheduledActionButtonWithModal";
import ButtonWithConfirm from "./Button/ButtonWithConfirm";

interface ScheduledActionRowButtonProps {
  status: string;
  testSendIsDisabled: boolean;
  scheduledAction: BaseAssignedAction;
  onPause: () => void;
  // onSkip: () => void;
  onRemove: () => void;
  onTestSend: () => void;
  onSendNow: () => void;
}

const CONFIRM_REMOVE = {
  header: "CONFIRM you want to remove this action",
  body: "This cannot be undone! Be sure you want to remove this action now.",
  confirmButtonText: "Confirm Remove",
};

const CONFIRM_SEND_NOW = {
  header: "CONFIRM sending the action now!",
  body: "This cannot be undone! Be sure you want to send this action now.",
  confirmButtonText: "Confirm Send Now",
};

export default function ScheduledActionRowButton({
  status,
  testSendIsDisabled,
  scheduledAction,
  onPause,
  onRemove,
  onTestSend,
  onSendNow,
}: ScheduledActionRowButtonProps) {
  return (
    <Menu>
      <MenuButton
        size="xs"
        isDisabled={status === "processed"}
        as={Button}
        colorScheme="gray"
        rightIcon={<ChevronDownIcon />}
      >
        More
      </MenuButton>
      <Portal>
        <MenuList>
          {/* MenuItems are not rendered unless Menu is open */}
          <ScheduledActionButtonWithModal scheduledAction={scheduledAction}>
            <MenuItem>Edit</MenuItem>
          </ScheduledActionButtonWithModal>
          <MenuItem onClick={onPause}>{status === "skipped" ? "Resume" : "Skip"}</MenuItem>
          <ButtonWithConfirm {...CONFIRM_REMOVE} handleConfirm={onRemove}>
            <MenuItem onClick={onRemove}>Remove</MenuItem>
          </ButtonWithConfirm>
          <MenuItem isDisabled={testSendIsDisabled} onClick={onTestSend}>
            Test Send
          </MenuItem>
          <ButtonWithConfirm {...CONFIRM_SEND_NOW} intent="confirmation" handleConfirm={onSendNow}>
            <MenuItem>Send Now</MenuItem>
          </ButtonWithConfirm>
        </MenuList>
      </Portal>
    </Menu>
  );
}
