import { HStack, Heading } from "@chakra-ui/react";
import { UseFormReturn } from "react-hook-form";
import { useEffect } from "react";
import { useModal } from "hooks/useModal";
import Modal from "components/Modal";
import PersonalizationTokenMenu from "components/FormElements/Fields/ActionRichTextAreaField/PersonalizationTokenMenu";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { NAME_FIELD } from "./fields";
import Trigger from "./Trigger";
import { SharedSchema } from "./schema";

export default function ActionInfo({
  workflowId,
  actionTypeName,
  form,
}: {
  workflowId: string;
  actionTypeName: string;
  form: UseFormReturn<SharedSchema>;
}) {
  const {
    formState: { errors },
    register,
    setFocus,
    setValue,
    watch,
    reset,
  } = form;
  const [targetType, triggerType, actionType, actionName] = watch(["targetType", "trigger.type", "actionType", "name"]);

  const tokenModal = useModal({
    id: "token",
    header: { title: "Insert Token" },
    size: "lg",
    isCentered: true,
  });

  useEffect(() => {
    setFocus("name");
    reset();
  }, [reset, setFocus]);

  useEffect(() => {
    let targetableType;
    if (targetType === "specific_user") {
      targetableType = "User";
    } else if (targetType === "message_service_channel") {
      targetableType = "MessageServiceChannel";
    }
    setValue("specificTargetableType", targetableType);
    setValue("specificTargetableId", undefined);
    setValue("relationTarget", undefined);
  }, [setValue, targetType]);

  useEffect(() => {
    setValue("trigger.data", undefined);
    if (triggerType === "dependency") {
      setValue("trigger.data", { event: "task.completed", dependencyType: "WorkflowAction", dependencyId: "" });
    } else if (triggerType === "timing") {
      setValue("trigger.data", { offsetDirection: "exact", referenceEvent: "start_date" });
    }
  }, [triggerType, setValue]);

  return (
    <>
      <Heading as="h3" size="xs" mb={4}>
        {`1. Name the ${actionTypeName}`}
      </Heading>
      <HStack width="100%">
        <TextInputField
          required
          {...NAME_FIELD}
          errors={errors}
          register={register}
          rightButton={
            actionType === "task" ? { text: "Personalize", width: "7.5rem", onClick: tokenModal.onOpen } : undefined
          }
        />
      </HStack>
      <Heading as="h3" size="xs" my={4}>
        2. Set up Trigger
      </Heading>
      <Trigger form={form} workflowId={workflowId} />
      <Modal modal={tokenModal}>
        <PersonalizationTokenMenu
          actionType={actionType}
          targetType={targetType}
          onSelect={(token) => {
            setValue(NAME_FIELD.name as keyof SharedSchema, `${actionName}{{${token.id}}}`, { shouldDirty: true });
            tokenModal.onClose();
          }}
        />
      </Modal>
    </>
  );
}
