import { SelectFieldProps } from "components/Form/typings";
import { useTheme } from "@chakra-ui/react";
import TimezoneSelect from "components/Form/Select/TimezoneSelect";
import FormField from "../FormField";

export default function TimezoneSelectField({
  name,
  label,
  helperText,
  control,
  errors,
  required,
  disabled,
  variant,
  formFieldProps = {},
  ...rest
}: SelectFieldProps) {
  const theme = useTheme();
  // Accessing default variant from the theme
  const formVariant = variant || theme.components.Form.defaultProps.variant;
  return (
    <FormField
      name={name}
      label={label}
      errors={errors}
      helperText={helperText}
      required={required}
      disabled={disabled}
      variant={formVariant}
      {...formFieldProps}
    >
      <TimezoneSelect control={control} name={name} variant={formVariant} {...rest} />
    </FormField>
  );
}
