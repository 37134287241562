/* eslint-disable import/prefer-default-export */

import { ToastPosition } from "@chakra-ui/react";

export const toastOptions = {
  defaultOptions: {
    position: "top" as ToastPosition,
    duration: 6000,
    isClosable: true,
  },
};
