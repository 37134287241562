import { relationTargetableField } from "components/Forms/PathActions/Shared/fields";

const TITLE_FIELD = {
  type: "text",
  label: "Title",
  placeholder: "Title",
  name: "title",
};

const DESCRIPTION_FIELD = {
  type: "text",
  label: "Task Description",
  name: "description",
};

const MESSAGE_SUGGESTION_FIELD = {
  type: "text",
  label: "Message Suggestion",
  placeholder: "Message Suggestion",
  name: "messageBody",
};

const MESSAGE_TARGET_FIELD = relationTargetableField({
  name: "messageTarget",
  placeholder: "Message Target",
  label: "Message Target",
});

export { TITLE_FIELD, DESCRIPTION_FIELD, MESSAGE_SUGGESTION_FIELD, MESSAGE_TARGET_FIELD };
