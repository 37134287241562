import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { MultiStatCard } from "components/PeopleTeamDashboard/NewHireStatsRow";
import { createFiltersQueryString, createPaginationQueryString } from "helpers/queryString";
import { backendTimeFormat } from "helpers/time";
import { NewHireJourney } from "models/onboarding";
import moment from "moment";
import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";

interface StatValue {
  label: string;
  value: string;
}

interface MultiStatValue {
  heading: string;
  stat1: StatValue;
  stat2: StatValue;
  stat3: StatValue;
}

export default function PeopleTeamDashboardStats() {
  const beginDate = moment().add(-3, "months").toDate();
  const endDate = moment().add(31, "days").toDate();
  const [stats, setStats] = useState<MultiStatValue[]>([]);

  const { data } = useQuery<NewHireJourney[]>({
    queryKey: [QUERY_KEYS.newHireJourneys, "PeopleTeamDashboardStat"],
    queryFn: () => apiClient.get(
      generatePath(
        `${API_ROUTES.newHireJourneys.base}?${createFiltersQueryString([
          {
            fieldName: "startDate",
            value: backendTimeFormat(beginDate),
            operator: ">",
          },
          {
            fieldName: "startDate",
            value: backendTimeFormat(endDate),
            operator: "<=",
          },
        ])}&${createPaginationQueryString(1, 300)}`,
      ),
    ),
  });

  useEffect(() => {
    const startsWithOnboardingPlans = (newHireJourneys: NewHireJourney[]) => newHireJourneys.filter((newHireJourney) => !!newHireJourney.onboardingPlanUrl);
    if (data) {
      const previousStarts = data.filter(
        (newHireJourney) => newHireJourney?.startDate && moment(newHireJourney?.startDate).toDate() <= moment().toDate(),
      );
      const previousStartsWithPlans = startsWithOnboardingPlans(previousStarts);
      const futureStarts = data.filter(
        (newHireJourney) => newHireJourney?.startDate && moment(newHireJourney?.startDate).toDate() > moment().toDate(),
      );
      const futureStartsWithPlans = startsWithOnboardingPlans(futureStarts);
      setStats([
        {
          heading: "Next Month",
          stat1: { value: futureStarts.length.toString(), label: "New Hires" },
          stat2: { value: futureStartsWithPlans.length.toString(), label: "Personalized Plans" },
          stat3: { value: !futureStarts.length ? "0" : `${Math.round((futureStartsWithPlans.length / futureStarts.length) * 100)}%`, label: "Coverage" },
        },
        {
          heading: "Past 3 Months",
          stat1: { value: previousStarts.length.toString(), label: "New Hires" },
          stat2: { value: previousStartsWithPlans.length.toString(), label: "Personalized Plans" },
          stat3: { value: !previousStarts.length ? "0" : `${Math.round((previousStartsWithPlans.length / previousStarts.length) * 100)}%`, label: "Coverage" },
        },
      ]);
    }
  }, [data, setStats]);

  return <MultiStatCard stats={stats} />;
}
