import { Flex, Heading, VStack } from "@chakra-ui/react";
import AssignedPathCard from "components/Card/AssignedPathCard";
import { NoPathsAssignedYetState } from "components/EmptyComponents/RandomEmptyState";
import { BaseAssignedPath } from "models/automation";
import { ExtendedNewHireJourney } from "models/joinedModels";

interface PathsTabsProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function PathsTabs({ newHireJourney }: PathsTabsProps) {
  return (
    <VStack align="flex-start">
      <Heading as="h6" size="xxs">
        Paths
      </Heading>
      <Flex p="1" width="100%" direction="column" overflow="scroll" maxH="320px">
        {newHireJourney?.assignedPaths?.length ? (
          newHireJourney?.assignedPaths.map((assignedPath: BaseAssignedPath) => (
            <AssignedPathCard key={assignedPath.id} assignedPath={assignedPath} />
          ))
        ) : (
          <Flex alignSelf="flex-start" width="800px" height="400px">
            <NoPathsAssignedYetState
              imageProps={{ maxWidth: "160px", maxHeight: "160px" }}
              textProps={{ fontSize: "md" }}
            />
          </Flex>
        )}
      </Flex>
    </VStack>
  );
}
