import {
  Box,
  Container,
  Flex,
  HStack,
  IconButton,
  useBreakpointValue,
  VStack,
  Button,
  Spacer,
  Text,
  Heading,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import AvatarDropdown from "components/AvatarDropdown";
import useCaminoStore from "hooks/useCaminoStore";
import { useNavigate } from "react-router-dom";
import { API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { User } from "models/user";
import { useQueryClient } from "@tanstack/react-query";
import Breadcrumbs from "components/Breadcrumbs";
import useNavbarStore from "hooks/useNavbar";
import ROLE from "models/role";
import NewHireButtonWithModal from "features/NewHireJourney/NewHireForm/NewHireButtonWithModal";

function ImpersonatingBanner() {
  const setValue = useCaminoStore((state) => state.setValue);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleClick = async () => {
    const { company, ...user } = await apiClient.delete<User>(API_ROUTES.admin.users.impersonations.base);
    queryClient.clear();
    setValue({
      currentUser: user,
      currentCompany: company,
      currentRole: user.permission[0],
      isImpersonating: false,
    });
    navigate(SHARED_ROUTES.home);
  };

  return (
    <HStack
      bg="sortaOrange.500"
      color="text.warning"
      fontSize="sm"
      fontWeight="bold"
      px="2"
      py="1"
      rounded="md"
      textTransform="uppercase"
      width="100%"
    >
      <Text>Impersonating User</Text>
      <Spacer />
      <Button onClick={handleClick}>Stop Impersonating</Button>
    </HStack>
  );
}

function Navbar() {
  const [user, isImpersonating] = useCaminoStore((state) => [state.currentUser, state.isImpersonating]);
  const isDesktop = useBreakpointValue({
    base: true,
    sm: false,
    md: false,
    lg: true,
  });
  const [breadcrumbs, pageTitle] = useNavbarStore((state) => [state.breadcrumbs, state.pageTitle]);

  return (
    <Box as="section" pb={{ base: "4", md: "6" }}>
      <Box as="nav" width={{ base: "full" }} bg="bg.surface">
        <Container py={{ base: "1", lg: "2" }} ml="0">
          {isImpersonating && <ImpersonatingBanner />}
          {isDesktop ? (
            <HStack spacing="10" justify="space-between" alignItems="center">
              <VStack alignItems="start" py="2">
                {pageTitle ? (
                  <Heading color="fg.muted" size="xs">
                    {pageTitle}
                  </Heading>
                ) : (
                  <Heading color="fg.muted" size="xs">
                    {" "}
                  </Heading>
                )}
                {breadcrumbs?.length ? (
                  <Breadcrumbs mt={-1} breadcrumbInfos={breadcrumbs} includeHome />
                ) : (
                  <Breadcrumbs mt={-1} breadcrumbInfos={[]} />
                )}
              </VStack>
              <Flex justify="end" align="center" flex="1">
                {user && (
                  <VStack spacing="2">
                    <HStack spacing="6">
                      {user.permission && user.permission.includes(ROLE.PEOPLE_TEAM) && <NewHireButtonWithModal />}
                      <AvatarDropdown />
                    </HStack>
                  </VStack>
                )}
              </Flex>
            </HStack>
          ) : (
            <HStack spacing="10" justify="space-between">
              <IconButton variant="tertiary" icon={<FiMenu fontSize="1.25rem" />} aria-label="Open Menu" />
            </HStack>
          )}
        </Container>
      </Box>
    </Box>
  );
}

export default Navbar;
