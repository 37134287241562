/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import {
  CardBody, Skeleton, Flex, LinkBox, LinkOverlay,
} from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";
import { relativeDate } from "helpers/time";
import { pendingNewHireJourneyPath } from "helpers/url";
import { CaminoHeading } from "components/Heading";
import { CardField } from "components/DataDisplay/Card";
import CaminoCalendar from "components/Icon/CaminoCalendar";
import CaminoManager from "components/Icon/CaminoManager";
import { BlueBorderCardWithHover } from "components/Card/BlueBorderCard";
import { ExtendedNewHireJourney } from "models/joinedModels";
import LinkButton from "components/Button/LinkButton";

interface PendingNewHireJourneyCardProps {
  rowData: ExtendedNewHireJourney;
  getCardProps: () => object;
}

export default function PendingNewHireJourneyCard({
  rowData: newHireJourney,
  getCardProps,
  ...rest
}: PendingNewHireJourneyCardProps) {
  if (!newHireJourney) {
    return <Skeleton height="100px" />;
  }
  return (
    <LinkBox
      as={BlueBorderCardWithHover}
      p="0"
      direction="row"
      boxShadow="md"
      mb="2"
      maxW="1000px"
      w="100%"
      overflow="hidden"
      _hover={{
        borderColor: "var(--chakra-colors-brand-DEFAULT)",
        borderWidth: "2px",
      }}
      {...rest}
    >
      <CardBody minW="600px" display="flex" py="5" px="7">
        <Flex direction="column" align="flex-start" gap="2" width="100%">
          <Flex justify="space-between" align="center" width="100%">
            <Flex display="table" width="100%">
              <Flex
                display="table-cell"
                whiteSpace="normal"
                wordBreak="break-word"
                textAlign="left"
                verticalAlign="middle"
                width="200px"
              >
                <CaminoHeading textAlign="start" flexShrink={0} marginRight={4}>
                  <LinkOverlay as={RouterLink} to={pendingNewHireJourneyPath(newHireJourney)}>
                    {`${newHireJourney?.user.fullName}`}
                  </LinkOverlay>
                </CaminoHeading>
              </Flex>
              <Flex
                display="table-cell"
                whiteSpace="normal"
                wordBreak="break-word"
                textAlign="left"
                verticalAlign="middle"
              >
                <Flex align="center" gap={16}>
                  <CardField icon={CaminoCalendar} label="Start Date">
                    {`${newHireJourney?.startDate ? relativeDate(newHireJourney.startDate) : "TBD"}`}
                  </CardField>
                  {newHireJourney.user?.manager?.fullName && (
                    <CardField icon={CaminoManager} label="Manager">
                      {newHireJourney.user?.manager?.fullName}
                    </CardField>
                  )}
                </Flex>
              </Flex>
            </Flex>
            <LinkButton to={pendingNewHireJourneyPath(newHireJourney)}>Add Info & Start Onboarding</LinkButton>
          </Flex>
        </Flex>
      </CardBody>
    </LinkBox>
  );
}
