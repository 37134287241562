import { Tag, TagProps } from "@chakra-ui/react";
import {
  scheduledActionToStatusColor,
  scheduledActionStatusConverter,
  isElevated,
} from "helpers/scheduledWorkflowConverters";
import { statusDateTime } from "helpers/status";
import { AssignedAction } from "models/automation/scheduledWorkflow";

interface AssignedActionStatusProps extends TagProps {
  assignedAction: AssignedAction;
}

export default function AssignedActionStatus({ assignedAction, ...rest }: AssignedActionStatusProps) {
  if (assignedAction.status === "awaiting_info" && isElevated(assignedAction)) {
    // eslint-disable-next-line no-param-reassign
    assignedAction.status = "needs_attention";
  }
  if (!assignedAction || ["ready", "awaiting_info"].includes(assignedAction.status)) {
    return null;
  }
  let statusAt = "";
  if (["processed", "error", "skipped"].includes(assignedAction.status)) {
    statusAt = ` ${statusDateTime(assignedAction.statusUpdatedAt)}`;
  }
  return (
    <Tag variant="solid" size="md" colorScheme={scheduledActionToStatusColor(assignedAction)} {...rest}>
      {`${scheduledActionStatusConverter(assignedAction?.status)}${statusAt}`}
    </Tag>
  );
}
