import {
  Image,
  Box,
  Card,
  Flex,
  Button,
  useDisclosure,
  Modal,
  ModalContent,
  ModalBody,
  Skeleton,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  Avatar,
} from "@chakra-ui/react";
import { capitalize } from "lodash";
import { Company } from "models/company";
import CompanyDefaultsForm from "components/ModalForm/CompanyDefaultsForm";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import { InfoColumn, InfoDisplayValue } from "components/DataDisplay/InfoDisplayValue";

interface CompanyInfoCardProps {
  company?: Company;
  editClickedHandler?: () => void;
}

function CompanyInfoCard({ company = undefined, editClickedHandler = () => null }: CompanyInfoCardProps) {
  return (
    <Skeleton isLoaded={!!company}>
      <Card direction="column" p="25px 35px" maxW={1300}>
        <Flex justifyContent="space-between" w="100%" alignItems="center" p={5}>
          <Box opacity="70%" fontSize="2xl">
            Company Info
          </Box>
          <Button onClick={editClickedHandler} px="20px">
            Edit
          </Button>
        </Flex>
        <Flex direction={{ base: "column", xl: "row" }} justifyContent="space-around" width="100%" h="100%">
          <Flex direction="column" alignItems="center" pb="35px">
            <Image
              boxSize="200px"
              objectFit="contain"
              src={company?.logoUrl}
              fallback={(
                <Flex direction="column" align="center">
                  <Avatar size="2xl" name={company?.name} icon={<HiOutlineOfficeBuilding fontSize="3rem" />} />
                  <Box textAlign="center" fontWeight="bold" fontSize="4xl">
                    {company?.name}
                  </Box>
                </Flex>
              )}
            />
          </Flex>
          <Flex direction={{ base: "column", lg: "row" }} justifyContent="space-around" width="100%" h="100%">
            <InfoColumn mt={{ base: "2", lg: "0" }}>
              <InfoDisplayValue value={capitalize(company?.name)} label="Name" />
              <InfoDisplayValue value={company?.websiteUrl} label="Website" pt={2} />
              <InfoDisplayValue value={company?.domain} label="Domain" />
            </InfoColumn>
            <InfoColumn mt={{ base: "2", lg: "0" }}>
              <InfoDisplayValue value={company?.suggestedEmailFormat} label="Suggested Email Format" />
              <InfoDisplayValue value={company?.defaultTimezone} label="Default Time Zone" pt={2} />
              <InfoDisplayValue value={company?.defaultTriggerTimeOfDay} label="Default Trigger Time" />
            </InfoColumn>
          </Flex>
        </Flex>
      </Card>
    </Skeleton>
  );
}

export default function CompanyInfoSection({ company = undefined }: CompanyInfoSectionProps) {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Box>
      <CompanyInfoCard company={company} editClickedHandler={onOpen} />
      <Modal size="5xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Update Company Defaults</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CompanyDefaultsForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
