const colors = {
  brand: {
    DEFAULT: "#58C5EE",
    50: "#ECF8FD",
    100: "#ECF8FD",
    200: "#C7ECF9",
    300: "#A2DFF6",
    400: "#7DD2F2",
    500: "#59C5EE",
    600: "#25B3E9",
    700: "#1492C2",
    800: "#0F6C8F",
    900: "#09465C",
    950: "#073243",
  },
  boldBlue: {
    DEFAULT: "#9ACDFF",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#F4F8FF",
    300: "#ECF5FF",
    400: "#C3E1FF",
    500: "#9ACDFF",
    600: "#62B1FF",
    700: "#2A95FF",
    800: "#007AF1",
    900: "#005DB9",
    950: "#004F9D",
  },
  ocean: {
    DEFAULT: "#C7E2D3",
    50: "#E2F0E8",
    100: "#E2F0E8",
    200: "#E2F0E8",
    300: "#FDFEFD",
    400: "#E2F0E8",
    500: "#C7E2D3",
    600: "#A2CFB6",
    700: "#7DBC99",
    800: "#58A97C",
    900: "#448461",
    950: "#3B7253",
  },
  coral: {
    DEFAULT: "#8af3cf",
    50: "#eefdf8",
    100: "#defcf1",
    200: "#cdfaea",
    300: "#bcf8e3",
    400: "#acf7dd",
    500: "#9bf5d6",
    600: "#8af3cf",
    700: "#79f1c8",
    800: "#69f0c1",
    900: "#58eeba",
    950: "#4fd6a7",
    1000: "#7cdaba",
    1050: "#46be95",
  },
  mellowYellow: {
    DEFAULT: "#F6EFA2",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FDFCEC",
    400: "#FAF5C7",
    500: "#F6EFA2",
    600: "#F1E66F",
    700: "#ECDD3C",
    800: "#DBCA15",
    900: "#A79B10",
    950: "#8E830E",
  },
  neutral: {
    DEFAULT: "#FBE8C2",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FFFFFF",
    400: "#FEF6E8",
    500: "#FBE8C2",
    600: "#F8D48D",
    700: "#F4C059",
    800: "#F1AC24",
    900: "#CF8F0E",
    950: "#B57C0C",
  },
  sortaOrange: {
    DEFAULT: "#EAB840",
    50: "#FCF5E5",
    100: "#FAEFD3",
    200: "#F6E1AE",
    300: "#F2D38A",
    400: "#EEC665",
    500: "#EAB840",
    600: "#DAA118",
    700: "#A77C12",
    800: "#75560D",
    900: "#423107",
    950: "#291E05",
  },
  rose: {
    DEFAULT: "#EABDB8",
    50: "#FFFFFF",
    100: "#FFFFFF",
    200: "#FFFFFF",
    300: "#FDF8F7",
    400: "#F3DAD7",
    500: "#EABDB8",
    600: "#DD958D",
    700: "#D06D61",
    800: "#C14739",
    900: "#95372C",
    950: "#802F26",
  },
  notBlack: {
    DEFAULT: "#393939",
    50: "#959393",
    100: "#8B8B8B",
    200: "#767676",
    300: "#626262",
    400: "#4D4D4D",
    500: "#393939",
    600: "#1D1D1D",
    700: "#010101",
    800: "#000000",
    900: "#000000",
    950: "#000000",
  },
  linkBlue: {
    DEFAULT: "#4097EB",
    500: "#4097EB",
  },
  helperText: {
    DEFAULT: "#959393",
    500: "#959393",
  },
  tableUnderlineBorder: {
    DEFAULT: "#D9D9D9",
    500: "#D9D9D9",
  },
  hasValueBorder: {
    DEFAULT: "#BBDDFE",
    500: "#BBDDFE",
  },
  multiSelectBlue: {
    DEFAULT: "rgba(89, 197, 238, 0.1)",
    500: "rgba(89, 197, 238, 0.1)",
  },
  successGreen: {
    DEFAULT: "#77D1A1",
    500: "#77D1A1",
  },
  error: {
    DEFAULT: "#EC7464",
    500: "#EC7464",
  },
  creamBg: {
    DEFAULT: "#FDFDFD",
    500: "#FDFDFD",
  },
  dateYellow: {
    DEFAULT: "rgba(234, 184, 64)",
    500: "rgba(234, 184, 64)",
    background: "rgba(234, 184, 64, 0.05)",
  },
  dateBlue: {
    DEFAULT: "rgba(64, 151, 235, 1)",
    500: "rgba(64, 151, 235, 1)",
    background: "rgba(64, 151, 235, 0.05)",
  },
  dateGreen: {
    DEFAULT: "rgba(119, 209, 161, 1)",
    500: "rgba(119, 209, 161, 1)",
    background: "rgba(119, 209, 161, 0.05)",
  },
  border: {
    default: "gray.200",
  },
} as const;

export type Colors = typeof colors;
export default colors;
