import React, { PropsWithChildren } from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  Button,
  useDisclosure,
} from "@chakra-ui/react";

interface ButtonWithConfirmProps extends PropsWithChildren {
  header: string;
  body: string | JSX.Element;
  handleConfirm: () => void;
  confirmButtonText: string;
  buttonText?: string;
  intent?: "warning" | "confirmation";
  isDisabled?: boolean;
  buttonProps?: object;
}

export default function ButtonWithConfirm({
  buttonText,
  header,
  body,
  handleConfirm,
  confirmButtonText,
  intent,
  isDisabled,
  children,
  buttonProps,
}: ButtonWithConfirmProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const onConfirm = () => {
    handleConfirm();
    onClose();
  };

  const intentStyling = intent === "warning" ? { colorScheme: "red", variant: "outline" } : { colorScheme: "brand" };

  return (
    <>
      {children ? (
        React.cloneElement(children as JSX.Element, { onClick: onOpen, ...(isDisabled ? { isDisabled: true } : {}) })
      ) : (
        <Button {...intentStyling} onClick={onOpen} {...(isDisabled ? { isDisabled: true } : {})} {...buttonProps}>
          {buttonText}
        </Button>
      )}

      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {header}
            </AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>{body}</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme={intentStyling.colorScheme} onClick={onConfirm}>
                {confirmButtonText}
              </Button>
              <Button ref={cancelRef} variant="outline" colorScheme="gray" onClick={onClose} ml={3}>
                Cancel
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

ButtonWithConfirm.defaultProps = {
  intent: "warning",
  buttonText: null,
  isDisabled: null,
  buttonProps: {},
};
