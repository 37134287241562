/* eslint-disable no-nested-ternary */
import {
  Flex, StackProps, Text, TextProps,
} from "@chakra-ui/react";
import { isValidElement } from "react";
import { CopyableValue } from "./Copyable";

interface FieldRowProps extends StackProps {
  fieldName: string;
  displayValue: string | JSX.Element;
  copyableValue?: string;
  headerProps?: TextProps;
}

export default function FieldRow({
  fieldName, displayValue, copyableValue, headerProps, ...rest
}: FieldRowProps) {
  if (copyableValue && isValidElement(displayValue)) {
    console.error("copyableValue is true and displayValue is not a string");
  }
  return (
    <Flex width="100%" direction="column" spacing="0.5" fontSize="sm" {...rest}>
      <Text color="fg.emphasized" fontWeight="medium" {...headerProps}>
        {fieldName}
      </Text>
      {copyableValue ? (
        <CopyableValue displayValue={displayValue as string} copyableValue={copyableValue} />
      ) : isValidElement(displayValue) ? (
        displayValue
      ) : (
        <Text color="fg.muted">{displayValue}</Text>
      )}
    </Flex>
  );
}

FieldRow.defaultProps = {
  copyableValue: null,
  headerProps: {},
};
