import {
  Box,
  HStack,
  Icon,
  Text,
  useRadioGroup,
  useColorModeValue,
  useId,
  UseRadioProps,
  useRadio,
  ButtonGroupProps,
} from "@chakra-ui/react";
import { Children, cloneElement, isValidElement, ReactElement, useMemo } from "react";
import { IconType } from "react-icons";

interface SegmentedControlGroupProps<T> extends Omit<ButtonGroupProps, "onChange" | "variant" | "isAttached"> {
  name: string;
  value: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
  children: React.ReactNode;
}

export function SegmentedControlGroup<T extends string>(props: SegmentedControlGroupProps<T>) {
  const { children, name, defaultValue, value, onChange, isDisabled, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const buttons = useMemo(
    () =>
      Children.toArray(children)
        .filter<ReactElement<SegmentedControlButtonProps>>(isValidElement)
        .map((button, index, array) => {
          const isFirstItem = index === 0;
          const isLastItem = array.length === index + 1;

          const styleProps = {
            ...(isFirstItem && !isLastItem ? { borderRightRadius: 0 } : {}),
            ...(!isFirstItem && isLastItem ? { borderLeftRadius: 0 } : {}),
            ...(!isFirstItem && !isLastItem ? { borderRadius: 0 } : {}),
            ...(!isLastItem ? { mr: "-px" } : {}),
          };

          return cloneElement(button, {
            ...styleProps,
            radioProps: getRadioProps({
              value: button.props.value,
              disabled: isDisabled || button.props.isDisabled,
            }),
          });
        }),
    [children, getRadioProps, isDisabled],
  );

  return (
    <HStack {...getRootProps(rest)} spacing="20px">
      {buttons}
    </HStack>
  );
}

SegmentedControlGroup.defaultProps = {
  onChange: () => {},
  defaultValue: null,
};

interface SegmentedControlButtonProps {
  value: string;
  icon?: IconType;
  radioProps?: UseRadioProps;
  children: React.ReactNode;
}

export function SegmentedControlButton(props: SegmentedControlButtonProps) {
  const { radioProps, icon: ButtonIcon, children, ...rest } = props;
  const id = useId(undefined, "radio-button");
  const selectedColor = useColorModeValue("blue.500", "blue.200");
  const unselectedColor = useColorModeValue("gray.600", "gray.400");

  const { getInputProps, getRadioProps, state } = useRadio(radioProps);

  const inputProps = getInputProps();
  const radioControlProps = getRadioProps();

  return (
    <Box
      as="label"
      cursor="pointer"
      {...radioControlProps}
      {...rest}
    >
      <input {...inputProps} aria-labelledby={id} style={{ display: "none" }} />
      <HStack
        spacing="4px"
        color={state.isChecked ? selectedColor : unselectedColor}
        position="relative"
        pb="2"
        _hover={{ color: selectedColor }}
      >
        {ButtonIcon && <Icon as={ButtonIcon} boxSize="5" />}
        <Text fontWeight={state.isChecked ? "bold" : "normal"}>{children}</Text>
        {state.isChecked && (
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            height="2px"
            bg={selectedColor}
          />
        )}
      </HStack>
    </Box>
  );
}

SegmentedControlButton.defaultProps = {
  icon: null,
  radioProps: {},
};
