/* eslint-disable implicit-arrow-linebreak */
import { get, keyBy, orderBy } from "lodash";
import {
  Card, CardBody, Flex, FormLabel, Heading, Skeleton, Switch, Text, useColorModeValue,
} from "@chakra-ui/react";
import { QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useCallback, useMemo, useState } from "react";
import { Notifier, NotifierTarget } from "models/notifiers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import useOnUpdate from "hooks/useOnUpdate";
import NotifierTargetTag from "./NotifierTargetTag";
import NotifierTargetFormButtonWithModal from "./NotifierTargetForm/NotifierTargetFormButtonWithModal";

const NotifierSections = ["Notifiers::NewHireImportNotifier", "Notifiers::IssuesNotifier"];

const NotificationSections = {
  "Notifiers::NewHireImportNotifier": {
    name: "enabled",
    notifierType: "Notifiers::NewHireImportNotifier",
    title: "New Hire Import Notification",
    helperText: "These notifications will be sent when a new hire is imported into Camino via an integration.",
  },
  "Notifiers::IssuesNotifier": {
    name: "enabled",
    notifierType: "Notifiers::IssuesNotifier",
    title: "Issues Notification",
    helperText: "These notifications will be sent when an issue arises that requires attention.",
  },
};

interface NotifierFormSectionProps {
  name: string;
  title: string;
  helperText: string;
  notifierSection: Notifier;
  notifierType: (typeof NotifierSections)[number];
}

function sortNotifierTargets(notifierTargets: NotifierTarget[]) {
  return notifierTargets.sort((a, b) => {
    // Sort by deliveryMethod
    if (a.deliveryMethod && b.deliveryMethod && a.deliveryMethod !== b.deliveryMethod) {
      return a.deliveryMethod.localeCompare(b.deliveryMethod);
    }

    // If deliveryMethod is the same, sort by recipientType
    if (a.recipientType && b.recipientType && a.recipientType !== b.recipientType) {
      return a.recipientType.localeCompare(b.recipientType);
    }

    // If recipientType is the same, sort by emailType
    if (a.deliveryMethod === "email" && a.emailType && b.emailType && a.emailType !== b.emailType) {
      return a.emailType.localeCompare(b.emailType);
    }

    // If everything is equal, maintain the original order
    return 0;
  });
}

function NotifierFormSection({
  title,
  name,
  notifierType,
  helperText,
  notifierSection,
}: NotifierFormSectionProps) {
  const [isEnabled, setIsEnabled] = useState(notifierSection.enabled);
  const [notifierId, setNotifierId] = useState(notifierSection.id);
  const notifyTargets = useMemo(
    () => sortNotifierTargets(notifierSection.notifierTargets || []),
    [notifierSection.notifierTargets],
  );
  const queryClient = useQueryClient();
  const bgColor = useColorModeValue("gray.200", "gray.600");

  const { mutate: createNewNotifier, isPending: isLoadingCreateNewNotifier } = useMutation(
    {
      mutationFn: async () => apiClient.post<Notifier>(generatePath(API_ROUTES.notifiers.base), { enabled: true, type: notifierType }),
      onSuccess: (newNotifier) => {
        setNotifierId(newNotifier.id);
        queryClient.setQueryData<Notifier[]>([QUERY_KEYS.notifiers], (prevData) => {
          if (!prevData) return [newNotifier]; // Handle case where data is null or undefined
          return [...prevData, newNotifier];
        });
      },
      onError: (e) => {
        console.log(e);
      },
    },
  );

  const { mutate: updateNotifier, isPending: isLoadingUpdateNotifier } = useMutation(
    {
      mutationFn: async (id: string) => apiClient.put<Notifier>(generatePath(API_ROUTES.notifiers.show, { id }), { enabled: isEnabled }),
      onSuccess: (updatedNotifier) => {
        queryClient.setQueryData<Notifier[]>([QUERY_KEYS.notifiers], (prevData) => {
          if (!prevData) return [updatedNotifier]; // Handle case where data is null or undefined
          return prevData.map((notifier) =>
            (notifier.id === updatedNotifier.id ? { ...notifier, ...updatedNotifier } : notifier));
        });
      },
      onError: (e) => {
        console.log(e);
      },
    },
  );

  useOnUpdate(() => {
    if (!notifierId) {
      createNewNotifier();
    } else {
      updateNotifier(notifierId);
    }
  }, [isEnabled]);

  const handleSwitchChange = useCallback(() => {
    setIsEnabled(!isEnabled);
  }, [setIsEnabled, isEnabled]);

  return (
    <Card minWidth="900px" width="calc(100% - 200px)" mt="4">
      <CardBody>
        <Flex direction="column">
          <Flex justify="space-between">
            <Heading mb="2" size="xs">
              {title}
            </Heading>
            {notifierId && <NotifierTargetFormButtonWithModal notifierId={notifierId} />}
          </Flex>
          {helperText && <Text mb="2">{helperText}</Text>}
          <Flex alignItems="center" mt="2">
            <FormLabel>Enabled?</FormLabel>
            <Switch
              id={name}
              colorScheme="brand"
              isChecked={isEnabled}
              onChange={handleSwitchChange}
              disabled={isLoadingCreateNewNotifier || isLoadingUpdateNotifier}
            />
          </Flex>
          {/* <SwitchField name={name} label={titleize(name)} sideLayout overrideHelperSpace errors={errors} control={control} /> */}
          {(isEnabled || !!notifyTargets.length) && (
            <Flex mt="2" bg={bgColor} gap="2" borderRadius="4px" p="2" minH="40px" width="100%" flexWrap="wrap">
              {notifyTargets?.length ? (
                orderBy(notifyTargets, "createdAt").map((target) => (
                  <NotifierTargetTag key={target.id} notifierTarget={target} />
                ))
              ) : (
                <Text>No notification recipients! Add your first one!</Text>
              )}
            </Flex>
          )}
        </Flex>
      </CardBody>
    </Card>
  );
}

export default function NotificationsForm() {
  const { data: notifiers, isLoading: notifiersIsLoading } = useQuery<Notifier[]>({
    queryKey: [QUERY_KEYS.notifiers],
    queryFn: () => apiClient.get(API_ROUTES.notifiers.base),
  });

  const notifiersByKey = useMemo(() => keyBy(notifiers || [], "type"), [notifiers]);

  if (notifiersIsLoading) {
    return <Skeleton height="100px" />;
  }

  return (
    <Flex direction="column">
      {NotifierSections.map((sectionName: string) => (
        <NotifierFormSection
          key={sectionName}
          notifierSection={get(notifiersByKey, sectionName, {})}
          {...get(NotificationSections, sectionName)}
          isLoadingNotifiers={notifiersIsLoading}
        />
      ))}
    </Flex>
  );
}
