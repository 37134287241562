import {
  Badge, Button, ButtonProps, HStack, Link, Text, useColorModeValue,
} from "@chakra-ui/react";
import { useLocation, Link as RouterLink } from "react-router-dom";

interface NavButtonProps extends ButtonProps {
  label: string;
  route: string;
  notifications?: number;
}

export default function SidebarSubNavButton({ route, label, notifications = undefined, ...buttonProps }: NavButtonProps) {
  const { pathname } = useLocation();
  const isActive = pathname === route;
  const activeBgColor = useColorModeValue("gray.100", "gray.700");
  return (
    <Link
      as={RouterLink}
      to={route}
      style={{ textDecoration: "none" }}
      _hover={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Button
        width="100%"
        variant="tertiary"
        justifyContent="start"
        bg={isActive ? activeBgColor : undefined}
        pl="16"
        size="xs"
        {...(notifications
          ? {
            rightIcon: (
              <Badge variant="solid" colorScheme={notifications < 5 ? "green" : "red"} borderRadius="8px" size="xs">
                {notifications}
              </Badge>
            ),
          }
          : {})}
        {...buttonProps}
      >
        <HStack spacing="3">
          <Text color="gray.400">{label}</Text>
        </HStack>
      </Button>
    </Link>
  );
}
