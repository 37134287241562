import * as yup from "yup";

const SUGGESTED_EMAIL_FORMAT_FIELD = {
  type: "text",
  label: "Suggested Email Format",
  name: "suggestedEmailFormat",
  helperText: "The email format that your company uses, e.g. {FIRST_NAME}.{LAST_NAME}@joincamino.com",
};

const DEFAULT_TIMEZONE_FIELD = {
  type: "select",
  label: "Default Timezone",
  name: "defaultTimezone",
};

const DEFAULT_TRIGGER_TIME_OF_DAY_FIELD = {
  type: "select",
  label: "Default Trigger Time Of Day",
  name: "defaultTriggerTimeOfDay",
};

export { DEFAULT_TIMEZONE_FIELD, DEFAULT_TRIGGER_TIME_OF_DAY_FIELD, SUGGESTED_EMAIL_FORMAT_FIELD };

export const schema = yup.object({
  suggestedEmailFormat: yup.string().nullable().optional().label(SUGGESTED_EMAIL_FORMAT_FIELD.label),
  defaultTimezone: yup.string().nullable().optional().label(DEFAULT_TIMEZONE_FIELD.label),
  defaultTriggerTimeOfDay: yup.string().nullable().optional().label(DEFAULT_TRIGGER_TIME_OF_DAY_FIELD.label),
});

export type Schema = yup.InferType<typeof schema>;
