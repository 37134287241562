import {
  Card, CardBody, Heading, Flex, HStack, Skeleton, CardProps,
} from "@chakra-ui/react";
import { NewHireJourney } from "models/onboarding";
import { InfoDisplayValue } from "components/DataDisplay/InfoDisplayValue";
import NewHireJourneyEditButtonWithModal from "components/ModalForm/NewHireJourneyEditForm/NewHireJourneyEditButtonWithModal";
import { truncateMiddle } from "helpers/string";
import { removeUrlScheme } from "helpers/url";
import InputEditButton from "components/Button/InputEditButton";

interface NewHireJourneyResourcesProps extends CardProps {
  newHireJourney: NewHireJourney | undefined;
}

export default function NewHireJourneyResources({ newHireJourney, ...rest }: NewHireJourneyResourcesProps) {
  return (
    <Card minW="300px" height="100%" mt="4" {...rest}>
      <Skeleton isLoaded={!!newHireJourney} height="100%">
        <CardBody height="100%" display="flex" alignItems="center" px="8">
          <Flex direction="column" justify="space-evenly">
            <HStack justifyContent="flex-start" gap="1" align="end">
              <Heading size="xs" color="fg.muted">
                Onboarding Plan
              </Heading>
              <NewHireJourneyEditButtonWithModal
                alignSelf="center"
                formType="RESOURCES"
                newHireJourney={newHireJourney}
              >
                <InputEditButton aria-label="Edit new hire onboarding plan" />
              </NewHireJourneyEditButtonWithModal>
            </HStack>
            <Flex ml="2" direction="column" height="100%" align="flex-start" justifyContent="space-between">
              <InfoDisplayValue
                value={
                  newHireJourney?.onboardingPlanUrl && truncateMiddle(removeUrlScheme(newHireJourney.onboardingPlanUrl))
                }
                label=""
                toLink={newHireJourney?.onboardingPlanUrl}
              />
            </Flex>
          </Flex>
        </CardBody>
      </Skeleton>
    </Card>
  );
}
