import { Flex } from "@chakra-ui/react";
import { FieldPathByValue, UseFormReturn, useWatch } from "react-hook-form";
import TimePickerField from "components/FormElements/Fields/TimePickerField";
import SingleSelectField from "components/FormElements/Fields/SelectFields/SingleSelectField";
import TimezoneSelectField from "components/FormElements/Fields/SelectFields/TimezoneSelectField";
import { useEffect, useMemo } from "react";
import { TRIGGER_DEFAULT_VALUES } from "definitions/constants/trigger";
import { Maybe } from "yup";
import { schedulingFields } from "../fields";
import { SharedSchema, TimingDataSchema, TriggerSchema } from "../schema";
import { isImmediateNoOffsetTrigger } from "../helpers";

export default function TimeOfDay<Schema extends SharedSchema>({
  fieldName,
  form: {
    setValue,
    control,
    formState: { errors },
  },
}: {
  fieldName: FieldPathByValue<Schema, Maybe<TriggerSchema>>;
  form: UseFormReturn<Schema>;
}) {
  const fields = schedulingFields(fieldName);
  // using "useWatch" - "watch" was not triggering the useEffect to run when referenceEvent or offsetDirection changed
  const [timeOfDay, timezoneType, referenceEvent, offsetDirection] = useWatch({
    control,
    name: [
      fields.timeOfDay.name as FieldPathByValue<Schema, TimingDataSchema["timeOfDay"]>,
      fields.timezoneType.name as FieldPathByValue<Schema, TimingDataSchema["timezoneType"]>,
      fields.referenceEvent.name as FieldPathByValue<Schema, TimingDataSchema["referenceEvent"]>,
      fields.offsetDirection.name as FieldPathByValue<Schema, TimingDataSchema["offsetDirection"]>,
    ],
  });

  const isImmediate = useMemo(
    () => isImmediateNoOffsetTrigger({ referenceEvent, offsetDirection }),
    [referenceEvent, offsetDirection],
  );

  useEffect(() => {
    // clear time related fields if the trigger is immediate
    if (isImmediate) {
      setValue(fields.timeOfDay.name, undefined);
      setValue(fields.timezoneType.name, undefined);
      setValue(fields.specificTimezone.name, undefined);
    }
  }, [setValue, isImmediate, fields.timeOfDay.name, fields.timezoneType.name, fields.specificTimezone.name]);

  useEffect(() => {
    if (!timeOfDay && !isImmediate) {
      setValue(fields.timeOfDay.name, TRIGGER_DEFAULT_VALUES.trigger.data.timeOfDay);
    }
  }, [setValue, timeOfDay, isImmediate, fields.timeOfDay.name]);

  if (isImmediate) {
    return null;
  }

  return (
    <Flex gap="2" align="center">
      <TimePickerField
        {...fields.timeOfDay}
        errors={errors}
        control={control}
        width="170px"
        formFieldProps={{ width: "auto" }}
      />
      <SingleSelectField
        size="sm"
        {...fields.timezoneType}
        isClearable={false}
        control={control}
        minWidth="300px"
        formFieldProps={{ minWidth: "228px", width: "228px" }}
      />
      {timezoneType === "specific_timezone" && (
        <TimezoneSelectField
          size="sm"
          displayValue=""
          control={control}
          {...fields.specificTimezone}
          formFieldProps={{ width: "100%" }}
        />
      )}
    </Flex>
  );
}
