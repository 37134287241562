/* eslint-disable import/prefer-default-export */
import { Action, PathDependencyTrigger, PathTrigger } from "models/automation";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import { TemplateAction } from "models/automation/templateWorkflow";

export function isAssignedAction(action: Action | AssignedAction): action is AssignedAction {
  return (action as AssignedAction)?.status !== undefined;
}

export function isTemplateAction(action: Action | TemplateAction): action is TemplateAction {
  return (action as TemplateAction)?.trigger !== undefined;
}

export function trigger(action: Action | TemplateAction): PathTrigger {
  return isTemplateAction(action) ? action.trigger : action.actionable.trigger;
}

export function isDependencyTrigger(pathTrigger: PathTrigger): pathTrigger is PathDependencyTrigger {
  return pathTrigger?.type === "dependency";
}
