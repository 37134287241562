import { ExtendedNewHireJourney } from "models/joinedModels";
import { TIME_RANGE } from "definitions/constants";
import { Flex } from "@chakra-ui/react";
import { useMemo } from "react";
import { Filter } from "models/filter";
import ActivityList from "pages/PeopleTeam/ActivityFeedPage/ActivityList";

interface ActivityFeedTabProps {
  newHireJourney: ExtendedNewHireJourney;
}

export default function ActivityFeedTab({ newHireJourney }: ActivityFeedTabProps) {
  const filters: Filter[] = useMemo(
    () => [
      {
        fieldName: "scheduledWorkflowId",
        value: (newHireJourney?.assignedPaths || []).map((scheduledWorkflow) => scheduledWorkflow.id),
        operator: "in",
      },
    ],
    [newHireJourney],
  );

  return (
    <Flex direction="column" alignItems="flex-start">
      <ActivityList defaultTimePeriod={TIME_RANGE.INFINITE_TO_NOW.value} defaultFilters={filters} />
    </Flex>
  );
}
