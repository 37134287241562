/* eslint-disable import/prefer-default-export */
import { HStack, StackProps, Text } from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface SimpleInfoProps extends StackProps {
  label: string;
}

export function SimpleText({ children }: PropsWithChildren) {
  return (
    <Text fontSize="md" fontWeight="medium" color="chakra-body-text">
      {children}
    </Text>
  );
}

export function SimpleInfo({ label, children, ...rest }: SimpleInfoProps) {
  return (
    <HStack gap="2" {...rest}>
      <Text fontSize="xs" color="chakra-body-text">
        {label}
      </Text>
      {typeof children === "string" ? (
        <SimpleText>{children}</SimpleText>
      ) : (
        children
      )}
    </HStack>
  );
}
