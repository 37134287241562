import { SegmentedControlGroup, SegmentedControlButton } from "components/FormElements/UnderlineSegmentedControlGroup";
import { Dispatch, SetStateAction } from "react";
import { AiOutlineCheckSquare, AiOutlineFolderOpen } from "react-icons/ai";
import { Text } from "@chakra-ui/react"
import { BiTimeFive } from "react-icons/bi";
import { FaRegFlag } from "react-icons/fa";

export type TaskStatusType = "open" | "all" | "upcoming" | "closed";

const SelectableTasksByStatusOptions = [
  { label: "Open", value: "open", icon: AiOutlineFolderOpen },
  { label: "Upcoming", value: "upcoming", icon: BiTimeFive },
  { label: "Closed", value: "closed", icon: AiOutlineCheckSquare },
  { label: "All", value: "all", icon: FaRegFlag },
];

interface SelectableTasksByStatusProps {
  setTaskStatusType: Dispatch<SetStateAction<TaskStatusType>>;
  taskStatusType: TaskStatusType;
}

export default function SelectableTasksByStatus({ taskStatusType, setTaskStatusType }: SelectableTasksByStatusProps) {
  return (
    <SegmentedControlGroup
      name="taskStatusType"
      value={taskStatusType}
      defaultValue={taskStatusType}
      spacing="2"
      onChange={(value: any) => setTaskStatusType(value)}
    >
      {SelectableTasksByStatusOptions.map(({ value, icon, label }) => (
        <SegmentedControlButton key={value} value={value} icon={icon}>
          <Text size="sm">{label}</Text>
        </SegmentedControlButton>
      ))}
    </SegmentedControlGroup>
  );
}
