import { useMemo } from "react";
import { SystemStyleObject } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { GenericTableProps } from "components/Table/GenericTable";
import { ResponseModel } from "services/ApiClient/responseModel";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { QUERY_KEYS } from "definitions/constants";
import GenericCardTable from "components/Table/CardTable/GenericCardTable";
import { EmptyPendingNewHireJourneyState } from "components/EmptyComponents/RandomEmptyState";
import { range } from "lodash";
import PendingNewHireJourneyCard from "./PendingNewHireJourneyCard";

const columnHelper = createColumnHelper<ExtendedNewHireJourney>();

const columns = [
  columnHelper.accessor((row) => row, {
    id: "name",
    header: "New Hire",
  }),
  columnHelper.accessor((row) => row, {
    id: "createdAt",
    header: "Added",
  }),
  columnHelper.accessor("startDate", {
    id: "startDate",
    header: "Start Date",
    sortDescFirst: false,
  }),
  columnHelper.accessor("manager", {
    id: "manager",
    header: "Manager",
  }),
];

interface PendingNewHireJourneysTableProps {
  sx?: SystemStyleObject;
  getCardProps?: () => object;
}

export default function PendingNewHireJourneyCardTable({
  getCardProps,
  sx,
  ...rest
}: PendingNewHireJourneysTableProps) {
  const additionalQueries = useMemo(() => {
    const queries: Record<string, string | string[]> = {
      includes: ["assigned_paths"],
    };
    return queries;
  }, []);

  const tableProps: GenericTableProps<ExtendedNewHireJourney> = {
    fetchData: (query, signal) => apiClient
      .performRequest<
    ResponseModel<ExtendedNewHireJourney[]>
    >(`${API_ROUTES.newHireJourneys.pending.base}${query}`, signal)
      .then((response) => response.payload),
    queryKey: [QUERY_KEYS.newHireJourneysPending],
    columns,
    meta: { pagination: { perPage: 20 }, sort: [{ sortBy: "startDate", direction: "asc" }] },
    additionalQueries,
  };

  return (
    <GenericCardTable
      {...tableProps}
      emptyStateComponent={<EmptyPendingNewHireJourneyState />}
      cardComponent={PendingNewHireJourneyCard}
      getCardProps={getCardProps}
      getTdProps={() => ({
        py: "6px",
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: "2",
      })}
      pageSizeOptions={range(20, 100, 20)}
      sx={{
        "& tbody tr:first-of-type td:first-of-type": {
          paddingTop: "20px",
        },
        mt: "2px",
        ...sx,
      }}
      {...rest}
    />
  );
}

PendingNewHireJourneyCardTable.defaultProps = {
  getCardProps: () => ({}),
  sx: undefined,
};
