import { Skeleton, SkeletonProps, Td, Tr } from "@chakra-ui/react";
import { MAX_CARD_WIDTH } from "definitions/constants";
import { PropsWithChildren } from "react";

interface SkeletonCardsProps extends PropsWithChildren, SkeletonProps {
  columnNumber: number;
  isLoading?: boolean;
  getCardProps: () => object;
  getTrProps: () => object;
  getTdProps: () => object;
  skeletonHeight?: string;
  skeletonWidth?: string;
  numberOfRows?: number;
}

export default function SkeletonCards({
  columnNumber,
  isLoading = true,
  getCardProps,
  getTrProps,
  getTdProps,
  skeletonHeight = "100px",
  skeletonWidth = MAX_CARD_WIDTH,
  numberOfRows = 5,
  children,
  ...rest
}: SkeletonCardsProps) {
  return (
    <>
      {[...Array(numberOfRows).keys()].map((value) => (
        <Tr key={`skeleton-row-${value}`} {...getTrProps()}>
          <Td px="2" colSpan={columnNumber} textAlign="center" borderBottom="none" {...getTdProps()}>
            <Skeleton
              key={`skeleton-card-${value}`}
              height={skeletonHeight}
              width={skeletonWidth}
              borderRadius="lg"
              {...rest}
            />
          </Td>
        </Tr>
      ))}
    </>
  );
}
