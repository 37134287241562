import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import React, { PropsWithChildren, useCallback } from "react";
import { generatePath, useNavigate } from "react-router-dom";

const text1 = "In order to send quick messages via Slack from your account, you must give Camino permission.";
const text2 = "Camino will not send automated messages from your account, only messages you explicitly approve/send.";
const text4 = "To give Camino permission, click the button below and follow the instructions.";

export default function OauthButton({
  children,
  ...rest
}: PropsWithChildren) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();

  const redirectAndClose = useCallback(() => {
    onClose();
    navigate(generatePath(PEOPLE_TEAM_ROUTES.company.individualSettings));
  }, [navigate, onClose]);

  return (
    <>
      {React.cloneElement(children as JSX.Element, { onClick: onOpen })}

      <Modal isOpen={isOpen} onClose={onClose} {...rest}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Slack Permissions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" gap="2" pb="2">
              <Text>
                {text1}
              </Text>
              <Text fontWeight="medium" fontStyle="italic">
                {text2}
              </Text>
              <Text mb="4">
                {text4}
              </Text>
              <Button onClick={redirectAndClose}>Authorize Camino</Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
