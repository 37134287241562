import {
  Box,
  Button,
  Center,
  DarkMode,
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";

import SignInForm from "components/Forms/SignInForm";

const HEADING_MAIN = "Unlock New-Hire Potential";
const HEADING_SECONDARY = "Join the many teams that are accelerating their new hire's success.";

const NO_ACCOUNT_QUESTION = "Don't have an account?";
const NO_ACCOUNT_CALL_TO_ACTION = "Email us for access";

function LoginPage() {
  return (
    <Flex
      minH={{ base: "auto", md: "100vh" }}
      bgGradient={{
        md: mode("linear(to-r, brand.600 50%, white 50%)", "linear(to-r, brand.600 50%, gray.900 50%)"),
      }}
    >
      <Flex maxW="8xl" mx="auto" width="full">
        <Box flex="1" display={{ base: "none", md: "block" }}>
          <DarkMode>
            <Flex direction="column" px={{ base: "4", md: "8" }} pl={{ xl: 0 }} height="full" color="fg.accent.default">
              <Flex flex="1" align="center">
                <Stack spacing="8">
                  <Stack spacing="6">
                    <Heading size={{ md: "lg", xl: "xl" }}>{HEADING_MAIN}</Heading>
                    <Text textStyle="lg" maxW="md" fontWeight="medium">
                      {HEADING_SECONDARY}
                    </Text>
                  </Stack>
                  <HStack spacing="4">
                    <Text fontWeight="medium">{NO_ACCOUNT_QUESTION}</Text>
                    <Button size="lg">{NO_ACCOUNT_CALL_TO_ACTION}</Button>
                  </HStack>
                </Stack>
              </Flex>
            </Flex>
          </DarkMode>
        </Box>
        <Center flex="1">
          <SignInForm px={{ base: "4", md: "8" }} py={{ base: "12", md: "48" }} width="full" maxW="md" />
        </Center>
      </Flex>
    </Flex>
  );
}

export default LoginPage;
