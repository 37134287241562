import {
  Box, Button, Flex, Heading,
} from "@chakra-ui/react";
import {
  COMMUNICATION_ACTION_TYPE_VALUES,
  TASK_ACTION_TYPE_VALUES,
  ActionTypeContent,
  SelectableActionTypeContent,
} from "components/Form/SelectableActionType";
import { PropsWithChildren, useCallback } from "react";
import { TASK_TYPES } from "definitions/constants";
import EmailForm from "./Email";
import ChatForm from "./Chat";
import TaskForm from "./Task";

function BoxButton({ children, onClick }: { onClick: () => void } & PropsWithChildren) {
  return (
    <Box
      onClick={onClick}
      as={Button}
      background="transparent"
      cursor="pointer"
      width="160px"
      height="160px"
      border="1px solid"
      borderColor="chakra-border-color"
      borderRadius="md"
      color="chakra-body-text"
      _hover={{
        borderWidth: "2px",
      }}
    >
      {children}
    </Box>
  );
}

type ComponentGetterProps = { workflowId: string; onSuccess: () => void };

const ACTION_TYPE_VALUES_TO_COMPONENTS: Record<string, (props: ComponentGetterProps) => JSX.Element> = {
  email_personal: (props) => (
    <EmailForm
      {...props}
      defaultValues={{ actionType: "email", emailType: "personal_email", targetType: "relation" }}
    />
  ),
  email_work: (props) => (
    <EmailForm {...props} defaultValues={{ actionType: "email", emailType: "work_email", targetType: "relation" }} />
  ),
  chat: (props) => <ChatForm {...props} defaultValues={{ actionType: "chat" }} />,
  task_custom: (props) => <TaskForm {...props} defaultValues={{ actionType: "task", taskType: TASK_TYPES.CUSTOM }} />,
  task_assign_buddy: (props) => (
    <TaskForm {...props} defaultValues={{ actionType: "task", taskType: TASK_TYPES.ASSIGN_BUDDY }} />
  ),
  task_create_onboarding: (props) => (
    <TaskForm {...props} defaultValues={{ actionType: "task", taskType: TASK_TYPES.CREATE_ONBOARDING_PLAN }} />
  ),
  task_create_email: (props) => (
    <TaskForm {...props} defaultValues={{ actionType: "task", taskType: TASK_TYPES.CREATE_EMAIL_ACCOUNT }} />
  ),
  task_message_prompt: (props) => (
    <TaskForm
      {...props}
      defaultValues={{
        actionType: "task",
        taskType: TASK_TYPES.SEND_MESSAGE_PROMPT,
        content: { messageTarget: { targetType: "OnboardingJourneyNetworkRelation", targetId: "" } },
      }}
    />
  ),
};

interface ActionTypeSelectorProps {
  workflowId: string;
  handleSelect: ({ title, component }: { title: string; component: JSX.Element }) => void;
  onSuccess: () => void;
}

export default function ActionTypeSelector({ handleSelect, workflowId, onSuccess }: ActionTypeSelectorProps) {
  const onSelect = useCallback(
    ({ name, value }: SelectableActionTypeContent) => {
      handleSelect({
        title: name,
        component: ACTION_TYPE_VALUES_TO_COMPONENTS[value]({ workflowId, onSuccess }),
      });
    },
    [handleSelect, workflowId, onSuccess],
  );

  return (
    <Flex flexDirection="column">
      <Heading as="h2" size="xs" my={2}>
        Communication
      </Heading>
      <Flex flexDirection="row" flexWrap="wrap" gap={2} my={2}>
        {COMMUNICATION_ACTION_TYPE_VALUES.map((actionTypeField) => (
          <BoxButton key={actionTypeField.value} onClick={() => onSelect(actionTypeField)}>
            <ActionTypeContent {...actionTypeField} />
          </BoxButton>
        ))}
      </Flex>
      <Heading as="h2" size="xs" my={2}>
        Task
      </Heading>
      <Flex flexDirection="row" flexWrap="wrap" gap={2} my={2}>
        {TASK_ACTION_TYPE_VALUES.map((actionTypeField) => (
          <BoxButton key={actionTypeField.value} onClick={() => onSelect(actionTypeField)}>
            <ActionTypeContent {...actionTypeField} />
          </BoxButton>
        ))}
      </Flex>
    </Flex>
  );
}
