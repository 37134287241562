import {
  Card, CardBody, HStack, Icon, LinkBox, LinkBoxProps, LinkOverlay, Text,
} from "@chakra-ui/react";
import { FiPlusSquare } from "react-icons/fi";

interface AddPathsButtonProps {
  onOpen: () => void;
}

export default function AddPathsButton({ onOpen, ...rest }: AddPathsButtonProps & LinkBoxProps) {
  return (
    <LinkBox as={Card} maxWidth="400px" cursor="pointer" _hover={{ bg: "background.tertiary" }} {...rest}>
      <CardBody>
        <LinkOverlay onClick={onOpen}>
          <HStack width="100%" height="100%" alignSelf="center" justifyContent="center">
            <Icon as={FiPlusSquare} boxSize="12" color="fg.subtle" />
            <Text fontSize="18">Add Paths</Text>
          </HStack>
        </LinkOverlay>
      </CardBody>
    </LinkBox>
  );
}
