import { CreateToastFnReturn, Button } from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { useSyncUsers } from "features/Integrations/hooks";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { User } from "models/user";

interface SyncUsersButtonProps {
  onSuccess?: () => void;
}

export default function SyncUsersButton({ onSuccess = () => { } }: SyncUsersButtonProps) {
  const {
    performRequest: syncChannels,
    state: { loading: isLoading },
  } = useSyncUsers()
  return (
    <Button onClick={() => syncChannels()} isLoading={isLoading} loadingText="Syncing Users">
      Sync Users
    </Button>
  );
}
