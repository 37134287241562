import { Link as RouterLink, generatePath, useParams, useSearchParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { SHARED_COMPANY_ROUTES } from "definitions/constants/routeConstants";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Card,
  CardBody,
  Center,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavbar } from "hooks/useNavbar";
import { useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";
import OverviewTab from "./Tabs/OverviewTab";
import Schedule from "../../../../features/NewHireJourney/Schedule";
import TasksTab from "./Tabs/TasksTab";
import NewHireJourneyTitleBar from "../NewHiresTitleBar";
import FieldsTab from "./Tabs/FieldsTab";
import PathsTab from "./Tabs/PathsTab";
import ActivityFeedTab from "./Tabs/ActivityFeedTab";

const TAB_QUERY_PARAM = "t";

function NewHireJourneyPage() {
  const { id } = useParams<{ id: string }>();
  const { setBreadcrumbs, setPageTitle } = useNavbar();
  const alertTextColor = useColorModeValue("fg.subtle", "inherit");

  const [searchParams, setSearchParams] = useSearchParams();
  const [tabIndex, setTabIndex] = useState(() => Number(searchParams.get(TAB_QUERY_PARAM)) || 0);
  const handleTabChange = useCallback((index: number) => {
    setTabIndex(index);
    setSearchParams(
      (params) => {
        params.set(TAB_QUERY_PARAM, String(index));
        return params;
      },
      { replace: true },
    );
  }, [setSearchParams]);

  const { data, isLoading } = useNewHireJourneyDetailQuery(id || "");

  const memoizedTabs = useMemo(() => [
    { label: "Overview", content: <OverviewTab newHireJourney={data} /> },
    { label: "Fields", content: <FieldsTab newHireJourney={data} /> },
    { label: "Paths", content: <PathsTab newHireJourney={data} /> },
    { label: "Schedule", content: <Schedule newHireJourney={data} isLoading={isLoading} /> },
    { label: "Tasks", content: <TasksTab newHireJourney={data} /> },
    { label: "Activity Feed", content: <ActivityFeedTab newHireJourney={data} /> },
  ], [data, isLoading]);

  useEffect(() => {
    if (data?.user?.fullName && !isLoading) {
      setPageTitle(`${data?.user?.fullName ? `${data.user.fullName}'s Onboarding` : ""}`);
      setBreadcrumbs([
        {
          name: "New Hires",
          toUrl: generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.index),
          isCurrentPage: false,
        },
        {
          name: data?.user?.fullName,
          isCurrentPage: true,
        },
      ]);
    }
  }, [data?.user?.fullName, isLoading, setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      {data?.isPending && (
        <RouterLink to={generatePath(SHARED_COMPANY_ROUTES.newHireJourneys.pending.show, { id })}>
          <Alert status="warning" borderTopRadius="10px">
            <AlertIcon />
            <AlertTitle color={alertTextColor}>This new hire is pending!</AlertTitle>
            <AlertDescription color={alertTextColor}>
              Visit the pending new hire page to add additional information and kick off the automations.
            </AlertDescription>
          </Alert>
        </RouterLink>
      )}
      <NewHireJourneyTitleBar newHireJourney={data} />
      <Card mt="6">
        <CardBody>
          <Tabs index={tabIndex} onChange={handleTabChange} size="lg" variant="underline" isLazy>
            <TabList>
              {memoizedTabs.map(({ label }) => (
                <Tab key={label} color="fg.muted">{label}</Tab>
              ))}
            </TabList>
            <TabIndicator />
            <TabPanels>
              {memoizedTabs.map(({ label, content }) => (
                <TabPanel key={label}>
                  {data ? content : (
                    <Center>
                      <Spinner thickness="4px" size="xl" />
                    </Center>
                  )}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </CardBody>
      </Card>
    </AppPage>
  );
}

export default React.memo(NewHireJourneyPage);
