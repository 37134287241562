import * as yup from "yup";
import { ActionableType, PathRelationTarget } from "models/automation";
import { LegacyTargetType, TargetType } from "models/record_target";
import { TaskType } from "models/task";
import { TASK_TYPES } from "definitions/constants";
import { sharedSchema, TimingSchema, TriggerSchema } from "../Shared/schema";
import { isNil, isEmpty, omitBy } from "lodash";

const messageTarget = yup.object({
  targetType: yup.mixed<TargetType>().required(),
  targetId: yup.string().required().label("Message Recipient"),
});

const content = yup
  .object({
    description: yup.string().label("Description"),
    messageTarget: messageTarget.notRequired().label("Message Recipient"),
    messageBody: yup.string().notRequired().label("Message Suggestion"),
  })
  .when("taskType", {
    is: TASK_TYPES.SEND_MESSAGE_PROMPT || TASK_TYPES.CUSTOM,
    then: (s) => s.shape({
      description: yup.string().required().label("Description"),
      messageTarget: messageTarget.required(),
      messageBody: yup.string().required().label("Message Suggestion"),
    }),
    otherwise: (s) => s.shape({
      description: yup.string().notRequired().label("Description"),
      messageTarget: messageTarget.strip(),
    }),
  });

const reminder = yup.object({
  trigger: yup.mixed<Extract<TriggerSchema, TimingSchema>>().required().nonNullable(),
  message: yup.string().optional(), // e.g. "this is your final reminder"
});

export const schema = sharedSchema.concat(
  yup.object({
    actionType: yup.mixed<Extract<ActionableType, "task">>().required().label("Action Type"),
    targetType: yup.mixed<LegacyTargetType>().required().label("Assignee"),
    taskType: yup.mixed<TaskType>().required().label("Task Type"),
    relationTarget: yup
      .mixed<PathRelationTarget>()
      .label("Assignee")
      .when("targetType", {
        is: "relation",
        then: (s) => s.required().nonNullable(),
        otherwise: (s) => s.notRequired().nonNullable(),
      }),
    specificTargetableId: yup.string().when("targetType", {
      is: "specific_user",
      then: (s) => s.required().label("User"),
      otherwise: (s) => s.notRequired(),
    }),
    specificTargetableType: yup.string().when("targetType", {
      is: "specific_user",
      then: (s) => s.required(),
      otherwise: (s) => s.notRequired(),
    }),
    content,
    dueDateTrigger: yup.mixed<Extract<TriggerSchema, TimingSchema>>().label("Due Date").notRequired().nonNullable().transform((value) => {
      if (value?.data && isEmpty(omitBy(value.data, isNil))) {
        return undefined;
      }
      return value;
    }),
    reminders: yup.array().of(reminder).optional().label("Reminders"),
  }),
);

export type Schema = yup.InferType<typeof schema>;
