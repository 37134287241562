import { isEmpty, isNil, omitBy, range } from "lodash";
import * as yup from "yup";
import {
  ActionableType,
  DayOfWeek,
  EmailRecipient,
  EmailType,
  OffsetUnit,
  PathReferenceEvent,
  PathRelationTarget,
  PathTriggerType,
  SpecificTimezone,
  TimeOfDay,
  TimezoneType,
} from "models/automation";
import { LegacyTargetType, TargetType } from "models/record_target";

const timingData = yup.object({
  referenceEvent: yup.mixed<PathReferenceEvent>().required().label("Event"),
  offsetDirection: yup.mixed<"exact" | "before" | "after">().required().label("Before/After/On"),
  offsetValue: yup.number().when("offsetDirection", {
    is: (value: string) => value && value !== "exact",
    then: (schema) => schema.required().oneOf(range(1, 120)).label("Timing"),
    otherwise: (schema) => schema.notRequired(),
  }),
  offsetUnit: yup
    .mixed<OffsetUnit>()
    .label("Day/Week/Month")
    .when("offsetDirection", {
      is: (value: string) => value && value !== "exact",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  dayOfWeek: yup
    .mixed<DayOfWeek>()
    .label("Day Of Week")
    .when("offsetUnit", {
      is: (value: string) => value === "specific_day",
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.notRequired(),
    }),
  timeOfDay: yup.mixed<TimeOfDay>().label("Send At").notRequired().nonNullable(),
  timezoneType: yup.mixed<TimezoneType>().label("Timezone Source").notRequired(),
  specificTimezone: yup
    .mixed<SpecificTimezone>()
    .label("Which Timezone?")
    .when("target_timezone", {
      is: (value: string) => value === "specific_timezone",
      then: (schema) => schema.required().default("America/Los_Angeles"),
      otherwise: (schema) => schema.notRequired(),
    }),
});

export type TimingDataSchema = yup.InferType<typeof timingData>;

const timing = yup.object({
  type: yup.mixed<Extract<PathTriggerType, "timing">>().required().nonNullable(),
  data: yup.mixed<TimingDataSchema>().required().nonNullable(),
});

export type TimingSchema = yup.InferType<typeof timing>;

const dependencyData = yup.object({
  event: yup.mixed<"task.completed">().required(),
  dependencyType: yup.mixed<"WorkflowAction">().required(),
  dependencyId: yup.string().label("Task").required(),
});

type DependencyDataSchema = yup.InferType<typeof dependencyData>;

const dependency = yup.object({
  type: yup.mixed<Extract<PathTriggerType, "dependency">>().required().nonNullable(),
  data: yup.mixed<DependencyDataSchema>().required().nonNullable(),
});

type DependencySchema = yup.InferType<typeof dependency>;

const trigger = yup.mixed<TimingSchema | DependencySchema>();

export type TriggerSchema = yup.InferType<typeof trigger>;

const reminder = yup.object({
  trigger,
  message: yup.string().optional(), // e.g. "this is your final reminder"
});

const target = yup.object({
  targetType: yup.mixed<TargetType>().required(),
  targetId: yup.string().required().label("Message Recipient"),
});

const targetNotRequired = yup.object({
  targetType: yup.mixed<TargetType>().notRequired(),
  targetId: yup.string().notRequired(),
});

const content = yup.object({
  subject: yup.string().optional().label("Subject"),
  body: yup.string().optional().label("Body"),
  title: yup.string().optional().label("Title"),
  description: yup.string().optional().label("Description"),
  messageTarget: targetNotRequired.optional().label("Message Recipient"),
  messageBody: yup.string().optional().label("Message Suggestion"),
});

export const schema = yup.object({
  actionType: yup.mixed<ActionableType>().required().label("Action Type"),
  targetType: yup.mixed<LegacyTargetType>().required().label("Recipient/Assignee Type"),
  emailType: yup.mixed<EmailType>().when("actionType", {
    is: "email",
    then: (s) => s.required().label("Email Type"),
    otherwise: (s) => s.notRequired().transform(() => undefined),
  }),
  taskType: yup.string().when("actionType", {
    is: "task",
    then: (s) => s.required().label("Task Type"),
    otherwise: (s) => s.notRequired().transform(() => undefined),
  }),
  dueDateTrigger: trigger.notRequired().nonNullable().transform((value) => {
    if (value?.data && isEmpty(omitBy(value.data, isNil))) {
      return undefined;
    }
    return value;
  }),
  relationTarget: yup
    .mixed<PathRelationTarget>()
    .label("Recipient/Assignee")
    .when("targetType", {
      is: "relation",
      then: (s) => s.required(),
      otherwise: (s) => s.notRequired(),
    }),
  specificTargetableId: yup.string().when("targetType", {
    is: "specific_user",
    then: (s) => s.required().label("User"),
    otherwise: (s) => s.when("targetType", {
      is: "message_service_channel",
      then: (s2) => s2.required().label("Channel"),
      otherwise: (s2) => s2.notRequired(),
    }),
  }),
  specificTargetableType: yup.string().when("targetType", {
    is: "specific_user",
    then: (s) => s.required(),
    otherwise: (s) => s.when("targetType", {
      is: "message_service_channel",
      then: (s2) => s2.required(),
      otherwise: (s2) => s2.notRequired(),
    }),
  }),
  additionalRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Additional Recipients"),
  ccRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Cc Recipients"),
  bccRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Bcc Recipients"),
  name: yup.string().required().label("Name"),
  trigger,
  content: content
    .when("actionType", {
      is: "email",
      then: (s) => s.shape({
        subject: yup.string().required().label("Subject"),
        body: yup.string().required().label("Body"),
        title: yup
          .string()
          .optional()
          .transform(() => undefined),
        description: yup
          .string()
          .optional()
          .transform(() => undefined),
        messageTarget: targetNotRequired.optional().transform(() => undefined),
        messageBody: yup
          .string()
          .optional()
          .transform(() => undefined),
      }),
    })
    .when("actionType", {
      is: "chat",
      then: (s) => s.shape({
        body: yup.string().required().label("Message"),
        subject: yup
          .string()
          .optional()
          .transform(() => undefined),
        title: yup
          .string()
          .optional()
          .transform(() => undefined),
        description: yup
          .string()
          .optional()
          .transform(() => undefined),
        messageTarget: targetNotRequired.optional().transform(() => undefined),
        messageBody: yup
          .string()
          .optional()
          .transform(() => undefined),
      }),
    })
    .when(["actionType", "taskType"], ([actionType, taskType], s) => {
      if (actionType !== "task") return s;
      return s.shape({
        description: ["send_message", "custom"].includes(taskType)
          ? yup.string().required().label("Description")
          : yup
            .string()
            .optional()
            .transform(() => undefined),
        body: yup
          .string()
          .optional()
          .transform(() => undefined),
        subject: yup
          .string()
          .optional()
          .transform(() => undefined),
        messageTarget:
          taskType === "send_message" ? target.required() : targetNotRequired.notRequired().transform(() => undefined),
        messageBody:
          taskType === "send_message"
            ? yup.string().required().label("Message Suggestion")
            : yup
              .string()
              .notRequired()
              .transform(() => undefined),
      });
    }),
  reminders: yup.array().of(reminder).optional().label("Reminders"),
});

export type Schema = yup.InferType<typeof schema>;
