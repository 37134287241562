import {
  Card, Text, CardBody, Heading, Flex, LinkBox, LinkOverlay,
} from "@chakra-ui/react";
import { Task } from "models/joinedModels";
import { TaskStatusWithTime } from "features/Task/TaskStatus";
import { SHARED_ROUTES } from "definitions/constants/routeConstants";
import { generatePath, Link as ReactRouterLink } from "react-router-dom";
import TaskContentSection from "components/Task/TaskContent";
import { TargetTypeDisplay } from "components/Task";
import { User } from "models/user";

interface TaskProps {
  task: Task;
}

function TaskContent({ task }: TaskProps) {
  return (
    <Flex justify="space-between">
      {task && <TaskContentSection {...task} />}
      <Text fontSize="sm" color="fg.muted">
        {task?.estimatedTime}
      </Text>
    </Flex>
  );
}

function TaskTitle({ task }: TaskProps) {
  return (
    <Flex direction="column" gap="1" align-items="flex-start">
      <Heading color="fg.muted" size="sm">
        {task?.title}
      </Heading>
      <Flex gap="4" align="center">
        <TargetTypeDisplay targetType="User" record={(task?.assignee as User) || {}} />
        <TaskStatusWithTime width="fit-content" task={task as Task} />
      </Flex>
    </Flex>
  );
}

export default function MiniTaskCard({ task }: TaskProps) {
  return (
    <LinkBox as={Card} _hover={{ bg: "background.tertiary" }} maxWidth="500px">
      <CardBody>
        <Flex direction="column" gap="4" width="100%">
          <LinkOverlay
            as={ReactRouterLink}
            to={{ pathname: generatePath(SHARED_ROUTES.tasks.show, { id: task?.id || "" }) }}
          >
            <TaskTitle task={task} />
          </LinkOverlay>
          <TaskContent task={task} />
        </Flex>
      </CardBody>
    </LinkBox>
  );
}
