import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import { User } from "models/user";
import { generatePath } from "react-router-dom";
import apiClient from "services/ApiClient";
import UserUpdateForm, { UserUpdateFormValues } from "components/Forms/UserUpdateForm";
import BaseDialogFormHeader from "components/Forms/FormHeader";

interface IssuesUserFormProps {
  userId: string;
  title: string;
  cardLink?: string;
  fields: Array<keyof UserUpdateFormValues>;
  onSuccess: () => void;
  onClose: () => void;
}

export default function IssuesUserForm({
  userId, fields, title, cardLink, onSuccess, onClose,
}: IssuesUserFormProps) {
  const { data: user, isLoading } = useQuery<User>({
    queryKey: [QUERY_KEYS.users, userId],
    queryFn: async () => apiClient.get(generatePath(API_ROUTES.users.show, { id: userId })),
  });
  return (
    <BaseDialogFormHeader
      linkTo={cardLink as string}
      label={title}
      value={user?.fullName as string}
      imageUrl={user?.avatarUrl}
      isLoaded={!isLoading}
    >
      <UserUpdateForm userId={userId} fields={fields} onSuccess={onSuccess} onClose={onClose} />
    </BaseDialogFormHeader>
  );
}

IssuesUserForm.defaultProps = {
  cardLink: null,
};
