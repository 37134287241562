import {
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Text,
} from "@chakra-ui/react";
import { QUERY_KEYS } from "definitions/constants";
import { apiNewHireJourneyPathById } from "helpers/url";
import { ExtendedNewHireJourney } from "models/joinedModels";
import apiClient from "services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { ImmediateActionsSummary, TotalActionsSummary } from "./Components";
import { useNewHireJourneyDetailQuery } from "features/NewHireJourney/hooks";

interface ReviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  newHireJourneyId: any;
  isSubmitting: boolean;
  handleSubmit: () => void;
}

export default function ReviewModal({
  isOpen,
  onClose,
  newHireJourneyId,
  isSubmitting,
  handleSubmit,
}: ReviewModalProps) {
  const { data: newHireJourney } = useNewHireJourneyDetailQuery(newHireJourneyId);

  const titleName = newHireJourney?.user?.fullName ? `${newHireJourney?.user?.fullName}'s ` : "";
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{`Kick Off ${titleName}Onboarding`}</ModalHeader>
        <ModalBody>
          {!newHireJourney ? (
            <Text>Loading...</Text>
          ) : (
            <>
              <ImmediateActionsSummary newHireJourney={newHireJourney} />
              <Text>&nbsp;</Text>
              <TotalActionsSummary newHireJourney={newHireJourney} />
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button variant="ghost" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button isLoading={isSubmitting} colorScheme="blue" onClick={handleSubmit}>
            Kick Off Onboarding
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
