import { Heading } from "@chakra-ui/react";
import EmailSettings from "features/Settings/Email";
import { useNavbar } from "hooks/useNavbar";
import AppPage from "layouts/AppPage";
import InnerSidebar from "layouts/InnerSidebar";
import { useEffect } from "react";

export default function EmailSettingsPage() {
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Email Settings",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Email Settings");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <InnerSidebar>
        <Heading mb="8" size="sm" color="fg.subtle">
          Email Settings
        </Heading>
        <EmailSettings />
      </InnerSidebar>
    </AppPage>
  );
}
