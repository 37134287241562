import { InfoField, InfoFieldProfile } from "components/DataDisplay/InfoField";
import { RadioCardGroup, RadioCard } from "components/FormElements/RadioCardGroup";
import { ExtendedNewHireJourney } from "models/joinedModels";

interface SelectableSupportTeamProps {
  setFilterByUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
  newHireJourney: ExtendedNewHireJourney;
}

export default function SelectableSupportTeam({ newHireJourney, setFilterByUserId }: SelectableSupportTeamProps) {
  return (
    <RadioCardGroup
      direction="row"
      justify="stretch"
      defaultValue=""
      spacing="3"
      onChange={(value: any) => setFilterByUserId(value === "" ? null : value)}
    >
      <RadioCard alignSelf="stretch" value="">
        <InfoField label="Everyone" value="(All Stakeholders)" />
      </RadioCard>
      <RadioCard alignSelf="stretch" value={newHireJourney?.user?.id as string}>
        <InfoFieldProfile
          avatarInfo={
            newHireJourney?.user?.id
              ? {
                src: newHireJourney?.user?.avatarUrl,
                name: newHireJourney?.user?.fullName,
              }
              : { name: "TBD" }
          }
          fieldInfo={{ label: "New Hire", value: newHireJourney?.user?.fullName || "TBD" }}
        />
      </RadioCard>
      <RadioCard alignSelf="stretch" value={newHireJourney?.manager?.id as string}>
        <InfoFieldProfile
          avatarInfo={
            newHireJourney?.manager?.id
              ? {
                src: newHireJourney?.manager?.avatarUrl,
                name: newHireJourney?.manager?.fullName,
              }
              : { name: "TBD" }
          }
          fieldInfo={{ label: "Manager", value: newHireJourney?.manager?.fullName || "TBD" }}
        />
      </RadioCard>
      <RadioCard alignSelf="stretch" value={newHireJourney?.buddy?.id as string}>
        <InfoFieldProfile
          avatarInfo={
            newHireJourney?.buddy?.id
              ? {
                src: newHireJourney?.buddy?.avatarUrl,
                name: newHireJourney?.buddy?.fullName,
              }
              : { name: "TBD" }
          }
          fieldInfo={{ label: "Buddy", value: newHireJourney?.buddy?.fullName || "TBD" }}
        />
      </RadioCard>
      <RadioCard alignSelf="stretch" value={newHireJourney?.peopleTeamContact?.id as string}>
        <InfoFieldProfile
          avatarInfo={
            newHireJourney?.peopleTeamContact?.id
              ? {
                src: newHireJourney?.peopleTeamContact?.avatarUrl,
                name: newHireJourney?.peopleTeamContact?.fullName,
              }
              : { name: "TBD" }
          }
          fieldInfo={{ label: "People Team", value: newHireJourney?.peopleTeamContact?.fullName || "TBD" }}
        />
      </RadioCard>
    </RadioCardGroup>
  );
}
