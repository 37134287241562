import AppPage from "layouts/AppPage";
import LinkCard from "components/Card/LinkCard";
import { Wrap, WrapItem } from "@chakra-ui/react";
import { GoPersonFill } from "react-icons/go";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { PiChartLineUp } from "react-icons/pi";
import { BsBuildingGear } from "react-icons/bs";
import { ADMIN_ROUTES } from "definitions/constants/routeConstants";
import useNavbar from "hooks/useNavbar";
import { useEffect } from "react";

export default function HubPage() {
  const { setPageTitle, setBreadcrumbs } = useNavbar();
  useEffect(() => {
    setPageTitle("Admin");
    setBreadcrumbs([{ name: "Admin", isCurrentPage: true }]);
  }, [setBreadcrumbs, setPageTitle]);
  return (
    <AppPage>
      <Wrap width="100%" spacingX="4">
        <WrapItem height="209px">
          <LinkCard
            target={{ url: ADMIN_ROUTES.blazer, description: "Business intelligence" }}
            label="Blazer"
            icon={PiChartLineUp}
          />
        </WrapItem>
        <WrapItem height="209px">
          <LinkCard
            target={{ url: ADMIN_ROUTES.users.index, description: "Manage users", isExternal: false }}
            label="Users"
            icon={GoPersonFill}
          />
        </WrapItem>
        <WrapItem height="209px">
          <LinkCard
            target={{ url: ADMIN_ROUTES.companies.index, description: "Manage companies", isExternal: false }}
            label="Companies"
            icon={HiOutlineBuildingOffice2}
          />
        </WrapItem>
        <WrapItem height="209px">
          <LinkCard
            target={{ url: ADMIN_ROUTES.jobs, description: "Manage jobs" }}
            label="Jobs"
            icon={BsBuildingGear}
          />
        </WrapItem>
      </Wrap>
    </AppPage>
  );
}
