/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import { Box, IconButton } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";

interface ClickableSuccessCheckProps {
  skipped: boolean;
  complete: boolean;
  onClick: (e: any) => void;
  size?: string;
}

export default function ClickableSuccessCheck({ skipped, complete, onClick, size }: ClickableSuccessCheckProps) {
  return (
    <>
      {complete ? (
        <IconButton
          isRound
          size={size}
          onClick={(e) => onClick(e)}
          variant="solid"
          colorScheme="green"
          aria-label="Mark Incomplete"
          icon={<CheckIcon style={{ strokeWidth: "1.5" }} />}
        />
      ) : (
        skipped ? (
          <IconButton
            isRound
            size={size}
            disabled={skipped}
            variant="outline"
            aria-label="Skipped"
            sx={{ strokeWidth: "1.5", cursor: "default" }}
            borderWidth="3px"
            borderColor="gray.200"
            icon={(
              <Box
                as="div"
                borderRadius="50%"
                width="100%"
                height="100%"
                bg="gray.200"
                _hover={{ bg: "gray.200" }}
              />
            )}
          />
        ) : (
          <IconButton
            isRound
            size={size}
            onClick={(e) => onClick(e)}
            variant="outline"
            aria-label="Mark Complete"
            sx={{ strokeWidth: "1.5" }}
            borderWidth="3px"
            borderColor="gray.200"
            icon={<CheckIcon color="gray.200" style={{ strokeWidth: "1.5" }} />}
          />
        )
      )}
    </>
  );
}

ClickableSuccessCheck.defaultProps = {
  size: "xs",
};
