import { CreateToastFnReturn } from "@chakra-ui/react";
import { QueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useDeferredApiCall from "hooks/useDeferredApiCall";
import { MessageServiceChannel } from "models/integration/messageService";
import { User } from "models/user";

export function useSyncChannels() {
  return useDeferredApiCall<MessageServiceChannel[]>(API_ROUTES.messageServices.channels.sync, {
    method: "POST",
    onSuccess: (_, queryClient, toast) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.integrations] });
      toast({ title: "Channels synced" });
    },
    onFailure: (_, _2: QueryClient, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
}

interface SyncUsersOptions {
  onSuccess?: () => void;
}

export function useSyncUsers(options: SyncUsersOptions = {}) {
  const { onSuccess } = options;
  return useDeferredApiCall<User[]>(API_ROUTES.messageServices.users.sync, {
    method: "POST",
    onSuccess: (_, queryClient, toast) => {
      onSuccess?.();
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.integrations] });
      toast({ title: "Users synced" });
    },
    onFailure: (_, _2: QueryClient, toast: CreateToastFnReturn) => {
      toast({ title: "There was an error, please try again", status: "error" });
    },
  });
}

