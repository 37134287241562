import { useToast, HStack, Stack } from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import ProceedCancelButtons from "components/Button/ProceedCancelButtons";
import { useEffect, useState } from "react";
import { API_ROUTES } from "definitions/constants/routeConstants";
import apiClient from "services/ApiClient";
import { useQueryClient } from "@tanstack/react-query";
import { generatePath } from "react-router-dom";
import { QUERY_KEYS } from "definitions/constants";
import { Company } from "models/company";
import TextInputFieldWithSetValueButton from "components/FormElements/Fields/InputFields/TextInputFieldWithSetValueButton";
import TextInputField from "components/FormElements/Fields/InputFields/TextInputField";
import { useCurrentCompanyQuery, useUpdateCompany } from "features/Company/hooks";
import {
  BUDDY_EXPECTATIONS_FIELD,
  BUDDY_SELECTION_FIELD,
  ONBOARDING_PLAN_TEMPLATES_FIELD,
  BENEFITS_INFO_FIELD,
  HRIS_FIELD,
  COMPANY_HANDBOOK_FIELD,
  COMPANY_WIKI_FIELD,
  COMPANY_GOALS_FIELD,
  Schema,
  schema,
} from "./definitions";

const values = (company: Company | null | undefined) => {
  if (!company) return {};
  const {
    newHireInfoPacketUrl,
    benefitsInfoUrl,
    hrisUrl,
    companyHandbookUrl,
    companyWikiUrl,
    companyGoalsUrl,
    buddyExpectationsUrl,
    buddySelectionUrl,
    onboardingPlanTemplatesUrl,
  } = company;
  return {
    newHireInfoPacketUrl,
    benefitsInfoUrl,
    hrisUrl,
    companyHandbookUrl,
    companyWikiUrl,
    companyGoalsUrl,
    buddyExpectationsUrl,
    buddySelectionUrl,
    onboardingPlanTemplatesUrl,
  };
};

export default function CompanyResourcesForm({
  onClose = () => {},
}: {
  onClose: () => void;
}) {
  const { data: company } = useCurrentCompanyQuery();
  const defaultValues: Partial<Schema> = values(company);
  const { mutate, isPending } = useUpdateCompany();
  const {
    formState: { errors, isDirty, dirtyFields },
    handleSubmit,
    register,
    reset,
    setValue,
  } = useForm<Schema>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    values: defaultValues,
  });

  return (
    <Stack alignItems="start">
      <TextInputFieldWithSetValueButton
        {...BUDDY_EXPECTATIONS_FIELD}
        errors={errors}
        register={register}
        setValue={setValue}
        buttonText="Use Default"
        defaultValue="https://joincamino.notion.site/Onboarding-Buddy-Expectations-b192e0e8248b4616964eddebc4c68eb1"
      />
      <TextInputFieldWithSetValueButton
        {...BUDDY_SELECTION_FIELD}
        errors={errors}
        register={register}
        setValue={setValue}
        buttonText="Use Default"
        defaultValue="https://joincamino.notion.site/Selecting-a-Buddy-for-Managers-996d0c3fce8946d2a3fc0789ad75a54b"
      />
      <TextInputFieldWithSetValueButton
        {...ONBOARDING_PLAN_TEMPLATES_FIELD}
        errors={errors}
        register={register}
        setValue={setValue}
        buttonText="Use Default"
        defaultValue="https://www.notion.so/joincamino/Onboarding-Templates-d4b9f219ff124c4b8f9cd4339f33083f"
      />
      <TextInputField {...COMPANY_WIKI_FIELD} errors={errors} register={register} />
      <TextInputField {...COMPANY_HANDBOOK_FIELD} errors={errors} register={register} />
      <TextInputField {...COMPANY_GOALS_FIELD} errors={errors} register={register} />
      <TextInputField {...HRIS_FIELD} errors={errors} register={register} />
      <TextInputField {...BENEFITS_INFO_FIELD} errors={errors} register={register} />

      <HStack my={8} alignSelf="end" justify="end">
        <ProceedCancelButtons
          handleSubmit={handleSubmit((data) => mutate({ data, dirtyFields }, { onSuccess: () => { reset(); onClose(); } }))}
          onClose={onClose}
          proceedDisabled={!isDirty}
          proceedText="Update Resources"
          isLoading={isPending}
        />
      </HStack>
    </Stack>
  );
}
