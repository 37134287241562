import * as yup from "yup";
import { EmailRecipient } from "models/automation";
import { sharedSchema } from "../Shared/schema";
import { BODY_FIELD, SUBJECT_FIELD } from "./fields";

const content = yup.object({
  subject: yup.string().required().label(SUBJECT_FIELD.label),
  body: yup.string().required().label(BODY_FIELD.label),
});

export const schema = sharedSchema.concat(
  yup.object({
    additionalRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Additional Recipients"),
    ccRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Cc Recipients"),
    bccRecipients: yup.array().of(yup.mixed<EmailRecipient>()).optional().label("Bcc Recipients"),
    content,
  }),
);

export type Schema = yup.InferType<typeof schema>;
