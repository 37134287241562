import { Table } from "@tanstack/react-table";
import { Select } from "chakra-react-select";

interface SortOption {
  id: string;
  desc: boolean;
  label: string;
}

interface TableSortSelectProps<T> {
  table: Table<T> | null;
  sortOptions: SortOption[];
}

export default function TableSortSelect<T>({ table, sortOptions, ...rest }: TableSortSelectProps<T>) {
  if (!table) {
    return null;
  }

  const options = sortOptions.map((sortOption) => ({
    value: { id: sortOption.id, desc: sortOption.desc },
    label: sortOption.label,
  }));

  const defaultSort = table.getState()?.sorting;

  return (
    <Select
      onChange={(selectedOption) => {
        if (selectedOption) {
          table.setSorting([selectedOption.value]);
        }
      }}
      useBasicStyles
      menuPortalTarget={document.body}
      name="tableSortBy"
      placeholder="Sort By"
      options={options}
      defaultValue={options.find((opt) => opt.value === defaultSort[0])}
      colorScheme="blue"
      chakraStyles={{
        option: (provided) => ({
          ...provided,
          color: "fg.emphasized",
        }),
        valueContainer: (provided) => ({
          ...provided,
          className: "single-select",
        }),
        placeholder: (provided) => ({
          ...provided,
          color: "fg.emphasized",
        }),
        control: (provided) => ({
          ...provided,
          border: "1px solid var(--chakra-colors-border-default)",
          borderColor: "var(--chakra-colors-border-default)",
          width: "220px",
        }),
      }}
      {...rest}
    />
  );
}
