/* eslint-disable no-nested-ternary */
import { Box } from "@chakra-ui/react";
import { generatePath, useParams } from "react-router-dom";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { NetworkingError, isNetworkingError } from "services/ApiClient/networkingError";
import { API_ROUTES, PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { useEffect, useState } from "react";
import UnfoundResource from "components/EmptyComponents/UnfoundResource";
import UnknownError from "components/EmptyComponents/UnknownError";
import { ActionExtended } from "models/automation";
import PathActionCard from "components/Path/ActionCard/PathActionCard";
import { QUERY_KEYS } from "definitions/constants";
import { useNavbar } from "hooks/useNavbar";

export default function ActionPage() {
  const { id } = useParams<{ id: string }>();
  const [isNotFound, setIsNotFound] = useState(false);
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  const {
    data,
    isLoading,
    error: queryError,
  } = useQuery({
    queryKey: [QUERY_KEYS.action, id],
    queryFn: async ({ signal }) => apiClient.get<ActionExtended>(generatePath(API_ROUTES.actions.show, { id }), signal),
    retry: (failureCount, error) => {
      if (isNetworkingError(error) && error.status === 404) {
        return failureCount < 1;
      }
      return failureCount < 3;
    },
    refetchOnWindowFocus: !isNotFound,
    networkMode: "always",
  });

  useEffect(() => {
    if (data && !isLoading) {
      setBreadcrumbs([
        {
          name: "Paths",
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.paths.index),
          isCurrentPage: false,
        },
        {
          name: data.workflow?.name,
          toUrl: generatePath(PEOPLE_TEAM_ROUTES.paths.show, { id: data?.workflowId || "" }),
          isCurrentPage: false,
        },
        {
          name: data.actionable?.name,
          isCurrentPage: true,
        },
      ]);
      setPageTitle(data.actionable?.name);
    }
  }, [data, isLoading, setBreadcrumbs, setPageTitle]);

  useEffect(() => {
    // to stop automation refetches
    if ((queryError as NetworkingError)?.status === 404) {
      setIsNotFound(true);
    }
  }, [queryError]);

  return (
    <AppPage>
      {queryError ? (
        (queryError as NetworkingError)?.status === 404 ? (
          <UnfoundResource resourceId={id} resourceName="Action" />
        ) : (
          <UnknownError resourceId={id} resourceName="Action" />
        )
      ) : (
        <Box>{data && <PathActionCard isLoading={isLoading} workflowAction={data} />}</Box>
      )}
    </AppPage>
  );
}
