import { Button, ButtonProps } from "@chakra-ui/react";
import { LinkProps as ReactRouterLinkProps, Link as ReactRouterLink } from "react-router-dom";

type LinkButtonProps = Pick<ReactRouterLinkProps, "to"> & ButtonProps;

export default function LinkButton({ to, children, ...rest }: LinkButtonProps) {
  return (
    <ReactRouterLink to={to}>
      <Button cursor="pointer" {...rest}>
        {children}
      </Button>
    </ReactRouterLink>
  );
}
