import SelectableScheduleType, { ScheduleType } from "components/Selectable/SelectableScheduleType";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { useState } from "react";
import { Stack } from "@chakra-ui/react";
import ActionsByList from "./ActionsByList";
import ActionsByStage from "./ActionsByStage";

interface ScheduleProps {
  newHireJourney: ExtendedNewHireJourney;
  isLoading: boolean;
}

export default function Schedule({ newHireJourney, isLoading }: ScheduleProps) {
  const [scheduleType, setScheduleType] = useState<ScheduleType>("list");
  return (
    <Stack>
      <SelectableScheduleType scheduleType={scheduleType} setScheduleType={setScheduleType} />
      {scheduleType === "list" ? (
        <ActionsByList newHireJourney={newHireJourney} />
      ) : (
        <ActionsByStage
          newHireJourney={newHireJourney}
          isLoading={isLoading}
          showImmediateDate
        />
      )}
    </Stack>
  );
}
