import { Flex, Wrap, WrapItem } from "@chakra-ui/react";
import AppPage from "layouts/AppPage";
import { useQuery } from "@tanstack/react-query";
import apiClient from "services/ApiClient";
import { API_ROUTES } from "definitions/constants/routeConstants";
import PathMiniCard from "features/Path/PathMiniCard";
import { Path } from "models/automation";
import LoadingCard from "components/Card/LoadingCard";
import CreatePathButtonWithModal from "pages/PeopleTeam/PathsPage/CreatePathButtonWithModal";
import { QUERY_KEYS } from "definitions/constants";
import useQueryString from "hooks/useQueryString";
import { useEffect } from "react";
import { useNavbar } from "hooks/useNavbar";
import { NoPathsCreatedYetState } from "components/EmptyComponents/RandomEmptyState";
import PathTemplatesModalButton from "./PathTemplatesModalButton";

function PathCards({ data, isLoading }: { data: Path[] | undefined; isLoading: boolean }) {
  if (isLoading) return <LoadingCard />;

  if (data && data.length > 0) {
    return data.map((workflow) => (
      <WrapItem key={workflow.id}>
        <PathMiniCard workflow={workflow} minWidth="320px" maxWidth="320px" height="150px" />
      </WrapItem>
    ));
  }
}

export default function PathsPage() {
  const { queryString } = useQueryString({ sort: [{ sortBy: "updatedAt", direction: "desc" }] });
  const { data: workflows, isLoading: workflowsLoading } = useQuery<Path[]>({
    queryKey: [QUERY_KEYS.paths],
    queryFn: () => apiClient.get(`${API_ROUTES.paths.base}?${queryString}`),
  });
  const { setBreadcrumbs, setPageTitle } = useNavbar();

  useEffect(() => {
    setBreadcrumbs([
      {
        name: "Paths",
        isCurrentPage: true,
      },
    ]);
    setPageTitle("Paths");
  }, [setBreadcrumbs, setPageTitle]);

  return (
    <AppPage>
      <Flex gap="4" mb="6" mt="4">
        <CreatePathButtonWithModal />
        <PathTemplatesModalButton />
      </Flex>
      {!workflowsLoading && !workflows?.length ? (
        <Flex>
          <NoPathsCreatedYetState />
        </Flex>
      ) : (
        <Wrap pb={4} spacing="40px" mb={2}>
          <PathCards data={workflows} isLoading={workflowsLoading} />
        </Wrap>
      )}
    </AppPage>
  );
}
