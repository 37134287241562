/* eslint-disable */

import { Controller } from "@hotwired/stimulus";
import { createRoot } from "react-dom/client";
import App from "../App";

// Connects to data-controller="root"
export default class extends Controller {
  connect() {
    createRoot(this.element).render(<App />);
  }
}
