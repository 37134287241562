import { Icon, IconProps } from "@chakra-ui/react";

function CaminoMessage(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 36 36"
      fill="none"
      transform="translateY(2.5px)"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2.25H31.5V24.3409V29.25L26.7353 24.3409H5.5C4.94772 24.3409 4.5 23.8932 4.5 23.3409V2.25Z"
        fill="#BCECFD"
      />
      <path
        d="M4.5 20.25C4.5 21.0456 4.81607 21.8087 5.37868 22.3713C5.94129 22.9339 6.70435 23.25 7.5 23.25H25.5L31.5 29.25V5.25C31.5 4.45435 31.1839 3.69129 30.6213 3.12868C30.0587 2.56607 29.2956 2.25 28.5 2.25H7.5C6.70435 2.25 5.94129 2.56607 5.37868 3.12868C4.81607 3.69129 4.5 4.45435 4.5 5.25V20.25Z"
        stroke="#959393"
        strokeWidth="3px"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}
export default CaminoMessage;