import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link, Tooltip, Text, TextProps } from "@chakra-ui/react";

interface ExternalLinkProps extends TextProps {
  to: string;
  text: string;
  linkProps?: Record<string, unknown>;
}

export default function ExternalLink({ to, text, linkProps, ...rest }: ExternalLinkProps) {
  return (
    <Tooltip label={to} placement="top">
      <Text {...rest}>
        <Link href={to} isExternal {...linkProps}>
          {text}
          {" "}
          <ExternalLinkIcon mb="4px" ml="4px" />
        </Link>
      </Text>
    </Tooltip>
  );
}

ExternalLink.defaultProps = {
  linkProps: {},
};
