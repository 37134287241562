import { createColumnHelper } from "@tanstack/react-table";
import { ExtendedNewHireJourney } from "models/joinedModels";
import { get } from "lodash";
import { Link as RouterLink, generatePath } from "react-router-dom";
import { assignedActionPath, newHireJourneyPath, taskPath } from "helpers/url";
import { titleize, truncateEnd } from "helpers/string";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import {
  Card, Flex, FlexProps, Icon, LinkBox, LinkOverlay, Text,
} from "@chakra-ui/react";
import { isInPast, relativeDate } from "helpers/time";
import { Action } from "models/automation";
import { PEOPLE_TEAM_ROUTES } from "definitions/constants/routeConstants";
import { combinedActionTaskStatusConverter } from "./helpers";
import RowCheckbox from "./RowCheckbox";

interface OverviewHeaderProps extends FlexProps {
  column: ColumnDefinition;
}

function OverviewHeader({ column, ...rest }: OverviewHeaderProps) {
  return (
    <LinkBox as={Flex} maxWidth="40" width="40" direction="column" {...rest}>
      <Flex gap="1">
        <Text fontSize="xs">
          <LinkOverlay
            as={RouterLink}
            to={generatePath(PEOPLE_TEAM_ROUTES.actions.show, { id: column.actionId || "" })}
          >
            {titleize(column.actionType)}
          </LinkOverlay>
        </Text>
        <Text fontSize="2xs">➡️</Text>
        <Text fontSize="2xs">{titleize(column.readableTarget)}</Text>
      </Flex>
      <Text fontSize="2xs">{truncateEnd(column.shortName, 30)}</Text>
    </LinkBox>
  );
}

function NoActionsHeader({ ...rest }: FlexProps) {
  return (
    <Flex maxWidth="80" width="80" direction="column" align="center" {...rest}>
      <Text fontSize="xs">No Actions for Stage</Text>
    </Flex>
  );
}

interface OverviewActionCardProps extends FlexProps {
  action: AssignedAction;
}

function OverviewActionCard({ action, ...rest }: OverviewActionCardProps) {
  if (!action) {
    return null;
  }
  const {
    value, icon, iconColor, bgColor, fontColor,
  } = combinedActionTaskStatusConverter(action);
  return (
    <LinkBox as={Card} width="40" height="20" bg={bgColor} align="center" {...rest}>
      <Flex width="100%" maxWidth="32" height="100%" justify="center" align="center" gap="2" whiteSpace="normal">
        <Text fontSize="md" fontWeight="medium" color={fontColor || "var(--chakra-colors-chakra-body-text)"}>
          <LinkOverlay
            as={RouterLink}
            to={
              action.actionType === "task_notifier" && action?.task ? taskPath(action.task) : assignedActionPath(action)
            }
          >
            {value}
          </LinkOverlay>
        </Text>
        {icon && <Icon as={icon} boxSize="6" color={iconColor || "gray.500"} />}
      </Flex>
    </LinkBox>
  );
}

export interface StageInfo {
  name: string;
  id: string;
  columnDefinitions: ColumnDefinition[];
  position: number;
}

export interface ColumnDefinition {
  stageName: string;
  actionId: string;
  shortName: string;
  position: number;
  actionType: string;
  readableTarget: string;
  action: Action;
}

function getMeta(isLastColumn: boolean) {
  return isLastColumn
    ? {
      headerProps: { className: "divider-column" },
      cellProps: { className: "divider-column" },
    }
    : {};
}

function createColumnDefinition(column: ColumnDefinition, isLastColumn: boolean) {
  return {
    header: <OverviewHeader width="40" maxWidth="40" column={column} />,
    accessorFn: (originalRow) => get(originalRow?.actionsById, column.actionId),
    // cell: (row) => <OverviewActionCard className={stageByStartDate(row.getValue()?.startDate) === column.stageName ? "current-stage" : ""} action={row.getValue()} />,
    cell: (row) => <OverviewActionCard action={row.getValue()} />,
    id: column.actionId,
    meta: getMeta(isLastColumn),
    enableSorting: false,
  };
}

function createEmptyColumn(stageName: string) {
  return {
    header: <NoActionsHeader />,
    id: `empty-${stageName}`,
    meta: getMeta(true),
    enableSorting: false,
  };
}

interface StageDefinition {
  selectedStageId?: string;
  stage: StageInfo;
  columnFilterFn?: (columns: ColumnDefinition[]) => ColumnDefinition[];
}

function createStageDefinition({ selectedStageId, stage, columnFilterFn }: StageDefinition) {
  if (selectedStageId && selectedStageId !== "all" && selectedStageId !== stage.id) {
    return null;
  }
  const columns = (stage?.columnDefinitions || []).sort((a, b) => a.position - b.position);
  const filteredColumns = columnFilterFn ? columnFilterFn(columns) : columns;

  return {
    header: stage.name,
    id: stage.name,
    enableSorting: false,
    columns: filteredColumns.length
      ? filteredColumns.map((col, index, arr) => createColumnDefinition(col, index === arr.length - 1))
      : [createEmptyColumn(stage.name)],
    meta: {
      headerProps: {
        className: "divider-column",
        textAlign: "center",
        fontWeight: "medium",
        backgroundColor: "brand.400",
      },
    },
  };
}

interface BuildColumns {
  stages: StageInfo[];
  columnFilterFn?: (columns: ColumnDefinition[]) => ColumnDefinition[];
  stageFilterFn?: (stages: StageInfo[]) => StageInfo[];
  selectedStageId?: string;
}

export function buildColumns({ stages, columnFilterFn, stageFilterFn, selectedStageId }: BuildColumns) {
  const sortedStages = stageFilterFn
    ? stageFilterFn(stages.sort((a, b) => a.position - b.position))
    : stages.sort((a, b) => a.position - b.position);
  return sortedStages
    .map((stage) => createStageDefinition({ stage, columnFilterFn, selectedStageId }))
    .filter((n) => n);
}

const columnHelper = createColumnHelper<ExtendedNewHireJourney>();

export function makeColumns({ stages, columnFilterFn, stageFilterFn, selectedStageId }: BuildColumns) {
  return [
    {
      id: "select",
      header: ({ table }) => (
        <RowCheckbox isChecked={table.getIsAllRowsSelected()} onChange={table.getToggleAllRowsSelectedHandler()} />
      ),
      cell: ({ row }) => (
        <div className="px-1">
          <RowCheckbox isChecked={row.getIsSelected()} onChange={row.getToggleSelectedHandler()} />
        </div>
      ),
      minSize: 8,
      maxSize: 8,
    },
    columnHelper.accessor((row) => row, {
      id: "name",
      header: "Name",
      cell: (row) => (
        <RouterLink to={row.getValue() ? newHireJourneyPath(row.getValue()) : ""}>
          <Flex direction="column">
            <Text fontSize="sm">{truncateEnd(row.getValue()?.user?.fullName, 30)}</Text>
            <Text fontSize="xs" fontWeight="bold">
              {isInPast(row.getValue()?.startDate)
                ? `Started ${relativeDate(row.getValue()?.startDate)}`
                : `Starts ${relativeDate(row.getValue()?.startDate)}`}
            </Text>
          </Flex>
        </RouterLink>
      ),
      minSize: 40,
      maxSize: 40,
      size: 40,
      meta: {
        cellProps: {
          className: "divider-column",
        },
        headerProps: {
          className: "divider-column",
        },
      },
    }),
    ...buildColumns({
      stages,
      columnFilterFn,
      stageFilterFn,
      selectedStageId,
    }),
  ];
}
