import {
  Box, Card, CardProps, Flex, Skeleton,
} from "@chakra-ui/react";
import { PropsWithChildren } from "react";

interface IntegrationSectionProps extends PropsWithChildren, CardProps {
  title: string;
  isLoaded: boolean;
}

export default function IntegrationSection({
  isLoaded,
  title,
  children,
  ...rest
}: IntegrationSectionProps) {
  return (
    <Skeleton isLoaded={isLoaded}>
      <Card direction="column" p="25px 35px" maxW={1300} {...rest}>
        <Flex justifyContent="start" gap="10" w="100%" alignItems="start" p={5}>
          <Box opacity="70%" fontSize="2xl">
            {title}
          </Box>
        </Flex>
        <Flex justifyContent="start" width="100%" h="100%">
          {children}
        </Flex>
      </Card>
    </Skeleton>
  );
}
