import { createColumnHelper } from "@tanstack/react-table";
import CardTable, { ControlledCardTableProps } from "components/Table/CardTable/ControlledCardTable";
import { SystemStyleObject } from "@chakra-ui/react";
import { scheduledTriggerSort } from "helpers/trigger";
import { AssignedAction } from "models/automation/scheduledWorkflow";
import AssignedActionCard from "./AssignedActionCard";

const columnHelper = createColumnHelper<AssignedAction>();

const columnDefs = [
  columnHelper.accessor((row) => row, {
    header: "Type",
    accessorKey: "actionType",
  }),
  columnHelper.accessor((row) => row, {
    id: "target",
    header: "Recipient/Assignee",
  }),
  columnHelper.accessor((row) => row, {
    id: "trigger",
    header: "Trigger",
    sortingFn: (a, b) => scheduledTriggerSort(a.original.trigger, b.original.trigger),
  }),
  columnHelper.accessor((row) => row, {
    id: "referenceTrigger",
    header: "Reference Trigger",
  }),
  columnHelper.accessor((row) => row, {
    id: "status",
    header: "Status",
  }),
];

interface AssignedActionCardTableProps {
  data: AssignedAction[];
  initialPagination?: { pageIndex: number; pageSize: number };
  isLoading?: boolean;
  getCardProps?: () => object;
  setTable?: () => object;
  emptyStateComponent?: React.ReactNode;
  sx?: SystemStyleObject;
}

export default function AssignedActionCardTable({
  data,
  isLoading,
  setTable,
  getCardProps,
  emptyStateComponent,
  sx,
  ...rest
}: AssignedActionCardTableProps) {
  const tableProps: Pick<
  ControlledCardTableProps<AssignedAction>,
  "columns" | "initialPagination" | "defaultSort" | "emptyStateComponent" | "data"
  > = {
    columns: columnDefs,
    initialPagination: { pageSize: 20, pageIndex: 0 },
    defaultSort: [{ id: "trigger", desc: false }],
    emptyStateComponent,
    data,
  };

  return (
    <CardTable
      {...tableProps}
      isLoading={isLoading}
      cardComponent={AssignedActionCard}
      getCardProps={getCardProps}
      getTdProps={() => ({ py: "6px" })}
      setTable={setTable}
      sx={{
        "& tbody tr:first-of-type td:first-of-type": {
          paddingTop: "20px",
        },
        mt: "2px",
        ...sx,
      }}
      {...rest}
    />
  );
}

AssignedActionCardTable.defaultProps = {
  isLoading: false,
  initialPagination: undefined,
  getCardProps: () => ({}),
  setTable: () => ({}),
  emptyStateComponent: null,
  sx: {},
};
