import { Icon, IconProps } from '@chakra-ui/react';

export default function CaminoTask(props: IconProps) {
  return (
    <Icon
      viewBox="0 0 40 40"
      fill="none"
      transform="translateY(.5px)"
      {...props}
    >
      <circle cx="19.9998" cy="20.0008" r="11.7" fill="#EABDB8"/>
      <path d="M22.6998 20.1531C22.6998 21.6443 21.491 22.8531 19.9998 22.8531C18.5086 22.8531 17.2998 21.6443 17.2998 20.1531C17.2998 18.6619 18.5086 17.4531 19.9998 17.4531C21.491 17.4531 22.6998 18.6619 22.6998 20.1531Z" fill="white"/>
      <path d="M20 38C29.9411 38 38 29.9411 38 20C38 10.0589 29.9411 2 20 2C10.0589 2 2 10.0589 2 20C2 29.9411 10.0589 38 20 38Z" stroke="#959393" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 30.8C25.9647 30.8 30.8 25.9647 30.8 20C30.8 14.0353 25.9647 9.2 20 9.2C14.0353 9.2 9.2 14.0353 9.2 20C9.2 25.9647 14.0353 30.8 20 30.8Z" stroke="#959393" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20 23.6C21.9882 23.6 23.6 21.9882 23.6 20C23.6 18.0118 21.9882 16.4 20 16.4C18.0118 16.4 16.4 18.0118 16.4 20C16.4 21.9882 18.0118 23.6 20 23.6Z" stroke="#959393" strokeWidth="3px" strokeLinecap="round" strokeLinejoin="round"/>
    </Icon>
  );
}
