import { Checkbox } from "@chakra-ui/react";

interface RowCheckboxProps {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
}

export default function RowCheckbox({ isChecked, onChange, ...rest }: RowCheckboxProps) {
  return (
    <Checkbox size="lg" colorScheme="brand" isChecked={isChecked} onChange={(e) => onChange(e.target.checked)} {...rest} />
  );
};
