/* eslint-disable import/prefer-default-export */
import { IconProps } from "@chakra-ui/react";
import { ActionableType, BaseAssignedAction } from "models/automation";
import { BaseTask } from "models/task";
import { isTask } from "features/Task/helpers";
import CaminoEmail from "./CaminoEmail";
import CaminoTask from "./CaminoTask";
import CaminoMessage from "./CaminoMessage";

interface ActionIconStyle {
  icon: React.FC<IconProps>;
  iconStyles: object;
}

export const CaminoActionIcons: Record<ActionableType, ActionIconStyle> = {
  task: { icon: CaminoTask, iconStyles: { mt: "2.5px" } },
  email: { icon: CaminoEmail, iconStyles: { mt: "2.5px" } },
  chat: { icon: CaminoMessage, iconStyles: { mt: "2px" } },
};

export function actionIcon(action: BaseAssignedAction | BaseTask) {
  if (isTask(action)) {
    return CaminoActionIcons["task"];
  }
  return CaminoActionIcons[(action as BaseAssignedAction).actionType as ActionableType];
}
