/* eslint-disable react/no-array-index-key */
import {
  Box, Flex, Image, Stack, Divider, Text,
} from "@chakra-ui/react";
import { useLocation, Link } from "react-router-dom";
import { Fragment, PropsWithChildren, useEffect } from "react";
import sideBarItemsByRole from "definitions/sidebarDefinitions";
import ROLE from "models/role";
import { roleFromPath, roleInOrder } from "utils/role";
import Logo from "assets/CaminoLogo.svg";
import useCaminoStore from "hooks/useCaminoStore";
import useFeatureFlagStore from "hooks/useFeatureFlagStore";
import { ADMIN_ROUTES, API_ROUTES, SHARED_ROUTES } from "definitions/constants/routeConstants";
import { BiCog } from "react-icons/bi";
import { MdAdminPanelSettings } from "react-icons/md";
import { QUERY_KEYS } from "definitions/constants";
import apiClient from "services/ApiClient";
import { useQuery } from "@tanstack/react-query";
import { removeLastPath } from "helpers/url";
import SidebarNavButton from "./SidebarNavButton";
import SidebarSubNavButton from "./SidebarSubNavButton";
import MakeAWishButton from "./MakeAWishButton";

interface SidebarHeaderSectionProps extends PropsWithChildren {
  header?: string;
}

function SidebarSection({ children, header = undefined }: SidebarHeaderSectionProps) {
  return header ? (
    <Stack>
      <Text textStyle="sm" color="fg.subtle" fontWeight="medium">
        {header}
      </Text>
      <Stack spacing="1">{children}</Stack>
    </Stack>
  ) : (
    <Stack spacing="1">{children}</Stack>
  );
}

export default function Sidebar() {
  const location = useLocation();
  const [currentRole, user, setCurrentRole] = useCaminoStore((state) => [
    state.currentRole,
    state.currentUser,
    (arg0: ROLE) => state.setValue({ currentRole: arg0 }),
  ]);

  const [showSettings] = useFeatureFlagStore((state) => [state.settings]);
  // TODO - THIS IS TEMP - We should fix once we have a better idea of 'currentRole' and it being atomic / valid / up to date
  const computedRole = roleFromPath(location.pathname) || currentRole || roleInOrder(user);

  const { data: issuesCount } = useQuery<{ pastCount: number; futureCount: number }>({
    queryKey: [QUERY_KEYS.assignedActions, "issues", "count"],
    queryFn: async () => apiClient.get(API_ROUTES.assignedActions.issues.count),
    enabled: !!computedRole && computedRole === ROLE.PEOPLE_TEAM,
  });

  const { data: pendingNewHiresCount } = useQuery<{ count: number }>({
    queryKey: [QUERY_KEYS.newHireJourneysPending, "count"],
    queryFn: async () => apiClient.get(API_ROUTES.newHireJourneys.pending.count),
    enabled: !!computedRole && computedRole === ROLE.PEOPLE_TEAM,
  });

  const { data: tasksCount } = useQuery<{ count: number }>({
    queryKey: [QUERY_KEYS.tasks, "count"],
    queryFn: async () => apiClient.get(API_ROUTES.tasks.count),
  });

  const notificationCounts: { [key: string]: number | undefined } = {
    Issues: issuesCount?.pastCount,
    Failed: issuesCount?.pastCount,
    "Upcoming Failures": issuesCount?.futureCount,
    "Pending New Hires": pendingNewHiresCount?.count,
    "My Tasks": tasksCount?.count,
  };

  useEffect(() => {
    if (computedRole !== currentRole) setCurrentRole(computedRole);
  }, [computedRole, currentRole, setCurrentRole]);

  if (!computedRole || !user) {
    console.log(`ERROR: SIDEBAR WAS UNABLE TO FIND USER/ROLE`);
    return null;
  }

  const sidebarItems = sideBarItemsByRole(currentRole || ROLE.BASIC);

  return (
    <Flex as="section" minH="100vh" bg="bg.canvas" borderRight="1px" borderRightColor="gray.200">
      <Flex
        flex="1"
        bg="bg.surface"
        boxShadow="sm"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
      >
        <Stack justify="space-between" spacing="1" width="full">
          <Stack spacing="2" shouldWrapChildren>
            <Box mb="6">
              <Link to={SHARED_ROUTES.home}>
                <Image mx={{ base: 6 }} maxWidth="160px" src={Logo} />
              </Link>
            </Box>
            <Box>
              {sidebarItems.map(({ header, items, id }) => (
                <Fragment key={`fragment-${id}`}>
                  <Divider key={`divider-${id}`} my="12px" />
                  <SidebarSection key={`section-${id}`} header={header}>
                    {null}
                    {(items || []).map(({
                      name, route, icon, role, subItems,
                    }) => (
                      <Fragment key={`section-fragment-${id}-${name}`}>
                        <SidebarNavButton
                          route={route}
                          key={`navbutton-${id}-${role}-${name}`}
                          label={name}
                          icon={icon}
                          {...(["Issues", "Pending New Hires", "My Tasks"].includes(name)
                            && !location.pathname.includes("/people-team/issues")
                            ? { notifications: notificationCounts[name] }
                            : {})}
                        />
                        {location.pathname.includes(removeLastPath(route)) && subItems ? (
                          <>
                            {(subItems || []).map(({ name: subName, route: subRoute }) => (
                              <SidebarSubNavButton
                                route={subRoute}
                                key={`${name}-${subName}`}
                                label={subName}
                                {...(["Failed", "Upcoming Failures"].includes(subName)
                                  ? { notifications: notificationCounts[subName] }
                                  : {})}
                              />
                            ))}
                          </>
                        ) : null}
                      </Fragment>
                    ))}
                  </SidebarSection>
                </Fragment>
              ))}
            </Box>
          </Stack>
          {user.companyId === process.env.CAMINO_PREFIX_ID && (
            <Stack spacing="1">
              <SidebarNavButton subtle route={ADMIN_ROUTES.hub} icon={MdAdminPanelSettings} label="Admin" />
            </Stack>
          )}
          {showSettings && (
            <Stack spacing="1">
              <SidebarNavButton subtle route={SHARED_ROUTES.settings} icon={BiCog} label="Settings" />
            </Stack>
          )}
          <Stack gap="1">
            <MakeAWishButton />
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
}
