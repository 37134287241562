import {
  Box, Text, VStack, StackDivider, useColorModeValue,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "definitions/constants";
import { API_ROUTES } from "definitions/constants/routeConstants";
import useQueryString from "hooks/useQueryString";
import { ActionableType } from "models/automation";
import { LegacyTargetType } from "models/record_target";
import { ReactNode, useState } from "react";
import apiClient from "services/ApiClient";

type PersonalizationToken = {
  id: string;
  name: string;
  content: string;
  sgid: string;
};

function TokenItem({
  onClick,
  isSelected,
  children,
}: {
  onClick: () => void;
  isSelected: boolean;
  children: ReactNode;
}) {
  const backgroundColor = useColorModeValue("gray.100", "gray.800");
  return (
    <Text
      p={1}
      cursor="pointer"
      onClick={onClick}
      _hover={{ backgroundColor }}
      borderRadius="md"
      backgroundColor={isSelected ? backgroundColor : undefined}
    >
      {children}
    </Text>
  );
}

export default function PersonalizationTokenMenu({
  actionType,
  targetType,
  onSelect,
}: {
  actionType: ActionableType;
  targetType: LegacyTargetType | undefined;
  onSelect: (token: PersonalizationToken) => void;
}) {
  const dividerColor = useColorModeValue("gray.200", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.800");
  const [selectedNamespace, setSelectedNamespace] = useState<string>();
  const { queryString } = useQueryString(undefined, { actionType, targetType });
  const { data } = useQuery<PersonalizationToken[]>({
    queryKey: [QUERY_KEYS.templateVariables, actionType, targetType],
    queryFn: () => apiClient.get(`${API_ROUTES.templateVariables.base}?${queryString}`),
    initialData: [],
    refetchOnWindowFocus: false,
  });
  const namespaces = data.map((token) => token.name.split(" - ")[0]);
  const uniqueNamespaces = Array.from(new Set(namespaces)).sort();
  const tokensByNamespace = data.reduce(
    (acc, token) => {
      const [namespace] = token.name.split(" - ");
      if (!acc[namespace]) {
        acc[namespace] = [];
      }
      acc[namespace].push(token);
      return acc;
    },
    {} as Record<string, PersonalizationToken[]>,
  );

  /* eslint-disable react/no-array-index-key */
  return (
    <Box display="flex" height="200px" minWidth="400px">
      <Box width="200px" px={1} borderRight="1px solid" borderColor={borderColor} overflowY="auto">
        <VStack align="stretch" spacing="0" divider={<StackDivider borderColor={dividerColor} mx="6px !important" />}>
          {uniqueNamespaces.map((namespace, index) => (
            <TokenItem
              key={index}
              onClick={() => setSelectedNamespace(namespace)}
              isSelected={namespace === selectedNamespace}
            >
              {namespace}
            </TokenItem>
          ))}
        </VStack>
      </Box>
      <Box width="200px" pl={2} pr={1} flex="1" overflowY="auto">
        {selectedNamespace ? (
          <VStack align="stretch" spacing="0" divider={<StackDivider borderColor={dividerColor} mx="6px !important" />}>
            {tokensByNamespace[selectedNamespace]?.map((token) => (
              <TokenItem key={token.id} onClick={() => onSelect(token)} isSelected={false}>
                {token.name.split(" - ").slice(1).join(" - ")}
              </TokenItem>
            ))}
          </VStack>
        ) : (
          <Text>Select from the left</Text>
        )}
      </Box>
    </Box>
  );
  /* eslint-enable react/no-array-index-key */
}
