import {
  ButtonGroup, Card, CardBody, CardFooter, Flex, Heading, Skeleton,
} from "@chakra-ui/react";
import { ActionableType, Path } from "models/automation";
import AssignPathButtonWithModal from "components/ModalForm/AssignPathForm/AssignPathButtonWithModalForm";
import ModalButton from "components/ModalButton";
import ActionTypeSelector from "components/Forms/PathActions/ActionTypeSelector";
import { useModal } from "hooks/useModal";
import { useCallback, useEffect, useState } from "react";
import { TitleCardAttribute } from "components/Card/TitleCard";
import PathMenuButton from "features/Path/PathMenuButton";
import { GoChecklist, GoCommentDiscussion, GoMail } from "react-icons/go";
import { BUSINESS_ICONS } from "definitions/constants/icons";

interface PathInfoCardProps {
  workflow: Path;
  isLoading?: boolean;
}

export default function PathInfoCard({ workflow, isLoading }: PathInfoCardProps) {
  const countWhereActionType = (actionType: ActionableType) => (workflow?.actions || []).reduce((acc, action) => acc + (action.actionable.actionType === actionType ? 1 : 0), 0);
  const totalTasks = countWhereActionType("task");
  const totalMessages = countWhereActionType("chat");
  const totalEmails = countWhereActionType("email");
  const [modalContents, setModalContents] = useState<JSX.Element>();
  const [modalTitle, setModalTitle] = useState<string>("");
  const workflowId = workflow!.id;

  const modal = useModal({ id: "actionTypeSelector", header: { title: modalTitle }, size: "3xl" });

  const resetModalContents = useCallback(() => {
    setModalTitle("Select Action Type");
    setModalContents(
      <ActionTypeSelector
        workflowId={workflowId}
        handleSelect={({ title, component }) => {
          setModalTitle(title);
          setModalContents(component);
        }}
        onSuccess={modal.onClose}
      />,
    );
  }, [workflowId, modal.onClose]);

  useEffect(() => {
    resetModalContents();
  }, [modal.isOpen, resetModalContents]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => resetModalContents(), []);

  return (
    <Card>
      <Skeleton isLoaded={!isLoading}>
        <CardBody>
          <Flex justify="space-between">
            <Heading size="md">{workflow?.name}</Heading>
            <PathMenuButton path={workflow} />
          </Flex>
          <Flex direction="row" gap="12" ml="4" mt="12">
            <TitleCardAttribute
              icon={BUSINESS_ICONS.ASSIGNMENT_TYPE}
              label="Assignment Type"
              value={workflow?.autoAdd ? "Auto-Assign to All New Hires" : "Manually Assign"}
            />
            <TitleCardAttribute
              icon={BUSINESS_ICONS.TOTAL_ACTIONS}
              label="Total Actions"
              value={workflow?.actions?.length?.toString() || "0"}
            />
            {!!totalTasks && <TitleCardAttribute icon={GoChecklist} label="Tasks" value={totalTasks.toString()} />}
            {!!totalMessages && (
              <TitleCardAttribute icon={GoCommentDiscussion} label="Messages" value={totalMessages.toString()} />
            )}
            {!!totalEmails && <TitleCardAttribute icon={GoMail} label="Emails" value={totalEmails.toString()} />}
          </Flex>
        </CardBody>
        <CardFooter>
          <ButtonGroup spacing="4">
            <ModalButton modal={modal} title="Create Action">
              {modalContents}
            </ModalButton>
            <AssignPathButtonWithModal workflow={workflow} />
          </ButtonGroup>
        </CardFooter>
      </Skeleton>
    </Card>
  );
}

PathInfoCard.defaultProps = {
  isLoading: false,
};
