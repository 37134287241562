import { Button } from "@chakra-ui/react";
import { PropsWithChildren } from "react";
import SimpleAdminEmployeeForm from "components/Forms/SimpleAdminEmployeeForm";
import { useQueryClient } from "@tanstack/react-query";
import ModalFormWrapper from "./ModalFormWrapper";

interface NewAdminEmployeeButtonWithModalProps extends PropsWithChildren {
  queryKey: string[];
}

export default function NewAdminEmployeeButtonWithModal({ children, queryKey }: NewAdminEmployeeButtonWithModalProps) {
  const queryClient = useQueryClient();
  return (
    <ModalFormWrapper modalHeader="Add New Employee" button={(children as JSX.Element) || <Button>Add New Employee</Button>}>
      <SimpleAdminEmployeeForm onSuccess={() => queryClient.invalidateQueries({ queryKey })} />
    </ModalFormWrapper>
  );
}
