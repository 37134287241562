import {
  Box,
  BoxProps,
  Card,
  CardBody,
  CardProps,
  Flex,
  Heading,
  Icon,
  Skeleton,
  SkeletonCircle,
} from "@chakra-ui/react";
import { InfoFieldOrSpacer } from "components/DataDisplay/InfoField";
import { PropsWithChildren } from "react";
import { IconType } from "react-icons";
import { Link as ReactRouterLink } from "react-router-dom";

interface TitleCardAttributeProps {
  icon?: IconType;
  label: string;
  value: string;
  boxSize?: string;
  padding?: Pick<BoxProps, "padding">;
}

export function TitleCardSkeleton() {
  return (
    <Flex h="40px" w="130px">
      <SkeletonCircle size="10" />
      <Skeleton ml="4" noOfLines={4} skeletonHeight="2" />
    </Flex>
  );
}

export function TitleCardAttribute({
  icon, label, value, boxSize, padding, ...rest
}: TitleCardAttributeProps) {
  return (
    <Flex direction="row" align="center" gap="3" {...rest}>
      <Box
        borderRadius="full"
        bgColor="brand.200"
        {...(padding ? { padding } : {})}
        color="brand.700"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <Icon as={icon} boxSize={boxSize} color="brand.700" />
      </Box>
      <InfoFieldOrSpacer fieldInfo={{ label, value }} />
    </Flex>
  );
}

TitleCardAttribute.defaultProps = {
  icon: null,
  boxSize: "4",
  padding: 2,
};

export function LinkTitleCardAttribute({
  icon, label, value, to, ...rest
}: TitleCardAttributeProps & { to: string }) {
  return (
    <ReactRouterLink to={to}>
      <TitleCardAttribute icon={icon} label={label} value={value} {...rest} />
    </ReactRouterLink>
  );
}

LinkTitleCardAttribute.defaultProps = {
  icon: null,
  boxSize: "4",
  padding: 2,
};

interface TitleCardProps extends PropsWithChildren, CardProps {
  titleText?: string;
}

export default function TitleCard({ titleText, children, ...rest }: TitleCardProps) {
  return (
    <Card width="100%" {...rest}>
      <CardBody>
        <Flex direction="row">
          {titleText && (
            <Heading color="fg.muted" size="xs">
              {titleText}
            </Heading>
          )}
          <Flex direction="row" gap="12" ml="4">
            {children}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
}

TitleCard.defaultProps = {
  titleText: null,
};
