import { CenterProps, useColorMode } from "@chakra-ui/react";
import ExampleImage from ".";

interface TaskExampleImageProps extends CenterProps {
  taskType: string;
}

export default function TaskExampleImage({ taskType, ...rest }: TaskExampleImageProps) {
  const { colorMode } = useColorMode();
  const imgSrc = `https://camino-public-production.s3.amazonaws.com/task_${taskType}~${colorMode}.png`;

  return <ExampleImage imageSource={imgSrc} {...rest} />;
}
