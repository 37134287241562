import { Text, TextProps } from "@chakra-ui/react";

interface TextErrorProps extends TextProps {}

export default function ErrorText({ children, ...props }: TextErrorProps) {
  return (
    <Text {...props} color="error.DEFAULT" lineHeight="normal" fontSize="xs">
      {children}
    </Text>
  );
}
