import * as yup from "yup";

const NAME_FIELD = {
  type: "text",
  label: "Name",
  name: "name",
  autoComplete: "off",
};

const SLUG_FIELD = {
  type: "text",
  label: "Slug",
  name: "slug",
};

const DOMAIN_FIELD = {
  type: "text",
  label: "Domain",
  helperText: "Email domain (no scheme)",
  name: "domain",
};

const WEBSITE_URL_FIELD = {
  type: "text",
  label: "Website Url",
  name: "websiteUrl",
};

const LOGO_URL_FIELD = {
  type: "text",
  label: "Logo Url",
  name: "logoUrl",
};

const NEW_HIRE_INFO_PACKET_URL_FIELD = {
  label: "New Hire Info Packet Url",
  name: "newHireInfoPacketUrl",
};

const BENEFITS_INFO_URL_FIELD = {
  label: "Benefits Info Url",
  name: "benefitsInfoUrl",
};

const HRIS_URL_FIELD = {
  label: "HRIS Url",
  name: "hrisUrl",
};

const COMPANY_HANDBOOK_URL_FIELD = {
  label: "Company Handbook Url",
  name: "companyHandbookUrl",
};

const COMPANY_WIKI_URL_FIELD = {
  label: "Company Wiki Url",
  name: "companyWikiUrl",
};

const COMPANY_GOALS_URL_FIELD = {
  label: "Company Goals Url",
  name: "companyGoalsUrl",
};

const ACRONYM_REPOSITORY_URL_FIELD = {
  label: "Acronym Repository Url",
  name: "acronymRepositoryUrl",
};

const BUDDY_EXPECTATIONS_URL_FIELD = {
  label: "Buddy Expectations Url",
  name: "buddyExpectationsUrl",
};

const BUDDY_SELECTION_URL_FIELD = {
  label: "Buddy Selection Url",
  name: "buddySelectionUrl",
};

const ONBOARDING_PLAN_TEMPLATES_URL_FIELD = {
  label: "Onboarding Plan Templates Url",
  name: "onboardingPlanTemplatesUrl",
};

const CUSTOM_EMAIL_DEFAULT_FROM_FIELD = {
  label: "Custom Email Default From",
  name: "customEmailDefaultFrom",
};

const CUSTOM_EMAIL_DEFAULT_REPLY_TO_FIELD = {
  label: "Custom Email Default Reply To",
  name: "customEmailDefaultReplyTo",
};

export {
  NAME_FIELD,
  SLUG_FIELD,
  DOMAIN_FIELD,
  WEBSITE_URL_FIELD,
  LOGO_URL_FIELD,
  NEW_HIRE_INFO_PACKET_URL_FIELD,
  BENEFITS_INFO_URL_FIELD,
  HRIS_URL_FIELD,
  COMPANY_HANDBOOK_URL_FIELD,
  COMPANY_WIKI_URL_FIELD,
  COMPANY_GOALS_URL_FIELD,
  ACRONYM_REPOSITORY_URL_FIELD,
  BUDDY_EXPECTATIONS_URL_FIELD,
  BUDDY_SELECTION_URL_FIELD,
  ONBOARDING_PLAN_TEMPLATES_URL_FIELD,
  CUSTOM_EMAIL_DEFAULT_FROM_FIELD,
  CUSTOM_EMAIL_DEFAULT_REPLY_TO_FIELD,
};

const domainRegex = /^(?!https?:\/\/)([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

export const schema = yup.object({
  name: yup.string().required().label(NAME_FIELD.label),
  slug: yup.string().required().label(SLUG_FIELD.label),
  domain: yup
    .string()
    .matches(domainRegex, "Please enter a valid domain without the scheme")
    .required()
    .label(DOMAIN_FIELD.label),
  websiteUrl: yup.string().url().required().label(WEBSITE_URL_FIELD.label),
  logoUrl: yup.string().url().notRequired().label(LOGO_URL_FIELD.label),
  newHireInfoPacketUrl: yup.string().url().notRequired().label(NEW_HIRE_INFO_PACKET_URL_FIELD.label),
  benefitsInfoUrl: yup.string().url().notRequired().label(BENEFITS_INFO_URL_FIELD.label),
  hrisUrl: yup.string().url().notRequired().label(HRIS_URL_FIELD.label),
  companyHandbookUrl: yup.string().url().notRequired().label(COMPANY_HANDBOOK_URL_FIELD.label),
  companyWikiUrl: yup.string().url().notRequired().label(COMPANY_WIKI_URL_FIELD.label),
  companyGoalsUrl: yup.string().url().notRequired().label(COMPANY_GOALS_URL_FIELD.label),
  acronymRepositoryUrl: yup.string().url().notRequired().label(ACRONYM_REPOSITORY_URL_FIELD.label),
  buddyExpectationsUrl: yup.string().url().notRequired().label(BUDDY_EXPECTATIONS_URL_FIELD.label),
  buddySelectionUrl: yup.string().url().notRequired().label(BUDDY_SELECTION_URL_FIELD.label),
  onboardingPlanTemplatesUrl: yup.string().url().notRequired().label(ONBOARDING_PLAN_TEMPLATES_URL_FIELD.label),
  customEmailDefaultFrom: yup.string().email().notRequired().label(CUSTOM_EMAIL_DEFAULT_FROM_FIELD.label),
  customEmailDefaultReplyTo: yup.string().email().notRequired().label(CUSTOM_EMAIL_DEFAULT_REPLY_TO_FIELD.label),
});

export type Schema = yup.InferType<typeof schema>;
