export const FIRST_NAME_FIELD = {
  type: "text",
  label: "First Name",
  name: "firstName",
};
export const LAST_NAME_FIELD = {
  type: "text",
  label: "Last Name",
  name: "lastName",
};
export const TITLE_FIELD = {
  type: "text",
  label: "Job Title",
  name: "title",
};
export const START_DATE_FIELD = {
  type: "date",
  label: "Start Date",
  name: "startDate",
};
export const OFFER_SIGN_DATE_FIELD = {
  type: "date",
  label: "Offer Sign Date",
  name: "offerSignDate",
};
export const LINKEDIN_URL_FIELD = {
  label: "LinkedIn Url",
  name: "linkedinUrl",
};
export const MANAGER_FIELD = {
  type: "SELECT",
  label: "Manager",
  name: "managerId",
};
export const BUDDY_FIELD = {
  type: "SELECT",
  label: "Buddy",
  name: "buddyId",
  helperText: "Typically assigned by manager. Can add now if known",
};
export const PEOPLE_TEAM_CONTACT_FIELD = {
  type: "SELECT",
  label: "People Team Contact",
  name: "peopleTeamContactId",
};
export const PERSONAL_EMAIL_FIELD = {
  label: "Personal Email",
  name: "personalEmail",
  type: "email",
  textTransform: "lowercase",
};
export const WORK_EMAIL_FIELD = {
  label: "Future Work Email",
  name: "workEmail",
  type: "email",
  textTransform: "lowercase",
};
export const LOCATION_FIELD = {
  type: "text",
  label: "Location",
  name: "location",
  helperText: "Location of where the new hire will be during onboarding",
};
export const TIMEZONE_FIELD = {
  type: "timezone",
  label: "Timezone",
  name: "timezone",
  helperText: "Timezone of where the new hire will be during onboarding",
};
export const WORKFLOW_SELECT_FIELD = {
  type: "text",
  label: "Select Paths",
  name: "workflowIds",
};
