/* eslint-disable import/prefer-default-export */
import moment from "moment";

export function statusDateTime(value: Date | undefined) {
  // Will display date/time like: "9/22 or Yesterday or at 12:47 pm"
  if (!value) {
    return "";
  }
  if (moment(value).isBetween(moment().startOf("day"), moment().endOf("day"))) {
    return moment(value).format("[at] h:mm a");
  }
  if (moment(value).isBetween(moment().subtract(1, "day").startOf("day"), moment().add(1, "day").endOf("day"))) {
    return moment(value).calendar(null, { lastDay: "[Yesterday]" });
  }
  return value ? moment(value).format("MMM D") : "";
}
