import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import {
  useEditableControls,
  ButtonGroup,
  IconButton,
  Editable,
  EditablePreview,
  Input,
  EditableInput,
  FlexProps,
  ButtonGroupProps,
  InputProps,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";

interface EditableControlsProps extends FlexProps, ButtonGroupProps {}

function EditableControls({ ...rest }: EditableControlsProps) {
  const { isEditing, getSubmitButtonProps, getCancelButtonProps } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" alignItems="center" size="xs" {...rest}>
      <IconButton icon={<CheckIcon />} {...getSubmitButtonProps()} />
      <IconButton icon={<CloseIcon />} {...getCancelButtonProps()} />
    </ButtonGroup>
  ) : null;
}

interface WidthEditableInputProps extends InputProps {
  controlledTitle: string;
}

function WidthEditableInput({ controlledTitle, ...rest }: WidthEditableInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { isEditing } = useEditableControls();

  useEffect(() => {
    if (inputRef.current && isEditing) {
      inputRef.current.style.width = "auto"; // Reset width to auto to measure content width
      inputRef.current.style.width = `${inputRef.current.scrollWidth}px`; // Set width to the measured scroll width
    }
  }, [isEditing, controlledTitle]);

  return <Input ref={inputRef} as={EditableInput} {...rest} />;
}

interface EditableTaskTitleFieldProps {
  title: string;
  onUpdate: ({ title }: { title: string }) => void;
}

export default function EditableTaskTitleField({ title, onUpdate }: EditableTaskTitleFieldProps) {
  const [controlledTitle, setControlledTitle] = useState(title);
  const [originalTitle, setOriginalTitle] = useState(title);

  const handleUpdate = () => {
    if (controlledTitle === originalTitle) return; // No need to update if the title hasn't changed
    setOriginalTitle(controlledTitle);
    setControlledTitle(controlledTitle);
    onUpdate({ title: controlledTitle });
  };

  const handleCancel = () => {
    setControlledTitle(originalTitle); // Revert to the original title
  };

  return (
    <Editable
      textAlign="center"
      value={controlledTitle}
      onChange={setControlledTitle}
      onSubmit={handleUpdate}
      onCancel={handleCancel}
      fontSize="2xl"
      submitOnBlur={false}
      width="fit-content"
      display="flex"
      flexDirection="row"
    >
      <EditablePreview width="fit-content" />
      <WidthEditableInput controlledTitle={controlledTitle} fontSize="xl" fontWeight="medium" />
      <EditableControls ml="4" />
    </Editable>
  );
}
